import React from 'react';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader
} from 'reactstrap';

const CustomerDetails = ({selectedItem, isOpen, hide}) => (         
            <Modal 
                isOpen={isOpen} 
                toggle={hide}
                size="xl" 
            >
                <ModalHeader>
                    { selectedItem ? 'ID#' + selectedItem.id + ' - ' +selectedItem.name : 'view details'}
                </ModalHeader>                
                <Card>                    
                    {
                        selectedItem ?
                            <ListGroup>            
                                <ListGroupItem href="#" action><span>Name</span><span>{selectedItem.name}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Phone</span><span>{selectedItem.phone}</span></ListGroupItem>                                
                                <ListGroupItem href="#" action><span>Coperate Company</span><span>{selectedItem.corporate_company_id}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Status</span><span>{selectedItem.inactive === 1 ? 'inactive' : 'active'}</span></ListGroupItem>
                            </ListGroup>
                            :
                            <div>something worng</div>
                    }                                        
                </Card>
            </Modal>
        ); 
export default CustomerDetails;