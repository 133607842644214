import * as actions from '../constants/actionTypes';

export function loadOperatorData(){
    return({
        type: actions.LOAD_OPERATOR_DATA
    })
}
export function addOperator(user, data){    
    return({
        type: actions.ADD_OPERATOR,
        payload: data,
        user: user
    })
}

export function updateOperator(user, id, data){
    return({
        type: actions.UPDATE_OPERATOR,
        payload: data,
        user: user,
        id: id
    })
}

export function clearUpdateOperator(){
    return({
        type: actions.CLEAR_EDIT_DATA
    })
}

export function loadCabFormData(data){
    return({
        type: actions.LOAD_CABS_FORM_DATA,
        payload: data
    })
}