import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router';
import history from '../utils/history';
import operatorReducer from './operatorReducer';
import user from './user';
import coporateReducer from './coporateReducer';
import uploadReducer from './uploadReducer';
import fetchingReducer from './fetchingReducer';
import customerReducer from './customerReducer';
import driverReducer from './driverReducer';
import managerReducer from './managerReducer';
import fuelTransactionReducer from './fuelTransactionReducer';
import fuelStationReducer from './fuelStationReducer';
import fuelPartnerReducer from './fuelPartnerReducer';
import errors from './errors';
import alerts from './alerts';
import wallet from './wallet';
import cabs from './cabs';
import agentTransactionReducer from './agentTransactionReducer';
import aircabOrderReducer from './aircabOrderReducer';
import agentReducer from './agentReducer';
import aircabMapLocationReducer from './aircabMapLocationReducer';
import corporateOrderReducer from './corporateOrderReducer';
import ferryCustomerReducer from './ferryReducer';
import driverHCMReducer from './driverHCMReducer'

/**
 * shopping cart reducer.
 */
const appState = combineReducers({
  operatorReducer: operatorReducer,
  coporateReducer : coporateReducer,
  uploadReducer : uploadReducer,
  fetchingReducer : fetchingReducer,
  customerReducer : customerReducer,
  driverReducer : driverReducer,
  managerReducer : managerReducer,
  fuelTransactionReducer : fuelTransactionReducer,
  fuelStationReducer : fuelStationReducer,
  fuelPartnerReducer : fuelPartnerReducer,
  agentTransactionReducer : agentTransactionReducer,
  aircabOrderReducer : aircabOrderReducer,
  form: formReducer,
  user: user,
  errors: errors,
  alerts: alerts,
  wallet: wallet,
  cabs: cabs,
  agentReducer : agentReducer,
  aircabMapLocationReducer : aircabMapLocationReducer,
  corporateOrderReducer : corporateOrderReducer,
  ferryCustomerReducer: ferryCustomerReducer,
  driverHCMReducer : driverHCMReducer, 
  router: connectRouter(history)
})

/**
 * Returns the combined reducer.
 * @param state
 * @param action
 */
export const rootReducer = (state, action) => {

  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }
  return appState(state, action)
}