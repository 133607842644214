import * as actions from '../constants/actionTypes';
export function requestAgentTransactions(user,page,limit){
  return ({
      type : actions.REQUEST_AGENT_TRANSACTION,
      page : page,
      user :user,
      limit : limit
  })
}
export function filterAgentTransactions(user,query,page,limit){
    return ({
        type : actions.FILTER_AGENT_TRANSACTION,
        page : page,
        user : user,
        query : query,
        limit : limit
    })
}

export function exportCSV(user,query){
    return({
        type : actions.EXPORT_AGENT_TRANSACTION_CSV,
        user : user,
        query : query
    })
}

export function receiveAgentTransactions(data,page,filtered = false){
    return ({
        type : actions.RECEIVE_AGENT_TRANSACTION,
        page : page,
        payload : data,
        filtered : filtered
    })
}