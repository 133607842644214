import * as actions from '../constants/actionTypes';

export function loadData(data){
    return({
        type: actions.LOAD_DATA,
        payload: data
    })
}   

export function loadDriverData(data){
    return({
        type: actions.LOAD_DRIVER_DATA,
        payload: data
    })
}
export function requestCoporateCompanines(user, page,limit){    
    return ({
        type : actions.REQUEST_COPORATE_DATA,
        user : user,
        page : page,
        limit : limit
    })
}
export function receiveCoporateCompanies(data, page){
     return ({
         type : actions.RECEIVE_COPORATE_DATA_RESULT,
         payload:data,
         page: page
     })
}

export function searchCorporateCompanies(user,page,params,limit){
    return ({
        type : actions.SEARCH_CORPORATE_DATA,
        user : user,
        params : params,
        page : page,
        limit : limit
    })
}

export function receiveSearchCorporateResults(data,page){
    return ({
        type : actions.RECEIVE_SEARCH_CORPORATE_DATA,
        payload : data,
        page : page
    })
}
export function loadCustomerData(data){
    return({
        type: actions.LOAD_CUSTOMER_DATA,
        payload: data
    })
}
export function pushError(err){
    return({
        type: actions.FOUND_FORM_ERROR,
        payload: err
    })
}
export function fetchingData(){
    return ({
        type : actions.FETCHING_DATA,
        payload : {is_fetching:false}
    })
}

export function fetchData(){
    return({
        type: actions.FETCH_DATA        
    })
}
export function completeFetching(){
    return({
        type : actions.COMPLETE_FETCHING
    })
}
export function loadCabData(data){
    return({
        type: actions.LOAD_CAB_ASSIGN_DATA,
        payload: data
    })
}

export function loadFuelStationData(data){
   
    return({
        type : actions.LOAD_FUEL_STATION_DATA,
        payload : data
    })
}

export function loadFuelPartnerData(data){
     return ({
         type : actions.LOAD_FUEL_PARTNER_DATA,
         payload : data
     })
}

export function loadAgentData(data){
    return ({
        type : actions.LOAD_AGENT_DATA,
        payload : data
    })
}