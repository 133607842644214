import * as types from '../constants/actionTypes';
/**
 * form reducers.
 * @param state
 * @param action
 * @returns {*}
 */

const INITIAL_STATE = {
    errors: []
};    

function errors(state = INITIAL_STATE , action) {
    switch (action.type) {
        case types.PUSH_ERROR:
            return Object.assign({}, state, {
                errors: action.payload
            });
        case types.CLEAR_ERROR:
            return Object.assign({}, state, {
                errors: []
            });        
        default:
          return state
      }       
}

export default errors;