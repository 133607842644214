import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FormInput from '../../components/FormInput';
import { loadData } from '../../actions/formActions';
import { requestFuelStationPartner, addNewFuelStation,updateFuelStation } from '../../actions/fuelStationAction';
import {
    Row, Col
} from 'reactstrap';
import {errorMessage} from '../../constants/constants';
import { Button } from 'antd';
import 'antd/dist/antd.css';


class FuelStationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
        
        }
    }
    componentDidMount() {
        const { user } = this.props;
        this.props.requestFuelStationPartner(user.user);
    }

    getKeyValue = (value) => !!value ? value : ' ';
    
    validateForm(fuel_station){
        let keys = Object.keys(fuel_station);
        let errors = {}
        if(fuel_station){
            for(var i=0; i<keys.length; i++){
                var key = keys[i];
               
                if(!fuel_station[key]){
                  if(errorMessage[key]){
                    errors = {...errors,[key]:errorMessage[key]};
                  }     
                
                }
            }
            if (Object.keys(errors).length === 0) {
                return false;
            } else {
                
                this.setState({errors});
               
                return true;
            }
        }else{
            this.setState({
                errors : errorMessage
            });
            return true;
        }
       

    }
    handleUpdate(){
        const { fuel_station, user } = this.props;
       const isError = this.validateForm(fuel_station.fuel_station.values);
       let data = fuel_station.fuel_station.values;
       if(!isError){
           let payload = {
               name :data.name,
               fuel_partner_id : data.fuel_partner_id,
               station_code : data.station_code,
               phone : data.phone,
               inactive : (data.status === "true")? 0 : 1,
               location : { 
                //    location : data.location.split(","),
                   latitude : Number(data.latitude),
                   longitude : Number(data.longitude),

               },
               prices : {
                   octane_92 : data.octane_92,
                   octane_95 : data.octane_95,
                   diesel : data.diesel,
                   premium_diesel : data.premium_diesel,    
               },
               address : data.address,
               username : data.username,
               password : data.manager_password,
               password: this.getKeyValue(data.manager_password),
               city : data.city
            
           }
        
           this.props.updateFuelStation(user.user,{fuel_station:payload},this.props.editItem.id);
           this.props.callbackHide(); 
       }

    }
    handleSubmit() {
        const { fuel_station, user } = this.props;
        if (fuel_station.fuel_station.values) {
            let errors = {};
            let payload = {};
            let location = {};
            let prices = {};
            if (fuel_station.fuel_station.values.name)
                payload = { ...payload, name: fuel_station.fuel_station.values.name }
            else
                errors = { ...errors, name: "Please enter fuel station name" }

            if (fuel_station.fuel_station.values.fuel_partner_id)
                payload = { ...payload, fuel_partner_id: fuel_station.fuel_station.values.fuel_partner_id }
            else
                errors = { ...errors, fuel_partner_id: "Please select fuel partner name" }

            if (fuel_station.fuel_station.values.station_code)
                payload = { ...payload, station_code: fuel_station.fuel_station.values.station_code }
            else
                errors = { ...errors, station_code: "Please enter fuel station code" }

            if (fuel_station.fuel_station.values.phone)
                payload = { ...payload, phone: fuel_station.fuel_station.values.phone }
            else
                errors = { ...errors, phone: "Please enter phone" }

            if (fuel_station.fuel_station.values.status)
                payload = { ...payload, inactive: (fuel_station.fuel_station.values.status === "true")? 0 : 1 }
            else
                errors = { ...errors, status: "Please select status" }

            if (fuel_station.fuel_station.values.address) {
                // location = { ...location, address: fuel_station.fuel_station.values.address }
                payload = { ...payload, address: fuel_station.fuel_station.values.address }
            }
            else
                errors = { ...errors, address: "Please enter fuel station address" }

            // if (fuel_station.fuel_station.values.location) {
            //     let latLng = fuel_station.fuel_station.values.location.split(',');
            //     location = { ...location, location: latLng }
            //     payload = { ...payload, location }
            // }

            // if (fuel_station.fuel_station.values.location) {
            //     location = {
            //         ...location,
            //         latitude: fuel_station.fuel_station.values.location.latitude,
            //         longitude: fuel_station.fuel_station.values.longitude

            //     }
            //     payload = { ...payload, location }
            // }

            if (fuel_station.fuel_station.values.latitude) {
                location = {
                    ...location,
                    latitude: Number(fuel_station.fuel_station.values.latitude)
                }
                payload = { ...payload, location }
            }
            if (fuel_station.fuel_station.values.longitude) {
                location = {
                    ...location,
                    longitude: Number(fuel_station.fuel_station.values.longitude)
                }
                payload = { ...payload, location }
            }

            if (fuel_station.fuel_station.values.octane_92) {
                prices = {
                    ...prices,
                    octane_92: fuel_station.fuel_station.values.octane_92
                }
                payload = { ...payload, prices }
            }

            else
                errors = { ...errors, location: " Please enter fuel station (lat, lng)" }

            if (fuel_station.fuel_station.values.octane_92) {
                prices = {
                    ...prices,
                    octane_92: fuel_station.fuel_station.values.octane_92
                }
                payload = { ...payload, prices }
            }

            else
                errors = { ...errors, octane_92: "Please enter octane 92 price" }

            if (fuel_station.fuel_station.values.octane_95) {
                prices = {
                    ...prices,
                    octane_95: fuel_station.fuel_station.values.octane_95
                }
                payload = { ...payload, prices }
            }

            else
                errors = { ...errors, octane_95: "Please enter octane 95 price" }


            if (fuel_station.fuel_station.values.diesel) {
                prices = { ...prices, diesel: fuel_station.fuel_station.values.diesel }
                payload = { ...payload, prices }
            }

            else
                errors = { ...errors, diesel: "Please enter diesel price" }


            if (fuel_station.fuel_station.values.premium_diesel) {
                prices = { ...prices, premium_diesel:fuel_station.fuel_station.values.premium_diesel }
                payload = { ...payload, prices }
            }

            else
                errors = { ...errors, premium_diesel: "Please enter premium diesel price" }

            if (fuel_station.fuel_station.values.username) {
                payload = { ...payload, username: fuel_station.fuel_station.values.username }
            }
            else
                errors = { ...errors, username: "Please enter username" }


            if (fuel_station.fuel_station.values.manager_password) {
                payload = { ...payload, password: fuel_station.fuel_station.values.manager_password }
            }
            else
                errors = { ...errors, password: "Please enter password" }

            if (fuel_station.fuel_station.values.city) {
                payload = { ...payload, city: fuel_station.fuel_station.values.city }
            }
            else
                errors = { ...errors, city: "Please enter city name" }

            if (Object.keys(errors).length === 0) {
                
                this.props.addNewFuelStation(user.user, {fuel_station:payload});
                this.props.callbackHide();
            } else {
                this.setState({ errors: errors })
            }


        }
        else {
            this.setState({
                errors: {
                    name: "Please enter fuel station name",
                    // fuel_partner_name: "Please select fuel partner name",
                    station_code: "Please enter fuel station code",
                    phone: "Please enter phone",
                    status: "Please select status",
                    octane_92: "Please enter octane 92 price",
                    octane_95: "Please enter octane 95 price",
                    diesel: "Please enter diesel price",
                    premium_diesel: "Please enter premium diesel price",
                    username: "Please enter username",
                    password: "Please enter password",
                    city: "Please enter city name"

                }
            })
        }
    }
    render() {
        return (
            <form className="form">
                <h5>Fuel Station</h5>
                <div className="form-border">
                    <FormInput
                        labelName="Name"
                        name="name"
                        placeholder="Enter Station Name"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.name) ? this.state.errors.name : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Partner Name"
                        name="fuel_partner_id"
                        placeholder="Select Partner Name"
                        component="select"
                        selectValue={this.props.fuelStationReducer.partners}
                        selectedValue={(this.props.editItem)?this.props.editItem.fuel_partner_id:null}
                        option={true}
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.fuel_partner_id) ? this.state.errors.fuel_partner_id : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Station Code"
                        name="station_code"
                        placeholder="Enter Station Code"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.station_code) ? this.state.errors.station_code : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Address"
                        name="address"
                        placeholder="Enter Address"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.address) ? this.state.errors.address : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Phone"
                        name="phone"
                        placeholder="Enter Phone"
                        component="input"
                        type="tel"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.phone) ? this.state.errors.phone : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Status"
                        name="status"
                        component="input"
                        checkbox={true}
                    />
                </div>

                <h5>Location</h5>
                <div className="form-border">
                    <FormInput
                        labelName="Latitude"
                        name="latitude"
                        placeholder="Enter Location"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.latitude ) ? this.state.errors.latitude : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Longitude"
                        name="longitude"
                        placeholder="Enter Location"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.longitude) ? this.state.errors.longitude : null}</span></Col>
                    </Row>
                </div>

                <h5>Prices</h5>
                <div className="form-border">
                    <FormInput
                        labelName="Octane 92 Price"
                        name="octane_92"
                        placeholder="Enter Octane 92 Price"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.octane_92) ? this.state.errors.octane_92 : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Octane 95 Price"
                        name="octane_95"
                        placeholder="Enter Octane 95 Price"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.octane_95) ? this.state.errors.location : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Diesel Price"
                        name="diesel"
                        placeholder="Enter Diesel Price"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.diesel) ? this.state.errors.diesel : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Premium Diesel"
                        name="premium_diesel"
                        placeholder="Enter Premium Diesel Price"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.premium_diesel) ? this.state.errors.premium_diesel : null}</span></Col>
                    </Row>
                </div>
                <h5>User</h5>
                
                <div className="form-border">
                    <FormInput
                        labelName="User Name"
                        name="username"
                        placeholder="Enter Username"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.username) ? this.state.errors.username : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Password"
                        name="manager_password"
                        placeholder="Enter Password"
                        component="input"
                        type="password"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.password) ? this.state.errors.password : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="City"
                        name="city"
                        placeholder="Enter City"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.city) ? this.state.errors.city : null}</span></Col>
                    </Row>
                </div>

                <div className="action-button">
                    {
                        this.props.editItem != null ?
                            <Button 
                            type="primary"
                            size='large'
                            disabled={this.props.pristine || this.props.submitting}
                            onClick={() => this.handleUpdate()}
                            >Submit</Button>
                            :
                            <Button  
                            type="primary" 
                            size='large'
                            disabled={this.props.pristine || this.props.submitting}
                            onClick={() => this.handleSubmit()}>Submit</Button>
                    }

                    <Button className="ml-2" size='large' onClick={() => this.props.callbackHide()}>Cancel</Button>
                 
                </div>



            </form>
        );
    }
}
FuelStationForm = reduxForm({
    form: 'fuel_station'
})(FuelStationForm);
const mapStateToProps = state => ({
    initialValues: state.operatorReducer.fuel_station,
    fuel_station: state.form,
    user: state.user,
    fuelStationReducer: state.fuelStationReducer
});

const mapDispatchToProps = dispatch => {
    return {
        loadData: (data) => dispatch(loadData(data)),
        requestFuelStationPartner: (user) => dispatch(requestFuelStationPartner(user)),
        addNewFuelStation: (data, user) => dispatch(addNewFuelStation(data, user)),
        updateFuelStation : (user,data,id)=> dispatch(updateFuelStation(user,data,id))
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(FuelStationForm);