import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestAircabOrder, receiveAircabOrder, filterAircabOrders, exportCSV, selectFilter, searchAircabOrder } from '../../actions/aircabOrderActions';
import { clearAlert } from '../../actions/alertActions';
import PaginationFooter from '../../components/PaginationFooter';
import ListingTable from '../../components/ListingTables';
import TableCaptions from '../../components/TableCaptions';

import { DatePicker, Input } from 'antd';
import 'antd/dist/antd.css';

import GoogleMap from '../../components/GoogleMap';
import AircabRouteModal from './AircabRouteModel';

import { pushError } from '../../actions/errorActions';
import {isEmpty} from 'ramda';
import './style.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    Label,
    CardBody,
    CardFooter
} from 'reactstrap'
import { Button } from 'antd'
const drown_arrow = require('../../assests/images/down-arrow.svg');
class AircabOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            query: {},
            searchTerm: '',
            stations: [],
            start: '',
            end: '',
            company_id: 0,
            station_query: '',
            showList: false,
            showEntries: 25,
            isOpenModel: false,
            currentRoute: null
        }
        this.handleChangeCalender = this.handleChangeCalender.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleExportCSV = this.handleExportCSV.bind(this);
        this.handleResetFilter=this.handleResetFilter.bind(this);
        
    }

    isFetchingData = () => this.state.isFetching;
    handleOpenModel = () => this.setState({ isOpenModel: true })
    handleHideModel = () => this.setState({ isOpenModel: false })

    loadByLimit(limit) {
        const { user, requestAircabOrder } = this.props;
        this.setState({ showEntries: limit });
        this.props.requestAircabOrder(user.user, 1, limit);
    }

    componentDidMount() {
        this.loadData();
    }

    handleResetFilter() {
       
        this.loadData();
    }

    loadData() {
        // if (this.props.aircabOrderReducer.transactionList.length === 0 || this.props.aircabOrderReducer.isUpdated) {
        const { user } = this.props;
        const page = 1;
        this.props.requestAircabOrder(user.user, page);
        // }
    }

    loadByPage(page) {
        const { user, aircabOrderReducer } = this.props;
        if (this.isEmpty(this.state.query))
            this.props.requestAircabOrder(user.user, page, aircabOrderReducer.limit);
        else
            this.props.filterAircabOrders(user.user, this.state.query, page);
    }

    search(params) {
       
        const { user, aircabOrderReducer } = this.props;
        this.props.searchAircabOrder(user.user, 1, params, aircabOrderReducer.limit);
    }

    loadNextPage() {
        const { user, aircabOrderReducer } = this.props;
        if (this.isEmpty(this.state.query))
            this.props.requestAircabOrder(user.user, aircabOrderReducer.page + 1, aircabOrderReducer.limit);
        else
            this.props.filterAircabOrders(user.user, this.state.query, aircabOrderReducer.page + 1, aircabOrderReducer.limit);
    }

    loadBackPage() {
        const { user, aircabOrderReducer } = this.props;
        if (this.isEmpty(this.state.query))
            this.props.requestAircabOrder(user.user, aircabOrderReducer.page - 1, aircabOrderReducer.limit);
        else
            this.props.filterAircabOrders(user.user, this.state.query, aircabOrderReducer.page - 1, aircabOrderReducer.limit);
    }

    handleFilter(e) {
        const { user } = this.props;
        const page = 1;

        if (isEmpty(this.state.query)) {
            const errors = {
                message: "Please Select at least One Filter"
            }

            this.props.pushError(errors);
        } else {
            this.props.filterAircabOrders(user.user, this.state.query, page);
        }
    }

    handleExportCSV() {
        const { user } = this.props;
        this.props.exportCSV(user.user, this.state.query);
    }
    
    handleChangeCalender(type, date, dateString) {
        let query = this.state.query;
        query[type] = dateString;
        this.setState({
            query,
            [type]: dateString
        });
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

 

    renderTable() {
        const aircarOrder = this.props.aircabOrderReducer;
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader className='page-header'>
                            <h4>AirCab Orders</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="filter-section">
                                <Row>
                                    <Col md={3}>
                                        <Label>Car Plate Number</Label>
                                        <Input
                                            type="text"
                                            name="cab_car_plate_number"
                                            placeholder="Car Plate Number"
                                            onChange={(e) => this.setState({ ...this.state, query: { ...this.state.query, cab_car_plate_number: e.target.value } })}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Label>Start Date</Label> <br/>
                                        <DatePicker
                                            onChange={(date, dateString) => this.handleChangeCalender('start', date, dateString)}
                                            style={{width: "100%",}}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Label>End Date</Label>
                                        <DatePicker
                                            onChange={(date, dateString) => this.handleChangeCalender('end', date, dateString)}
                                            style={{width: "100%",}}
                                        />
                                    </Col>
                                    <Col md={3} className="button-action" style={{ paddingTop: 30 }}>
                                        <Button className='ant-btn-success' size='large' onClick={(e) => this.handleFilter(e)}>
                                            Search
                                        </Button>

                                        <Button  type="danger" size='large' onClick={(e) => this.handleResetFilter(e)}>
                                            Reset
                                        </Button>
                                        <Button className='ant-btn-warning' size='large' onClick={this.handleExportCSV} >Export CSV</Button>
                                    </Col>
                                </Row>
                                {/* </Row>
                                    <div className="advacne-search">
                                        <a href="#" id="toggler" style={{ marginBottom: '1rem' }}>
                                        Advance Search  <img alt="drop down arrow" src={drown_arrow} />
                                        </a>
                                        <UncontrolledCollapse toggler="#toggler">
                                        
                                            <Row>
                                                <Col md={3}>
                                                    <Label>Start Date</Label>
                                                    <Input
                                                        name="start"
                                                        type="date"
                                                        onChange={this.handleChangeCalender}
                                                        placeholder="Choose Start Date" />
                                                </Col>
                                                <Col md={3}>
                                                    <Label>End Date</Label>
                                                    <Input
                                                        name="end"
                                                        type="date"
                                                        onChange={this.handleChangeCalender}
                                                        placeholder="Choose Start Date" />
                                                </Col>
                                            </Row>
                                            
                                        </UncontrolledCollapse>
                                    </div>
                                
                                <Row style={{ "margin": "5px" }}>
                                    <Col md={3} className="button-action">
                                        <Button type="submit" color="success" onClick={(e) => this.handleFilter(e)}>
                                            Search
                                        </Button>

                                        <Button  color="danger" onClick={(e) => this.handleResetFilter(e)}>
                                            Reset
                                        </Button>
                                    </Col>
                                </Row> */}
                            </div>
                            
                            {/* <Row>
                                <div className="aircab-status-information">
                                        <b>Status Type</b>
                                    <ul>

                                    <li><h5> Not Accepted</h5> Order is pending</li>
                                    <li><h5> Started </h5> Driver has accepted the order and trip has started/in progress</li>
                                    <li><h5> Finished </h5> Order is completed</li>
                                    <li><h5> Canceled </h5> Customers cancelled theirs requested orders</li>
                                    </ul>
                                </div>
                            </Row> */}
                            {/* <Row>
                                <div className="result-layout">
                                    {(this.props.aircabOrderReducer.meta) ?
                                        <h6>{`Results: ${this.props.aircabOrderReducer.transactionList.length}    of ${this.props.aircabOrderReducer.meta.total} transactions`}</h6>
                                        :
                                        <h6>{`Results : 0 transactions`}</h6>
                                    }
                                </div>
                            </Row> */}
                            
                            <div className="aircab-order"><TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['Car Plate No']}
                                search={(params) => this.search(params)}
                            /></div>
                            {
                                (this.props.aircabOrderReducer.isSearching) ?
                                    <div>Searching......</div>
                                    :
                                    <div></div>
                            }
                            <ListingTable
                                isAircabOrderTable={true}
                                headers={[
                                    'No',
                                    'ID',
                                    'Booking Type',
                                    'Driver Name',
                                    'Cab Phone',
                                    'Car Plate No',
                                    'Customer Phone',
                                    'Status',
                                    'Trip Distance',
                                    'Start Trip Time',
                                    'End Trip Time',
                                    'Amount [MMK]',
                                    'Start Location',
                                    'End Location',
                                    'Route'

                                ]}
                                listingData={aircarOrder.transactionList}
                                callbackHistoryView= {(params) =>this.handleSetModle(params)}
                                
                            />

                        </CardBody>
                        <CardFooter>
                            {
                                (this.props.aircabOrderReducer.meta) ?
                                    <PaginationFooter listTotal={this.props.aircabOrderReducer.meta.total}
                                        listItem={this.props.aircabOrderReducer.meta.per_page}

                                        current={this.props.aircabOrderReducer.page}
                                        callbackParent={(page) => this.loadByPage(page)}
                                        callbackNext={(page) => this.loadNextPage(page)}
                                        callbackBack={(page) => this.loadBackPage(page)} /> :
                                    <PaginationFooter />
                            }

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            
        )
    }
    handleSetModle = (currentRoute) => this.setState({isOpenModel: true, currentRoute: currentRoute.point})
    callbackHistoryView(){
        const {aircabOrderReducer } = this.props;
        return (
            <AircabRouteModal
                isOpen={this.state.isOpenModel}
                callbackHide={() => this.handleHideModel()}
                places={this.state.currentRoute}
                // callMapHistory= {this.callMapHistory()}
            />
        )

    }

    render() {
        return (
           <div className='content'>
                {this.renderTable()},
                {this.state.isOpenModel && this.callbackHistoryView()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    alerts: state.alerts,
    aircabOrderReducer: state.aircabOrderReducer
});

const mapDispatchToProps = dispatch => {
    return {
        requestAircabOrder: (user, page, limit = 25) => dispatch(requestAircabOrder(user, page, limit)),
        filterAircabOrders: (user, query, page, limit = 25) => dispatch(filterAircabOrders(user, query, page, limit)),
        searchAircabOrder: (user, page = 1, params, limit = 25) => dispatch(searchAircabOrder(user, page, params, limit)),
        exportCSV: (user, query) => dispatch(exportCSV(user, query)),
        clearAlert: () => dispatch(clearAlert()),
        pushError: (error) => dispatch(pushError(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AircabOrder);
