import React, { Component } from 'react';
import Spinner from '../../components/Spinner';
import ListingTables from '../../components/ListingTables';
import {requestDriverData,searchDriverData} from '../../actions/driverAction';
import {connect} from 'react-redux';
import './styles.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'
import DriverDetails from './DriverDetails';
import PaginationFooter from '../../components/PaginationFooter';
import TableTitleCard from '../../components/TableTitleCard';
import TableCaptions from '../../components/TableCaptions';
import DriverModal from './DriverModal';
import DriverAssignModel from './DriverAssignModal';
import {clearAlert} from'../../actions/alertActions';
import history from '../../utils/history';

class Drivers extends Component {
    constructor(props){
        super(props)
        this.state={
            driverLists: [],
            isFetching: true,
            editItem: null,
            isOpenForm: false,
            isOpenAssignForm : false,
            isOpenDetail: false,
            viewItem: null,
            showEntries: 25
        }
    }
    componentDidMount(){
        if(this.props.driverReducer.driverList.length ===0) {
            this.loadData();
        }

    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.alerts !== this.props.alerts && this.props.alerts.alerts!==null){
            setTimeout(() => {
                this.props.clearAlert()
            }, 3000);
        }
        if(prevProps.driverReducer.isUpdated !== this.props.driverReducer.isUpdated){
            this.loadData();
        }
    }
    search(params){
        const { user } = this.props;
        this.props.searchDriverData(user.user,1,params);
    }

    loadData(){
        const { user } = this.props;
        const page = 1
        if (user.user) {
            this.props.requestDriverData(user.user,page);
        }else {
            history.push('/sign_in');
        }
    }
    loadByPagy(page) {
        const { user } = this.props;
        this.props.requestDriverData(user.user, page, this.state.showEntries);
    }
    
    loadByLimit(limit){
        const { user } = this.props;
        this.setState({ showEntries: limit });
        this.props.requestDriverData(user.user,1,limit);
    }

    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({isOpenForm: true})
    handleHideForm = () => this.setState({isOpenForm: false, editItem: null})
    handleHideAssignForm = ()=>this.setState({isOpenAssignForm:false,assignItem:null});
    renderTables(){
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                    <CardHeader>
                        <TableTitleCard
                            title="Corporate Drivers"
                            className='button-driver'
                            callbackAction={()=> this.handleForm()}
                            actionName="Add Driver"
                        />
                    </CardHeader>
                    <CardBody>
                        <TableCaptions
                            selectedValue={this.state.showEntries}
                            callbackSelectFun={(value)=> this.loadByLimit(value)}
                            searchable = {['name',' phone']}
                            search= {(params)=>this.search(params)}
                            isFetching={this.props.driverReducer.isFetching}
                            isSearching={this.props.driverReducer.isSearching}
                        />
                        <ListingTables
                            isDriverTable={true}
                            headers={[
                                'Driver Id',
                                'Name',
                                'License Number',
                                'Phone',
                                'Pickup Address',
                                'Status',
                                'Options'
                            ]}
                            listingData={this.props.driverReducer.driverList}
                            callbackEdit={(item)=> this.setState({isOpenForm: true, editItem: item})}
                            callbackView={(item)=> this.setState({isOpenDetail: true, viewItem: item})}
                            // callbackAssign ={(item)=>this.setState({isOpenAssignForm:true,assignItem:item})}
                        />
                    </CardBody>
                    <CardFooter>
                        {
                            (this.props.driverReducer.meta) &&
                                <PaginationFooter
                                listTotal={this.props.driverReducer.meta.total}
                                listItem={this.props.driverReducer.meta.per_page}
                                perPage={this.state.showEntries}
                                callbackPagy={(page) => this.loadByPagy(page)} />
                        }

                    </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }
    renderForm(){
        return(
            <DriverModal
                isOpen={this.state.isOpenForm}
                callbackHide={()=> this.handleHideForm()}
                callbackFormSuccess={()=> this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    renderAssignForm(){
        return(
            <DriverAssignModel
              isOpen={this.state.isOpenAssignForm}
              callbackHide={()=>this.handleHideAssignForm()}
              assignItem={this.state.assignItem}
            />
        )
    }
    renderDetails(){
        return(
            <DriverDetails
                isOpen={this.state.isOpenDetail}
                hide={()=> this.setState({isOpenDetail: false})}
                viewItem={this.state.viewItem}
            />
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenDetail && this.renderDetails()}
                {this.state.isOpenAssignForm && this.renderAssignForm()}
            </div>
        );
    }
}
const mapStateToProps = state=>({
    driverReducer:state.driverReducer,
    user : state.user,
    alerts : state.alerts
});

const  mapDispatchToProps = dispatch=>{
     return {
        requestDriverData : (user,page,limit=25)=>dispatch(requestDriverData(user,page,limit)),
        searchDriverData : (user,page=1,params) => dispatch(searchDriverData(user,page,params)),
        clearAlert : ()=>dispatch(clearAlert())
     }
};

export default connect(mapStateToProps,mapDispatchToProps)(Drivers);
