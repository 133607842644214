import React from "react";

const Marker = ({ text, tooltip, $hover }) => {
  return (
    <div className={$hover ? "circle hover" : "circle"}>
      <span className="pointer" title={tooltip}>
        <img src={require('../../assests/images/google-map-location-icon.png')} className='location-icon' alt='Location Icon'/>
      </span>
    </div>
  );
};

export default Marker;
