import * as actions from '../constants/actionTypes';

const INITIAL_STATE={
    uploadFile : [],
    fileName : "",
    fileData : null,
    fileErr : false,
    isUploading : false,
}

function uploadReducer(state=INITIAL_STATE, action) {
    switch(action.type) {
        case actions.UPLOAD_CSV :
            return Object.assign({}, state, {
                isUploading: true,
            });

        case actions.UPLOAD_CSV_RESPONSE :
            return Object.assign({}, state, {
                isUploading : false,
            })

        case actions.ADD_CSV :
            return Object.assign({}, state, {
                fileErr  : false,
                fileName : action.payload,
                fileData : action.fileData
            });

        case actions.DISPLAY_CSV_ERR :
            return Object.assign({}, state, {
                fileErr  : true,
                fileData : null,
                fileName : "",
            });

        case actions.REMOVE_CSV:
            return Object.assign({}, state, {
                fileErr  : false,
                fileData : null,
                fileName : "",
            });
        default : return state;
    }
}

export default uploadReducer;