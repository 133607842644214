import * as actions from '../constants/actionTypes';

const INITIAL_DATA = {
    customerList: [],
    isFetching: false,
    meta: null,
    page: 1,
    isUpdated : false,
    isSearching : false,
    limit : 25

}

function customerReducer(state = INITIAL_DATA, action) {
    switch (action.type) {
        case actions.REQUEST_CUSTOMER_DATA: 
            return Object.assign({}, state, {
                    isFetching: true,
                    limit:action.limit
            });
        case actions.RECIEVE_CUSTOMER_DATA_RESULT: 
            return Object.assign({},state, {
                    customerList:(action.payload)? action.payload.data : [],
                    isFetching: false,
                    isUpdated : false,
                    meta: (action.payload)?action.payload.meta:null,
                    page: action.page
            });
        case actions.SEARCH_CUSTOMER_DATA :
            return Object.assign({},state,{
                   isSearching : true
            });
        
        case actions.RECEIVE_SEARCH_CUSTOMER_DATA :
            return Object.assign({},state,{
                    customerList:(action.payload)? action.payload.data : [],
                    isSearching : false,
            })
        case actions.SEARCH_CUSTOMER_ATTENDANCE :
            return Object.assign({},state,{
                isSearching : true,
            })
        case actions.RECEIVE_CUSTOMER_ATTENDANCE:
            return Object.assign({},state, {
                customerList:(action.payload)? action.payload.data : [],
                isSearching: false,
                isUpdated : false,
                meta: (action.payload)?action.payload.meta:null,
                page: action.page
            });
        case actions.PUSH_ALERT: 
            if (action.updatedData === "customer") 
               return Object.assign({}, state, { isUpdated: true })  
            else
               return state                                         

        case actions.SUCCESS_ADDING_CUSTOMER : 
            return Object.assign({},state,{   
                    customerList : [...state.customerList,action.payload] 
            })        
        default: return state;
    }
}

export default customerReducer;