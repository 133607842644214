import * as actions from "../constants/actionTypes";


export function requestCorporateFerryList(value){
    return({
        type : actions.REQUEST_COMPANY_FERRY_LIST,
        payload : value
    })
}


export function receiveCorporateFerryList(value){
    return({
        type : actions.RECEIVE_COMPANY_FERRY_LIST,
        payload : value
    })
}

export function addFerryRoute(value) {
    return({
        type : actions.ADD_FERRY_ROUTE,
        payload: value
    })
}

export function removeFerryRoute(value) {
    return({
        type : actions.REMOVE_FERRY_ROUTE,
        payload: value
    })
}

export function removePreviousFerryInput() {
    return({
        type : actions.CLEAR_FERRY_ROUTE,
    })
}

export function requestCsv(value, metadata) {
    return({
        type : actions.REQUEST_CSV,
        payload : value,
        meta: metadata
    })
}

export function requestFerryCustomerData(ssoToken, companyId) {
    return({
        type : actions.REQUEST_FERRY_CUSTOMER_TEMPLATE_DATA,
        token : ssoToken,
        companyId : companyId
    })

}

export function receiveFerryCustomerData(data) {
    return {
        type    : actions.RECEIVE_FERRY_CUSTOMER_TEMPLATE_DATA,
        payload : data
    }
}

export function downloadQr(value, ssoToken, companyName) {
    return({
        type : actions.DOWNLOAD_QR,
        url : value,
        token: ssoToken,
        name :companyName
    })
}

export function updateDriverRoute(updateRoute, currentRoute) {
    return({
        type : actions.UPDATE_ROUTE,
        payload: updateRoute,
        prevData: currentRoute
    })
}

export function editDriverRoute(payload, token) {
    return({
        type : actions.EDIT_ROUTE,
        payload: payload,
        token: token
    })
}