import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    transactionList: [],
    isFetching: false,
    meta: null,
    page: 1,
    isLoadingStations: false,
    isFiltered: false,
}

function agentTransactionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actions.REQUEST_AGENT_TRANSACTION: return Object.assign({}, state, {
            isFetching: true,
            limit : action.limit
        });
        case actions.RECEIVE_AGENT_TRANSACTION : return Object.assign({},state,{
            isFetching : false,
            transactionList :(action.payload)?action.payload.data:[],
            meta : (action.payload)?action.payload.meta:null,
            page: action.page,
        });
        case actions.EXPORT_AGENT_TRANSACTION_CSV : 
            return Object.assign({}, state, {
                isFetching: true
            }); 
        default : return  state

    }
}

export default agentTransactionReducer;
