import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CabAssignFerryForm from "./CabAssignFerryForm";
import {connect} from "react-redux";
import FormInput from "../../components/FormInput";
import {loadCabData, loadData} from "../../actions/formActions";
import {assignCabToDriver} from "../../actions/cabActions";
import DriverAssignForm from "../Drivers/DriverAssignForm";

class CabAssignFerryModel extends Component{
    /*ferryAssignRoute(){
        const { ferry_assign } =  this.props

        alert(ferry_assign);
    }*/
    render() {
        const { assignItem} = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={()=> this.props.callbackHide()}
                    size="m"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Edit Ferry Route</ModalHeader>
                    <ModalBody>
                        <CabAssignFerryForm
                            callbackHide={this.props.callbackHide}
                            driverInfo={assignItem}
                        />


                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        /*loadData      : (data)=> dispatch(loadData(data)),
        assignCabToDriver : (user,data)=> dispatch(assignCab
                 */
    }
}


export default connect(null,mapDispatchToProps)(CabAssignFerryModel);