import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,    
} from 'reactstrap';
import { loadCabData } from '../../actions/formActions';
import CabAssignForm from './CabAssignForm';

class CabAssignModal extends Component {
    componentDidMount(){
        if(this.props.assignItem != null){                  
            this.props.loadCabData(this.props.assignItem);
        }else{
            this.props.loadCabData(null)
        }
    }
    render() {
        return (
            <div>
                <Modal
                 isOpen={this.props.isOpen} 
                 toggle={()=> this.props.callbackHide()}                 
                 size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Assign Cab To Driver</ModalHeader>
                    <ModalBody>
                        <CabAssignForm
                            assignItem={this.props.assignItem} 
                            callbackHide={this.props.callbackHide}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return{                            
        loadCabData      : (data)=> dispatch(loadCabData(data))
    }
}

export default connect(null,mapDispatchToProps)(CabAssignModal);