import React, {Component} from "react";
import { reduxForm } from 'redux-form';
import FormInput from "../../components/FormInput";
import {Button} from "antd";
import {loadData} from "../../actions/formActions";
import {assignCabToDriver} from "../../actions/cabActions";
import {connect} from "react-redux";
import {updateDriverRoute, editDriverRoute} from "../../actions/ferryActions"

class CabAssignFerryForm extends Component{

    handleFerryRoute(){
        const {driverInfo, ferry_assign, coporateReducer, user } = this.props;

        const route = (coporateReducer.ferryRoute.length !=0 ) ? coporateReducer.ferryRoute : driverInfo.ferry_route

       let payload = {
            cab_id : driverInfo.id,
            ferry_route: route,
        }

        this.props.editDriverRoute(payload, user.user.sso_token)
        this.props.callbackHide()

    }
    render() {
        const { driverInfo, ferry_assign } = this.props;

        return (
            <div>
                <form className="form">
                    <FormInput
                        labelName="Current route"
                        name="edit_route"
                        placeholder="Select route to edit"
                        component="select"
                        editRoute={true}
                        routeDetail={driverInfo.ferry_route}
                    />
                    {
                        ferry_assign.ferry_assign != undefined && ferry_assign.ferry_assign.values != undefined ?
                        <FormInput
                            labelName="Edit route"
                            name="update_route"
                            placeholder="Enter new value"
                            component="input"
                            updateRoute={true}
                            routeDetail={driverInfo.ferry_route}
                            selectedRoute={ferry_assign.ferry_assign.values.edit_route}
                            updateDriverRoute={(updateRoute, oldRoute)=>this.props.updateDriverRoute(updateRoute, oldRoute)}
                        />
                            :
                            null
                    }
                    <br />
                    <Button type="primary" size='large' onClick={()=> this.handleFerryRoute()}>Submit</Button>
                </form>

            </div>
        );
    }

}

CabAssignFerryForm = reduxForm({
    form: 'ferry_assign'
})(CabAssignFerryForm)

const mapStateToProps =state=>({
    initialValues : state.operatorReducer.ferry_assign,
    ferry_assign : state.form,
    user : state.user,
    coporateReducer: state.coporateReducer
});

const mapDispatchToProps = dispatch => {
    return{
        updateDriverRoute :(updateRoute, oldRoute)=> dispatch(updateDriverRoute(updateRoute, oldRoute)),
        editDriverRoute : (payload, token) => dispatch(editDriverRoute(payload, token))

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CabAssignFerryForm);