import React from 'react';
import { connect } from 'react-redux';
import { requestCorporateOrders, searchCorporateOrders, requestCorporateOrdersExcel } from '../../actions/corporateOrderActions';
import { requestCompanyNameList } from '../../actions/coporateCompanyAction';
import 'antd/dist/antd.css';
import './style.scss'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label
} from 'reactstrap'
import { Button } from 'antd';
import { DatePicker, AutoComplete } from 'antd';
import moment from 'moment'
import history from "../../utils/history";
import ListingTables from '../../components/ListingTables';
import PaginationFooter from '../../components/PaginationFooter';
import TableCaptions from '../../components/TableCaptions';
import OrderDetails from './OrderDetails';

class CorporateOrders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenDetail: false,
            viewItem: null,
            showEntries: 25,
            searchParam: '',
            metadata: null,
            corporateCompany: '',
            start: '',
            end: '',
            errrors: null,
            isResetting: false
        }
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleEndDateChange = this.handleEndDateChange.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    checkMetaData() {
        let metaData;
        if (this.state.metadata == null) {
            metaData = {
                corporate_company_id: '',
                startDate: '',
                endDate: ''
            }
        } else {
            metaData = this.state.metadata;
        }
        return metaData;
    }

    loadData() {
        const { user } = this.props;
        const page = 1;
        let meta = this.checkMetaData();
        this.setState({isResetting: false})

        if (user.user) {
            this.props.requestCorporateOrders(user.user, page, this.state.showEntries, meta);
            this.props.requestCompanyNameList(user);
        } else {
            history.push('/sign_in');
        }
    }

    loadByPagy(page) {
        const { user } = this.props;
        let limit = this.state.showEntries;
        let meta = this.checkMetaData();
        this.setState({isResetting: false})
        this.props.requestCorporateOrders(user.user, page, limit, meta);
    }

    loadByLimit(limit) {
        const { user } = this.props;
        this.setState({ showEntries: parseInt(limit) });
        let meta = this.checkMetaData();
        this.props.requestCorporateOrders(user.user, 1, limit, meta);
    }

    search(params) {
        const { user } = this.props;
        this.setState({ searchParam: params })
        this.props.searchCorporateOrders(user.user, 1, params, this.state.metadata);
    }

    getdataSource() {
        const companyLists = this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value: item.name
        }));
        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }

    handleSelectCompanyList = (e) => {
        this.setState({ corporateCompany: e })
    }

    handleStartDateChange(date, dateString) {
        let errors = {}
        const now = moment()
        if (this.state.end !== "") {
            const endDate = moment(this.state.end, "YYYY-MM-DD")
            if (endDate.isBefore(date)) {
                errors = { ...errors, start_date: "Start Date must be lesser than end date" }
            } else if (now.isBefore(date)) {
                errors = { ...errors, start_date: "Unable to search for that date range" }
            } else {
                delete errors["start_date"];
            }
        } else {
            if (now.isBefore(date)) {
                errors = { ...errors, start_date: "Unable to search for that date range" }
            } else {
                delete errors["start_date"];
            }
        }

        if (Object.keys(errors).length === 0) {
            this.setState({ errors: null })
            this.setState({ start: dateString })
        } else {
            this.setState({ errors: errors })
        }

    }

    handleEndDateChange(date, dateString) {
        let errors = {}
        const now = moment()
        if (this.state.start !== "") {
            const startDate = moment(this.state.start, "YYYY-MM-DD")
            if (startDate.isAfter(date)) {
                errors = { ...errors, end_date: "End Date must be greater than start date" }
            } else if (now.isBefore(date)) {
                errors = { ...errors, end_date: "Unable to search for that date range" }
            } else {
                delete errors["end_date"];
            }
        } else {
            errors = { ...errors, start_date: "Start date cannot be empty" }
            if (now.isBefore(date)) {
                errors = { ...errors, end_date: "Unable to search for that date range" }
            } else {
                delete errors["end_date"];
            }
        }

        if (Object.keys(errors).length === 0) {
            this.setState({ errors: null })
            this.setState({ end: dateString })
        } else {
            this.setState({ errors: errors })
        }
    }

    handleFilter() {
        const { user } = this.props;
        let errors = {},
            metaData, selectedCompany,
            role = this.props.user.user.role.name;

        if (this.state.corporateCompany == '' && role == 'Superadmin') {
            errors = { ...errors, company: "Please choose company" }
        } else {
            delete errors["company"]
        }

        if (Object.keys(errors).length === 0) {
            this.setState({ errors: null })
            if (role == 'Superadmin') {
                selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
            } else {
                selectedCompany = { id: '' }
            }
            metaData = {
                corporate_company_id: selectedCompany.id,
                startDate: this.state.start,
                endDate: this.state.end
            }
            this.setState({ metadata: metaData })
            this.props.searchCorporateOrders(user.user, 1, this.state.searchParam, metaData);
        } else {
            this.setState({ errors: errors })
        }

    }

    handleResetFilter() {
        const { user, orderReducer } = this.props;
        let metaData = {
            corporate_company_id: '',
            startDate: '',
            endDate: ''
        }
        this.setState({
            corporateCompany: '',
            start: '',
            end: '',
            errors: null,
            isResetting: true,
            metadata: metaData
        })
        
        this.props.requestCorporateOrders(user.user, orderReducer.page, this.state.showEntries, metaData);
    }

    handleExportExcel() {
        const { user } = this.props;
        let today = new Date().toISOString().slice(0, 10),
            corporateId,
            errors = {};

        const endDate = (this.state.end !== '') ? this.state.end : today;
        const startDate = (this.state.start !== '') ? this.state.start : today;

        if (user.user.role.name == "Superadmin") {
            if (this.state.corporateCompany == '') {
                errors = { ...errors, company: "Please choose company" }
            } else {
                corporateId = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
                if (corporateId.id == "") {
                    errors = { ...errors, company: "Invalid corporate company. Please choose another" }
                } else {
                    delete errors["company"];
                }
            }
        } else {
            corporateId = 0
        }

        if (Object.keys(errors).length === 0) {
            this.setState({ errors: null })
            const meta = {
                startDate: startDate,
                endDate: endDate,
                companyId: corporateId.id,
                searchParam: this.state.searchParam
            }
            this.props.requestCorporateOrdersExcel(user.user.sso_token, meta)
        } else {
            this.setState({ errors: errors })
        }

    }

    viewCorporateOrderDetails(e, item) {
        e.preventDefault();
        this.setState({ isOpenDetail: true, viewItem: item })
    }

    renderTable() {
        const orders = this.props.orderReducer,
            options = this.getdataSource(),
            role = this.props.user.user ? this.props.user.user.role.name : '';
        return (
            <Row className='order-page'>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader className='page-header'>
                            <h4>Corporate Orders</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="order-filter-section">
                                <Row>
                                    <Col sm={3}>
                                        <Label>Company Name</Label>
                                        {
                                            this.props.orderReducer.isFetching && this.state.isResetting?
                                                null
                                            :
                                            this.props.coporateReducer.companyNameList.length > 0 &&
                                                <AutoComplete
                                                    options={options}
                                                    id='companyId'
                                                    placeholder="Select company"
                                                    defaultValue={role !== 'Superadmin' ? options[0].value : this.state.corporateCompany}
                                                    onSelect={this.handleSelectCompanyList}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                        }
                                        <span className="error-span">{(this.state.errors && this.state.errors.company) ? this.state.errors.company : null}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <Label>Start Date</Label> <br />
                                        <DatePicker
                                            onChange={this.handleStartDateChange}
                                            value={this.state.start !== '' ? moment(this.state.start) : moment()}
                                            style={{ width: "100%", }}
                                        />
                                        <span className="error-span">{(this.state.errors && this.state.errors.start_date) ? this.state.errors.start_date : null}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <Label>End Date</Label>
                                        <DatePicker
                                            onChange={this.handleEndDateChange}
                                            value={this.state.end !== '' ? moment(this.state.end) : moment()}
                                            style={{ width: "100%", }}
                                        />
                                        <span className="error-span">{(this.state.errors && this.state.errors.end_date) ? this.state.errors.end_date : null}</span>
                                    </Col>

                                    <Col md={5} className="button-action" style={{ paddingTop: 25 }}>
                                        <Button className='ant-btn-success' size='large' onClick={(e) => this.handleFilter(e)}>
                                            Search
                                        </Button>

                                        <Button type="danger" size='large' onClick={(e) => this.handleResetFilter()}>
                                            Reset
                                        </Button>

                                        <Button className="ant-btn-warning" size='large' onClick={(e) => this.handleExportExcel()}>
                                            Export Excel
                                        </Button>
                                    </Col>

                                </Row>
                            </div>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['name', ' phone']}
                                search={(params) => this.search(params)}
                                isFetching={this.props.orderReducer.isFetching}
                                isSearching={this.props.orderReducer.isSearching}
                            />
                            <ListingTables
                                isCorporateOrderTabel={true}
                                headers={[
                                    'Order',
                                    'Customer Name',
                                    'Customer Phone',
                                    'Company Name',
                                    'Driver Name',
                                    'Driver Phone',
                                    'Cab Type',
                                    'Payment',
                                    'Pickup Date/Time',
                                    'Detail',
                                    'Trip Description'
                                ]}
                                listingData={orders.orderList}
                                callbackView={(e, item) => this.viewCorporateOrderDetails(e, item)}
                            />
                        </CardBody>
                        <CardFooter>
                            {
                                (orders.meta) &&
                                    <PaginationFooter listTotal={orders.meta.total}
                                        listItem={orders.meta.per_page}
                                        perPage={this.state.showEntries}
                                        totalAmt={orders.meta.total_amt}
                                        callbackPagy={(page) => this.loadByPagy(page)} 
                                    />
                            }

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )

    }
    renderDetails() {
        return (
            <OrderDetails
                isOpen={this.state.isOpenDetail}
                hide={() => this.setState({ isOpenDetail: false })}
                viewItem={this.state.viewItem}
            />
        )
    }

    render() {
        return (
            <div className='content'>
                { this.renderTable()}
                { this.state.isOpenDetail && this.renderDetails()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    alerts: state.alerts,
    orderReducer: state.corporateOrderReducer,
    coporateReducer: state.coporateReducer
});

const mapDispatchToProps = dispatch => {
    return {
        requestCorporateOrders: (user, page = 1, limit = 25, meta) => dispatch(requestCorporateOrders(user, page, limit, meta)),
        searchCorporateOrders: (user, page = 1, params, meta) => dispatch(searchCorporateOrders(user, page, params, meta)),
        requestCompanyNameList: (user) => dispatch(requestCompanyNameList(user)),
        requestCorporateOrdersExcel: (token, meta) => dispatch(requestCorporateOrdersExcel(token, meta))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateOrders);