import * as actions from '../constants/actionTypes';
export function requestCompanyNameList(user){
    return({
        type : actions.LOAD_COMPANY_NAME_LIST,
        user : user
    })
}

export function receiveCompnayNameList(data){
    return({
        type : actions.RECEIVE_COMPANY_NAME_LIST,
        payload : data
    })
}