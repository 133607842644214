import * as actions from '../constants/actionTypes';

export function requestCorporateOrders(user,page, limit, meta){
    return {
        type  : actions.REQUEST_CORPORATE_ORDERS,
        user  : user,
        page  : page,
        limit : limit,
        meta  : meta
    }
}

export function receiveCorporateOrders(data,page){
    return {
        type    : actions.RECEIVE_CORPORATE_ORDERS,
        payload : data,
        page    : page
    }
}

export function searchCorporateOrders(user,page,params,metadata){
    return ({
        type : actions.SEARCH_CORPORATE_ORDERS,
        user : user,
        page : page,
        params : params,
        metadata: metadata
    })
}

export function receiveSearchCorporateOrders(data, page) {
    return ({
        type : actions.RECEIVE_SEARCH_CORPORATE_ORDERS,
        payload : data,
        page : page
    })
}

export function requestCorporateOrdersExcel(token, metadata) {
    return ({
        type: actions.REQUEST_CORPORATE_ORDERS_EXCEL,
        payload: token,
        meta: metadata
    })
}
