import * as types from '../constants/actionTypes';

/**
 * product reducers.
 * @param state
 * @param action
 * @returns {*}
 */

const INITIAL_STATE = {
    cabList : [],
    cabStatus : [],
    isFetching : false,
    page: 1,
    limit : 25,
}

function aircabMapLocationReducer(state = INITIAL_STATE , action) {
    switch (action.type) {
        case types.REQUEST_LOAD_MAP:
            
            return Object.assign({}, state, {
                isFetching : true,
                limit : action.limit
            });
        case types.RECEIVE_LOAD_MAP:
            return Object.assign({}, state, {
                cabList : (action.payload)? action.payload.data : [],
                isFetching : false,
   
            });
            case types.REQUEST_CAB_STATUS:
            
                return Object.assign({}, state, {
                    isFetching : true
                });
            case types.RECEIVE_CAB_STATUS:
                return Object.assign({}, state, {
                    cabStatus : (action.payload)? action.payload: [],
                    isFetching : false,
                    meta : (action.payload)?action.payload.meta : null,
                    page : action.page
       
                });
        default:
          return state
      }       
}



export default aircabMapLocationReducer;