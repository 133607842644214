import * as actions from '../constants/actionTypes';

export function login(data){
    return({
        type: actions.LOGIN_USER,
        payload: data
    })
}
export function pushUser(user){
    return({
        type: actions.PUSH_USER,
        payload: user
    })
}

export function updateUser(id) {
    return ({
        type: actions.UPDATE_USER,
        id: id
    })
}

export function logoutUser() {
    return({
        type : actions.LOGOUT_USER
    })
}
