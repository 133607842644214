import React, {Component, useState} from 'react'
import { Field } from 'redux-form'
import './styles.scss';
import {Button, Col, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import {connect} from "react-redux";
import { validationLatitudeLongitude } from "validation-latitude-longitude";


const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

class FormInput extends Component {
    constructor(props){
        super(props);
        this.state={
            value: '',
            validateErr: false,
            hover: false,
            hoverValue: '',
            hoverKey: '',
        }
    }

    timer = null
    changesTrigger() {
        const { value } = this.state
        if (!this.state.validateErr) {
            this.props.addFerry(value)
            this.props.resetRoute("cab_assign", "start_route", '')
            this.setState({value : ''})
        }
    }

    updateRouteTrigger(){
        const { value } = this.state
        if (!this.state.validateErr) {
            const tempArr = []
            tempArr.push(value)
            this.props.routeDetail[this.props.selectedRoute] = tempArr

            this.props.updateDriverRoute(this.props.routeDetail, value)
            this.setState({value : ''})
        }
    }

    handleChange = e => {
        clearTimeout(this.timer)
        const inputValue = e.target.value

        this.setState({ value: inputValue })
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
    }

    handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(this.timer)
            this.triggerChange()
        }
    }

    openHover(key, value){
        const hoverKey = "Popover"+key;
        this.setState({hover: true})
        this.setState({hoverValue: value})
        this.setState({hoverKey: hoverKey})
    }

    closeHover(key, value){
        this.setState({hover: false})
        this.setState({hoverValue: ''})
        this.setState({hoverKey: ''})
    }

    triggerChange = () => {
        const { value } = this.state
        if (value != '') {
            const lagLong = value.split(',')

            if (lagLong[0] !== undefined && lagLong[1] !== undefined) {

                    this.setState({validateErr : false})

            } else {
                this.setState( {validateErr : true} )
            }
        } else {
            this.setState( {validateErr : false} )
        }
    }

    removeFerryRoute(value){
        this.props.removeFerry(value)
        this.setState({hover: false})
        this.setState({hoverValue: ''})
        this.setState({hoverKey: ''})
    }


    render(){
        return (
            <div className="row form-group">
                {
                    this.props.multipleCustomer ?
                        <Col sm={this.props.col ? this.props.col : 4}>
                            <Field
                                className="input form-control"
                                autoComplete="off"
                                name={this.props.name ? this.props.name : "name"}
                                component={this.props.component ? this.props.component : "input"}
                                onChange={(e)=> this.props.selectCorporate(e.target.value)}
                            >
                                {
                                    // months.map((day)=>(
                                    //     <option key={day} value={day}>{day}</option>
                                    // ))
                                    this.props.selectValue.map((value)=>(
                                        <option key={value.id} value={value.id}>{value.name}</option>
                                    ))
                                }
                            </Field>
                        </Col>
                        :
                        <Col sm={this.props.col ? this.props.col : 4}>
                            <label className="label">{this.props.labelName ? this.props.labelName : "Label"}</label>
                        </Col>
                }

                <Col sm={this.props.col ? this.props.col : 8}>
                    {
                        this.props.multipleCustomer ?
                                <Button
                                    color="primary"
                                    onClick={() => this.props.downloadCsv()}>
                                    Get Template
                                </Button>
                            :
                        this.props.ferry ?
                            <Field
                                className="input form-control"
                                autoComplete="off"
                                name={this.props.name ? this.props.name : "name"}
                                component={this.props.component ? this.props.component : "input"}
                                onChange={(e)=> this.props.selectCorporate(e.target.value)}
                            >
                                {
                                    (this.props.selectedValue)?
                                        <option key={this.props.selectedValue}>
                                            {
                                                this.props.selectValue.map(value=>
                                                    value.id === this.props.selectValue ? value.name : null
                                                )
                                            }
                                        </option>
                                        :<option/>
                                }
                                {/* <option /> */}
                                {
                                    // months.map((day)=>(
                                    //     <option key={day} value={day}>{day}</option>
                                    // ))
                                    this.props.selectValue.map((value)=>(
                                        <option key={value.id} value={value.id}>{value.name}</option>
                                    ))
                                }
                            </Field>
                            :
                            this.props.option ?
                                <Field
                                    className="input form-control"
                                    autoComplete="off"
                                    name={this.props.name ? this.props.name : "name"}
                                    component={this.props.component ? this.props.component : "input"}
                                >
                                    {
                                        (this.props.selectedValue)?
                                            <option key={this.props.selectedValue}>
                                                {
                                                    this.props.selectValue.map(value=>
                                                        value.id === this.props.selectValue ? value.name : null
                                                    )
                                                }
                                            </option>
                                            :<option/>
                                    }
                                    {
                                        // months.map((day)=>(
                                        //     <option key={day} value={day}>{day}</option>
                                        // ))
                                        this.props.selectValue.map((value)=>(
                                            <option key={value.id} value={value.id}>{value.name}</option>
                                        ))
                                    }
                                </Field>
                                :
                            this.props.ferryOption ?
                                <Field
                                    className="input form-control"
                                    autoComplete="off"
                                    name={this.props.name ? this.props.name : "name"}
                                    component={this.props.component ? this.props.component : "input"}
                                >
                                    {
                                        (this.props.selectedValue)?
                                            <option key={this.props.selectedValue}>
                                                {
                                                    this.props.selectValue.map(value=>
                                                        value.cab_id === this.props.selectValue ? value.driver_name : null
                                                    )
                                                }
                                            </option>
                                            :<option/>
                                    }
                                    {/* <option /> */}
                                    {
                                        // months.map((day)=>(
                                        //     <option key={day} value={day}>{day}</option>
                                        // ))
                                        this.props.selectValue.map((value)=>(
                                            <option key={value.cab_id} value={value.cab_id}>{value.driver_name}</option>
                                        ))
                                    }
                                </Field>
                                :
                                this.props.editRoute ?
                                    <Field
                                        className="input form-control"
                                        autoComplete="off"
                                        name={this.props.name ? this.props.name : "name"}
                                        component={this.props.component ? this.props.component : "input"}
                                    >

                                    {
                                        this.props.routeDetail.length > 0 ?
                                            <option key={0} value="" disabled selected> Select route to edit</option>
                                            :<option/>
                                    }
                                    {
                                        // months.map((day)=>(
                                        //     <option key={day} value={day}>{day}</option>
                                        // ))
                                        this.props.routeDetail.map((value, key)=>(

                                            <option key={key} value={key}>{value}
                                            </option>
                                        ))
                                    }
                                    </Field>

                                    :
                                this.props.updateRoute ?
                                    <div>
                                        <Field
                                            className="input form-control"
                                            autoComplete="off"
                                            name={this.props.name ? this.props.name : "name"}
                                            component={this.props.component ? this.props.component : "input"}
                                            type={this.props.type ? this.props.type : "text"}
                                            placeholder={this.props.placeholder ? this.props.placeholder : "Enter company name"}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                        {this.state.validateErr ?
                                            <div className="error-span"> Invalid Lag, long </div>
                                            :
                                            null
                                        }
                                        <Button color='success' onClick={() => this.updateRouteTrigger()}>Update</Button>
                                    </div>
                                    :

                                this.props.checkbox ?
                                    <div>
                                        <label className="radio-button">
                                            <Field name={this.props.name} component="input" type="radio" value="true" />{' '}
                                            Activate
                                        </label>
                                        <label className="radio-button">
                                            <Field name={this.props.name} component="input" type="radio" value="false" />{' '}
                                            Deactivate
                                        </label>
                                    </div>
                                    :
                                    this.props.catagory == "ferry" ?
                                        <div>
                                            <Field
                                                className="input form-control"
                                                autoComplete="off"
                                                name={this.props.name ? this.props.name : "name"}
                                                component={this.props.component ? this.props.component : "input"}
                                                type={this.props.type ? this.props.type : "text"}
                                                placeholder={this.props.placeholder ? this.props.placeholder : "Enter company name"}
                                                onChange={this.handleChange}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                            {this.state.validateErr ?
                                                <div className="error-span"> Invalid Lag, long </div>
                                                :
                                                null
                                            }

                                            {
                                                this.props.coporateReducer.ferryRoute.length > 0 ?
                                                <div>
                                                    {
                                                        this.props.coporateReducer.ferryRoute.map((value, key)=> (

                                                            <Button color='info' id={"Popover"+key}  onMouseEnter={()=> this.openHover(key, value)}  onMouseLeave={()=> this.closeHover(key, value)} key={key} value={value}>Route{key+1}
                                                               <Button close onClick={()=> this.removeFerryRoute(value)}/>
                                                                {
                                                                    this.state.hover ?
                                                                        <Popover placement="top" isOpen={this.state.hover} target={this.state.hoverKey}>
                                                                            <PopoverBody>{value}</PopoverBody>
                                                                        </Popover>:
                                                                        null
                                                                }
                                                            </Button>

                                                        ))
                                                    }
                                                </div>
                                                    :
                                                    null
                                            }
                                            <button type="button" onClick={() => this.changesTrigger()}>
                                                Add ferry route
                                            </button>

                                        </div>
                                        :
                                        <Field
                                            className="input form-control"
                                            autoComplete="off"
                                            name={this.props.name ? this.props.name : "name"}
                                            component={this.props.component ? this.props.component : "input"}
                                            type={this.props.type ? this.props.type : "text"}
                                            placeholder={this.props.placeholder ? this.props.placeholder : "Enter company name"}
                                        />
                    }

                </Col>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    initialValues: state.operatorReducer.customer,
    coporateReducer: state.coporateReducer,
});

export default connect(mapStateToProps, null)(FormInput);