import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';

import { loadCustomerData } from '../../actions/formActions';
import { requestCompanyNameList } from '../../actions/coporateCompanyAction';
import { requestCorporateFerryList } from '../../actions/ferryActions';
import { addNewCustomer,updateCustomer } from '../../actions/customerAction';
import {clearAlert} from '../../actions/alertActions';
import {Col, Row} from 'reactstrap'
import {AutoComplete, Button} from "antd";

class CustomerForm extends React.Component {
    constructor(props){
        super(props);
        this.state={
            errors:null,
            corporateCompany: null
        }
    }

    componentDidMount() {
        const {user}=this.props;
        this.loadCompanyNamelist();
        if (this.props.editItem && this.props.editItem.corporate_company_id) {
            this.props.requestFerryRouteList(this.props.editItem.corporate_company_id)
        }
        if (user.user) {
            this.props.requestCompanyNameList(user);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // s
    }
    loadCompanyNamelist() {
        let {user} = this.props;
        this.props.requestCompanyNameList(user);
    }
    handleSubmit() {
        const { customer,user } = this.props;
        if(customer.customer.values){
            let errors={};
            let payload ={}
            if(customer.customer.values.name){
                payload = {...payload,name:customer.customer.values.name}
            }else{
                errors = {...errors,name:"Please enter customer name"}
            }

            if(customer.customer.values.phone){
              payload = {...payload,phone:customer.customer.values.phone}
            } else{
              errors = {...errors,phone:"Please enter phone"}
            }
            if(this.state.corporateCompany) {
                const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
                payload = {...payload,corporate_company_id: Number(selectedCompany.id)}
            }else{
             errors = {...errors,corporate_company_id:"Please select company"}
            }

            if(customer.customer.values.ferry_pickup_point){
                const ferryPoint = []
                ferryPoint.push(customer.customer.values.ferry_pickup_point)
                payload = {...payload, ferry_pickup_point:ferryPoint}
            }

            if (customer.customer.values.driver_name) {
                payload = {...payload,ferry_cab: customer.customer.values.driver_name}
            }

            if (customer.customer.values.status) {
                payload = {...payload,inactive: (customer.customer.values.status==="true")? 0 : 1}
            } else {
                errors = {...errors,inactive:"Status is required"}
            }

            if(Object.keys(errors).length === 0){
                this.props.addNewCustomer(user,payload);
                this.props.callbackHide();
            }else{
                this.setState({errors :errors})
            }
          }else{
              this.setState({
                  errors:{
                      name : "Customer is Required",
                      phone : "Please enter phone",
                      registration_number : "Registration Number is required",
                      corporate_company_id : "Please select company",
                      status : "Status is required"
                  }
              })
          }
    }

    handleShowMultiRoute(){
        this.setState({multiRoute: true} )
    }

    handleSelectCompanyList = (e) => {
        const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === e);
        if (selectedCompany.id != null) {
            this.props.requestFerryRouteList(selectedCompany.id)
            this.setState({corporateCompany : e})
        }

    }

    getdataSource () {
        const companyLists =  this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value : item.name
        }));
        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }

    handleUpdate(){
        const { customer,user } = this.props;
        let id = this.props.editItem.id;
       
        if(customer.customer.values){
            let errors={};
            let payload ={}
            if(customer.customer.values.name){
                payload = {...payload,name:customer.customer.values.name}
            }else{
                errors = {...errors,name:"Please enter customer name"}
            }

            if (customer.customer.values.driver_name) {
                payload = {...payload,ferry_cab: customer.customer.values.driver_name}
            }

            if(customer.customer.values.ferry_pickup_point){
                let ferryPoint;
                const ferryPointArr = []
                if (typeof (customer.customer.values.ferry_pickup_point) != 'string') {
                    let initFerryRoute = ''
                    for (let value of customer.customer.values.ferry_pickup_point) {
                        initFerryRoute += value + ','
                    }
                    ferryPoint = initFerryRoute.replace(/^,|,$/g, '').toString();
                } else {
                    ferryPoint = customer.customer.values.ferry_pickup_point
                }

                ferryPointArr.push(ferryPoint)
                payload = {...payload, ferry_pickup_point:ferryPointArr}
            }

            if(customer.customer.values.phone){
              payload = {...payload,phone:customer.customer.values.phone}
            }else{
              errors = {...errors,phone:"Please enter phone"}
            }

            if (this.props.editItem && this.props.editItem.corporate_company_id != null) {
                const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.id === this.props.editItem.corporate_company_id);
                this.state.corporateCompany = selectedCompany.name
            }

            if(this.state.corporateCompany){
                const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
                payload = {...payload,corporate_company_id: Number(selectedCompany.id)}
           }else{
             errors = {...errors,corporate_company_id:"Please select company"}
           }
           if(Object.keys(errors).length === 0){
                payload = {...payload,inactive: (customer.customer.values.status==="true")? 0 : 1};
                this.props.updateCustomer(id,user,payload);
                this.props.callbackHide();
            }else{
              this.setState({errors :errors})
            }
          }else{
              this.setState({
                  errors:{
                      name : "Please enter customer name",
                      phone : "Please enter phone",
                      registration_number : "Registration Number is required",
                      corporate_company_id : "Please select company",
                      driver_name: "Please select ferry cab",
                      status : "Please choose status"
                  }
              })
          }
    }

    getDefaultValue(){
        if (this.props.editItem && this.props.editItem.corporate_company_id) {
            const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.id === this.props.editItem.corporate_company_id);
            return selectedCompany.name
        } else {
            return ""
        }
    }
    render() {
        return (
            <form className="form">
                <div className="form-border">
                <FormInput
                    labelName="Name"
                    name="name"
                    placeholder="Enter customer name"
                    component="input"
                    type="text"
                />
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.name)? this.state.errors.name : null}</span></Col>
                </Row>
                <FormInput
                    labelName="Phone No."
                    name="phone"
                    placeholder="Enter phone"
                    component="input"
                    type="tel"
                />
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.phone)? this.state.errors.phone : null}</span></Col>
                </Row>
                <div className="row form-group">
                    <Col sm={4}>
                        <label className="label">Company Name</label>
                    </Col>
                    <Col sm={8} >
                        {
                            this.props.coporateReducer.companyNameList.length > 0 ?
                                <AutoComplete
                                    options={this.getdataSource()}
                                    id='companyId'
                                    placeholder="Select company"
                                    onSelect={this.handleSelectCompanyList}
                                    defaultValue={this.getDefaultValue()}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    style={{
                                        width: "100%",
                                    }}
                                />
                                :
                                null
                        }
                    </Col>
                </div>
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.corporate_company_id)? this.state.errors.corporate_company_id : null}</span></Col>
                </Row>
                    {
                        (this.props.coporateReducer.corporateFerryList.length !== 0) ?
                            <>
                            <FormInput
                                labelName="Driver"
                                name="driver_name"
                                placeholder="select company"
                                component="select"
                                type="number"
                                selectValue={this.props.coporateReducer.corporateFerryList}
                                selectedValue={ this.props.editItem ? this.props.editItem.ferry_driver : '' }
                                ferryOption={true}
                                selectCorporate = {(payload)=> this.props.requestFerryRouteList(payload)}
                            />
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.driver_name)? this.state.errors.driver_name : null}</span></Col>
                            </Row>
                            </>
                        :
                        null

                    }

                    <FormInput
                        labelName="Ferry Pickup Point"
                        name="ferry_pickup_point"
                        placeholder="Enter ferry pickup point"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.ferry_pickup_point)? this.state.errors.ferry_pickup_point : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Status"
                        name="status"
                        component="input"
                        checkbox={true}
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.status) ? this.state.errors.status : null}</span></Col>
                    </Row>
                </div>

                <div className="action-button">
                    {
                        this.props.editItem != null ?
                            <Button 
                                type='primary'
                                size='large'
                                disabled={this.props.pristine || this.props.submitting}
                                onClick={() => this.handleUpdate()}>
                                    Submit
                            </Button>
                            :
                            <Button 
                                type='primary'
                                size='large'
                                disabled={this.props.pristine || this.props.submitting}
                                onClick={() => this.handleSubmit()}>
                                    Submit                            
                            </Button>
                    }
                    <Button  className="ml-2" size='large' onClick={() => this.props.callbackHide()}>Cancel</Button>
                </div>
            </form>
        );
    }
}

CustomerForm = reduxForm({
    form: 'customer'
})(CustomerForm)

const mapStateToProps = state => ({
    initialValues: state.operatorReducer.customer,
    coporateReducer: state.coporateReducer,
    customer: state.form,
    alerts : state.alerts,
    user : state.user
});

const mapDispatchToProps = dispatch => {
    return {
        loadCustomerData: (data) => dispatch(loadCustomerData(data)),
        requestCompanyNameList: (user) => dispatch(requestCompanyNameList(user)),
        requestFerryRouteList: (payload) => dispatch(requestCorporateFerryList(payload)),
        addNewCustomer: (user,payload) => dispatch(addNewCustomer(user,payload)),
        updateCustomer: (id,user,payload)=>dispatch(updateCustomer(id,user,payload)),
        clearAlert : ()=> (dispatch(clearAlert()))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
