import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    orderList : [],
    isFetching : false,
    meta : null,
    page :1,
    isSearching: false,
}

function corporateOrderReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.REQUEST_CORPORATE_ORDERS : 
            return Object.assign({},state,{
                isFetching : true         
            });
        case actions.RECEIVE_CORPORATE_ORDERS :
            return Object.assign({},state,{
                orderList : (action.payload) ? action.payload.data : [],
                isFetching : false,
                meta : (action.payload)? action.payload.meta : null,
                page: action.page
            })   
        case actions.SEARCH_CORPORATE_ORDERS : 
            return Object.assign({},state,{
                isSearching : true
            });
        case actions.RECEIVE_SEARCH_CORPORATE_ORDERS : 
            return Object.assign({},state,{
                orderList:(action.payload)? action.payload.data : [],
                isSearching : false,
                meta : (action.payload)? action.payload.meta : null,
                page: action.page
            });
        default : return state                                                                  
    }
}

export default corporateOrderReducer;