import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import FuelStationForm from './FuelStationForm';

import { loadFuelStationData } from '../../actions/formActions';


class FuelStationModal extends Component {
    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadFuelStationData(this.props.editItem)
        } else {
            this.props.loadFuelStationData(null)
        }
    }
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            this.props.editItem == null ?
                                'New Fuel Station'
                                :
                                `FUEL STATION #${this.props.editItem.id} : ${this.props.editItem.name}`
                        }
                    </ModalHeader>
                    <ModalBody>
                        <FuelStationForm
                            callbackFormSuccess={this.props.callbackFormSuccess}
                            callbackHide={this.props.callbackHide}
                            editItem={this.props.editItem}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadFuelStationData: (data) => dispatch(loadFuelStationData(data))
    }
}

export default connect(null, mapDispatchToProps)(FuelStationModal);