import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    ferryCustomerList : [],
    filename: ''
}

function ferryCustomerReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.RECEIVE_FERRY_CUSTOMER_TEMPLATE_DATA :
            return Object.assign({},state,{
                ferryCustomerList : (action.payload.data) ? action.payload.data : [],
                fileName: action.payload.meta.filename
            })   
        default : return state                                                                  
    }
}

export default ferryCustomerReducer;