import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import { Button } from 'antd';
import 'antd/dist/antd.css';
import { pushError } from '../../actions/formActions';
import { requestCompanyNameList } from '../../actions/coporateCompanyAction';
import { assignCabCoperate } from '../../actions/cabActions';

class CabForm extends React.Component {    
    componentDidMount(){
        const {user}=this.props;        
        this.props.requestCompanyNameList(user);        
    }
    handleSubmit(){        
        const { user, cab_form } = this.props; 
        const assign_data = {
            cab_id: parseInt(cab_form.cab_form.values.cap_id),
            corporate_company_id: parseInt(cab_form.cab_form.values.corporate_company_id)
        }         
        this.props.assignCabCoperate(user.user, assign_data);
        this.props.callbackHide();
    }        
    render(){              
        return (            
        <form className="form">
            <div className="form-border">
                <FormInput 
                    labelName="Cap Id"
                    name="cap_id"
                    placeholder="Cap Id"
                    component="input"
                    type="number"
                    value={1603}
                /><br />     
                <FormInput
                    labelName="Company"
                    name="corporate_company_id"
                    placeholder="select company"
                    component="select"
                    type="number"
                    selectValue={this.props.coporateReducer.companyNameList}                
                    option={true}
                />
            </div>          
            <div className="form-group">            
                <Button 
                    type="primary"                                                 
                    size='large'
                    disabled={this.props.pristine || this.props.submitting}
                    onClick={()=> this.handleSubmit()}
                >
                    Submit
                </Button>                                        
                <Button 
                    size='large'
                    style={{ marginLeft: 5 }}
                    onClick={()=> this.props.callbackHide()}
                >
                    Cancel
                </Button>
            </div>
        </form>                                           
        );    
    }    
}

CabForm = reduxForm({    
    form: 'cab_form'
})(CabForm)

const mapStateToProps = state => ({     
    initialValues : state.operatorReducer.cab_form,
    cab_form          : state.form,
    user : state.user,
    coporateReducer: state.coporateReducer    
 });

const mapDispatchToProps = dispatch => {
    return{
        pushError     : (error)=> dispatch(pushError(error)),        
        requestCompanyNameList: (user)=> dispatch(requestCompanyNameList(user)),
        assignCabCoperate: (user, data)=> dispatch(assignCabCoperate(user, data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CabForm);