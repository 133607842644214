// import 'rxjs';
import {switchMap, map, catchError} from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import * as actions from '../constants/actionTypes';
import {logoutUser, pushUser} from '../actions/userActions';
import { pushError} from '../actions/errorActions';
import {receiveManagerData, receiveSearchManagerData} from '../actions/managerAction';
import {receiveCompnayNameList} from '../actions/coporateCompanyAction';
import {receiveCorporateFerryList} from '../actions/ferryActions';


import {loadDriverData,addNewDriver,updateDriver,assignDriver,searchDriverData} from './driverEpic';
import { loadCoporateCompanies,updateOperators,createOperators,searchCorporateCompanies} from './corpoateEpic';
import {loadCustomerData,addNewCustomer,updateCustomer,searchCustomerData,changeToNormalCustomer, searchCustomerAttendance } from './customerEpic';
import {loadTransaction,filterFuelTransaction,getFuelStation,exportCSV,searchFuelTransaction} from './fuelEpic';
import {loadCabsData,assignCab,cabAmount,cabUpdateAmount,assignCabDriver,removeAssignDriver,searchCabData,removeAssignCompany} from './cabEpic';

import {loadStationData,requestFuelStationPartners,addNewFuelStation,updateFuelStation,searchFuelStation} from './fuelStationEpic';

import {loadFuelPartnerData,addNewFuelPartner,updateFuelPartner,searchFuelPartner} from './fuelPartnerEpic';
import {loadAgentTransaction,filterAgentTransaction,exportAgentCSV} from './agentTransactionEpic';
import {loadAircabOrder,exportAircabOrderCSV,filterAircabOrder,searchAircabOrder} from './aircabOrderEpic';
import {loadAircabLocationMap,loadAircabStatus} from './aircabMapLocationEpic';

import {uploadDriverCsv} from './uploadCsvEpic'
// import {loadAircabOrder} from './aircabOrderEpic';

import {loadAgentData,updateAgentData,addNewAgent, searchAgentData} from './agentEpic'
import { loadCorporateOrders, searchCorporateOrdersData } from './corporateOrderEpic'
import { getFerryCustomerTemplateData } from './ferryEpic'
import { loadDriverAttendances, searchDriverAttendancesData, approvedDriverAttendanceByManager, driverHCMLocation, driverHCMDropLocation } from './driverHCMEpic'

import { API_URL } from '../constants/credentials';
import {pushAlert} from "../actions/alertActions";
import axios from "axios";
import queryString from "query-string";
import fileDownload from "js-file-download";

const authHeader= {
  'Authorization':'5280c76eaebdd9a6bbc28312ed14078d349e13346fcdc443845797708022db11',
  'Content-Type' : 'application/json'
};

/**
 * 
 * @param action$ 
 * @param store
 * @returns {any|*|Observable}
 */

function dataFetching( action$ ){
  return action$.pipe(
    ofType(actions.FETCH_DATA),
    switchMap((payload)=>
      ajax.getJSON(`${API_URL}/manage/api/corporate_companies`).pipe(
          map((response) =>{ }),
          catchError(error => {
            return of(error)
          })
          )
      ))    
}
function logoutAction(action$) {
    return action$.pipe(
        ofType(actions.LOGIN_USER),
    )
}
function loginUser(action$){
    return action$.pipe(
        ofType(actions.LOGIN_USER),
        switchMap((payload)=>
          ajax({
              url: `${API_URL}/sessions/managers`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', 
              },
              body: payload.payload
          }).pipe(
                map((response) => pushUser(response.response)),
                  catchError(error => { 
                    if(error === null){
                      return of(pushError(['something worng !!']))  
                    }else{
                      // return of(pushError(['something worng !!']))      
                      return of(pushError(error.response.errors))
                    }                    
                  })                  
                )          
          ))
    }

function updateUser(action$) {
    return action$.pipe(
        ofType(actions.UPDATE_USER),
        switchMap((payload)=> {
            return  axios.put(`${API_URL}/sessions/managers/${payload.id}`,{
                responseType:'json',headers:{'Authorization': payload.token}}).then((res)=>{
                const data = {
                    notice : "User Session Logout!!"
                }
                return pushAlert(data);
            })
        })
    )
}
    

function loadManagerData(action$){
  return action$.pipe(
     ofType(actions.REQUEST_MANAGER_DATA),
     switchMap((payload)=>
     ajax.getJSON(
         `${API_URL}/manage/api/corporate_managers?per_page=${payload.limit}&page=${payload.page}\`,`,
         authHeader
     ).pipe(
           map((response)=>receiveManagerData(response, payload.page)),
           catchError(error=>{
               if (error.status === 401) {
                   const errorData = {
                       message: "session expired: please try again "
                   }
                   return of(
                       pushError(errorData),
                       logoutUser()
                   )
               } else {
                   if(error.response){
                       return of(pushError(error.response.errors))
                   }else{
                       return of(pushError(error))
                   }
               }
           })
         ) 
     )
  )
}

function searchManagerData(action$){
    return action$.pipe(
        ofType(actions.SEARCH_MANAGER_DATA),
        switchMap((payload)=>
            ajax.getJSON(
                `${API_URL}/manage/api/corporate_managers?per_page=${payload.limit}&page=${payload.page}&search=${payload.param} `,
                authHeader
            ).pipe(
                map((response)=> receiveSearchManagerData(response, payload.page)),
                catchError(error=>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again "
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })
            )
        )
    )
}

function createManager(action$){
    return action$.pipe(
        ofType(actions.CREATE_MANAGER),
        switchMap((payload)=>
            ajax({
                url: `${API_URL}/manage/api/corporate_companies/corporate_manager_setup`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': payload.authorization
                },
                body: payload.payload
            }).pipe(
                map((response) => pushAlert({notice:"manager account created successfully"}, 'manager') ),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again "
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(['an error occurred while creating manager']))
                        }
                    }
                })
            )
        )
    )
}

function updateManager(action$){
    return action$.pipe(
        ofType(actions.UPDATE_MANAGER),
        switchMap((payload)=>
            ajax({
                url: `${API_URL}/manage/api/corporate_companies/corporate_manager_edit/${payload.managerId}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': payload.authorization
                },
                body: payload.payload
            }).pipe(
                map((response) => pushAlert({notice:"manager account updated successfully"}, "manager") ),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again "
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(['an error occurred while creating manager']))
                        }
                    }
                })
            )
        )
    )
}

function loadCompanyNameList(action$){
  return action$.pipe(
    ofType(actions.LOAD_COMPANY_NAME_LIST),
    switchMap((payload)=>
     ajax.getJSON(`${API_URL}/manage/api/corporate_companies/lists_array`,
        {
          'Authorization': payload.user.user.sso_token
        })     
         .pipe(
           map((response)=>{return receiveCompnayNameList(response)}),
           catchError(error=>{
               if (error.status === 401) {
                   const errorData = {
                       message: "session expired: please try again"
                   }
                   return of(
                       pushError(errorData),
                       logoutUser()
                   )
               } else {
                   if(error.response){
                       return of(pushError(error.response.errors))
                   }else{
                       return of(pushError(error))
                   }
               }

           })
         )
    )
  )
}

function loadCompanyFerryList(action$) {
    return action$.pipe(
        ofType(actions.REQUEST_COMPANY_FERRY_LIST),
        switchMap((payload)=>
            ajax.getJSON(`${API_URL}/manage/api/corporate_companies/${payload.payload}/ferry_cab_list`,
                authHeader)
                .pipe(
                    map((response)=>{return receiveCorporateFerryList(response)}),
                    catchError(error=>{
                        if (error.status === 401) {
                            const errorData = {
                                message: "session expired: please try again "
                            }
                            return of(
                                pushError(errorData),
                                logoutUser()
                            )
                        } else {
                            if(error.response) {
                                return of(pushError(error.response.errors))
                            } else {
                                return of(pushError(error))
                            }
                        }
                    })
                )
        )
    )
}

function downloadFerryCsv(action$) {
    return action$.pipe(
        ofType(actions.REQUEST_CSV),
        switchMap((payload)=> {
            let baseUrl = `${API_URL}/manage/api/corporate_customers?status=csv&start=${payload.meta.startDate}&end=${payload.meta.endDate}`

            if (payload.meta.companyId != 0) {
                const urlString = `&corporate_company_id=${payload.meta.companyId}`
                baseUrl = baseUrl.concat(urlString)
            }
            if (payload.meta.cabId != 0) {
                const urlString = `&corporate_cab_id=${payload.meta.cabId}`
                baseUrl = baseUrl.concat(urlString)
            }
            if (payload.meta.searchParam != "") {
                const searchParam = `&search=${payload.meta.searchParam}`
                baseUrl = baseUrl.concat(searchParam)
            }

            return  axios.get(baseUrl,{
                responseType:'blob',headers:{'Authorization': payload.payload}}).then((res)=>{
                fileDownload(res.data,`ferry_staff_list.csv`);
                const data = {
                    notice : "CSV File is Downloaded"
                }
                return pushAlert(data);
            })
        })
    )
}

function downloadQr(action$) {
    return action$.pipe(
        ofType(actions.DOWNLOAD_QR),
        switchMap((payload)=> {
            return  axios.get(payload.url,{
                responseType:'blob',headers:{'Authorization': payload.token}}).then((res)=>{
                fileDownload(res.data,`${payload.name}_Qr.jpg`);
                const data = {
                    notice : "QR code is Downloaded"
                }
                return pushAlert(data);
            })
        })
    )
}

function downloadCorporateOrdersExcel(action$) {
    return action$.pipe(
        ofType(actions.REQUEST_CORPORATE_ORDERS_EXCEL),
        switchMap((payload)=> {
            let baseUrl = `${API_URL}/manage/api/corporate_companies/corporate_orders.xlsx?start=${payload.meta.startDate}&end=${payload.meta.endDate}&status=${payload.meta.status}`

            if (payload.meta.companyId != 0) {
                const urlString = `&corporate_company_id=${payload.meta.companyId}`
                baseUrl = baseUrl.concat(urlString)
            }
            if (payload.meta.searchParam != "") {
                const searchParam = `&search=${payload.meta.searchParam}`
                baseUrl = baseUrl.concat(searchParam)
            }

            return  axios.get(baseUrl,{
                responseType:'blob',headers:{'Authorization': payload.payload}}).then((res)=>{
                fileDownload(res.data,`corporate_orders_excel.xlsx`);
                const data = {
                    notice : "Excel File is Downloaded"
                }
                return pushAlert(data);
            })
        })
    )
}

function downloadDriverAttendancesExcel(action$) {
    return action$.pipe(
        ofType(actions.REQUEST_DRIVER_ATTENDANCES_EXCEL),
        switchMap((payload)=> {
            let baseUrl = `${API_URL}/manage/api/corporate_driver_attendances?start=${payload.meta.startDate}&end=${payload.meta.endDate}&status=csv`

            if (payload.meta.companyId != 0) {
                const urlString = `&corporate_company_id=${payload.meta.companyId}`
                baseUrl = baseUrl.concat(urlString)
            }
            if (payload.meta.searchParam != "") {
                const searchParam = `&search=${payload.meta.searchParam}`
                baseUrl = baseUrl.concat(searchParam)
            }

            return  axios.get(baseUrl,{
                responseType:'blob',headers:{'Authorization': payload.payload}}).then((res)=>{
                fileDownload(res.data,`corporate_driver_attendances.csv`);
                const data = {
                    notice : "Excel File is Downloaded"
                }
                return pushAlert(data);
            })
        })
    )
}

function editRoute(action$) {
    return action$.pipe(
        ofType(actions.EDIT_ROUTE),
        switchMap((payload)=>
            ajax({
                url: `${API_URL}/manage/api/corporate_managers/update_ferry_route`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': payload.token
                },
                body: payload.payload
            }).pipe(
                map((response) => pushAlert({notice:"current driver route is updated"}, "manager") ),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again "
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(['an error occurred while creating manager']))
                        }
                    }
                })
            )
        )
    )
}

export const rootEpic = combineEpics(    
    dataFetching,
    loadCoporateCompanies,
    loadCustomerData,
    searchCustomerAttendance,
    loadDriverData,
    loginUser,
    updateUser,
    createOperators,
    updateOperators,
    searchCorporateCompanies,            
    loadManagerData,
    searchManagerData,
    createManager,
    updateManager,
    loadCompanyNameList,
    loadCompanyFerryList,
    downloadFerryCsv,
    downloadQr,
    editRoute,
    getFerryCustomerTemplateData,
    addNewCustomer,
    addNewDriver,
    updateCustomer,
    changeToNormalCustomer,
    updateDriver,
    assignDriver,
    cabAmount,
    cabUpdateAmount,
    loadCabsData,
    assignCab,
    assignCabDriver,
    removeAssignDriver,
    removeAssignCompany,
    loadDriverData, 
    loadTransaction,
    filterFuelTransaction,
    searchFuelTransaction,
    getFuelStation,
    exportCSV,
    loadStationData,
    requestFuelStationPartners,
    addNewFuelStation,
    updateFuelStation,
    loadFuelPartnerData,
    addNewFuelPartner,
    updateFuelPartner,
    searchFuelPartner,
    searchDriverData,
    searchFuelStation,
    searchCabData,
    searchCustomerData,
    loadAgentTransaction,
    filterAgentTransaction,
    

    loadAgentData,
    updateAgentData,
    addNewAgent,
    exportAgentCSV,
    searchAgentData,
    
    loadAircabOrder,
    exportAircabOrderCSV,
    filterAircabOrder,
    searchAircabOrder,
    loadAircabLocationMap,
    loadAircabStatus,

    uploadDriverCsv,
    
    loadCorporateOrders,
    searchCorporateOrdersData,
    downloadCorporateOrdersExcel,

    loadDriverAttendances,
    searchDriverAttendancesData,
    downloadDriverAttendancesExcel,
    approvedDriverAttendanceByManager,
    driverHCMLocation,
    driverHCMDropLocation
);