import React, { Component } from 'react';

import './styles.scss';
const setting = require('../../assests/images/more.svg');
const edit = require('../../assests/images/edit.svg');
const remove = require('../../assests/images/delete.svg');
const view = require('../../assests/images/visibility-button.svg');
const add_money = require('../../assests/images/add.svg');
const add_user = require('../../assests/images/add-user.svg');
const assign_cab = require('../../assests/images/car.svg');
const remove_person = require('../../assests/images/remove_person.svg');
const remove_company = require('../../assests/images/company.svg');

class MoreOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.handleOpenOpt = this.handleOpenOpt.bind(this);
        this.hideOpt = this.hideOpt.bind(this);
    }

    handleOpenOpt(event) {
        event.preventDefault();
        this.setState({ open: true }, () => {
            document.addEventListener('click', this.hideOpt);
        });
    }
    hideOpt() {
        this.setState({ open: false }, () => {
            document.removeEventListener('click', this.hideOpt);
        });
    }
    render() {
        switch (this.props.type) {
            case 'operator':
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackView}><img src={view} alt="view item detail" /><span>View</span></li>
                            <li onClick={this.props.callbackEdit}><img alt="edit item data" src={edit} /><span>Edit</span></li>
                            <li onClick={this.props.callbackAddAmount}><img alt="assign item data" src={add_money} /><span>Add Cap Amount</span></li>

                        </ul>
                    </div>
                );
            case 'manager':
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackView}><img src={view} alt="view item detail" /><span>View</span></li>
                            <li onClick={this.props.callbackEdit}><img alt="edit item data" src={edit} /><span>Edit</span></li>

                        </ul>
                    </div>
                );
            case 'driver':
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackView}><img src={view} alt="view item detail" /><span>View</span></li>
                            <li onClick={this.props.callbackEdit}><img alt="edit item data" src={edit} /><span>Edit</span></li>
                            {/* <li onClick={this.props.callbackAssign}><img alt="assign item data" src={add_user} /><span>Assign Cab</span></li> */}
                        </ul>
                    </div>
                );
            case 'cab':
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackAssign}><img alt="assign item data" src={assign_cab} /><span>Assign to company</span></li>
                            <li onClick={this.props.callbackAssignCabtoDriver}><img alt="assign item data" src={assign_cab} /><span>Assign to driver</span></li>
                            <li onClick={this.props.callbackEditFerryRoute}><img alt="edit item data" src={assign_cab} /><span>Edit ferry route</span></li>
                            <li onClick={this.props.callbackRemoveAssignDriver}><img alt="assign item data" src={remove_person} /><span>Remove assign driver</span></li>
                            <li onClick={this.props.callbackRemoveAssignCompany}><img alt="assign item data" src={remove_company} /><span>Remove assign Company</span></li>
                        </ul>
                    </div>
                );
            case 'fuel_station':
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackView}><img src={view} alt="view item detail" /><span>View</span></li>
                            <li onClick={this.props.callbackEdit}><img alt="edit item data" src={edit} /><span>Edit</span></li>

                        </ul>
                    </div>
                );
            case 'driver_attendance':
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackApproved}><img src={edit} alt="view item detail" /><span>Approve / Reject</span></li>
                        </ul>
                    </div>
                );
            default:
                return (
                    <div className="dropdown">
                        <button className="opt-btn" onClick={this.handleOpenOpt}>
                            <img className="option" src={setting} alt="oway ride table row setting option images" />
                        </button>
                        <ul className="opt-list" style={this.state.open ? { display: 'block' } : { display: 'none' }}>
                            <li onClick={this.props.callbackView}><img src={view} alt="view item detail" /><span>View</span></li>
                            <li onClick={this.props.callbackEdit}><img alt="edit item data" src={edit} /><span>Edit</span></li>
                            <li onClick={this.props.callbackChangeToNormal}><img alt="assign item data" src={add_money} /><span>Change to Normal User</span></li>
                        </ul>
                    </div>
                );
        }
    }
}

export default MoreOption;
