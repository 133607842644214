import * as actions from '../constants/actionTypes';
const INITIAL_STATE={
    stationList :[],
    isFetching : false,
    isSearching: false,
    partners:[],
    isUpdated:false,
    meta:null,
    page: 1,
    limit : 25

}
function toPairs(jsonObj){
    let partners = [];
    Object.keys(jsonObj).forEach(function(key) {
         const partner = {
             id : key,
             name : jsonObj[key]
         }
         partners.push(partner);
      })
    return partners;
}
function fuelStationReducer(state = INITIAL_STATE,action){
    switch(action.type){
        case actions.REQUEST_FUEL_STATION : 
            return Object.assign({}, state, {
                isFetching: true,
                limit : action.limit
            });  
        case actions.RECEIVE_FUEL_STATION : 
            return Object.assign({},state,{
                isFetching : false,
                stationList :(action.payload)?action.payload.data:[],
                meta : (action.payload)?action.payload.meta:null,
                page: action.page
            });
        case actions.RECEIVE_FUEL_STATION_PARTNER :
            const partners = toPairs(action.payload);
            return Object.assign({},state,{
                partners : partners
            })  
        case actions.UPDATE_FUEL_STATION:
                return Object.assign({}, state, {
                    isUpdated: false
                });
        case actions.PUSH_ALERT: 
                if (action.updatedData === "fuel_station") 
                    return Object.assign({}, state, { isUpdated: true })  
                 else
                    return state 
        case actions.SEARCH_FUEL_STATION :
            return Object.assign({},state,{
                        isSearching : true 
                    })
        case actions.RECEIVE_FUEl_STATION_SEARCH_RESULT :
            return Object.assign({},state,{
                        isSearching : false,
                        stationList : (action.payload)? action.payload.data : [],
                        meta : (action.payload)?action.payload.meta : null,
                        page : action.page
                    })             
        default : return state;      
    }
}
export default fuelStationReducer;