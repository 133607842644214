import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import AgentForm from './AgentForm';
import { loadAgentData } from '../../actions/formActions';
class AgentModal extends Component {
    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadAgentData(this.props.editItem)
        } else {
            this.props.loadAgentData(null)
        }
    }
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            this.props.editItem == null ?
                                'New Agent'
                                :
                                `AGENT #${this.props.editItem.id} : ${this.props.editItem.name}`
                        }
                    </ModalHeader>
                    {/* {this.state.showErr && this.renderErrorMsg()} */}
                    {/* {this.state.isOpenSuccess && this.renderSuccessMessage()} */}
                    <ModalBody>
                        <AgentForm
                            editItem={this.props.editItem}
                            callbackHide={this.props.callbackHide}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadAgentData: (data) => dispatch(loadAgentData(data))
    }
}

export default connect(null, mapDispatchToProps)(AgentModal);