import * as actions from '../constants/actionTypes';

const INITIAL_STATE={
    driverList :[],
    isFetching : false,
    meta:null,
    isSearching : false,
    isUpdated : false,
    page: 1,
    limit : 25
}

function driverReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.REQUEST_DRIVER_DATA : 
            return Object.assign({}, state, {
                    isFetching: true,
                    limit : action.limit
                    });
        case actions.RECEIVE_DRIVER_DATA_RESULT : 
            return Object.assign({},state,{
                    driverList :(action.payload)?action.payload.data:[],
                    isFetching : false,
                    meta : (action.payload)?action.payload.meta:null,
                    page: action.page
                    });
        // case actions.ADD_NEW_DRIVER : 
        //     return Object.assign({},state,{
        //             isFetching : true
        //             });
        case actions.SUCCESS_ADDING_DRIVER :
            return Object.assign({},state,{
                      isFetching : false,
                      driverList : [...state.driverList,action.payload]
                    })  
        case actions.SEARCH_DRIVER_DATA :
            return Object.assign({},state,{
                      isSearching : true 
                    })

        case actions.PUSH_ALERT: 
                    if (action.updatedData === "driver" ) 
                       return Object.assign({}, state, { isUpdated: true })  
                    else
                       return state            
        
        case actions.RECEIVE_DRIVER_SEARCH_RESULT :
            return Object.assign({},state,{
                       isSearching : false,
                       driverList : (action.payload)? action.payload.data : [],
                       meta : (action.payload)?action.payload.meta : null,
                       page : action.page
                    })            
                                                    
        default : return state;
    }
}
export default driverReducer;