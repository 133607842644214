import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    partnerList: [],
    isFetching: false,
    isSearching: false,
    partners: [],
    isUpdated : false,
    meta: null,
    page: 1,
    limit : 25
}

function fuelPartnerReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actions.REQUEST_FUEL_PARTNER:
            return Object.assign({}, state, {
                isFetching: true,
                limit : action.limit
            });
        case actions.RECEIVE_FUEL_PARTNER:
            return Object.assign({}, state, {
                isFetching: false,
                partnerList: (action.payload) ? action.payload.data : [],
                meta: (action.payload) ? action.payload.meta : null,
                page: action.page
            });
        case actions.UPDATE_FUEL_PARTNER:
            return Object.assign({}, state, {
                isUpdated: false
            });
        case actions.SEARCH_FUEL_PARTNER : 
            return Object.assign({},state,{
            isSearching: true,
            limit : action.limit
        });
        case actions.RECEIVE_FUEL_PARTNER_SEARCH_RESULT :
            return Object.assign({},state,{
                    isSearching : false,
                    partnerList : (action.payload)? action.payload.data : [],
                    meta : (action.payload)?action.payload.meta : null,
                    page : action.page,
                    limit : action.limit
        });         
        case actions.PUSH_ALERT: 
             if (action.updatedData === "fuel_partner") 
                return Object.assign({}, state, { isUpdated: true })  
             else
                return state

        default: return state;
    }
}

export default fuelPartnerReducer;