import React from 'react';
import { connect } from 'react-redux'
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'
import DriverForm from './DriverForm';
import { loadDriverData } from '../../actions/formActions';

class DriverModal extends React.Component {
    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadDriverData(this.props.editItem);
        } else {
            this.props.loadDriverData(null)
        }
    }

    validInput() {

    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            this.props.editItem == null ?
                                'New Driver'
                                :
                                `DRIVER #${this.props.editItem.id} : ${this.props.editItem.name}`
                        }
                    </ModalHeader>
                    {/* {this.state.showErr && this.renderErrorMsg()} */}
                    {/* {this.state.isOpenSuccess && this.renderSuccessMessage()} */}
                    <ModalBody>
                        <DriverForm editItem={this.props.editItem}
                            callbackHide={this.props.callbackHide} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadDriverData: (data) => dispatch(loadDriverData(data))
    }
}

export default connect(null, mapDispatchToProps)(DriverModal);