import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import { Row, Col } from 'reactstrap';
import { Button } from 'antd';
import { pushError } from '../../actions/formActions';
import {updateAgent,addNewAgent} from '../../actions/agentActions';
class AgentForm extends Component {
    constructor(props){
         super(props);
         this.state={
             errors : null
         }
    }
    componentDidMount(){
        const {user} = this.props;
    }
    handleSubmit(){
      const {agent_form,user} = this.props;
      if(agent_form.agent_form.values){
        let errors = {};
        let payload = {};
        if(agent_form.agent_form.values.name){
            payload = {...payload,name : agent_form.agent_form.values.name}
        }else{
            errors = {...errors,name:"Please enter agent name"}
        }

        if(agent_form.agent_form.values.phone){
            payload = {...payload,phone : agent_form.agent_form.values.phone}
        }
        else{
            errors = {...errors,phone:"Please enter phone"}
        }
        if(Object.keys(errors).length === 0){
            this.props.addNewAgent(user.user,{agent:payload});
            this.props.callbackHide();
        }else{
          this.setState({errors :errors})
        }
      }else{
        const  errors = {
              name  : "Please enter agent name",
              phone : "Please enter phone"
          }
          this.setState({errors :errors})
      }
    }
    handleUpdate(){
        const { agent_form,user } = this.props;
        let id = this.props.editItem.id;
        if(agent_form.agent_form.values){
            let errors = {};
            let payload = {};
            if(agent_form.agent_form.values.name){
                payload = {...payload,name : agent_form.agent_form.values.name}
            }else{
                errors = {...errors,name:"Please enter agent name"}
            }

            if(agent_form.agent_form.values.phone){
                payload = {...payload,phone : agent_form.agent_form.values.phone}
            }
            else{
                errors = {...errors,phone:"Please enter phone"}
            }
            if(agent_form.agent_form.values.username){
                payload = {...payload,username : agent_form.agent_form.values.username}
            }else{
                errors = {...errors,username:"Please enter agentusername"}
            }
            if(Object.keys(errors).length === 0){
                this.props.updateAgent(user.user,{agent:payload},id);
                this.props.callbackHide();
            }else{
              this.setState({errors :errors})
            }
        }else{
          const  errors = {
                name  : "Please enter agent name",
                phone : "Please enter phone"
            }
            this.setState({errors :errors})
        }

    }
    render() {
        return (
            <form className="form">
            <div className="form-border">
            <FormInput 
                labelName="Name"
                name="name"
                placeholder="Enter Name"
                component="input"
                type="text"
            /> 
            <Row>
                <Col sm={4}></Col>
                <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.name)? this.state.errors.name : null}</span></Col>
            </Row>
            <FormInput 
                labelName="Phone"
                name="phone"
                placeholder="Enter Phone"
                component="input"
                type="text"
            /> 
            <Row>
                <Col sm={4}></Col>
                <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.phone)? this.state.errors.phone : null}</span></Col>
            </Row>
            {
                this.props.editItem != null &&
                <>
                    <FormInput
                        labelName="Username"
                        name="username"
                        placeholder="Enter Username"
                        component="input"
                        type="text"
                    />
                    <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.username)? this.state.errors.username : null}</span></Col>
                    </Row>
                </>
            }
            </div>                                          
            <div className="form-group">   
            {
                this.props.editItem != null ?
                    <Button 
                        disabled={this.props.pristine || this.props.submitting}
                        type='primary' 
                        size='large' 
                        onClick={() => this.handleUpdate()}>Submit</Button>
                    :
                    <Button 
                    disabled={this.props.pristine || this.props.submitting}
                    type='primary' 
                    size='large' 
                    onClick={() => this.handleSubmit()}>Submit</Button>
            }                                               
            <Button
                style={{marginLeft: 5}}
                size='large'
                onClick={()=> this.props.callbackHide()}
                >
                Cancel
            </Button>
            </div>
        </form>
        );
    }
}

AgentForm = reduxForm({
    form : 'agent_form'
})(AgentForm);

const mapStateToProps = state => ({     
    initialValues : state.operatorReducer.agent_form,
    agent_form          : state.form,
    user : state.user,
    coporateReducer: state.coporateReducer    
 });

const mapDispatchToProps = dispatch => {
    return{
        pushError     : (error)=> dispatch(pushError(error)),
        updateAgent :(user,data,id)=> dispatch(updateAgent(user,data,id)),
        addNewAgent : (user,data)=> dispatch(addNewAgent(user,data))      
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AgentForm);