import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    agentList : [],
    isFetching : false,
    isSearching: false,
    meta : null,
    page :1
}

function agentReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.REQUEST_AGENT : return Object.assign({},state,{
                                       isFetching : true         
                                    });
        case actions.RECEIVE_AGENT_DATA : return Object.assign({},state,{
                                            agentList : (action.payload)? action.payload.data : [],
                                            isFetching : false,
                                            meta : (action.payload)?action.payload.meta:null,
                                            page: action.page
                                         }) 
        case actions.SEARCH_AGENT : return Object.assign({},state,{
                                                isSearching : true
                                             });
        case actions.RECEIVE_SEARCH_AGENT_DATA : return Object.assign({},state,{
                                                    agentList:(action.payload)? action.payload.data : [],
                                                    isSearching : false,
                                                    meta : (action.payload)? action.payload.meta : null,
                                                    page: action.page
                                                }); 
        case actions.PUSH_ALERT: 
                                if (action.updatedData === "agent" )
                                    return Object.assign({}, state, { isUpdated: true })  
                                else
                                    return state
        default : return state                                                                  
    }
}

export default agentReducer;