import * as actions from '../constants/actionTypes';
const INITIAL_DATA = {
    managerList: [],
    manager: null,
    isFetching: false,
    isLoading: false,
    isUpdated: false,
    isSearching: false,
    meta: null,
    page: 1,
    limit : 25,
}

function managerReducer(state = INITIAL_DATA, action){
    switch(action.type){
        case actions.REQUEST_MANAGER_DATA :
            return Object.assign({}, state, {
                isFetching: true,
                isUpdated: false,
            });
        case actions.SEARCH_MANAGER_DATA :
            return Object.assign({}, state, {
                isSearching: true,
                isUpdated: false,
            });
        case actions.RECEIVE_MANAGER_DATA_RESULT :
            return Object.assign({},state, {
                managerList: action.payload.data,
                isFetching: false,
                isUpdated: false,
                meta: action.payload.meta,
                page: action.page
            });
        case actions.RECEIVE_SEARCH_MANAGER_DATA :
            return Object.assign({},state,{
                isSearching : false,
                managerList : (action.payload)? action.payload.data : [],
                meta : (action.payload)?action.payload.meta : null,
                page : action.page
            })
        case actions.PUSH_ALERT: 
            if (action.updatedData === "manager" ) 
               return Object.assign({}, state, { isUpdated: true })  
            else
               return state 
        default : return state;                                                                                                  
    }
}

export default managerReducer;