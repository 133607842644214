import React from 'react';
import { Badge } from 'reactstrap';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader
} from 'reactstrap';


const FuelPartnerDetail = ({viewItem, isOpen, hide}) => {
    return (
        <Modal 
        isOpen={isOpen} 
        toggle={hide}
        size="xl" 
        >
        <ModalHeader>
            { viewItem ? viewItem.name : 'view details'}
        </ModalHeader>
        <Card>                    
            {
                viewItem ?
                    <ListGroup>            
                        <ListGroupItem tag="a" href="#" action><span>Name</span><span>{viewItem.name}</span></ListGroupItem>
                        <ListGroupItem tag="a" href="#" action><span>Station Code</span><span>{viewItem.station_code}</span></ListGroupItem> 
                        <ListGroupItem tag="a" href="#" action><span>Phone</span><span>{viewItem.phone}</span></ListGroupItem> 
                        <ListGroupItem tag="a" href="#" action><span>Address</span><span>{viewItem.address}</span></ListGroupItem> 
                        <ListGroupItem tag="a" href="#" action><span>Manager Name</span><span>{viewItem.manager_name}</span></ListGroupItem> 
                        <ListGroupItem tag="a" href="#" action><span>Manager Email</span><span>{viewItem.manager_email}</span></ListGroupItem> 
                        <ListGroupItem tag="a" href="#" action><span>Manager Phone</span><span>{viewItem.manager_phone}</span></ListGroupItem>  
                        <ListGroupItem tag="a" href="#" action><span>Number of Fuel Stations</span><span>{viewItem.fuel_stations.length}</span></ListGroupItem>                              
                        <ListGroupItem tag="a" href="#" action>
                            <span>Status</span>
                            <span>
                                {
                                    viewItem.inactive === 1 ? 
                                    <Badge color="danger" className="badge-padding">Inactive</Badge>
                                        :
                                    <Badge color="success" className="badge-padding">Active</Badge>
                                }
                            </span>
                        </ListGroupItem>                                
                    </ListGroup>
                    :
                    <div>something worng</div>
            }                                        
        </Card>
    </Modal>
    );
};

export default FuelPartnerDetail;

