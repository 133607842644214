import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Badge,
    Button,
    CardFooter
} from 'reactstrap';
import history from '../../utils/history';
class Profile extends Component {
    render() {
        const { user } = this.props.user;
        return (
            <div className='content'>
                <Row className='profile-page'>
                    <Col md="12" sm="12" xs="12">
                        <Card>
                            <CardHeader>Admin Profile</CardHeader>
                            <CardBody>
                                {
                                    (user) ?
                                        <>
                                            <Row>
                                                <Col sm={3}><span>Name</span></Col>
                                                <Col sm={9}><span>{user.name}</span></Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}><span>Username</span></Col>
                                                <Col sm={9}><span>{user.username}</span></Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}><span>Email</span></Col>
                                                <Col sm={9}><span>{user.email}</span></Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}><span>Phone</span></Col>
                                                <Col sm={9}><span>{user.phone}</span></Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}><span>Role</span></Col>
                                                <Col sm={9}>
                                                    <Badge className="badge-padding" color={(user.role.name === "Superadmin") ? "info" : "success"}>
                                                        {user.role.name}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}><span>Status</span></Col>
                                                <Col sm={9}>
                                                    <Badge className="badge-padding" color={(user.status === "enabled") ? "success" : "danger"}>
                                                        {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <Row><h4>No Data</h4></Row>
                                }

                            </CardBody>
                            <CardFooter>
                                <Button onClick={() => history.goBack()}>Back</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, null)(Profile);