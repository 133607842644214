import {switchMap, map, catchError} from 'rxjs/operators';
import {  ofType } from 'redux-observable';
import { of } from 'rxjs';
import { pushError} from '../actions/errorActions';
import { pushAlert } from "../actions/alertActions";
import { API_URL } from '../constants/credentials';
import axios from "axios";
import { receiveFerryCustomerData } from '../actions/ferryActions';
import * as actions from '../constants/actionTypes';

export function getFerryCustomerTemplateData(action$) {
    return action$.pipe(
        ofType(actions.REQUEST_FERRY_CUSTOMER_TEMPLATE_DATA),
        switchMap((payload)=> {
            const url = (payload.companyId === 0) ? `${API_URL}/manage/api/corporate_customers/download_ferry_customers` : `${API_URL}/manage/api/corporate_customers/download_ferry_customers?corporate_company_id=${payload.companyId}`;
            return  axios.get(url,{
                responseType:'json',headers:{'Authorization': payload.token}}).then((res)=>{
                const data = {
                  notice: res['data']['message']
                }
                return pushAlert(data);
            })
        })
    )
}