import * as actions from '../constants/actionTypes';

export function requestManagerData(userToken, page, limit){
    return ({
        type : actions.REQUEST_MANAGER_DATA,
        token: userToken,
        page : page,
        limit: limit,
    })
}

export function searchManagerData(userToken, page, limit, param) {
    return ({
        type : actions.SEARCH_MANAGER_DATA,
        token: userToken,
        page : page,
        limit: limit,
        param: param,
    })
}

export function receiveManagerData(data, page){
    return ({
        type : actions.RECEIVE_MANAGER_DATA_RESULT,
        payload : data,
        page : page,
    })
}

export function receiveSearchManagerData(data,page){
    return ({
        type : actions.RECEIVE_SEARCH_MANAGER_DATA,
        payload : data,
        page : page
    })
}

export function createManager(payload, token){
    return ({
        type : actions.CREATE_MANAGER,
        payload: payload,
        authorization: token
    })
}

export function updateManager(payload, token, managerId){
    return ({
        type : actions.UPDATE_MANAGER,
        payload : payload,
        authorization : token,
        managerId : managerId
    })
}

export function loadManager(payload) {
    return({
        type : actions.LOAD_MANAGER,
        payload : payload
    })
}