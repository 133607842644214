import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import * as fileDownload from 'js-file-download';
import { API_URL } from '../constants/credentials';
import axios from 'axios';
import queryString from 'query-string';
import { pushAlert } from '../actions/alertActions';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import {receiveAgentTransactions,filterAgentTransactions} from '../actions/agentTransactionAction';
import {logoutUser} from "../actions/userActions";

export function loadAgentTransaction(action$){
    return action$.pipe(
        ofType(actions.REQUEST_AGENT_TRANSACTION),
        switchMap((payload)=>{ 
            return ajax.getJSON(`${API_URL}/manage/api/agents/agent_transactions?per_page=${payload.limit}&page=${payload.page}`,{
                'Authorization': payload.user.sso_token
            })
            .pipe(
                map((response)=>{ return receiveAgentTransactions(response,payload.page)}),
                catchError(error=>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again "
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                  })
            )
        })
    )
}

export function exportAgentCSV(action$){
  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
   return action$.pipe(
         ofType(actions.EXPORT_AGENT_TRANSACTION_CSV),
         switchMap((payload)=>{
           let parameters = {};
           if(payload.query && payload.query.start){
            parameters = {...parameters,start:payload.query.start};
          }
          if(payload.query && payload.query.end){
            parameters = {...parameters,end : payload.query.end};
          }
          if(payload.query && payload.query.transaction_type){
           parameters = {...parameters,transaction_type : payload.query.transaction_type};
          }
          if(payload.query && payload.query.driver_phone_number){
           parameters = {...parameters,driver_phone_number : payload.driver_phone_number};
          }
          if(payload.query && payload.query.agent_phone_number){
            parameters = {...parameters,agent_phone_number : payload.agent_phone_number};
          }
          return  axios.get(
              `${API_URL}/manage/api/agents/agent_transactions.xlsx?${queryString.stringify(parameters)}`, {
                  responseType:'blob',
                  headers: {
                      'Authorization': payload.user.sso_token
                  }
              }).then((res)=>{
                 fileDownload(res.data,`Agent_Transactions_${day}${month}${year}.xlsx`);
                 const data = {
                     notice : "CSV File is Downloaded"
                 }
                return pushAlert(data);
              })
         })
   )
}

export function filterAgentTransaction(action$){
    return action$.pipe(
        ofType(actions.FILTER_AGENT_TRANSACTION),
        switchMap((payload)=>{
            let parameters = {};
            if(payload.query && payload.query.start){
              parameters = {...parameters,start:payload.query.start};
            }
            if(payload.query && payload.query.end){
              parameters = {...parameters,end : payload.query.end};
            }
            if(payload.query && payload.query.transaction_type){
             parameters = {...parameters,transaction_type : payload.query.transaction_type};
            }
            if(payload.query && payload.query.driver_id){
             parameters = {...parameters, driver_id : payload.query.driver_id};
            }
            if(payload.query && payload.query.agent_phone){
              parameters = {...parameters, agent_phone : payload.query.agent_phone};
            }
            return ajax.getJSON(`${API_URL}/manage/api/agents/agent_transactions?${queryString.stringify(parameters)}&page=${payload.page}`,{
                'Authorization': payload.user.sso_token
            })
            .pipe(
                map((response)=>{ return receiveAgentTransactions(response,payload.page,true)}),
                catchError(error=>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })
            )
        })
    )
}