import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    requestFuelTransaction, filterFuelTransaction, getCoporateCompany,
    getStationName, exportCSV, selectFilter,searchFuelTransaction
} from '../../actions/fuelTransactionActions';
import { clearAlert } from '../../actions/alertActions';
import ListingTables from '../../components/ListingTables';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import { requestCompanyNameList } from '../../actions/coporateCompanyAction';
import './style.scss';
import { pushError} from '../../actions/errorActions';
import { DatePicker, AutoComplete, Button, Input } from 'antd';
import moment from 'moment'
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Card,
    CardHeader,
    Label,
    CardBody,
    CardFooter,
    ListGroup,
    ListGroupItem
} from 'reactstrap'
import history from "../../utils/history";
class FuelTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            query: {},
            searchTerm: '',
            stations: [],
            start: '',
            end: '',
            company_id: 0,
            station_query: '',
            showList: false,
            showEntries: 25,
            isResetting: false
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleAutoCompleteOnChange = this.handleAutoCompleteOnChange.bind(this);
        this.handleAutoCompleteSelect = this.handleAutoCompleteSelect.bind(this);
        this.handleChangeCalender = this.handleChangeCalender.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleExportCSV = this.handleExportCSV.bind(this);
        this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);
    }
    componentDidMount() {
        if (this.props.fuelTransactionReducer.transactionList.length === 0) {
            this.loadData();
            this.loadCompanyName();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // if (prevState.query.start != this.state.query.start) { 
        //     const { user } = this.props;
        //     const page = 1;
        //     const query = this.state.query;
        //     this.props.filterFuelTransaction(user, query, page);
        // }
    }
    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({ isOpenForm: true })
    handleHideForm = () => this.setState({ isOpenForm: false, editItem: null })
    loadByLimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        if(this.isEmpty(this.state.query))
            this.props.requestFuelTransaction(user.user, 1, limit);   
        else
            this.props.filterFuelTransaction(user.user, this.state.query, 1, limit); 
    } 
  
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    loadData() {
        const { user } = this.props;
        const page = 1;
        if (user.user) {
            this.props.requestFuelTransaction(user.user, page, this.state.showEntries);
        }
        this.setState({ isResetting: false })
    }
    loadCompanyName() {
        const { user } = this.props;
        if (user.user) {
            this.props.requestCompanyNameList(user);
        } else {
            history.push('/sign_in');
        }

    }
    loadStationName(query) {
        const { user } = this.props;
        this.props.getStationName(user.user, query);
    }
    loadByPagy(page) {
        const { user } = this.props;
        this.setState({ isResetting: false })
        if(this.isEmpty(this.state.query))
            this.props.requestFuelTransaction(user.user, page, this.state.showEntries);
        else
            this.props.filterFuelTransaction(user.user, this.state.query, page, this.state.showEntries);
    }
    search(params){
        const { user } = this.props;   
        this.props.searchFuelTransaction(user.user, 1, params, this.state.showEntries);
    } 
    
    handleSelectChange(e) {
        let query = this.state.query;
        let name = e.target[e.target.selectedIndex].getAttribute('data')
        if(e.target.value !== 0){
            const item = {
                id: e.target.value,
                name
            }
            query[e.target.name] = item;
            this.setState({ query, company_id: e.target.value });
            this.props.selectFilter();
        }else{
            const item = {
                id: e.target.value,
                name
            }
            query[e.target.name] = item;
            this.setState({query,company_id:e.target.value});
        }
    }
    handleSearchChange(e) {
        let query = this.state.query;
        let searchTerm = e.target.value;
        query[e.target.name] = searchTerm;
        this.setState({
            query,
            searchTerm
        });
    }
    handleAutoCompleteSelect(item) {
        let query = this.state.query;
        query['station'] = item;
        this.setState({
            query,
            station_query: item.name,
            showList: false
        })
        this.props.selectFilter();
    }
    handleFilter(e) {
        const { user } = this.props;
        const page = 1;
        if(this.isEmpty(this.state.query)){    
            const errors = {
                message : "Please select at least one filter"
            }
           this.props.pushError(errors);
        }else{
            this.props.filterFuelTransaction(user.user, this.state.query, page);
        }
    }
    handleDeleteFilter(key) { 
        let query = this.state.query;
        delete query[key];
        switch (key) {
            case "station": this.setState({ station_query: '' }); break;
            case "driver_id": this.setState({ searchTerm: '' }); break;
            case "start": this.setState({ start: '' }); break;
            case "end": this.setState({ end: '' }); break;
            case "company": this.setState({ company_id: 0 }); break;
            default :break;
        }

        this.setState({ query });
        const { user } = this.props;
        const page = 1;
        this.props.filterFuelTransaction(user.user, query, page);
    }
    handleResetFilter() {
        const query = {};
        const { user, fuelTransactionReducer } = this.props;

        this.setState({
            query,
            searchTerm: '',
            stations: [],
            start: '',
            end: '',
            company_id: 0,
            station_query: '',
            isResetting: true
        });
        this.setState({ isResetting: true })
        this.props.requestFuelTransaction(user.user, fuelTransactionReducer.page, this.state.showEntries);
        
    }
    handleExportCSV() {
        const { user } = this.props;
        this.props.exportCSV(user.user, this.state.query);
    }

    handleChangeCalender(type, date, dateString) {
        let query = this.state.query;
        query[type] = dateString;
        this.setState({
            query,
            [type]: dateString
        });
        this.props.selectFilter();
    }
    handleAutoCompleteOnChange(e) {
        let text = e.target.value;
        if (text.length > 3) {
           
            this.setState({ showList: true, station_query: text });
            this.loadStationName(text);
        } else {
            this.setState({ showList: false, station_query: text });
        }
        if(text.length == 0 ){
            this.loadData();
        }
    }
    renderAutoCompleteItems(stations) {
        if (stations.length > 0)
            return stations.map((station) => {
                return <ListGroupItem key={station.id}
                    onClick={() => this.handleAutoCompleteSelect(station)}>
                    {station.name}
                </ListGroupItem>
            })
        else
            return <ListGroupItem>No result Found</ListGroupItem>
    }
    getdataSource () {
        const companyLists =  this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value : item.name
        }));
        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }

    handleSelectCompanyList = (e) => {
        let company = this.props.coporateReducer.companyNameList.find(c => c.name == e);
        let query = this.state.query;
        query['company'] = company;
        this.setState({ query, company_id: company.id })
    }

    renderTables() {
        const fuelTransaction = this.props.fuelTransactionReducer;
        const loadingStations = fuelTransaction.isLoadingStations;
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader className='page-header'>
                            <h4>Fuel Transactions</h4>
                        </CardHeader>

                        <CardBody>
                            <div className="fuel-filter-section">
                                <Row>
                                    <Col md={2}>
                                        <Label>Cab Id</Label>
                                        <Input type="text" name="driver_id" placeholder="Cab Id" value={this.state.searchTerm} onChange={this.handleSearchChange} />
                                    </Col>
                                    <Col sm={2}>
                                        <Label>Company Name</Label>
                                        {
                                            (fuelTransaction.isFetching && this.state.isResetting) ?
                                                null
                                            :
                                                (this.props.coporateReducer.companyNameList != null && this.props.coporateReducer.companyNameList.length > 0) &&
                                                <AutoComplete
                                                    options={this.getdataSource()}
                                                    id='companyId'
                                                    placeholder="Select company"
                                                    onSelect={this.handleSelectCompanyList}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                        }
                                    </Col>
                                    <Col md={2}>
                                        <Label>Fuel Station Name</Label>
                                        <Input type="text" name="stations-autocomplete"
                                            placeholder="Fuel Station Name"
                                            value={this.state.station_query}
                                            onChange={this.handleAutoCompleteOnChange}
                                        >
                                        </Input>
                                        {
                                            (this.state.showList) ?
                                                <ListGroup style={{ zIndex: 1000, position: "absolute", width: "80%", fontSize: 14 }}>
                                                    {
                                                        (loadingStations) ?
                                                            <ListGroupItem>Loading ....</ListGroupItem> :
                                                            this.renderAutoCompleteItems(fuelTransaction.stations)
                                                    }                                                  
                                                </ListGroup> :
                                                <div></div>
                                        }
                                    </Col>
                                    <Col sm={2}>
                                        <Label>Start Date</Label> <br/>
                                        <DatePicker
                                            onChange={(date, dateString) => this.handleChangeCalender('start', date, dateString)}
                                            value={this.state.start !== '' ? moment(this.state.start) : ''}
                                            style={{width: "100%",}}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Label>End Date</Label>
                                        <DatePicker
                                            onChange={(date, dateString) => this.handleChangeCalender('end', date, dateString)}
                                            value={this.state.end !== '' ? moment(this.state.end) : ''}
                                            style={{width: "100%",}}
                                        />
                                    </Col>
                                    <Col className="button-action" style={{ paddingTop: 25 }}>
                                        <Button className='ant-btn-success' size='large' style={{marginRight: '5px'}} onClick={(e) => this.handleFilter(e)}>Search</Button>
                                        <Button type='danger' size='large' onClick={(e)=> this.handleResetFilter()}>Reset</Button>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <div className="result-layout">
                                {
                                    (this.props.fuelTransactionReducer.meta) ?
                                        <h6>{`Results: ${this.props.fuelTransactionReducer.transactionList.length} of ${this.props.fuelTransactionReducer.meta.total} transactions`  }</h6>
                                    :
                                        <h6>{`Results : 0 transactions`}</h6>
                                }
                                    <Button className='ant-btn-warning' size='large' onClick={this.handleExportCSV}>Export CSV</Button>
                                </div>
                            </Row>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['name', ' phone']}
                                search={(params) => this.search(params)}
                                isFetching={this.props.fuelTransactionReducer.isFetching}
                                isSearching={this.props.fuelTransactionReducer.isSearching}
                            />
                            <ListingTables
                                isFuelTransactionTable={true}
                                headers={[
                                    'Cab Id',
                                    'Name',
                                    'Phone Number',
                                    'Driver Type',
                                    'Coporate Company',
                                    'Amount',
                                    'Fuel Station Name',
                                    'Fuel Type',
                                    'Price',
                                    'Litres',
                                    'Previous Milage',
                                    'Current Milage',
                                    'Car Plate Number',
                                    'Ini Drvier Balance',
                                    'After Driver Balance',
                                    'Ini Fuel Station Balance',
                                    'Fuel Station Balance',
                                    'Created At'

                                ]}
                                listingData={this.props.fuelTransactionReducer.transactionList}
                                page = {this.props.fuelTransactionReducer.page}
                            />
                        </CardBody>
                        <CardFooter>
                            {
                                (this.props.fuelTransactionReducer.meta) ?
                                    <PaginationFooter listTotal={this.props.fuelTransactionReducer.meta.total}
                                        listItem={this.props.fuelTransactionReducer.meta.per_page}
                                        perPage={this.state.showEntries}
                                        callbackPagy={(page) => this.loadByPagy(page)} /> :
                                    <PaginationFooter />
                            }

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    fuelTransactionReducer: state.fuelTransactionReducer,
    user: state.user,
    alerts: state.alerts,
    coporateReducer: state.coporateReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        requestFuelTransaction: (user, page,limit=25) => dispatch(requestFuelTransaction(user, page,limit)),
        filterFuelTransaction: (user, query, page,limit=25) => dispatch(filterFuelTransaction(user, query, page,limit)),
        searchFuelTransaction : (user,page=1,params,limit=25) => dispatch(searchFuelTransaction(user,page,params,limit)),
        getCoporateCompany: (user) => dispatch(getCoporateCompany(user)),
        getStationName: (user, query) => dispatch(getStationName(user, query)),
        requestCompanyNameList: (user) => dispatch(requestCompanyNameList(user)),
        exportCSV: (user, query) => dispatch(exportCSV(user, query)),
        clearAlert: () => dispatch(clearAlert()),
        selectFilter: () => dispatch(selectFilter()),
        pushError : (error) => dispatch(pushError(error))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelTransactions);