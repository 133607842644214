import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import FuelPartnerForm from './FuelPartnerForm';
import { loadFuelPartnerData } from '../../actions/formActions';

class FuelPartnerModal extends Component {
    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadFuelPartnerData(this.props.editItem)
        } else {
            this.props.loadFuelPartnerData(null)
        }
    }
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            this.props.editItem == null ?
                                'Add New Fuel Partner'
                                :
                                `FUEL PARTNER #${this.props.editItem.id} : ${this.props.editItem.name}`
                        }
                    </ModalHeader>
                    <ModalBody>
                        <FuelPartnerForm
                            callbackFormSuccess={this.props.callbackFormSuccess}
                            callbackHide={this.props.callbackHide}
                            editItem={this.props.editItem}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadFuelPartnerData: (data) => dispatch(loadFuelPartnerData(data))
    }
}

export default connect(null, mapDispatchToProps)(FuelPartnerModal);