import React, { Component } from 'react';
import {change, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import { loadData } from '../../actions/formActions';
import { Col } from 'reactstrap';
import { Button } from 'antd';
import { assignCabToDriver, removeDraggableFerryRoute } from '../../actions/cabActions';
import {cab_driver} from "../../constants/constants";
import { addFerryRoute, removeFerryRoute } from "../../actions/ferryActions";
import DragAndDrop from "../../components/DragAndDrop"; 
import MapWithADirectionsRenderer from './DirectionRenderMap';
import GoogleMapLatLng from '../../components/GoogleMapLatLng';

class CabAssignForm extends Component {
	constructor(props){
		super(props);
		this.state={
			selectedRoute: "",
			showFerryForm: false,
			errors: null
		}
	}

	onChangeRemoved(index){ 
		const { cab_assign, user, cabs} = this.props;
		const ferryRoute = (cabs.ferryRoute.length !=0 ) ? cabs.ferryRoute : this.props.assignItem.ferry_route;
		const filteredRoutes = ferryRoute.filter((item, i) => i !== index); 
		let payload = {
			corporate_driver_id : Number(cab_assign.cab_assign.values.corporate_driver_id),
			cab_id : cab_assign.cab_assign.values.id,
			ferry_route: filteredRoutes,
		}
		this.props.assignCabToDriver(user.user,JSON.stringify(payload));
		this.props.callbackHide();
	}

	validateForm(cab){
		let keys = Object.keys(cab.registeredFields);
		let values = cab.values;
		let errors = {}
		if(values){
			for(var i=0; i<keys.length; i++){
				var key = keys[i];
				if(!values[key]){
					if(cab_driver[key]){
						errors = {...errors,[key]:cab_driver[key]};
					}
				}
			}
			if (Object.keys(errors).length === 0) {
				return false;
			} else {
				this.setState({errors});
				return true;
			}
		}else{
			this.setState({
				errors : cab_driver
			});
			return true;
		}
	}
	
	handleAssign(){ 
		const { cab_assign, user, coporateReducer, cabs} = this.props;
		const ferryRoute = (cabs.ferryRoute.length !=0 ) ? cabs.ferryRoute : this.props.assignItem.ferry_route
		let payload = {
			corporate_driver_id : Number(cab_assign.cab_assign.values.corporate_driver_id),
			cab_id : cab_assign.cab_assign.values.id,
			ferry_route: ferryRoute,
		}
		this.props.assignCabToDriver(user.user,JSON.stringify(payload));
		this.props.callbackHide();
	}

	reformatedPlaces(places){ 
		var formtedPlaces = []
		places && places.map( item => {
			formtedPlaces.push({lat: parseFloat(item[0].split(',')[0]), lng: parseFloat(item[0].split(',')[1])})
		})
		return formtedPlaces;
	}

	render() {

		return (
			<div>
				<form className="form">
					<FormInput
						labelName="Cab Id"
						name="id"
						placeholder="Enter cab Id"
						component="input"
						type="text"
					/>
					<span className="error-span">{(this.state.errors && this.state.errors.id) ? this.state.errors.id : null}</span>
					<FormInput
						labelName="Driver Id"
						name="corporate_driver_id"
						placeholder="Enter driver Id"
						component="input"
						type="text"
					/>
					<span className="error-span">{(this.state.errors && this.state.errors.corporate_driver_id) ? this.state.errors.corporate_driver_id : null}</span>
					{this.props.assignItem.ferry_route && this.props.assignItem.ferry_route.length != 0 ?
						<div>
							<div className="row form-group">
								<Col sm={4}>
									<label className="label">Ferry Stops</label>
								</Col>
								<Col sm={8} >
									<DragAndDrop 
										onChangeRemoved={(item)=>{ this.onChangeRemoved(item) }} 
										ferry_route={this.props.assignItem.ferry_route} 
									/>
								</Col>
							</div>
							<br />
							{this.props.assignItem.ferry_route.length > 1 ?
								<MapWithADirectionsRenderer ferry_route={this.reformatedPlaces(this.props.assignItem.ferry_route)} />
							:
								<GoogleMapLatLng point={this.props.assignItem.ferry_route[0][0]}  address={"Ferry Stop"}  />
							}
						</div>
					:
						null
					}
					<br />
					<Button type="primary" size='large' onClick={()=> this.handleAssign()}>Assign</Button>
				</form>
			</div>
		);
	}
}

CabAssignForm = reduxForm({ form: 'cab_assign' })(CabAssignForm)

const mapStateToProps =state=>({
    initialValues : state.operatorReducer.cab_assign,
    cab_assign : state.form,
    user : state.user,
    coporateReducer: state.coporateReducer,
    cabs : state.cabs
});
const mapDispatchToProps = dispatch => {
    return{                            
        loadData      : (data)=> dispatch(loadData(data)),
        assignCabToDriver : (user,data)=> dispatch(assignCabToDriver(user,data)),
        addFerryRoute : (payload) => dispatch(addFerryRoute(payload)),
        resetValue : (form, field, value) => dispatch(change(form, field, value)),
        removeFerryRoute: (payload) => dispatch(removeFerryRoute(payload)),
        removeDraggableFerryRoute: () => dispatch(removeDraggableFerryRoute()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CabAssignForm);