import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import * as fileDownload from 'js-file-download';
import { API_URL } from '../constants/credentials';
import axios from 'axios';
import queryString from 'query-string';
import { pushAlert } from '../actions/alertActions';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import {receiveAircabOrder,filterAircabOrders,receiveAircabOrderSearchResult} from '../actions/aircabOrderActions';
import {logoutUser} from "../actions/userActions";

export function loadAircabOrder(action$){
    return action$.pipe(
        ofType(actions.REQUEST_AIRCAB_ORDER),
        switchMap((payload)=>{
            return ajax.getJSON(`${API_URL}/manage/api/admins/aircab_orders?per_page=${payload.limit}&page=${payload.page}`,{
                'Authorization': payload.user.sso_token
            })
            .pipe(
                map((response)=>{ return receiveAircabOrder(response,payload.page)}),
                catchError(error=>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })
            )
        })
    )
}

export function exportAircabOrderCSV(action$){
  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
   return action$.pipe(
         ofType(actions.EXPORT_AIRCAB_ORDER_CSV),
         switchMap((payload)=>{
          
          let parameters = {};

          if (payload.query) {
            if (payload.query.start)
              parameters = { ...parameters, start: payload.query.start };

            if (payload.query.end)
              parameters = { ...parameters, end: payload.query.end };

            if (payload.query.cab_car_plate_number)
              parameters = { ...parameters, search: payload.query.cab_car_plate_number };

            if (payload.query.customer_id)
              parameters = { ...parameters, customer_id: payload.query.customer_id };
          }
          
          return  axios.get(`${API_URL}/manage/api/admins/aircab_orders.xlsx?${queryString.stringify(parameters)}`,{responseType:'blob',headers:{'Authorization': payload.user.sso_token}}).then((res)=>{
             fileDownload(res.data,`aircab_order_${day}${month}${year}.xlsx`);
             const data = {
                 notice : "CSV File is Downloaded"
             }
             return pushAlert(data);
           })
         })
   )
}

export function filterAircabOrder(action$) {
  return action$.pipe(
    ofType(actions.FILTER_AIRCAB_ORDER),
    switchMap((payload) => {
      let parameters = {};

      if (payload.query) {
        if (payload.query.start)
          parameters = { ...parameters, start: payload.query.start };

        if (payload.query.end)
          parameters = { ...parameters, end: payload.query.end };

        if (payload.query.cab_car_plate_number)
          parameters = { ...parameters, search: payload.query.cab_car_plate_number };

        if (payload.query.customer_id)
          parameters = { ...parameters, customer_id: payload.query.customer_id };
      }

      let url = `${API_URL}/manage/api/admins/aircab_orders?${queryString.stringify(parameters)}&page=${payload.page}`;

      return ajax.getJSON(url , {
        'Authorization': payload.user.sso_token
      })
        .pipe(
          map((response) => { return receiveAircabOrder(response, payload.page, true) }),
          catchError(error => {
              if (error.status === 401) {
                  const errorData = {
                      message: "session expired: please try again"
                  }
                  return of(
                      pushError(errorData),
                      logoutUser()
                  )
              } else {
                  if(error.response){
                      return of(pushError(error.response.errors))
                  }else{
                      return of(pushError(error))
                  }
              }
          })
        )
    })
  )
}

export function searchAircabOrder(action$){
  return action$.pipe(
   ofType(actions.SEARCH_AIRCAB_ORDER),
   switchMap((payload)=>{
   return ajax.getJSON(`${API_URL}/manage/api/admins/aircab_orders?per_page=${payload.limit}&search=${payload.params}&page=${payload.page}`,
          {
           'Authorization': payload.user.sso_token
           }).pipe(
               map((response)=> receiveAircabOrderSearchResult(response,payload.page)),
               catchError(error=>{
                   if (error.status === 401) {
                       const errorData = {
                           message: "session expired: please try again"
                       }
                       return of(
                           pushError(errorData),
                           logoutUser()
                       )
                   } else {
                       if(error.response){
                           return of(pushError(error.response.errors))
                       }else{
                           return of(pushError(error))
                       }
                   }
               })
           )
      })
  ) 
}