import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter    
} from 'reactstrap';
import CustomerForm from './CustomerForm';
import { loadCustomerData } from '../../actions/formActions';
import {addNewCustomer} from '../../actions/customerAction';
import MultipleCustomerForm from "./MultipleCustomerForm";

class MultipleCustomerModal extends React.Component {

    componentDidMount(){
        if(this.props.editItem != null){
            this.props.loadCustomerData(this.props.editItem)
        }else{
            this.props.loadCustomerData(null)
        }
    }
    
    
    render(){
        return (
            <div>                
                <Modal 
                    isOpen={this.props.isOpen} 
                    toggle={()=> this.props.callbackHide()}                 
                    size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Add Multiple Customers</ModalHeader>
                    {/* {this.state.showErr && this.renderErrorMsg()} */}
                    {/* {this.state.isOpenSuccess && this.renderSuccessMessage()} */}
                    <ModalBody>
                        <MultipleCustomerForm
                            editItem={this.props.editItem} 
                            callbackHide={this.props.callbackHide}                            
                            />
                    </ModalBody>
                </Modal>
            </div>
        );    
    }    
}

const mapDispatchToProps = dispatch => {
    return{                            
        loadCustomerData      : (data)=> dispatch(loadCustomerData(data)),
        addNewCustomer : (data)=>dispatch(addNewCustomer(data))
    }
}

export default connect(null, mapDispatchToProps)(MultipleCustomerModal);