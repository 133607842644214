import * as actions from '../constants/actionTypes';
export function requestFuelStation(user,page,limit) {
    return ({
        type : actions.REQUEST_FUEL_STATION,
        user : user,
        page : page,
        limit : limit
    })   
}

export function receiveFuelStation(data,page){
    return ({
        type : actions.RECEIVE_FUEL_STATION,
        payload : data,
        page : page
    })
}

export function requestFuelStationPartner(user){
    return ({
        type : actions.REQUEST_FUEL_STATION_PARTNER,
        user : user
    })
}

export function receiveFuelStationPartner(data){
    return ({
        type : actions.RECEIVE_FUEL_STATION_PARTNER,
        payload : data
    })
}

export function addNewFuelStation(user,data){
    return ({
        type : actions.ADD_NEW_STATION,
        payload : data,
        user : user
    })
}

export function updateFuelStation(user,data,id){
    return ({
        type : actions.UPDATE_FUEL_STATION,
        payload : data,
        user  : user,
        id:id
    })
}

export function searchFuelStation(user,page,params){
    return ({
        type : actions.SEARCH_FUEL_STATION,
        user : user,
        params : params,
        page : page
    })
}
export function receiveFuelStationSearchResult(data,page){
   
    return ({
        type : actions.RECEIVE_FUEl_STATION_SEARCH_RESULT,
        payload : data,
        page : page
    })
}