import * as types from '../constants/actionTypes';
/**
 * form reducers.
 * @param state
 * @param action
 * @returns {*}
 */

const INITIAL_STATE = {    
    user: null    
}

function user(state = INITIAL_STATE , action) {
    switch (action.type) {
        case types.PUSH_USER:
            return Object.assign({}, state, {
                user: action.payload
            });
        case types.LOGOUT_USER:
            return INITIAL_STATE
        default:
          return state
      }       
}

export default user;
