import React, { Component } from 'react';
import { Button } from 'antd';
import './styles.scss'

class TableTitleCard extends Component {
    // constructor(props){
    //     super(props)
    // }
    render() {
        return (
            <div className="table-title">
                <h4>{this.props.title ? this.props.title : 'Table Title'}</h4>
                <Button type='large' onClick={()=> this.props.callbackAction()}>
                    {this.props.actionName ? this.props.actionName : 'action'}
                </Button>
            </div>
        );
    }
}

export default TableTitleCard;