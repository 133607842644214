export const loadState = () => {
    try{
        const serializedState = localStorage.getItem('oway_ride');
        if(serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState);
    }catch (err) {
        return undefined;
    }
}
export const saveState = (state) => {
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('oway_ride', serializedState);
    }catch (err) {
        // Ignored wirte err
    }
}