import * as actions from '../constants/actionTypes';

export function requestDriverAttendances(user,page, limit, meta){
    return {
        type  : actions.REQUEST_DRIVER_ATTENDANCES_DATA,
        user  : user,
        page  : page,
        limit : limit,
        metadata  : meta
    }
}

export function receiveDriverAttendances(data,page){    
    return {
        type    : actions.RECEIVE_DRIVER_ATTENDANCES_DATA,
        payload : data,
        page    : page
    }
}

export function searchDriverAttendances(user,page,params,metadata){
    return ({
        type : actions.SEARCH_DRIVER_ATTENDANCES,
        user : user,
        page : page,
        params : params,
        metadata: metadata
    })
}

export function receiveSearchDriverAttendances(data, page) {
    return ({
        type : actions.RECEIVE_SEARCH_DRIVER_ATTENDANCES,
        payload : data,
        page : page
    })
}

export function requestDriverAttendancesExcel(token, metadata) {
    return ({
        type: actions.REQUEST_DRIVER_ATTENDANCES_EXCEL,
        payload: token,
        meta: metadata
    })
}

export function approvedDriverAttendance(token, status, id) {
    let notice, error;
    if (status == true) {
        notice = 'Approved Driver Attendance.'
        error = 'An error occurs while approving attendance!'
    } else {
        notice = 'Rejected Driver Attendance.'
        error = 'An error occurs while rejecting attendance!'
    }
    return ({
        type: actions.APPROVED_DRIVER_ATTENDANCE,
        token: token,
        status: status,
        id: id,
        notice: notice,
        error: error
    })
}

export function requestAddressPickDriver(lat, long, id) {
    return ({
        type: actions.REQEUST_ADDRESS_PICKUP_DRIVER,
        payload: { 
            lat: lat, 
            long: long,
            id: id
        }
    })    
}

export function receiveAddressPickDriver(payload, id) {    
    return({
        type: actions.RECEIVE_ADDRESS_PICKUP_DRIVER,
        payload: payload,
        id: id
    })
}

export function requestAddressDropDriver(lat, long, id) {
    return ({
        type: actions.REQEUST_ADDRESS_DROPOFF_DRIVER,
        payload: { 
            lat: lat, 
            long: long,
            id: id
        }
    })    
}

export function receiveAddressDropDriver(payload, id) {
    return({
        type: actions.RECEIVE_ADDRESS_DROPOFF_DRIVER,
        payload: payload,
        id: id
    })
}