import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../utils/history';

import { Nav, NavItem, NavLink } from 'reactstrap';
import './styles.scss';
import { fetchData } from '../../actions/formActions';
// import { from } from 'rxjs';

const sidebar_list = [
    // {
    //     name: 'Dashboard',
    //     url:'/'
    // },
    // {
    //     name: 'Manager Lists',
    //     url: '/manager_list'
    // },
    {
        name: 'Corporate Company',
        url: '/'
    },
    {
        name: 'Managers',
        url:'/manager'
    },
    {
        name: 'Drivers',
        url: '/operator_drivers'
    },
    {
        name: 'Driver Attendances',
        url: '/driver_hcm'
    },
    {
        name: 'Customers',
        url: '/operator_customers'
    },
    {
        name: 'Cabs',
        url: '/cabs'
    },
    {
        name: 'Corporate Orders',
        url: '/corporate_orders'
    },
    {
        name: 'AirCab Orders',
        url: '/aircab_order'
    },
    ,
    {
        name: 'AirCab Dashboard',
        url: '/aircab_location_map'
    },
    {
        name : 'Fuel Transactions',
        url:'/fuel_transactions'
    },
    {
        name : 'Fuel Stations',
        url:'/fuel_stations'  
    },
    {
        name : 'Fuel Partners',
        url : 'fuel_partners'
    },
    {
        name : 'Agents',
        url :'agents'
    },
    {
        name : 'Agent Transactions',
        url :'/agent_transactions'
    },
    // {
    //     name : 'Fuel Stations',
    //     url:'/fuel_stations'  
    // },
    // {
    //     name : 'Fuel Partners',
    //     url : '/fuel_partners'
    // }

    // {
    //     name: 'Transitions',
    //     url: '/transitions'
    // }
]

const aircab_order_sidebar_list = [
    {
        name: 'AirCab Orders',
        url: '/aircab_order'
    },
    {
        name: 'AirCab Dashboard',
        url: '/aircab_location_map'
    }
]
const manager_sidebar_list = [
    {
        name: 'Customers',
        url: '/operator_customers'
    },
    {
        name: 'Driver Attendances',
        url: '/driver_hcm'
    },
    {
        name: 'Cabs',
        url: '/cabs'
    },
    {
        name: 'Corporate Orders',
        url: '/corporate_orders'
    },
    {
        name : 'Fuel Transactions',
        url:'/fuel_transactions'
    },
  
   

]

const fuel_manager_sidebar_list = [
  
    {
       name : 'Fuel Transactions',
       url:'/fuel_transactions'
    },
    {
        name : 'Fuel Stations',
        url:'/fuel_stations'  
    },
    
   

]

class Sidebar extends Component {
    constructor(props){
        super(props)
        this.state={
            active: 0
        }
    }
    gotoLink(url, index){
        this.setState({active: index})
        history.push(url)
    }
    render() {
        let user = this.props.user;
        return (
            <div className="sidebar">
                <div className="logo" onClick={()=> this.gotoLink('/')}>                    
                    <img src={require('../../assests/images/oway-logo.svg')} alt="oway admin logo"/>
                    <h4>Oway</h4>                    
                </div>                
                <Nav vertical>
                  
                     {(() => {
                            switch (user.user && user.user.role&& user.user.role.name) {
                            case "Superadmin":   return sidebar_list.map((list, index)=> (
                                <NavItem key={index}>
                                    <NavLink className={index === this.state.active ? "active":""} onClick={()=> this.gotoLink(list.url, index)}>{list.name}</NavLink>
                                </NavItem>
                            ));
                                
                            
                            case "Fuel Manager": return fuel_manager_sidebar_list.map((list,index)=>(
                                <NavItem key={index}>
                                    <NavLink className={index === this.state.active ? "active":""} onClick={()=> this.gotoLink(list.url, index)}>{list.name}</NavLink>
                                </NavItem>
                            ));
                            case "Airport Authority": return aircab_order_sidebar_list.map((list,index)=>(
                                <NavItem key={index}>
                                    <NavLink className={index === this.state.active ? "active":""} onClick={()=> this.gotoLink(list.url, index)}>{list.name}</NavLink>
                                </NavItem>
                            ));
                            default:  return manager_sidebar_list.map((list,index)=>(
                                <NavItem key={index}>
                                    <NavLink className={index === this.state.active ? "active":""} onClick={()=> this.gotoLink(list.url, index)}>{list.name}</NavLink>
                                </NavItem>
                            ));
                            }
                        })()}
                </Nav>
            </div>
        );
    }
}

const mapStateToProps = state => ({     
    user : state.user,
    router : state.router
 });

const mapDispatchToProps = dispatch => {
    return{                            
        fetchData      : ()=> dispatch(fetchData()),
        // pushError     : (error)=> dispatch(pushError(error))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Sidebar);