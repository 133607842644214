import * as types from '../constants/actionTypes';
/**
 * form reducers.
 * @param state
 * @param action
 * @returns {*}
 */

const INITIAL_STATE = {
    alerts:null
};    

function alerts(state = INITIAL_STATE , action) {
    switch (action.type) {
        case types.PUSH_ALERT:
            return Object.assign({}, state, {
                alerts: action.payload
            });
        case types.CLEAR_ALERT:
            return Object.assign({}, state, {
                alerts: null
            });            
        default:
          return state
      }       
}

export default alerts;