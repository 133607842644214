import React, { Component } from 'react';
import ListingTables from '../../components/ListingTables';
import CustomerDetails from './CustomerDetails';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import { requestCustomerData, searchCustomerData, changeToNormalCustomer, searchCustomerAttendance } from '../../actions/customerAction';
import { requestCsv } from '../../actions/ferryActions';
import { clearAlert } from '../../actions/alertActions';
import { requestCompanyNameList } from '../../actions/coporateCompanyAction';
import { loadCabsData } from '../../actions/cabActions';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { DatePicker, Button, AutoComplete } from 'antd';
import moment from 'moment'
import './styles.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label
} from 'reactstrap'
import CustomerModal from './CustomerModal';
import MultipleCustomerModal from "./MultipleCustomerModal";
import history from "../../utils/history";

class Customers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerLists: [],
            isFetching: true,
            editItem: null,
            isOpenForm: false,
            isOpenMultipleCustomerForm: false,
            isOpenDetail: false,
            viewItem: null,
            showEntries: 25,
            errors: null,
            start: '',
            end: '',
            corporateCompany: null,
            corporateDriver: null,
            searchParam:'',
            metadata: null,
            isResetting: false
        }
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleEndDateChange = this.handleEndDateChange.bind(this)

    }
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.alerts !== this.props.alerts && this.props.alerts.alerts !== null) {

            setTimeout(() => {
                this.props.clearAlert()
            }, 3000);

        }
        if(prevProps.customerReducer.isUpdated !== this.props.customerReducer.isUpdated){
            this.loadData()
        }
    }

    handleStartDateChange(date, dateString) {
        let errors = {}
        const now = moment()
        if (this.state.end != "") {
            const endDate = moment(this.state.end, "YYYY-MM-DD")
            if (endDate.isBefore(date)) {
                errors = {...errors, start_date:"Start Date must be lesser than end date"}
            } else if (now.isBefore(date)) {
                errors = {...errors, start_date:"Unable to search for that date range"}
            } else {
                delete errors["start_date"];
            }
        } else {
            if (now.isBefore(date)) {
                errors = {...errors, start_date:"Unable to search for that date range"}
            } else {
                delete errors["start_date"];
            }
        }

        if(Object.keys(errors).length === 0){
            this.setState({errors :null})
            this.setState({start: dateString} )
        } else {
            this.setState({errors :errors})
        }


    }
    handleEndDateChange(date, dateString) {
        let errors = {}
        const now = moment()
        if (this.state.start != "") {
            const startDate = moment(this.state.start, "YYYY-MM-DD")
            if (startDate.isAfter(date)) {
                errors = {...errors, end_date:"End Date must be greater than start date"}
            } else if (now.isBefore(date)) {
                errors = {...errors, end_date:"Unable to search for that date range"}
            } else {
                delete errors["end_date"];
            }
        } else {
            errors = {...errors, start_date:"Start date cannot be empty"}
            if (now.isBefore(date)) {
                errors = {...errors, end_date:"Unable to search for that date range"}
            } else {
                delete errors["end_date"];
            }
        }

        if(Object.keys(errors).length === 0){
            this.setState({errors :null})
            this.setState({end: dateString} )
        } else {
            this.setState({errors :errors})
        }
    }

    loadData() {
        const { user } = this.props;
        this.setState({ isResetting: false })
        if (user.user) {
            if ((this.props.customerReducer.customerList.length === 0 || this.props.customerReducer.isUpdated)){
                const page = 1;
                this.props.requestCustomerData(user.user, page, this.state.showEntries);
                this.props.requestCompanyNameList(user)
            }
        } else {
            history.push('/sign_in');
        }

    }
    loadByPagy(page) {
        const { user } = this.props;
        this.setState({ isResetting: false })
        this.props.requestCustomerData(user.user, page,this.state.showEntries);
    }
    search(params) {
        const { user } = this.props;
        this.setState({searchParam: params})
        this.props.searchCustomerData(user.user, 1, params, this.state.metadata);
    }
    handleChangeToNormalUser(editItem){
        const { user } = this.props;
      
        this.props.changeToNormalCustomer(user.user,{customer_id:editItem.id});
    }
    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({ isOpenForm: true })
    handleMultipleCustomerForm = () => this.setState({ isOpenMultipleCustomerForm: true })
    handleHideForm = () => this.setState({ isOpenForm: false, editItem: null })
    handleHideMultipleCustomerForm = () => this.setState({ isOpenMultipleCustomerForm: false, editItem: null })
    loadByLimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        this.props.requestCustomerData(user.user,1,limit);    
    }
    getdataSource () {
        const companyLists =  this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value : item.name
        }));
        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }

    getDriverData () {
        const driverList = this.props.cabs.cabsList && this.props.cabs.cabsList.map(item => ({
           value: item.corporate_driver_name
        }));
        
        if (driverList != null) {
            driverList.sort((a,b) => a.value.localeCompare(b.value))
        }
        return driverList;
    }

    handleFilter(){
        const { user } = this.props;
        const page = 1;
        let errors = {}
        let metaData;
        let cabId;
        let today = new Date().toISOString().slice(0, 10);

        if (this.state.corporateCompany == null){
            errors = {...errors, company:"Please choose company"}
        } else {
            delete errors["company"]
        }

        if(Object.keys(errors).length === 0 ){
            
            this.setState({errors :null} )
            const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
            if (this.state.corporateDriver != null) {
                let selectedDriver = this.props.cabs.cabsList.find(o => o.corporate_driver_name === this.state.corporateDriver);
                cabId = selectedDriver.id;
            } else {
                cabId = "";
            }
            metaData = {
                corporate_company_id : selectedCompany.id,
                corporate_cab_id: cabId,
                startDate : this.state.start !== '' ? this.state.start : today,
                endDate : this.state.end !== '' ? this.state.end : today
            }
            this.setState({metadata : metaData})
            this.props.searchCustomerAttendance(user.user, metaData);
        } else {
            this.setState({errors :errors})
        }

    }

    handleResetFilter(){
        const { user } = this.props;
        this.setState({
            corporateCompany: null,
            corporateDriver: null,
            start: '',
            end: '',
            errors: null,
            isResetting: true
        })

        this.props.requestCustomerData(user.user, 1, this.state.showEntries);
    }

    handleSelectCompanyList = (e) => {
        const { user } = this.props;
        let company = this.props.coporateReducer.companyNameList.find(o => o.name === e);
        this.setState({corporateCompany : e})
        this.props.loadCabsData(user.user, 1, 25, company.id);
    }

    handleSelectDriverList = (e) => {
        this.setState({corporateDriver : e});
    }

    handleExportCsv(){
        const { user } = this.props;
        let today = new Date().toISOString().slice(0, 10)
        let corporateId;
        let cabId;
        let errors = {}

        const endDate = (this.state.end != '') ? this.state.end : today;
        const startDate = (this.state.start != '') ? this.state.start : today;

        if (this.state.corporateDriver == null) {
            cabId = 0
        } else {
            cabId = this.props.cabs.cabsList.find(o => o.corporate_driver_name === this.state.corporateDriver);
        }

        if (user.user.role.name == "Superadmin") {
            if (this.state.corporateCompany == null){
                errors = {...errors, company:"Please choose company first"}
            }else{
                corporateId = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
                if (corporateId.id == "") {
                    errors = {...errors, company:"Invalid corporate company. Please choose another"}
                } else {
                    delete errors["company"];
                }
            }
        } 
        else {
            corporateId = 0
        }

        if(Object.keys(errors).length === 0 ) {
            this.setState({errors :null} )
            const meta = {
                startDate : startDate,
                endDate : endDate,
                companyId : corporateId.id,
                cabId : cabId.id,
                searchParam: this.state.searchParam
            }
            this.props.requestCsv(user.user.sso_token, meta)
        } else {
            this.setState({errors :errors})
        }

    }

    renderTables() {
        const { user } = this.props;  
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader>
                            <h4>Corporate Customers</h4>
                            <div>
                                <Button size='large' onClick={() => this.handleForm()}>Add Customer</Button>
                                <Button size='large' onClick={() => this.handleMultipleCustomerForm()}>Upload Customers</Button>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <div className="customer-filter-section">
                                <Row>
                                    <Col md={3}>
                                        <h6>Attendance Search</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <Label>Company Name</Label>
                                        {
                                            (this.state.isResetting && this.props.customerReducer.isFetching ) ?
                                                null
                                            :
                                            this.props.coporateReducer.companyNameList.length > 0 &&
                                                <AutoComplete
                                                    options={this.getdataSource()}
                                                    id='companyId'
                                                    placeholder="Select company"
                                                    onSelect={this.handleSelectCompanyList}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                        }
                                        <span className="error-span">{(this.state.errors && this.state.errors.company)? this.state.errors.company : null}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <Label>Driver Name</Label>
                                        {
                                            (this.state.isResetting && this.props.customerReducer.isFetching ) ?
                                                null
                                            :
                                            this.props.coporateReducer.companyNameList.length > 0 &&
                                                <AutoComplete
                                                    options={this.getDriverData()}
                                                    id='driverId'
                                                    placeholder="Select driver"
                                                    onSelect={this.handleSelectDriverList}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                        }
                                        <span className="error-span">{(this.state.errors && this.state.errors.company)? this.state.errors.company : null}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <Label>Start Date</Label> <br/>
                                        <DatePicker
                                            onChange={this.handleStartDateChange}
                                            value={this.state.start !== '' ? moment(this.state.start) : moment()}
                                            style={{width: "100%",}}
                                        />
                                        <span className="error-span">{(this.state.errors && this.state.errors.start_date)? this.state.errors.start_date : null}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <Label>End Date</Label>
                                        <DatePicker
                                            onChange={this.handleEndDateChange}
                                            value={this.state.end !== '' ? moment(this.state.end) : moment()}
                                            style={{width: "100%",}}
                                        />
                                        <span className="error-span">{(this.state.errors && this.state.errors.end_date)? this.state.errors.end_date : null}</span>
                                        {/*<Input name="end" type="date" value={this.state.end} placeholder="Choose Start Date" onChange={this.handleChangeCalender} />*/}
                                    </Col>

                                    <Col sm={4} className="button-action" style={{ paddingTop: 25 }}>
                                        <Button className='ant-btn-success' size='large' onClick={(e) => this.handleFilter(e)}>
                                            Search
                                        </Button>

                                        <Button className="reset-btn" type='danger' size='large' onClick={(e)=> this.handleResetFilter()}>
                                            Reset
                                        </Button>

                                        <Button className='ant-btn-warning' size='large' onClick={() => this.handleExportCsv(user.user.sso_token)}>Export CSV</Button>
                                        
                                    </Col>

                                </Row>
                            </div>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['name', ' phone']}
                                search={(params) => this.search(params)}
                                isFetching={this.props.customerReducer.isFetching}
                                isSearching={this.props.customerReducer.isSearching}
                            />
                            <ListingTables
                                isCustomerTable={true}
                                headers={[
                                    'Name',
                                    'Phone',
                                    'Company',
                                    'Ferry Driver',
                                    'AM Check In',
                                    'PM Check In',
                                    'Account Status',
                                    'Options'
                                ]}
                                listingData={this.props.customerReducer.customerList}
                                callbackEdit={(item) => this.setState({ isOpenForm: true, editItem: item })}
                                callbackView={(item) => this.setState({ isOpenDetail: true, viewItem: item })}
                                callbackChangeToNormal={(item)=>this.handleChangeToNormalUser(item)}
                                companyNameList={this.props.coporateReducer.companyNameList}
                            />
                        </CardBody>
                        <CardFooter>
                            {
                                (this.props.customerReducer.meta) &&
                                    <PaginationFooter listTotal={this.props.customerReducer.meta.total} 
                                        listItem={this.props.customerReducer.meta.per_page}
                                        perPage={this.state.showEntries}
                                        callbackPagy={(page) => this.loadByPagy(page)}
                                    />
                            }

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }
    renderForm() {
        return (
            <CustomerModal
                isOpen={this.state.isOpenForm}
                callbackHide={() => this.handleHideForm()}
                callbackFormSuccess={() => this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }

    renderFormMultipleCustomerModel() {
        return (
            <MultipleCustomerModal
                isOpen={this.state.isOpenMultipleCustomerForm}
                callbackHide={() => this.handleHideMultipleCustomerForm()}
                callbackFormSuccess={() => this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }

    renderDetails() {
        return (
            <CustomerDetails
                isOpen={this.state.isOpenDetail}
                hide={() => this.setState({ isOpenDetail: false })}
                selectedItem={this.state.viewItem}
            />
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenMultipleCustomerForm && this.renderFormMultipleCustomerModel()}
                {this.state.isOpenDetail && this.renderDetails()}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    customerReducer: state.customerReducer,
    user: state.user,
    alerts: state.alerts,
    coporateReducer: state.coporateReducer,
    cabs: state.cabs
});

const mapDispatchToProps = dispatch => {
    return {
        requestCustomerData: (user, page, limit=25, metadata=null) => dispatch(requestCustomerData(user, page,limit, metadata)),
        requestCompanyNameList: (user) => dispatch(requestCompanyNameList(user)),
        searchCustomerData: (user, page = 1, params, metadata) => dispatch(searchCustomerData(user, page, params,metadata)),
        searchCustomerAttendance: (user, metadata=null) => dispatch(searchCustomerAttendance(user, metadata)),
        changeToNormalCustomer :(user,id)=> dispatch(changeToNormalCustomer(user,id)),
        clearAlert: () => dispatch(clearAlert()),
        requestCsv : (payload, meta=null) => dispatch(requestCsv(payload, meta)),
        loadCabsData: (user, page, limit=25, corporate_company_id=null)=> dispatch(loadCabsData(user, page, limit, corporate_company_id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);