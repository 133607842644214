import {switchMap, map, catchError, mergeMap} from 'rxjs/operators';
import {  ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL, MAP_URL } from '../constants/credentials';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import {pushAlert} from "../actions/alertActions";
import { receiveDriverAttendances, receiveSearchDriverAttendances, receiveAddressDropDriver, receiveAddressPickDriver } from '../actions/driverHCMActions';
import {logoutUser} from "../actions/userActions";
const MAP_API_KEY = "AIzaSyBEH6CtSIDi5YsTGfcHebGykudRsi_PJIc";

export function loadDriverAttendances(action$){
    return action$.pipe(
        ofType(actions.REQUEST_DRIVER_ATTENDANCES_DATA),
        switchMap((payload)=>{
            let url;
            if (payload.metadata != null) {
                url = `${API_URL}/manage/api/corporate_driver_attendances?corporate_company_id=${payload.metadata.corporate_company_id}&start=${payload.metadata.startDate}&end=${payload.metadata.endDate}&page=${payload.page}&per_page=${payload.limit}`
            } else {
                url = `${API_URL}/manage/api/corporate_driver_attendances?page=${payload.page}&per_page=${payload.limit}`
            }
           return ajax.getJSON(url, {
               'Authorization': payload.user.sso_token
            })
            .pipe(
                map((response)=> receiveDriverAttendances(response,payload.page)),                                     
                catchError(error=>{
                    if(error.response){
                      return of(pushError(error.response.errors))
                    }else{
                      return of(pushError(error))
                    } 
                  })
            )
        })
    )
}

export function searchDriverAttendancesData(action$){
    return action$.pipe(
        ofType(actions.SEARCH_DRIVER_ATTENDANCES),
        switchMap((payload)=> {
            let url;
            if (payload.metadata != null) {
                url = `${API_URL}/manage/api/corporate_driver_attendances?search=${payload.params}&corporate_company_id=${payload.metadata.corporate_company_id}&start=${payload.metadata.startDate}&end=${payload.metadata.endDate}&page=${payload.page}`
            } else {
                url = `${API_URL}/manage/api/corporate_driver_attendances?search=${payload.params}&page=${payload.page}`
            }

            return ajax.getJSON(url, {
                'Authorization': payload.user.sso_token
            }).pipe(
                map((response) => {
                    return receiveSearchDriverAttendances(response, payload.page)
                }),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )} else {
                        if (error.response) {
                            return of(pushError(error.response.errors))
                        } else {
                            return of(pushError(error))
                        }
                    }
                })
            )}
        )
    )
}  

export function approvedDriverAttendanceByManager(action$) {
    return action$.pipe(
        ofType(actions.APPROVED_DRIVER_ATTENDANCE),
        switchMap((payload)=>
            ajax({
                url: `${API_URL}/manage/api/corporate_driver_attendances/${payload.id}?status=${payload.status}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': payload.token
                }
            }).pipe(
                map((response) => pushAlert({notice: payload.notice}, "driver_hcm"),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again "
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError([payload.error]))
                        }
                    }
                })
            )
        )
    ))
}
export function driverHCMLocation(action$) {
    return action$.pipe(
        ofType(actions.REQEUST_ADDRESS_PICKUP_DRIVER),
        mergeMap(async (payload) => {
            const url = `${MAP_URL}/maps/api/geocode/json?address=${payload.payload.lat},${payload.payload.long}&key=${MAP_API_KEY}`;
            const address = await fetch(url).then(res => res.json());                                    
            if(address){
                return receiveAddressPickDriver(address.results[1].formatted_address, payload.payload.id)            
            }
          })
    )
}
export function driverHCMDropLocation(action$) {
    return action$.pipe(
        ofType(actions.REQEUST_ADDRESS_DROPOFF_DRIVER),
        mergeMap(async (payload) => {
            const url = `${MAP_URL}/maps/api/geocode/json?address=${payload.payload.lat},${payload.payload.long}&key=${MAP_API_KEY}`;
            const address = await fetch(url).then(res => res.json());                        
            if(address){
                return receiveAddressDropDriver(address.results[1].formatted_address, payload.payload.id)
            }
          })
    )
}