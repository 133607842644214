import * as actions from '../constants/actionTypes';

const INITIAL_STATE={
    transactionList :[],
    isFetching : false,
    meta:null,
    page: 1,
    limit : 25,
    isLoadingStations : false,
    isFiltered : false,
    companies : [],
    stations:[]
}

function fuelTransactionReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.REQUEST_FUEL_TRANSACTION : 
             return Object.assign({}, state, {
                isFetching: true,
                limit : action.limit
                });
        
        case actions.RECEIVE_FUEL_TRANSACTION :
              return Object.assign({},state,{
                transactionList :(action.payload)?action.payload.data:[],
                isFetching : false,
                meta : (action.payload)?action.payload.meta:null,
                page: action.page,
                isFiltered :action.filtered
              });

        case actions.REQUEST_FILTER_STATION_NAME :
             
              return Object.assign({},state,{
                isLoadingStations :true
              })      
        case actions.RECEIVE_FILTER_COMPANY_NAME :
               return Object.assign({},state,{
                   companies : (action.payload)? action.payload : []
               });
        case actions.RECEIVE_FILTER_STATION_NAME :
                return Object.assign({},state,{
                    stations : (action.payload)? action.payload : [],
                    isLoadingStations : false
                })  
        case actions.EXPORT_FUEL_TRANSACTION_CSV : 
            return Object.assign({}, state, {
                isFetching: true
            }); 
        case actions.PUSH_ALERT :
            return Object.assign({}, state, {
                    isFetching: false
                });       
        case actions.SELECT_FILTER:
            return Object.assign({},state,{
                isFiltered :false
            }) ;
        case actions.SEARCH_FUEL_TRANSACTION : 
        return Object.assign({},state,{
            isSearching: true,
            limit : action.limit
        });
        case actions.RECEIVE_FUEL_TRANSACTION_SEARCH_RESULT :
       
        return Object.assign({},state,{
                    isSearching : false,
                    transactionList : (action.payload)? action.payload.data : [],
                    meta : (action.payload)?action.payload.meta : null,
                    page : action.page,
                    limit : action.limit
                })  
                                 
        default : return state;            
    }
}
export default fuelTransactionReducer;