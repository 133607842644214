import React from 'react';
import { Alert, Row, Col } from 'reactstrap'
import { Button, AutoComplete } from 'antd'
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import FormInput from "../../components/FormInput";
import {requestCompanyNameList} from "../../actions/coporateCompanyAction";
import {createManager, updateManager} from "../../actions/managerAction"
import Loader from "../../components/Loader";

class ManagerForm extends React.Component {    
    constructor(props){
        super(props);
        this.state={
            errors:null,
            corporateCompany: null
        }
    }
    componentDidMount(){
        const {user}=this.props;
        if (user.user) {
            this.props.requestCompanyNameList(user);
        }
    }

  /*  handleTextChange(e){
        this.setState(update(this.state,{$set:{
            name: {
                value: e.target.value, 
                valid: !!e.target.value ? true : false 
            },
            showErr: false
        }}))        
    }
    handlePhoneChange(e){
        this.setState(update(this.state,{$set:{
            phone:{
                value: e.target.value,
                valid: !!e.target.value ? true : false
            },
            showErr: false
        }}))
    }
    handleEmailChange(e){
        this.setState(update(this.state,{$set:{
            email:{
                value: e.target.value,
                valid: !!e.target.value ? true : false
            },
            showErr: false
        }}))
    }
    handleTypeLocation(e){
        this.setState(update(this.state,{$set:{
            location:{
                value: e.target.value,
                valid: !!e.target.value ? true : false
            },
            showErr: false
        }}))
    }
    handleSubmit(){        
        if(this.validInput()){
            fetch('https://beta.devoway.com/manage/api/corporate_companies', {
			    method: 'POST',
			    body: JSON.stringify({
                    name: this.state.name.value,
                    phone: this.state.phone.value,
                    email: this.state.email.value,
                    location: this.state.location.value
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
		    }).then(response => {
                if(response.status === 200){                    
                    this.props.callbackFormSuccess()
                }
            }				
			)
        }else{
            this.setState(update(this.state, {$set:{showErr: true}}))
        }
    }
    handleUpdate(id){        
        if(this.validInput()){
            fetch(`https://beta.devoway.com/manage/api/corporate_companies/${this.state.id}?`, {
			    method: 'PUT',
			    body: JSON.stringify({
                    name: this.state.name.value,
                    phone: this.state.phone.value,
                    email: this.state.email.value,
                    location: this.state.location.value
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
		    }).then(response => {
               
                if(response.status === 200){                    
                    this.props.callbackFormSuccess()
                }
            }				
			)
        }else{
            this.setState(update(this.state, {$set:{showErr: true}}))
        }
    }
    validInput = () => !!this.state.name.valid && 
                       !!this.state.phone.valid &&
                       !!this.state.email.valid &&
                       !!this.state.location.valid  */
    
    renderErrorMsg(){                  
        return <Alert color="warning">something went wrong : please enter correctly</Alert>       
    }
    renderSuccessMessage(){
        return <Alert color="success">Success</Alert>
    }

    getdataSource () {
        const companyLists =  this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value : item.name
        }));
        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }

    handleSelectCompanyList = (e) => {
        this.setState({corporateCompany : e})
    }

    handleSubmit() {
        let errors={};
        let selectedCompany;
        const { user, manager } = this.props;

        if (!manager.manager.values.name) {
            errors = {...errors, name:"Please enter name"}
        } else {
            delete errors["name"];
        }

        if (!manager.manager.values.username) {
            errors = {...errors, username:"Please enter username"}
        } else {
            delete errors["username"];
        }

        if (!manager.manager.values.password) {
            errors = {...errors, password:"Please enter password"}
        } else {
            delete errors["password"];
        }

        if (!manager.manager.values.email) {
            errors = {...errors, email:"Please enter email"}
        } else {
         if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(manager.manager.values.email)){
             delete errors["email"];
         } else {
             errors = {...errors, email:"Please enter valid email address"}
         }
        }

        if (!this.state.corporateCompany) {
            errors = {...errors, corporateCompany:"Please select company"}
        } else {
            selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
            if (selectedCompany) {
                errors = {...errors, status:"Invalid corporate company. Please select another"}
            } else {
                delete errors["corporateCompany"];
            }
        }

        if (!manager.manager.values.phone) {
            errors = {...errors, phone:"Please enter phone"}
        } else {
            if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(manager.manager.values.phone)) {
                delete errors["phone"];
            } else{
                errors = {...errors, phone:"Please enter valid phone"}
            }
        }

        if (!manager.manager.values.status) {
            errors = {...errors, status:"Please select status"}
        } else {
            delete errors["status"];
        }

        if(Object.keys(errors).length === 0){
            this.setState({errors :null})
            if (this.props.editItem == null) {
                const requestPayload = {
                    corporate_manager : {
                        name: manager.manager.values.name,
                        username: manager.manager.values.username,
                        password: manager.manager.values.password,
                        email: manager.manager.values.email,
                        phone: manager.manager.values.phone,
                        corporate_company_id: selectedCompany.id,
                        inactive: (manager.manager.values.status == 'true') ? 0 : 1 
                    }
                }
                this.props.createManager(requestPayload, user.user.sso_token)
            } else {
                const payload = {
                    corporate_manager : {
                        name: manager.manager.values.name,
                        username: manager.manager.values.username,
                        password: manager.manager.values.password,
                        email: manager.manager.values.email,
                        phone: manager.manager.values.phone,
                        corporate_company_id: selectedCompany.id,
                        inactive: (manager.manager.values.status == 'true') ? 0 : 1
                    }
                }
                this.props.updateManager(payload, user.user.sso_token, this.props.editItem.id)
            }

            this.props.callbackHide();
        } else {
            this.setState({errors :errors})
        }
    }

    render(){
        return (
            /*<div>
                <Modal 
                    isOpen={this.props.isOpen} 
                    toggle={()=> this.props.callbackHide()}                 
                    size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Add New Company</ModalHeader>
                    {/!* {this.state.showErr && this.renderErrorMsg()} *!/}
                    {/!* {this.state.isOpenSuccess && this.renderSuccessMessage()} *!/}
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input 
                                    type="text" 
                                    onChange={(e)=> this.handleTextChange(e)} 
                                    value={this.state.name.value}                                     
                                    placeholder="Enter company name" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="tel">Phone No.</Label>
                                <Input 
                                    type="tel" 
                                    onChange={(e)=> this.handlePhoneChange(e)} 
                                    value={this.state.phone.value}  
                                    placeholder="Enter phone number" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input 
                                    type="email" 
                                    onChange={(e)=> this.handleEmailChange(e)} 
                                    value={this.state.email.value}
                                    placeholder="enter email" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="location">Location</Label>
                                <Input 
                                    type="text" 
                                    onChange={(e)=> this.handleTypeLocation(e)} 
                                    value={this.state.location.value}
                                    placeholder="enter location" />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.editItem != null ? 
                                <Button 
                                color="primary" 
                                disabled={this.props.pristine || this.props.submitting}
                                onClick={()=> this.handleUpdate()}>Update</Button>
                                :
                                <Button 
                                color="primary" 
                                disabled={this.props.pristine || this.props.submitting}
                                onClick={()=> this.handleSubmit()}>Add New</Button>    
                        }                        
                        <Button color="secondary" onClick={()=> this.props.callbackHide()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>*/
            <form className="form">
                {
                    this.props.coporateReducer.isFetching &&  this.props.coporateReducer.companyNameList.length === 0?
                        <Loader /> :
                        <div>
                            <div className="form-border">
                                <FormInput
                                    labelName="Name"
                                    name="name"
                                    placeholder="Enter manager name"
                                    component="input"
                                    type="text"
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.name)? this.state.errors.name : null}</span></Col>
                                </Row>
                                
                                <FormInput
                                    labelName="Username"
                                    name="username"
                                    placeholder="Enter username"
                                    component="input"
                                    type="text"
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.username)? this.state.errors.username : null}</span></Col>
                                </Row>
                                
                                <FormInput
                                    labelName="Password"
                                    name="password"
                                    placeholder="Enter password"
                                    component="input"
                                    type="password"
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.password)? this.state.errors.password : null}</span></Col>
                                </Row>
                                
                                <FormInput
                                    labelName="Email"
                                    name="email"
                                    placeholder="Enter email"
                                    component="input"
                                    type="text"
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.email)? this.state.errors.email : null}</span></Col>
                                </Row>
                                
                                <FormInput
                                    labelName="Phone"
                                    name="phone"
                                    placeholder="Enter phone"
                                    component="input"
                                    type="text"
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.phone)? this.state.errors.phone : null}</span></Col>
                                </Row>
                                
                                <div className="row form-group">
                                    <Col sm={4}>
                                        <label className="label">Company Name</label>
                                    </Col>
                                    <Col sm={8} >
                                    {
                                        this.props.coporateReducer.companyNameList.length > 0 ?
                                            <AutoComplete
                                                options={this.getdataSource()}
                                                id='companyId'
                                                placeholder="Select company"
                                                onSelect={this.handleSelectCompanyList}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                            :
                                            null
                                    }
                                    </Col>
                                </div>
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.corporateCompany)? this.state.errors.corporateCompany : null}</span></Col>
                                </Row>

                                <FormInput
                                    labelName="Status"
                                    name="status"
                                    component="input"
                                    checkbox={true}
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.status) ? this.state.errors.status : null}</span></Col>
                                </Row>

                            </div>
                            <div className="action-button">
                                {
                                    this.props.editItem != null ?
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={()=> this.handleSubmit()}>
                                            Submit
                                        </Button>
                                        :
                                        <Button
                                            type="primary"
                                            size="large"
                                            disabled={this.props.pristine || this.props.submitting}
                                            onClick={()=> this.handleSubmit()}
                                        >Submit</Button>
                                }

                                <Button className="ml-2" size="large" onClick={()=> this.props.callbackHide()}>Cancel</Button>

                            </div>
                        </div>
                }
            </form>
        );    
    }    
}

ManagerForm = reduxForm({
    form: 'manager'
})(ManagerForm)

const mapStateToProps =state=>({
    initialValues : state.operatorReducer.manager,
    manager : state.form,
    user : state.user,
    coporateReducer: state.coporateReducer
});
const mapDispatchToProps = dispatch => {
    return{
        requestCompanyNameList : (user)=>dispatch(requestCompanyNameList(user)),
        createManager : (payload, token)=> dispatch(createManager(payload, token)),
        updateManager : (payload, token, managerId) => dispatch(updateManager(payload, token, managerId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerForm);