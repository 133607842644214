import * as actions from "../constants/actionTypes";


export function uploadCsv(value, ssoToken){
    return({
        type : actions.UPLOAD_CSV,
        payload : value,
        token: ssoToken
    })
}

export function receiveCsvUploadResponse() {
    return({
        type : actions.UPLOAD_CSV_RESPONSE
    })
}

export function uploadError() {
    return({
        type : actions.UPLOAD_CSV_ERROR
    })
}


export function removeCsv(){
    return({
        type : actions.REMOVE_CSV
    })
}


export function addCsvFile(fileName, inputFile){
    return({
        type : actions.ADD_CSV,
        payload: fileName,
        fileData : inputFile
    })
}

export function displayCsvFileErr() {
    return({
        type : actions.DISPLAY_CSV_ERR,
    })
}