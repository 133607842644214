import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,    
} from 'reactstrap';
import DriverAssignForm from './DriverAssignForm';
import { loadDriverData } from '../../actions/formActions';

class DriverAssignModal extends Component {
    componentDidMount(){
        if(this.props.assignItem != null){                  
            this.props.loadDriverData(this.props.assignItem);
        }else{
            this.props.loadDriverData(null)
        }
    }
    render() {
        return (
            <div>
                <Modal
                 isOpen={this.props.isOpen} 
                 toggle={()=> this.props.callbackHide()}                 
                 size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Assign Driver To Cab</ModalHeader>
                    <ModalBody>
                        <DriverAssignForm
                            assignItem={this.props.assignItem}
                        callbackHide={this.props.callbackHide}/>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>     
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return{                            
        loadDriverData      : (data)=> dispatch(loadDriverData(data))
    }
}

export default connect(null,mapDispatchToProps)(DriverAssignModal);