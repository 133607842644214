import * as actions from '../constants/actionTypes';

export function pushError(error){
    return({
        type: actions.PUSH_ERROR,
        payload: error
    })
}

export function clearError(){
    return({
        type: actions.CLEAR_ERROR
    })
}