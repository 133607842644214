import * as actions from '../constants/actionTypes';
export function requestDriverData(user,page,limit){
    return({
        type : actions.REQUEST_DRIVER_DATA,
        user:user,
        page:page,
        limit:limit
    })
}
export function receiveDriverDataResult(data, page){    
    return ({
        type : actions.RECEIVE_DRIVER_DATA_RESULT,
        payload: data,
        page: page
    })
}

export function searchDriverData(user,page,params){
    return ({
        type : actions.SEARCH_DRIVER_DATA,
        user : user,
        params : params,
        page : page
    })
}

export function receiveDriverSearchResult(data,page){
    return ({
        type : actions.RECEIVE_DRIVER_SEARCH_RESULT,
        payload : data,
        page : page
    })
}



export function addNewDriver(user,data){
  
    return({
        type : actions.ADD_NEW_DRIVER,
        user :user,
        payload : data
    })
}

export function pushSuccessAddingDriver(data){
    return ({
        type : actions.SUCCESS_ADDING_DRIVER,
        payload : data
    })
}

export function updateDriver(id,user,data){
    return ({
        type : actions.UPDATE_DRIVER,
        id : id,
        user:user,
        payload : data
    })
}

export function assignDriver(user,data){
    return ({
        user : user,
        type : actions.ASSIGN_DRIVER_TO_CAB,
        payload : data
    })
}