import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import ListingTable from '../../components/ListingTables';
import { requestAgents, searchAgents } from '../../actions/agentActions';
import {clearAlert} from'../../actions/alertActions';
import AgentModal from './AgentModal';
import './style.scss';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap'
import { Button } from 'antd';
import history from "../../utils/history";

class Agents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            editItem: null,
            isOpenForm: false,
            isOpenDetail: false,
            viewItem: null,
            isOpenAssignForm: false,
            searchParam: '',
            showEntries: 25,
        }
    }
    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.alerts !== this.props.alerts && this.props.alerts.alerts!==null){
            setTimeout(() => {
                this.props.clearAlert()
            }, 3000);
        }
        if(prevProps.agentReducer.isUpdated !== this.props.agentReducer.isUpdated){
            this.loadData()
        }
    }

    loadData() {
        const { user } = this.props;
        const page = 1;
        if (user.user) {
            this.props.requestAgents(user.user, page, this.state.showEntries);
        } else {
            history.push('/sign_in');
        }
    }
    loadByLimit(limit){
        const { user } = this.props;
        this.setState({ showEntries: limit });
        this.props.requestAgents(user.user,1,limit);    
    }
    loadByPagy(page) {
        const { user } = this.props;
        this.props.requestAgents(user.user, page, this.state.showEntries);
    }
    search(params) {
        const { user } = this.props;
        this.setState({ searchParam: params })
        this.props.searchAgents(user.user, params, 1);
    }
    handleAddNew = () => this.setState({ isOpenForm: true, editItem: null })
    handleHideForm = () => this.setState({ isOpenForm: false, editItem: null })
    renderTable() {
        const agents = this.props.agentReducer;
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader className='page-header'>
                            <h4>Agent Lists</h4>
                            <Button size='large' onClick={() => this.handleAddNew()}>Add Agent</Button>{' '}
                        </CardHeader>
                        <CardBody>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['name', ' phone']}
                                search={(params) => this.search(params)}
                                isFetching={agents.isFetching}
                                isSearching={agents.isSearching}
                            />
                            <ListingTable
                                isAgentTable={true}
                                headers={[
                                    '#Id',
                                    'Name',
                                    'Phone',
                                    'Username',
                                    'Options'

                                ]}
                                listingData={agents.agentList}
                                callbackEdit={(item) => this.setState({ isOpenForm: true, editItem: item })}
                                callbackView={(item) => this.setState({ isOpenDetail: true, viewItem: item })}
                            />

                        </CardBody>
                        <CardFooter>
                            {
                                (agents.meta) &&
                                    <PaginationFooter 
                                        listTotal={agents.meta.total}
                                        listItem={agents.meta.per_page}
                                        perPage={this.state.showEntries}
                                        callbackPagy={(page) => this.loadByPagy(page)}
                                    />
                            }

                        </CardFooter>
                    </Card>

                </Col>
            </Row>
        )
    }
    renderForm() {
        return (
            <AgentModal
                isOpen={this.state.isOpenForm}
                callbackHide={() => this.handleHideForm()}
                // callbackFormSuccess={()=> this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTable()}
                {this.state.isOpenForm && this.renderForm()}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    user: state.user,
    alerts: state.alerts,
    agentReducer: state.agentReducer
});
const mapDispatchToProps = dispatch => {
    return {
        requestAgents: (user, page = 1, limit) => dispatch(requestAgents(user, page, limit)),
        searchAgents: (user, params, page = 1) => dispatch(searchAgents(user, params, page)),
        clearAlert : ()=>dispatch(clearAlert())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Agents);