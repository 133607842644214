
import React from 'react';
import Map from './Map';

const googleMapsApiKey = "AIzaSyBEH6CtSIDi5YsTGfcHebGykudRsi_PJIc";

const HistoryMapComponent = props => {
  const {places} = props;
  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultCenter,
    defaultZoom
  } = props;
 
  return (
    <Map
      googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + googleMapsApiKey + '&libraries=geometry,drawing,places'}
      markers={places}
      loadingElement={loadingElement || <div style={{height: `100%`}}/>}
      containerElement={containerElement || <div style={{height: "80vh"}}/>}
      mapElement={mapElement || <div style={{height: `100%`}}/>}
      defaultCenter={defaultCenter || {lat: 16.904037, lng: 96.135782}}
      defaultZoom={defaultZoom || 11}
  />
  );


}

export default HistoryMapComponent;
