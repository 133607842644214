import React from 'react';
import { Table, Badge } from 'reactstrap';
import MoreOption from '../MoreOption';
import './styles.scss';
import { DownloadOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

class ListingTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    getValueReformated = (value) => value ? value : '-';
    /*getCheckInOutStatus(value) {
        let arr = [
            { key:0, value: "Out"},
            { key:1, value: "In"},
            { key:"-", value: "-"},
            { key:2, value: "Full"}
        ];

        let obj = arr.find(o => o.key === value);
        return obj.value
    }*/
    downloadQr(item, ssoToken, name){
        this.props.downloadQr(item, ssoToken, name);
        return false;
    }
    getCompanyName = (id) => this.props.companyNameList.filter(item => item.id === id)[0];
    secondsToMinutes=(time)=>Math.floor(time / 60)+':'+Math.floor(time % 60);
    getModifyDateFormat = date => {
        let _d = new Date(this.getValueReformated(date)).toDateString();
        return _d.substr(_d.indexOf(' ')+1)
    }
    getTbodyDriver() {
        const {
            listingData,
            callbackEdit,
            callbackView,
            callbackAssign
        } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{this.getValueReformated(item.id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.license_id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.phone)}</td>                            
                            <td className="align-middle">{this.getValueReformated(item.driver_pickup_addr)}</td>
                            <td className="align-middle">
                                {(item.inactive === 1) ? 
                                    <CloseCircleOutlined className='inactive-icon' title='Inactive'/>
                                    : 
                                    <CheckCircleOutlined className='active-icon' title='Active'/>
                                }
                            </td>
                            <td className="align-middle">
                                <MoreOption
                                    type="driver"
                                    callbackEdit={() => callbackEdit(item)}
                                    callbackView={() => callbackView(item)}
                                    // callbackAssign={() => callbackAssign(item)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            )
        }
        else {
            return (<tbody>
                <tr>
                    <td colSpan="12" align="center">No data available in table</td>
                </tr>
            </tbody>)
        }
    }
    getTbodyManager() {
        const {
            listingData,
            callbackEdit,
            callbackView,
            callbackAssign
        } = this.props;
            if (listingData.length > 0) {
            return (
                <tbody>
                {listingData.map((item, index) => (
                    <tr key={index}>
                        <td className="align-middle">{this.getValueReformated(item.company_name)}</td>
                        <td className="align-middle">{this.getValueReformated(item.name)}</td>
                        <td className="align-middle">{this.getValueReformated(item.phone)}</td>
                        <td className="align-middle">{this.getValueReformated(item.email)}</td>
                        <td className="align-middle">
                            {(item.inactive != 0) ? 
                                <CloseCircleOutlined className='inactive-icon' title='Inactive'/>
                                : 
                                <CheckCircleOutlined className='active-icon' title='Active'/>
                            }
                        </td>
                        <td className="align-middle">
                            <MoreOption
                                type="manager"
                                callbackEdit={() => callbackEdit(item)}
                                callbackView={() => callbackView(item)}
                                // callbackAssign={() => callbackAssign(item)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            )
        }
        else {
            return (<tbody>
            <tr>
                <td colSpan="12" align="center">No data available in table</td>
            </tr>
            </tbody>)
        }
    }
    getTbodyOperator() {
        const {
            listingData,
            callbackEdit,
            callbackView,
            callbackAddAmount
        } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{item.name}</td>
                            <td className="align-middle">{item.phone}</td>
                            <td className="align-middle">{item.email}</td>
                            <td className="align-middle">
                                {
                                    item.wallet.hasOwnProperty('balance') ?
                                        this.getValueReformated(item.wallet.balance)
                                        :
                                        '-'
                                }
                            </td>
                            <td className="align-middle">{this.getValueReformated(item.total_current_usage)}</td>
                            <td className="align-middle">
                                {
                                    item.wallet.hasOwnProperty('reset_day') ?
                                        this.getValueReformated(item.wallet.reset_day)
                                        :
                                        '-'
                                }
                            </td>
                            <td className="align-middle">{(item.start_date) ? this.getModifyDateFormat(item.start_date) : '-'}</td>
                            <td className="align-middle">{(item.end_date) ? this.getModifyDateFormat(item.end_date) : '-'}</td>
                            <td className="align-middle">
                                <DownloadOutlined onClick={()=>this.downloadQr(item.qr_url, this.props.token, item.name)} className='download-btn' title='Download QR'/>
                                {/* <Badge color="primary" className="badge-padding" onClick={()=>this.downloadQr(item.qr_url, this.props.token, item.name)}>Download</Badge>  */}
                            </td>
                            <td className="align-middle">
                                {item.inactive === 0 ? 
                                    <CheckCircleOutlined className='active-icon' title='Active'/>
                                    : 
                                    <CloseCircleOutlined className='inactive-icon' title='Inactive'/>
                                }
                            </td>
                            <td className="align-middle">
                                <MoreOption
                                    callbackEdit={() => callbackEdit(item)}
                                    callbackView={() => callbackView(item)}
                                    callbackAddAmount={() => callbackAddAmount(item)}
                                    type="operator"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            )
        } else {
            return (<tbody>
                <tr>
                    <td colSpan="12" align="center">No data available in table</td>
                </tr>
            </tbody>)
        }

    }
    getTbodyCustomer() {
        const {
            listingData,
            callbackEdit,
            callbackView,
            callbackChangeToNormal
        } = this.props;
        if (listingData.length > 0) {
            listingData.sort((a, b) => {
                if(a.name != null) {
                    a.name.localeCompare(b.name)
                }
            })
            return (
                <tbody>
                {listingData.map((item, index) => (
                    <tr key={index}>
                        <td className="align-middle">{this.getValueReformated(item.name)}</td>
                        <td className="align-middle">{this.getValueReformated(item.phone)}</td>
                        <td className="align-middle">{!!this.getCompanyName(item.corporate_company_id) ? this.getCompanyName(item.corporate_company_id).name : '-'}</td>
                        <td className="align-middle">{this.getValueReformated(item.ferry_driver)}</td>
                        <td className="align-middle">{this.getValueReformated(item.check_in_time)}</td>
                        <td className="align-middle">{this.getValueReformated(item.check_out_time)}</td>
                        {/*<td className="align-middle">{this.getCheckInOutStatus(item.check_in_out)}</td>*/}
                        <td className="align-middle">{(item.inactive === 1) ?
                            <CloseCircleOutlined className='inactive-icon' title='Inactive'/> :
                            <CheckCircleOutlined className='active-icon' title='Active'/>
                        }</td>

                        <td className="align-middle">
                            <MoreOption
                                callbackEdit={() => callbackEdit(item)}
                                callbackView={() => callbackView(item)}
                                callbackChangeToNormal={() => callbackChangeToNormal(item)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            )
        } else {
            return (<tbody>
            <tr>
                <td colSpan="12" align="center">No data available in table</td>
            </tr>
            </tbody>)
        }
    }
    getTbodyFuelTransaction() {
        const {
            listingData
        } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {
                        listingData.map((item, index) => (
                            <tr key={index}>
                                <td className="align-middle">{this.getValueReformated(item.driver_id)}</td>
                                <td className="align-middle">{this.getValueReformated(item.driver_name)}</td>
                                <td className="align-middle">{this.getValueReformated(item.driver_phone)}</td>
                                <td className="align-middle">{this.getValueReformated(item.driver_type)}</td>
                                <td className="align-middle">{this.getValueReformated(item.corporate_company)}</td>
                                <td className="align-middle">{this.getValueReformated(item.transaction_amount)}</td>
                                <td className="align-middle">{this.getValueReformated(item.fuel_station_name)}</td>
                                <td className="align-middle">{this.getValueReformated(item.fuel_type)}</td>
                                <td className="align-middle">{this.getValueReformated(item.fuel_price)}</td>
                                <td className="align-middle">{this.getValueReformated(item.fuel_litres)}</td>
                                <td className="align-middle">{this.getValueReformated(item.previous_mileage)}</td>
                                <td className="align-middle">{this.getValueReformated(item.mileage)}</td>
                                <td className="align-middle">{this.getValueReformated(item.plate_number)} </td>
                                <td className="align-middle">{this.getValueReformated(item.initial_driver_balance)}</td>
                                <td className="align-middle">{this.getValueReformated(item.driver_balance)}</td>
                                <td className="align-middle">{this.getValueReformated(item.initial_fuel_station_balance)}</td>
                                <td>{this.getValueReformated(item.fuel_station_balance)}</td>
                                <td>{new Date(this.getValueReformated(item.created_at)).toDateString()}</td>
                            </tr>
                        ))
                    }
                </tbody>
            )
        } else {
            return (<tbody>
                <tr>
                    <td colSpan="24" align="center">No data available in table</td>
                </tr>
            </tbody>)
        }
    }
    getTbodyCab() {
        const {
            listingData,
            callbackEdit,
            callbackView,
            callbackAssign,
            callbackAssignCabtoDriver,
            callbackEditFerryRoute,
            callbackRemoveAssignDriver,
            callbackRemoveAssignCompany,
        } = this.props;
        if (listingData.length > 0)
            return (
                <tbody>
                    {listingData.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{this.getValueReformated(item.id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.car)}</td>
                            <td className="align-middle">{this.getValueReformated(item.registration_number)}</td>
                            <td className="align-middle">
                            {this.getValueReformated(item.corporate_driver_id)}
                            </td>
                            <td className="align-middle">
                            {this.getValueReformated(item.corporate_company_id)}
                            </td>
                            <td className="align-middle">
                            {this.getValueReformated(item.corporate_company_name)}
                            </td>
                            <td className="align-middle">
                            {this.getValueReformated(item.name)}
                            </td>
                            <td className="align-middle">
                            {this.getValueReformated(item.phone)}
                            </td>
                            <td className="align-middle">{(item.inactive === 1) ?
                                <CloseCircleOutlined className='inactive-icon' title='Inactive'/> :
                                <CheckCircleOutlined className='active-icon' title='Active'/>
                            }</td>
                            <td className="align-middle">
                                <MoreOption
                                    callbackEdit={() => callbackEdit(item)}
                                    callbackView={() => callbackView(item)}
                                    callbackAssign={() => callbackAssign(item)}
                                    callbackAssignCabtoDriver={() => callbackAssignCabtoDriver(item)}
                                    callbackEditFerryRoute={() => callbackEditFerryRoute(item)}
                                    callbackRemoveAssignDriver={() => callbackRemoveAssignDriver(item)}
                                    callbackRemoveAssignCompany={() => callbackRemoveAssignCompany(item)}
                                    type="cab"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            )
        else
            return (<tbody>
                <tr>
                    <td colSpan="12" align="center">No data available in table</td>
                </tr>
            </tbody>)
    }

    getTbodyFuelStation() {
        const { listingData, callbackEdit, callbackView } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{this.getValueReformated(item.id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.station_code)}</td>
                            <td className="align-middle">{this.getValueReformated(item.phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.fuel_partner_name)}</td>
                            <td className="align-middle">{this.getValueReformated((item.prices) ? item.prices.octane_92 : '')}</td>
                            <td className="align-middle">{this.getValueReformated((item.prices) ? item.prices.octane_95 : '')}</td>
                            <td className="align-middle">{this.getValueReformated((item.prices) ? item.prices.diesel : '')}</td>
                            <td className="align-middle">{this.getValueReformated((item.prices) ? item.prices.premium_diesel : '')}</td>
                            <td className="align-middle">{(item.inactive === 1) ?
                                <CloseCircleOutlined className='inactive-icon' title='Inactive'/> :
                                <CheckCircleOutlined className='active-icon' title='Active'/>
                            }</td>
                            <td className="align-middle">
                                <MoreOption
                                    callbackEdit={() => callbackEdit(item)}
                                    callbackView={() => callbackView(item)}
                                    type="fuel_station"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            )
        } else {
            return (<tbody>
                <tr>
                    <td colSpan="12" align="center">No data available in table</td>
                </tr>
            </tbody>)
        }
    }
    getTbodyAgentTransaction(){
        const {
          listingData
        } = this.props;
        if(listingData.length > 0){
            return (
                <tbody>
                    {
                    listingData.map((item,index)=>(
                        <tr key={index}>
                            <td className="align-middle">{index+1}</td>
                            <td className="align-middle">
                            {this.getValueReformated(item.id)}
                            </td>
                            <td className="align-middle">{this.getValueReformated(item.sender_name)}</td>
                            <td className="align-middle">
                                {this.getValueReformated(item.sender_phone)}
                            </td>
                            <td className="align-middle">
                                {this.getValueReformated(item.sender_type)}
                            </td>
                            <td className="align-middle">{this.getValueReformated(item.type)}
                            </td>
                            <td className="align-middle">{this.getValueReformated(item.transaction_amount)}</td>
                            <td className="align-middle">
                                {this.getValueReformated(item.receiver_name)}
                            </td>
                            <td className="align-middle">
                                {this.getValueReformated(item.receiver_phone)}
                            </td>
                            <td className="align-middle">
                                {this.getValueReformated(item.receiver_type)}
                            </td>
                            <td className="align-middle">{this.getValueReformated(item.description)}</td>
                            <td className="align-middle">{this.getValueReformated(item.initial_sender_balance)}</td>
                            <td className="align-middle">
                                {this.getValueReformated(item.sender_balance)}
                            </td>
                            <td className="align-middle">
                                {this.getValueReformated(item.initial_receiver_balance)}
                            </td>
                            <td className="align-middle">
                                {this.getValueReformated(item.receiver_balance)}
                            </td>
                            <td>
                            {new Date(this.getValueReformated(item.created_at)).toDateString()}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            )
        }else{
            return (<tbody>
                <tr>
                    <td colSpan="24" align="center">No data available in table</td>
                </tr>
            </tbody>)
        }
    }

    getTbodyAircabOrder(){
        const {
          listingData,
          callbackHistoryView
        } = this.props;
        if(listingData.length > 0){
            return (
                <tbody>
                    {
                    listingData.map((item,index)=>(
                        <tr key={index}>
                            <td className="align-middle">{index+1}</td>
                            <td className="align-middle">{this.getValueReformated(item.id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.booking_type)}</td>
                            <td className="align-middle"><a href="#">{this.getValueReformated(item.cab_name)}</a></td>
                            <td className="align-middle"><a href="#">{this.getValueReformated(item.cab_phone)}</a></td>
                            <td className="align-middle">{this.getValueReformated(item.cab_car_plate_number)}</td>
                            <td className="align-middle"><a href="#">{this.getValueReformated(item.customer_phone)}</a></td>
                            <td className="align-middle">{this.getValueReformated(item.status)}</td>
                            {/* <td className="align-middle"> {this.secondsToMinutes(this.getValueReformated(item.estimated_duration))}</td> */}
                            {/* <td className="align-middle"> {!! item.estimated_distance_in_meters ?  this.secondsToMinutes(this.getValueReformated(item.estimated_distance_in_meters)) : '-' }</td> */}
                            <td className="align-middle"> {this.getValueReformated(item.estimated_distance_in_meters) }</td>
                            <td className="align-middle">{this.getValueReformated(item.requested_pickup_time)}</td>
                            <td className="align-middle">{this.getValueReformated(item.finished_at)}</td>
                            <td className="align-middle">{this.getValueReformated(item.amount)}</td>
                            <td className="align-middle">{this.getValueReformated(item.start_location)}</td>
                            <td className="align-middle">{this.getValueReformated(item.end_location)}</td>
                            <td className="align-middle"> <a href='#' onClick={()=> this.props.callbackHistoryView(item)}>view</a></td>
                          
                           
                        </tr>
                    ))
                    }
                </tbody>
            )
        }else{
            return (<tbody>
                <tr>
                    <td colSpan="24" align="center">No data available in table</td>
                </tr>
            </tbody>)
        }
    }

    getTbodyAircabList(){
        const { listingData,} = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item) => (
                        <tr key={item.id}>
                            <td className="align-middle">{this.getValueReformated(item.id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.status)}</td>
                            <td className="align-middle">{this.getValueReformated(item.name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.cab_type)}</td>
                            <td className="align-middle">{this.getValueReformated(item.last_update)}</td>
                            <td className="align-middle">{this.getValueReformated(item.plate_number)}</td>
                            <td className="align-middle">{this.getValueReformated(item.license_id)}</td>
                            
                        </tr>
                    ))}
                </tbody>
            )
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="12" align="center">No data available in table</td>
                    </tr>
                </tbody>
            )
        }
    }

    getTbodyFuelPartner() {
        const { listingData, callbackEdit, callbackView } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item, index) => (
                        <tr key={item.id}>
                            <td className="align-middle">{this.getValueReformated(item.name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.station_code)}</td>
                            <td className="align-middle">{this.getValueReformated(item.phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.balance)}</td>
                            <td className="align-middle">{(item.inactive === 1) ?
                                <CloseCircleOutlined className='inactive-icon' title='Inactive'/> :
                                <CheckCircleOutlined className='active-icon' title='Active'/>
                            }</td>
                            <td className="align-middle">{this.getValueReformated(item.fuel_stations.length)}</td>
                            <td className="align-middle">
                                <MoreOption
                                    callbackEdit={() => callbackEdit(item)}
                                    callbackView={() => callbackView(item)}
                                    type="fuel_station"
                                />
                            </td>
                        </tr>
                ))}
                </tbody>
            )
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="12" align="center">No data available in table</td>
                    </tr>
                </tbody>
            )
        }
    }
    getTbodyAgent(){
        const { listingData, callbackEdit, callbackView } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item) => (
                        <tr key={item.id}>
                            <td className="align-middle">{this.getValueReformated(item.id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.username)}</td>
                            <td className="align-middle">
                                <MoreOption
                                    callbackEdit={() => callbackEdit(item)}
                                    callbackView={() => callbackView(item)}
                                    type="fuel_station"
                                />
                            </td>
                        </tr>
                ))}
                </tbody>
            )
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="12" align="center">No data available in table</td>
                    </tr>
                </tbody>
            )
        }
    }

    getTbodyCorOrder(){
        const { listingData, callbackView } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item) => (
                        <tr key={item.id}>
                            <td className="align-middle order">
                                <div className="order-id">
                                    <a href="#detail" className='order-link' onClick={(e) => callbackView(e, item)}>
                                        {this.getValueReformated(item.id)}
                                    </a>
                                </div>
                                <div className={`btn btn-xs order-status ${item.status.replace(" ", "-").toLowerCase() }`}>
                                    {this.getValueReformated(item.status.charAt(0).toUpperCase() + item.status.slice(1))}
                                </div>
                                <div className={`btn btn-xs order-fare ${item.status.replace(" ", "-").toLowerCase() }`}>
                                    {item.is_fixed_fare ? 'Fixed Fare' : 'Metered Fare'}
                                </div>
                            </td>
                            <td className="align-middle">{this.getValueReformated(item.customer_name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.customer_phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.corporate_company_name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.cab_name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.cab_phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.requested_cab_types[0].charAt(0).toUpperCase() + item.requested_cab_types[0].slice(1))}</td>
                            <td className="align-middle">{this.getValueReformated(item.payment_mode)}</td>
                            <td className="align-middle date-time">
                                <div>Date: {this.getValueReformated(item.requested_pickup_time.split('/')[0])}</div>
                                <div>Time: {this.getValueReformated(item.requested_pickup_time.split('/')[1])}</div>
                            </td>
                            <td className="align-middle trip-detail">
                                <div>From:</div>
                                <div>{this.getValueReformated(item.start_location['address'])}</div>
                                <div>To:</div>
                                <div>{this.getValueReformated(item.end_location['address'])}</div>
                            </td>
                            <td className="align-middle trip-desc">
                                <div>DIST: {this.getValueReformated(item.estimated_distance) + ' KM'}</div>
                                <div>TIME: {this.getValueReformated(item.act_str_tot_time)}</div>
                                <div>FARE: {this.getValueReformated(item.act_total_fare) + ' MMk'}</div>
                            </td>
                        </tr>
                ))}
                </tbody>
            )
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="12" align="center">No data available in table</td>
                    </tr>
                </tbody>
            )
        }
    }
    getTbodyDriverHCM() {
        const { listingData, callbackApproved, callbackPickAddressIn, callbackPickAddressOut } = this.props;
        if (listingData.length > 0) {
            return (
                <tbody>
                    {listingData.map((item, index) => (
                        <tr key={item.id}>
                            <td className="align-middle">{this.getValueReformated(item.corporate_driver_id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.corporate_driver_name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.corporate_driver_phone)}</td>
                            <td className="align-middle">{this.getValueReformated(item.corporate_cab_id)}</td>
                            <td className="align-middle">{this.getValueReformated(item.corporate_cab_name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.corporate_company_name)}</td>
                            <td className="align-middle">{this.getValueReformated(item.date)}</td>
                            <td className="align-left no-warp" >
                                <div style={{ paddingBottom: 5 }}>IN : <strong>{this.getValueReformated(item.check_in_time)}</strong></div>
                                <div>OUT : <strong>{this.getValueReformated(item.check_out_time)}</strong></div>
                            </td>                            
                            <td className="align-middle no-warp">{this.getValueReformated(item.pickup_addr['in'])}</td>
                            <td className="align-middle no-warp">{this.getValueReformated(item.pickup_addr['out'])}</td>
                            <td className="align-middle">{this.getValueReformated(item.old_mileage)}</td>
                            <td className="align-middle">{this.getValueReformated(item.current_mileage)}</td>
                            {
                                item.is_manager_approved !== null ?
                                    <td className="align-middle">
                                        {
                                            item.is_manager_approved == true ?
                                                <span style={{ color: 'green' }}>APPROVED</span>
                                            :
                                                <span style={{ color: 'red' }}>REJECTED</span>
                                        }
                                    </td>
                                    :
                                    <td className='align-middle'>-</td>
                            }
                            <td className="align-middle">
                                <MoreOption
                                    callbackApproved={() => callbackApproved(item)}
                                    type="driver_attendance"
                                />
                            </td>
                        </tr>
                ))}
                </tbody>
            )
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="24" align="center">No data available in table</td>
                    </tr>
                </tbody>
            )
        }
    }
    render() {
        const { headers } = this.props;
        return (
            <div className="fixedHead">
                <Table responsive hover className='listing-table'>
                    <thead className="striped">
                        <tr className="text-capitalize align-middle">
                            {headers.map((item, index) => <th key={index}>{item}</th>)}
                        </tr>
                    </thead>
                    {!!this.props.isDriverTable && this.getTbodyDriver()}
                    {!!this.props.isManagerTable && this.getTbodyManager()}
                    {!!this.props.isOperatorTable && this.getTbodyOperator()}
                    {!!this.props.isCustomerTable && this.getTbodyCustomer()}
                    {!!this.props.isCabTable && this.getTbodyCab()}
                    {!!this.props.isFuelTransactionTable && this.getTbodyFuelTransaction()}
                    {!!this.props.isAircabOrderTable && this.getTbodyAircabOrder()}
                    {!!this.props.isAircabListTable && this.getTbodyAircabList()}
                    {!!this.props.isFuelStationTable && this.getTbodyFuelStation()}
                    {!!this.props.isFuelPartnerTable && this.getTbodyFuelPartner()}
                    {!!this.props.isAgentTransactionTable && this.getTbodyAgentTransaction()}
                    {!!this.props.isAgentTable && this.getTbodyAgent()}
                    {!!this.props.isCorporateOrderTabel && this.getTbodyCorOrder()}
                    {!!this.props.isDriverHCMTable && this.getTbodyDriverHCM()}
                </Table>
            </div>

        )
    }
}
export default ListingTables;
