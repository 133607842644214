import {switchMap, map, catchError} from 'rxjs/operators';
import {  ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import { pushAlert } from '../actions/alertActions';
import {receiveAgentData, receiveSearchAgentData} from '../actions/agentActions';

export function loadAgentData(action$){
    return action$.pipe(
        ofType(actions.REQUEST_AGENT),
        switchMap((payload)=>{
           return ajax.getJSON(`${API_URL}/manage/api/agents?page=${payload.page}&per_page=${payload.limit}`,{
               'Authorization': payload.user.sso_token
            }
            )
            .pipe(
                map((response)=> receiveAgentData(response,payload.page)),
                catchError(error=>{
                    if(error.response){
                      return of(pushError(error.response.errors))
                    }else{
                      
                      return of(pushError(error))
                      
                    } 
                  })
            )
        })
    )
}

export function searchAgentData(action$){
    return action$.pipe(
        ofType(actions.SEARCH_AGENT),
        switchMap((payload)=>{
           return ajax.getJSON(`${API_URL}/manage/api/agents?page=${payload.page}&search=${payload.params}`,{
               'Authorization': payload.user.sso_token
            }
            )
            .pipe(
                map((response)=> receiveSearchAgentData(response,payload.page)),
                catchError(error=>{
                    if(error.response){
                      return of(pushError(error.response.errors))
                    }else{
                      
                      return of(pushError(error))
                      
                    } 
                  })
            )
        })
    )
}

export function addNewAgent(action$){
  return action$.pipe(
    ofType(actions.ADD_NEW_AGENT),
    switchMap((payload)=>{
      return ajax.post(`${API_URL}/manage/api/agents/`,payload.payload,{
        'Authorization' : payload.user.sso_token,
        'Content-Type': 'application/json',  
      })
      .pipe(
        map((response)=>{return pushAlert(response.response, 'agent')}),
        catchError(error=>{
          if(error.response){
            return of(pushError(error.response.errors))
          }
          else{
            return of(pushError(error))
          }
        })
      )
    })

  )
}

export function updateAgentData(action$){
  return action$.pipe(
    ofType(actions.UPDATE_AGENT),
    switchMap((payload)=>{
      return ajax.put(`${API_URL}/manage/api/agents/${payload.id}`,payload.payload,{
        'Content-Type': 'application/json',  
        'Authorization' : payload.user.sso_token
      })
      .pipe(
        map((response)=>{return pushAlert(response.response, 'agent')}),
        catchError(error=>{
          if(error.response){
            return of(pushError(error.response.errors))
          }else{
            
            return of(pushError(error))
            
          }  
        })
      )
    })
  )
}
