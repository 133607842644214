import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestAgentTransactions, receiveAgentTransactions,filterAgentTransactions,exportCSV } from '../../actions/agentTransactionAction';
import { clearAlert } from '../../actions/alertActions';
import PaginationFooter from '../../components/PaginationFooter';
import ListingTable from '../../components/ListingTables';
import TableCaptions from '../../components/TableCaptions';
import {pushError} from  '../../actions/errorActions';
import './style.scss';
import 'antd/dist/antd.css';
import { DatePicker, Select, Input, Button } from 'antd';
import moment from 'moment'
import {
    Row,
    Col,
    Card,
    CardHeader,
    Label,
    CardBody,
    CardFooter,
} from 'reactstrap'

const { Option } = Select;

class AgentTransaction extends Component {
    constructor(props){
        super(props);
        this.state={
            query : {},
            searchTerm: '',
            start : '',
            end : '',
            showList: false,
            showEntries: 25
        }
        this.handleChangeCalender = this.handleChangeCalender.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleExportCSV = this.handleExportCSV.bind(this);
    }
    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({ isOpenForm: true })
    handleHideForm = () => this.setState({ isOpenForm: false, editItem: null })
    loadByLimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        this.props.requestAgentTransactions(user.user,1,limit);    
    } 
    componentDidMount(){
     this.loadData();
    }
    loadData() {
        if (this.props.agentTransactionReducer.transactionList.length === 0 || this.props.agentTransactionReducer.isUpdated) {
            const { user } = this.props;
            const page = 1;
            this.props.requestAgentTransactions(user.user, page);
        }
    }
    loadByPagy(page) {
        const { user } = this.props;
        if(this.isEmpty(this.state.query))
            this.props.requestAgentTransactions(user.user, page, this.state.showEntries);
        else
            this.props.filterAgentTransactions(user.user, this.state.query, page);
    }
    handleFilter(e){
        const {user} = this.props;
        const page =1;
        if(this.isEmpty(this.state.query)){
            const errors = {
                message : "Please Select at least One Filter"
            } 
            this.props.pushError(errors);
        }else{
            this.props.filterAgentTransactions(user.user, this.state.query, page);
        }
    }

    handleExportCSV() {
        const { user } = this.props;
        this.props.exportCSV(user.user, this.state.query);
    }

    handleChangeCalender(type, date, dateString) {
        let query = this.state.query;
        query[type] = dateString;
        this.setState({
            query,
            [type]: dateString
        });
    }
    handleInput(e, type) {
        let query = this.state.query;
        if (e.target === undefined) {
            query[type] = e
        } else {
            query[type] = e.target.value;
        }
        this.setState({ query })
    }
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    handleResetFilter(){
        const { user } = this.props;
        this.setState({
            query: {},
            start: '',
            end: ''
        })

        this.props.requestAgentTransactions(user.user, 1, this.state.showEntries);
    }
    
    renderTable() {
        const agentTransactions = this.props.agentTransactionReducer;
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader className='page-header'>
                            <h4>Agent Transactions</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="agent-filter-section">
                                <Row>
                                    <Col lg={2} md={2} sm={3}>
                                        <Label>Cab Id</Label>
                                        <Input type="text" name="driver_id" placeholder="Cab Id" onChange={(e) => this.handleInput(e, 'driver_id')} />
                                    </Col>
                                    <Col lg={2} md={3} sm={3}>
                                        <Label>Start Date</Label> <br />
                                        <DatePicker
                                            onChange={(date, dateString) => this.handleChangeCalender('start', date, dateString)}
                                            value={this.state.start !== '' ? moment(this.state.start) : ''}
                                            style={{ width: "100%", }}
                                        />
                                    </Col>

                                    <Col lg={2} md={3} sm={3}>
                                        <Label>End Date</Label>
                                        <DatePicker
                                            onChange={(date, dateString) => this.handleChangeCalender('end', date, dateString)}
                                            value={this.state.end !== '' ? moment(this.state.end) : ''}
                                            style={{ width: "100%", }}
                                        />
                                    </Col>

                                    <Col lg={2} md={3} sm={3}>
                                        <Label>Agent Phone</Label>
                                        <Input type="text" name="agent_phone"placeholder="Agent Phone" onChange={(e) => this.handleInput(e, 'agent_phone')} />
                                    </Col>

                                    <Col lg={2} md={3} sm={4}>
                                        <Label>Transaction Type</Label>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder="Select Type"
                                            optionFilterProp="children"
                                            defaultValue={this.state.query['transaction_type']}
                                            onChange={(e) => this.handleInput(e, 'transaction_type')}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="cash_in">Cash In</Option>
                                            <Option value="cash_out">Cash Out</Option>
                                        </Select>
                                    </Col>

                                    <Col lg={2} md={4} sm={4} className="button-action">
                                        <Button className='ant-btn-success' size='large' style={{ marginRight: 5 }} onClick={(e) => this.handleFilter(e)}>
                                            Search
                                        </Button>

                                        <Button type='danger' size='large' onClick={(e) => this.handleResetFilter(e)}>
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <div className="result-layout">
                                {(this.props.agentTransactionReducer.meta) ?
                                    <h6>{`Results: ${this.props.agentTransactionReducer.transactionList.length} of ${this.props.agentTransactionReducer.meta.total} transactions`  }</h6>
                                    :
                                <h6>{`Results : 0 transactions`}</h6>
                                    }
                                    <Button className="ant-btn-warning" size='large'  onClick={this.handleExportCSV} >Export CSV</Button>
                                </div>
                            </Row>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['name', ' phone']}
                                search={(params) => this.search(params)}
                            />
                            {
                                (this.props.agentTransactionReducer.isSearching) ?
                                    <div>Searching......</div>
                                    :
                                    <div></div>
                            }
                            <ListingTable
                                isAgentTransactionTable={true}
                                headers={[
                                    'No',
                                    'Cab Id',
                                    'Sender Name',
                                    'Sender Phone',
                                    'Sender Type',
                                    'Transaction Type',
                                    'Amount',
                                    'Receiver Name',
                                    'Receiver Phone',
                                    'Receiver Type',
                                    'Description',
                                    'Initial Sender balance',
                                    'After Sender balance',
                                    'Initial Receiver Balance',
                                    'After Receiver balance',
                                    'Created At'

                                ]}
                                listingData={agentTransactions.transactionList}
                            />

                        </CardBody>
                        <CardFooter>
                            {
                                (this.props.agentTransactionReducer.meta) &&
                                    <PaginationFooter 
                                        listTotal={this.props.agentTransactionReducer.meta.total}
                                        listItem={this.props.agentTransactionReducer.meta.per_page}
                                        perPage={this.state.showEntries}
                                        callbackPagy={(page) => this.loadByPagy(page)}
                                    />
                            }

                        </CardFooter>
                    </Card>

                </Col>
            </Row>
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTable()}
             </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    alerts: state.alerts,
    agentTransactionReducer: state.agentTransactionReducer
});
const mapDispatchToProps = dispatch => {
    return {
        requestAgentTransactions: (user, page,limit=25) => dispatch(requestAgentTransactions(user, page,limit)),
        filterAgentTransactions : (user,query,page,limit=25) => dispatch(filterAgentTransactions(user,query,page,limit)),
        receiveAgentTransactions : (data =[],page=1,filtered=false)=> dispatch(receiveAgentTransactions(data, page,filtered)),
        exportCSV: (user, query) => dispatch(exportCSV(user, query)),
        clearAlert: () => dispatch(clearAlert()),
        pushError : (error) => dispatch(pushError(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentTransaction);

