import React from "react";
import "./styles.scss";

import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

export default function GoogleMapLatLng(props) {
    
    const distanceToMouse = (pt, mp) => {
        if (pt && mp) {
            // return distance between the marker and mouse pointer
            return Math.sqrt(
            (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
            );
        }
    };

    const defaultCenter = { lat: 16.7825034, lng: 96.1429527 } // oway office

    const handleClick = e => {
        if(props.callbackOnClick) {
            props.callbackOnClick(e)
        }
    }
    
    let arr, center = defaultCenter;
    if (props.point) {
        arr = props.point.split(',').map(v => parseFloat(v))
        center = { lat: arr[0], lng: arr[1] }
    }
    return (
        <div style={{ height: 350, width: '100%' }}>
            <GoogleMapReact
							bootstrapURLKeys={{
								key: "AIzaSyBEH6CtSIDi5YsTGfcHebGykudRsi_PJIc",
								language: "en",
								region: "MM"
							}}
							defaultCenter={center}
							center={center}
							defaultZoom={15}
							distanceToMouse={distanceToMouse}
							onClick={e => handleClick(e)}
            >
							{arr ?
								<Marker lat={arr[0]} lng={arr[1]} tooltip={props.address} />
							:
								<Marker lat={defaultCenter['lat']} lng={defaultCenter['lng']} tooltip='Oway Travel & Tours' />
							}
            </GoogleMapReact>
        </div>
      );
}
