import React from 'react';
import { connect } from 'react-redux';
import {    
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap'
// import OperatorForm from './OperatorForm';
import CabAmountForm from './CabAmountForm';

import { loadData } from '../../actions/formActions';

class CabAmountModal extends React.Component {        
    componentDidMount(){
        if(this.props.editItem != null){            
            this.props.loadData(this.props.editItem)
        }else{
            this.props.loadData(null)
        }
    }        
    render(){
        return (
            <div>                
                <Modal 
                    isOpen={this.props.isOpen} 
                    toggle={()=> this.props.callbackHide()}                 
                    size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Add Cap Amount</ModalHeader>                    
                    <ModalBody>
                        <CabAmountForm 
                            // callbackFormSuccess={this.props.callbackFormSuccess}
                            callbackHide={this.props.callbackHide}                            
                            selectedCabAmountItem={this.props.selectedCabAmountItem}
                        />                     
                    </ModalBody>                    
                </Modal>
            </div>
        );    
    }    
}

const mapDispatchToProps = dispatch => {
    return{                            
        loadData      : (data)=> dispatch(loadData(data))
    }
}

export default connect(null, mapDispatchToProps)(CabAmountModal);