import React from 'react';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader
} from 'reactstrap';
import { Button } from 'antd'
import {connect} from 'react-redux';
import { approvedDriverAttendance } from '../../actions/driverHCMActions';

class AttendanceDetails extends React.Component {
    state = {
        viewItem: this.props.viewItem,
        isOpen: this.props.isOpen,
        hide: this.props.hide
    }

    isURL = (str) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
    handleApproved = (id, status) => {
        let token = this.props.user.user.sso_token
        this.props.approvedDriverAttendance(token, status, id)
        this.props.callbackHide()
    }
    render() {
        const { viewItem, isOpen, hide } = this.state;
        return (
            <Modal 
            isOpen={isOpen} 
            toggle={hide}
            size="xl" 
            >
                <ModalHeader>
                    { viewItem ? 'Attendance Details - #ID-'+viewItem.id : 'view details'}
                </ModalHeader>
                <Card className='attendance-detail'>                    
                    {
                        viewItem ?
                            <ListGroup>            
                                <ListGroupItem href="#" action><span className='label'>Driver Id</span><span>{viewItem.corporate_driver_id}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Driver Name</span><span>{viewItem.corporate_driver_name}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Driver Phone</span><span>{viewItem.corporate_driver_phone}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Cab Id</span><span>{viewItem.corporate_cab_id}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Cab Name</span><span>{viewItem.corporate_cab_name}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Company Name</span><span>{viewItem.corporate_company_name}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Date</span><span>{viewItem.date}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Check-in Time</span><span>{viewItem.check_in_time}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Check-out Time</span><span>{viewItem.check_out_time !== ''  ? viewItem.check_out_time : '-' }</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Pickup Address</span><span>{viewItem.pickup_addr['in'] !== null ? viewItem.pickup_addr['in'] : '-'}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Drop Off Address</span><span>{viewItem.pickup_addr['out'] !== null ? viewItem.pickup_addr['out'] : '-'}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Old Mileage</span><span>{viewItem.old_mileage ? viewItem.old_mileage : '-'}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span className='label'>Current Mileage</span><span>{viewItem.current_mileage ? viewItem.current_mileage : '-'}</span></ListGroupItem>
                                <ListGroupItem href="#" action>
                                    <span className='label'>Mileage Photo</span>
                                    <span>
                                        {
                                            this.isURL(viewItem.mileage_img_url) === true &&
                                                <img src={`${viewItem.mileage_img_url}`} style={{ width: 160, height: 180}} alt='mileage' />
                                        }
                                    </span>
                                </ListGroupItem>
                                <ListGroupItem href="#" action>
                                    <span className='label'>Status</span>
                                    {
                                        viewItem.is_manager_approved != null ?
                                            viewItem.is_manager_approved === true ?
                                                <span style={{ color: 'green' }}>APPROVED</span>
                                                    :
                                                <span style={{ color: 'red' }}>REJECTED</span>
                                            :
                                            <span>'-</span>
                                    }
                                </ListGroupItem>
                                <ListGroupItem href='#' action className='action-btn'>
                                    <Button className='ant-btn-success' size='large' onClick={() => this.handleApproved(viewItem.id, true)}>Approve</Button>
                                    <Button type='danger' size='large' onClick={() => this.handleApproved(viewItem.id, false)}>Reject</Button>
                                </ListGroupItem>
                                
                            </ListGroup>
                            :
                            <div>something worng</div>
                    }                                        
                </Card>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        approvedDriverAttendance: (token, status, id) => dispatch(approvedDriverAttendance(token, status, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceDetails);

