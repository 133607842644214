import React from 'react';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader
} from 'reactstrap';

const Details = ({selectedItem, isOpen, hide}) => (         
            <Modal 
                isOpen={isOpen} 
                toggle={hide}
                size="xl" 
            >
                <ModalHeader>
                    { selectedItem ? 'ID#' + selectedItem.id + ' - ' + selectedItem.name : 'view details'}
                </ModalHeader>                
                <Card>                    
                    {
                        selectedItem ?
                            <ListGroup>            
                                <ListGroupItem href="#" action><span>Name</span><span>{selectedItem.name}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Phone</span><span>{selectedItem.phone}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Email</span><span>{selectedItem.email}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Alert Amount</span><span>{selectedItem.wallet.alert_amount ? selectedItem.wallet.alert_amount : "0"}</span></ListGroupItem>                                                                
                                <ListGroupItem href="#" action><span>Coperate Wallet</span><span>{selectedItem.wallet.balance ? selectedItem.wallet.balance : "0"}</span></ListGroupItem>                                                                
                                <ListGroupItem href="#" action><span>Location</span><span>{selectedItem.location}</span></ListGroupItem>                                
                            </ListGroup>
                            :
                            <div>something worng</div>
                    }                                        
                </Card>
            </Modal>
        ); 
export default Details;