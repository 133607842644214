import {switchMap, map, catchError} from 'rxjs/operators';
import {  ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import { pushAlert } from '../actions/alertActions';
import { receiveCabsData,receiveSearchCabData, receiveAssignCabToDriver } from '../actions/cabActions';
import {logoutUser} from "../actions/userActions";

export function cabAmount(action$){
    return action$.pipe(
      ofType(actions.ADD_CAB_AMOUNT),
      switchMap((payload)=>
        ajax({
            url: `${API_URL}/manage/api/corporate_companies/${payload.operate_id}/wallet`,          
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': payload.user.sso_token
            },          
            body: payload.payload        
        }).pipe(
              map((response) => pushAlert({notice:"Add cap amount successed"})),            
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })                  
              )          
        ))
  }

export function cabUpdateAmount(action$){
    return action$.pipe(
      ofType(actions.UPDATE_CAB_AMOUNT),
      switchMap((payload)=>
        ajax({
            url: `${API_URL}/manage/api/corporate_companies/${payload.operate_id}/wallet`,          
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': payload.user.sso_token
            },          
            body: payload.payload        
        }).pipe(
              map((response) => pushAlert({notice:"Add cap amount successed"})),            
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.error))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })                  
              )          
        ))
  }


export function loadCabsData(action$){
    return action$.pipe(
      ofType(actions.LOAD_CABS_DATA),
      switchMap((payload)=>
       ajax.getJSON(`${API_URL}/manage/api/corporate_companies/corporate_cabs?per_page=${payload.limit}&page=${payload.page}&corporate_company_id=${payload.corporate_company_id}`,
          {
            'Authorization': payload.user.sso_token
          })     
           .pipe(
             map((response)=>{return receiveCabsData(response,payload.page)}),
             catchError(error=>{
                 if (error.status === 401) {
                     const errorData = {
                         message: "session expired: please try again"
                     }
                     return of(
                         pushError(errorData),
                         logoutUser()
                     )
                 } else {
                     if(error.response){
                         return of(pushError(error.response.errors))
                     }else{
                         return of(pushError(error))
                     }
                 }
            })
           )
      )
    )
  }

export function searchCabData(action$){
    return action$.pipe(
      ofType(actions.SEARCH_CAB_DATA),
      switchMap((payload)=>
        ajax.getJSON(`${API_URL}/manage/api/corporate_companies/corporate_cabs?search=${payload.params}&page=${payload.page}`,
        {
          'Authorization': payload.user.sso_token
        }
        )
        .pipe(
          map((response)=>{return receiveSearchCabData(response,payload.page)}),
          catchError(error=>{
              if (error.status === 401) {
                  const errorData = {
                      message: "session expired: please try again"
                  }
                  return of(
                      pushError(errorData),
                      logoutUser()
                  )
              } else {
                  if(error.response){
                      return of(pushError(error.response.errors))
                  }else{
                      return of(pushError(error))
                  }
              }
          })
        )
      )
    )
}  

export function assignCab(action$){
    return action$.pipe(
      ofType(actions.ASSIGN_CAB_COPERATE),
      switchMap((payload)=>
        ajax({
            url: `${API_URL}/manage/api/corporate_managers/change_to_corporate_cab`,          
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': payload.user.sso_token
            },          
            body: payload.payload        
        }).pipe(
              map((response) => pushAlert({notice:"successfully assigned company"})),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }return of(pushError(error.response.error))
                })                  
              )          
        ))
  }  

export function assignCabDriver(action$){
    return action$.pipe(
      ofType(actions.ASSIGN_CAB_TO_DRIVER),
      switchMap((payload)=>{
        return ajax.post(`${API_URL}/manage/api/corporate_managers/assign_corporate_driver_to_cab`,payload.payload,
        {
          'Content-Type': 'application/json',  
          'Authorization': payload.user.sso_token
        })
          .pipe(
            map((response)=>{
                return (
                    receiveAssignCabToDriver()
                    )
            }),
            catchError(error=>{
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )
                } else {
                    if(error.response){
                        return of(pushError(error.response.errors))
                    }else{
                        return of(pushError(error))
                    }
                }
          })
        )      
      })
    )
  }
  
export function removeAssignDriver(action$){
    return action$.pipe(
      ofType(actions.REMOVE_ASSIGN_DRIVER),
      switchMap((payload)=>{
        return ajax.post(`${API_URL}/manage/api/corporate_managers/resign_corporate_driver_from_cab`,payload.payload,
        {
          'Content-Type': 'application/json',  
          'Authorization': payload.user.sso_token
        })
          .pipe(
            map((response)=>{return pushAlert(response.response)}),
            catchError(error=>{
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )
                } else {
                    if(error.response){
                        return of(pushError(error.response.errors))
                    }else{
                        return of(pushError(error))
                    }
                }
          })
        )      
      })
    )
  }  
  export function removeAssignCompany(action$){
    return action$.pipe(
      ofType(actions.REMOVE_ASSIGN_COMPANY),
      switchMap((payload)=>{
        return ajax.post(`${API_URL}/manage/api/corporate_managers/change_to_cab`,payload.payload,
        {
          'Content-Type': 'application/json',  
          'Authorization': payload.user.sso_token
        })
          .pipe(
            map((response)=>{return pushAlert(response.response)}),
            catchError(error=>{
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )
                } else {
                    if(error.response){
                        return of(pushError(error.response.errors))
                    }else{
                        return of(pushError(error))
                    }
                }
          })
        )      
      })
    )
  } 


