import React from 'react';
import ReactDOM from 'react-dom';
import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, createStore, compose } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux'
import history from './utils/history';
import { rootReducer } from './reducers';
import { rootEpic } from './epics';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './localStorage'

import 'bootstrap/dist/css/bootstrap.min.css';

const epicMiddleware = createEpicMiddleware();

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            
    }) : compose;      

const persistedState = loadState();

// const enhancer = composeEnhancers(
//     applyMiddleware(epicMiddleware)
    
//   );
var enhancer = null;
if(process.env.NODE_ENV === 'production'){  
  enhancer = compose(applyMiddleware(epicMiddleware));
}else{
  enhancer = composeEnhancers(
    applyMiddleware(epicMiddleware)  
  );
}
const store= createStore(rootReducer, persistedState, enhancer);

store.subscribe(()=> {
  saveState({    
    user: store.getState().user,    
    });
})

epicMiddleware.run(rootEpic);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>        
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
