import React from 'react';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader,
    Badge
} from 'reactstrap';
const FuelStationDetail = ({viewItem, isOpen, hide}) => {
    return (
        <Modal 
        isOpen={isOpen} 
        toggle={hide}
        size="xl" 
    >
        <ModalHeader>
            { viewItem ? 'ID#'+viewItem.id + ' - ' + viewItem.name : 'view details'}
        </ModalHeader>
        <Card>                    
            {
                viewItem ?
                    <ListGroup>            
                        <ListGroupItem href="#" action><span>Name</span><span>{viewItem.name}</span></ListGroupItem>
                        <ListGroupItem href="#" action><span>Station Code</span><span>{viewItem.station_code}</span></ListGroupItem> 
                        <ListGroupItem href="#" action><span>Phone</span><span>{viewItem.phone}</span></ListGroupItem> 
                        <ListGroupItem href="#" action><span>Address</span><span>{viewItem.address}</span></ListGroupItem> 
                        <ListGroupItem href="#" action><span>City</span><span>{viewItem.city}</span></ListGroupItem> 
                        <ListGroupItem href="#" action><span>Partner Name</span><span>{viewItem.fuel_partner_name}</span></ListGroupItem>
                        <ListGroupItem href="#" action><span>Diesel Price</span><span>{(viewItem.prices)?viewItem.prices.diesel:0}</span></ListGroupItem> 
                        <ListGroupItem href="#" action><span>Premium Diesel Price</span><span>{(viewItem.prices)?viewItem.prices.premium_diesel:0}</span></ListGroupItem>  
                        <ListGroupItem href="#" action><span>Octane 92 Price</span><span>{(viewItem.prices)?viewItem.prices.octane_92:0}</span></ListGroupItem> 
                        <ListGroupItem href="#" action><span>Octane 95 Price</span><span>{(viewItem.prices)?viewItem.prices.octane_95:0}</span></ListGroupItem>                             
                        <ListGroupItem href="#" action>
                            <span>Status</span>
                            <span>
                                {
                                    viewItem.inactive === 1 ? 
                                    <Badge color="danger" className="badge-padding">Inactive</Badge>
                                        :
                                    <Badge color="success" className="badge-padding">Active</Badge>
                                }
                            </span>
                        </ListGroupItem>                                
                    </ListGroup>
                    :
                    <div>something worng</div>
            }                                        
        </Card>
    </Modal>
    );
};

export default FuelStationDetail;