import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import {receiveDriverDataResult,receiveDriverSearchResult} from '../actions/driverAction';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import { pushAlert } from '../actions/alertActions';
import {logoutUser} from "../actions/userActions";

export function loadDriverData(action$){
    return action$.pipe(
      ofType(actions.REQUEST_DRIVER_DATA),
      switchMap((payload)=>
       ajax.getJSON(`${API_URL}/manage/api/corporate_drivers?per_page=${payload.limit}&page=${payload.page}`,
       {
        'Authorization': payload.user.sso_token
        })
            .pipe(
              map((response)=>receiveDriverDataResult(response, payload.page)),
              catchError(error=>{
                  if (error.status === 401) {
                      const errorData = {
                          message: "session expired: please try again"
                      }
                      return of(
                          pushError(errorData),
                          logoutUser()
                      )
                  } else {
                      if(error.response){
                          return of(pushError(error.response.errors))
                      }else{
                          return of(pushError(error))
                      }
                  }
              })
            )
      )
    )
  }

export function searchDriverData(action$){
  return action$.pipe(
    ofType(actions.SEARCH_DRIVER_DATA),
    switchMap((payload)=>
      ajax.getJSON(`${API_URL}/manage/api/corporate_drivers?search=${payload.params}&page=${payload.page}`,
      {
        'Authorization': payload.user.sso_token
      }
      )
      .pipe(
        map((response)=>receiveDriverSearchResult(response,payload.page)),
        catchError(error=>{
            if (error.status === 401) {
                const errorData = {
                    message: "session expired: please try again"
                }
                return of(
                    pushError(errorData),
                    logoutUser()
                )
            } else {
                if(error.response){
                    return of(pushError(error.response.errors))
                }else{
                    return of(pushError(error))
                }
            }
        })
      )
    )
  )
}  
  
export function updateDriver(action$){
    return action$.pipe(
      ofType(actions.UPDATE_DRIVER),
      switchMap((payload)=>{
        return ajax.put(`${API_URL}/manage/api/corporate_drivers/${payload.id}?language=en`,payload.payload,
        {
          'Content-Type': 'application/json',  
          'Authorization': payload.user.user.sso_token
        }
        )
        .pipe(
          map((response)=>{return pushAlert(response.response,'driver')}),
          catchError(error=>{
              if (error.status === 401) {
                  const errorData = {
                      message: "session expired: please try again"
                  }
                  return of(
                      pushError(errorData),
                      logoutUser()
                  )
              } else {
                  if(error.response){
                      return of(pushError(error.response.errors))
                  }else{
                      return of(pushError(error))
                  }
              }
          })
           
        )
      })
    )
  }  

export function addNewDriver(action$){
    return action$.pipe(
       ofType(actions.ADD_NEW_DRIVER),
       switchMap((payload)=>{
        return   ajax.post(`${API_URL}/manage/api/corporate_drivers?language=en`,payload.payload,
        {
          'Content-Type': 'application/json',  
          'Authorization': payload.user.user.sso_token
        }
        )
        .pipe(
           map((response)=>{
            return pushAlert(response.response,'driver')}),
             catchError(error=>{
                 if (error.status === 401) {
                     const errorData = {
                         message: "session expired: please try again"
                     }
                     return of(
                         pushError(errorData),
                         logoutUser()
                     )
                 } else {
                     if(error.response){
                         return of(pushError(error.response.errors))
                     }else{
                         return of(pushError(error))
                     }
                 }
           })
         )
        }
     )
    )  
  }

export function assignDriver(action$){
    return action$.pipe(
      ofType(actions.ASSIGN_DRIVER_TO_CAB),
      switchMap((payload)=>{
        return ajax.post(`${API_URL}/manage/api/corporate_managers/assign_corporate_driver_to_cab`,payload.payload,
        {
          'Content-Type': 'application/json',  
          'Authorization': payload.user.user.sso_token
        })
          .pipe(
            map((response)=> pushAlert(response.response)),
            catchError(error=>{
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )
                } else {
                    if(error.response){
                        return of(pushError(error.response.errors))
                    }else{
                        return of(pushError(error))
                    }
                }
          })
        )      
      })
    )
  }  