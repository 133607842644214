import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import { logoutUser } from '../actions/userActions';
import {receiveCoporateCompanies,receiveSearchCorporateResults} from '../actions/formActions';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import { pushAlert } from '../actions/alertActions';

export function loadCoporateCompanies(action$, store){
    return action$.pipe(
      ofType(actions.REQUEST_COPORATE_DATA),
      switchMap((payload)=>
        ajax.getJSON(`${API_URL}/manage/api/corporate_companies?per_page=${payload.limit}&page=${payload.page}`,{
          'Authorization': payload.user.sso_token
        }).pipe(
          map((response)=>{return receiveCoporateCompanies(response, payload.page);}),
          catchError(error=>{
              if (error.status === 401) {
                  const errorData = {
                      message: "session expired: please try again"
                  }
                  return of(
                      pushError(errorData),
                      logoutUser()
                  )
              } else {
                  if(error.response){
                      return of(pushError(error.response.errors))
                  }else{
                      return of(pushError(error))
                  }
              }
          })
        ))
    )
  }

export function searchCorporateCompanies(action$,param){
  return action$.pipe(
    ofType(actions.SEARCH_CORPORATE_DATA),
    switchMap((payload)=>
      ajax.getJSON(`${API_URL}/manage/api/corporate_companies?per_page=${payload.limit}&search=${payload.params}&page=${payload.page}`,
      {
        'Authorization': payload.user.sso_token
      }).pipe(
        map((response)=>{
          return receiveSearchCorporateResults(response,payload.page)
        }),
        catchError(error=>{
            if (error.status === 401) {
                const errorData = {
                    message: "session expired: please try again"
                }
                return of(
                    pushError(errorData),
                    logoutUser()
                )
            } else {
                if(error.response){
                    return of(pushError(error.response.errors))
                }else{
                    return of(pushError(error))
                }
            }
        })
      )
    )
  )
}  

export function createOperators(action$){
    return action$.pipe(
      ofType(actions.ADD_OPERATOR),
      switchMap((payload)=> 
        ajax({
            url: `${API_URL}/manage/api/corporate_companies/corporate_company_manager_setup`,          
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': payload.user.sso_token
            },          
            body: JSON.parse(payload.payload)                  
        })
        .pipe(
              map((response) => pushAlert(response.response,'corporate')),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })                  
              )          
        ))  
  }

export function updateOperators(action$){
    return action$.pipe(
      ofType(actions.UPDATE_OPERATOR),
      switchMap((payload)=>
        ajax({
            url: `${API_URL}/manage/api/corporate_companies/${payload.id}`,          
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': payload.user.sso_token                
            },          
            body: JSON.parse(payload.payload)         
        }).pipe(
              map((response) => pushAlert(response.response,'corporate')),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })                  
              )          
        ))
  }  