import * as actions from '../constants/actionTypes';
export function requestFuelPartner(user,page,limit){
    return ({
        type : actions.REQUEST_FUEL_PARTNER,
        user : user,
        page : page,
        limit :limit
    }) 
}
export function receiveFuelPartner(data,page){
    return ({
        type: actions.RECEIVE_FUEL_PARTNER,
        payload : data,
        page : page
    })
}

export function addNewFuelPartner(user,data){
    return ({
        type : actions.ADD_NEW_FUEL_PARTNER,
        payload : data,
        user : user
    })
}

export function updateFuelPartner(user,data,id){
    return ({
        type : actions.UPDATE_FUEL_PARTNER,
        payload : data,
        user  : user,
        id:id
    })
}

export function searchFuelPartner(user,page,params,limit,query){
    return({
        type : actions.SEARCH_FUEL_PARTNER,
        user : user,
        params : params,
        page : page,
        query : query,
        limit : limit
    })
}

export function receiveFuelPartnerSearchResult(data,page){
  
    return ({
        type : actions.RECEIVE_FUEL_PARTNER_SEARCH_RESULT,
        payload : data,
        page : page
    })
}
