import * as actions from '../constants/actionTypes';

export function addCabAmount(user, operate_id, data){    
    return({
        type: actions.ADD_CAB_AMOUNT,
        payload: data,
        user: user,
        operate_id: operate_id
    })
}
export function updateCabAmount(user, operate_id, data){    
    return({
        type: actions.UPDATE_CAB_AMOUNT,
        payload: data,
        user: user,
        operate_id: operate_id
    })
}
export function updateAmount(data){    
    return({
        type: actions.UPDATE_AMOUNT,
        payload: data        
    })
}
export function clearAmount(){    
    return({
        type: actions.CLEAR_AMOUNT        
    })
}
export function loadCabsData (user, page, limit, corporate_company_id){
    return({
        type: actions.LOAD_CABS_DATA,
        user: user,
        page: page,
        limit : limit,
        corporate_company_id: corporate_company_id
    })
}
export function receiveCabsData (data,page){
    return({
        type: actions.RECEIVE_CABS_DATA,
        payload: data,
        page : page
    })
}
export function assignCabCoperate(user, data){
    return({
        type: actions.ASSIGN_CAB_COPERATE,
        payload: data,
        user: user
    })
}

export function assignCabToDriver(user, data){
    return({
        type: actions.ASSIGN_CAB_TO_DRIVER,
        payload: data,
        user: user,
    })
}

export function receiveAssignCabToDriver() {
    return({
        type : actions.RECEIVE_ASSIGN_CAB_TO_DRIVER_RESPONSE
    })
}

export function removeAssignDriver(user, cab){
    return({
        type: actions.REMOVE_ASSIGN_DRIVER,
        payload: cab,
        user: user
    })
}
export function removeAssignCompany(user, cab){
    return({
        type: actions.REMOVE_ASSIGN_COMPANY,
        payload: cab,
        user: user
    })
}

export function searchCabData(user,page,params){
    return ({
         type : actions.SEARCH_CAB_DATA,
         user : user,
         page : page,
         params : params
    })
}
export function receiveSearchCabData(data,page){
    return ({
        type : actions.RECEIVE_SEARCH_CAB_DATA,
        payload : data,
        page : page
    })
}

export function getDraggableFerryRoute(payload){
    return ({
        type : actions.UPDATE_FERRY_ROUTE,
        payload : payload,
    })
}

export function removeDraggableFerryRoute(){
    return ({
        type : actions.REMOVE_DRAGGABLE_FERRY_ROUTE,
    })
}