import * as types from '../constants/actionTypes';
import * as actions from "../constants/actionTypes";
/**
 * form reducers.
 * @param state
 * @param action
 * @returns {*}
 */

const INITIAL_STATE = {
    operator: null,
    driver: null,
    customer: null,
    cab_form: null,
    manager: null,
    cab_assign: null,
    fuel_station: null
}

function operatorReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.LOAD_DATA:
            return Object.assign({}, state, {
                operator: action.payload
            });
        case types.LOAD_DRIVER_DATA:
            return Object.assign({}, state, {
                driver: action.payload
            })
        case actions.LOAD_MANAGER :
            return Object.assign({}, state, {
                manager : action.payload
            })
        case types.LOAD_CUSTOMER_DATA:
            return Object.assign({}, state, {
                customer: action.payload
            })
        case types.FOUND_FORM_ERROR:
            return Object.assign({}, state, {
                error: action.payload
            })
        case types.CLEAR_EDIT_DATA:
            return Object.assign({}, state, {
                operator: {}
            });
        case types.LOAD_CABS_FORM_DATA:
            return Object.assign({}, state, {
                cab_form: action.payload
            })
        case types.LOAD_CAB_ASSIGN_DATA:
            return Object.assign({}, state, {
                cab_assign: action.payload
            })
        case types.LOAD_FUEL_STATION_DATA: {
           
            if (action.payload && action.payload.prices) {
                const fuel_station = Object.assign({}, action.payload,
                    {
                        latitude:(action.payload.location)?  action.payload.location.latitude:0.0,
                        longitude:(action.payload.location)? action.payload.location.longitude:0.0,
                        octane_92: (action.payload.prices)? action.payload.prices.octane_92:0,
                        octane_95: (action.payload.prices)?action.payload.prices.octane_95:0,
                        diesel: (action.payload.prices)?action.payload.prices.diesel:0,
                        premium_diesel: (action.payload.prices)?action.payload.prices.premium_diesel:0,
                        fuel_partner_name: action.payload.fuel_partner_name,
                    }
                )
                return Object.assign({}, state, {
                    fuel_station
                })
            }else{
                return Object.assign({},state,{
                    fuel_station : action.payload
                })
            }


        }
        case types.LOAD_FUEL_PARTNER_DATA :{
        
                return Object.assign({}, state, {
                    fuel_partner : action.payload
                })
            
        }
        case types.LOAD_AGENT_DATA : {
            return Object.assign({},state,{
                agent_form: action.payload
            })
        }
        default:
            return state
    }
}

export default operatorReducer;
