import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    transactionList: [],
    isFetching: false,
    isSearching : false,
    meta: null,
    page: 1,
    limit : 25,
    isLoadingStations: false,
    isFiltered: false,
}

function aircabOrderReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actions.REQUEST_AIRCAB_ORDER: return Object.assign({}, state, {
            isFetching: true,
            limit : action.limit
        });
        case actions.RECEIVE_AIRCAB_ORDER : return Object.assign({},state,{
            isFetching : false,
            transactionList :(action.payload)?action.payload.data:[],
            meta : (action.payload)?action.payload.meta:null,
            page: action.page,
            isFiltered :action.filtered
        });
        case actions.EXPORT_AIRCAB_ORDER_CSV : 
            return Object.assign({}, state, {
                isSearching: true,
                limit : action.limit
            }); 
        case actions.PUSH_ALERT :
            return Object.assign({}, state, {
                    isFetching: false
                });       
        case actions.SELECT_FILTER:
            return Object.assign({},state,{
                isFiltered :false
            })
        case actions.SEARCH_AIRCAB_ORDER : 
        return Object.assign({},state,{
            isSearching: true,
            limit : action.limit
        });
                
        case actions.RECEIVE_AIRCAB_ORDER_SEARCH_RESULT :
           
            return Object.assign({},state,{
                        isSearching : false,
                        transactionList : (action.payload)? action.payload.data : [],
                        meta : (action.payload)?action.payload.meta : null,
                        page : action.page
                    })    
        default : return  state

    }
}

export default aircabOrderReducer;
