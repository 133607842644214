import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import CustomerForm from './CustomerForm';
import { loadCustomerData } from '../../actions/formActions';
import { addNewCustomer } from '../../actions/customerAction';

class CustomerModal extends React.Component {

    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadCustomerData(this.props.editItem)
        } else {
            this.props.loadCustomerData(null)
        }
    }


    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            this.props.editItem == null ?
                                'New Customer'
                                :
                                `CUSTOMER #${this.props.editItem.id} : ${this.props.editItem.name ? this.props.editItem.name : '-'}`
                        }
                    </ModalHeader>
                    {/* {this.state.showErr && this.renderErrorMsg()} */}
                    {/* {this.state.isOpenSuccess && this.renderSuccessMessage()} */}
                    <ModalBody>
                        <CustomerForm
                            editItem={this.props.editItem}
                            callbackHide={this.props.callbackHide}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCustomerData: (data) => dispatch(loadCustomerData(data)),
        addNewCustomer: (data) => dispatch(addNewCustomer(data))
    }
}

export default connect(null, mapDispatchToProps)(CustomerModal);