import React from 'react';
import './styles.scss';
import {addCsvFile, displayCsvFileErr} from "../../actions/uploadActions";
import {connect} from "react-redux";
import { Button } from "antd";
import 'antd/dist/antd.css';

class FileUploader extends React.Component {
    constructor(props){
        super(props);
        this.state={
            errors:null
        }
    }

    dragOver = (e) => {
        e.preventDefault();
    }

    dragEnter = (e) => {
        e.preventDefault();
    }

    handleFiles = (files) => {
        if (files.length === 1){
            if (this.validateFile(files[0])) {
                this.props.addCsvFile(files[0].name, files[0])
            } else {
                this.props.displayCsvFileErr()
            }

        }

    }

    dragLeave = (e) => {
        e.preventDefault();
    }
    validateFile = (file) => {
        const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }
    fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            this.handleFiles(files);
        }
    }

    filesSelected = (e) => {
        document.getElementById("selectFile").click()
    }
    checkFile = (e) => {
        const file = e.target.files
        if (file[0] != null){
            if (this.validateFile(file[0])) {
                this.props.addCsvFile(file[0].name, file[0])
            } else {
                this.props.displayCsvFileErr()
            }
        }

    }

    render() {
        return (

            <div className="drop-container"
                 onDragOver={this.dragOver}
                 onDragEnter={this.dragEnter}
                 onDragLeave={this.dragLeave}
                 onDrop={this.fileDrop}
                 >
                <div className="drop-message">
                    Drag & drop a file here <br/>
                    [or] <br/>
                    <Button type="primary" onClick={()=> this.filesSelected()}>Select a file from your computer</Button>
                    <input
                        id ='selectFile'
                        className="file-input"
                        type="file"
                        onChange={(e)=> this.checkFile(e)}
                    />
                </div>
            </div>

        )
    }

}

const mapStateToProps = state => ({
    user: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        addCsvFile : (payload, inputFile) => dispatch( addCsvFile(payload, inputFile)),
        displayCsvFileErr : ()     => dispatch( displayCsvFileErr())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);