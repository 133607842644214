import React, { Component } from 'react';
import ListingTables from '../../components/ListingTables';
import TableTitleCard from '../../components/TableTitleCard';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import {requestFuelStation,searchFuelStation} from '../../actions/fuelStationAction';
import FuelStationDetail from './FuelStationDetail';
import {connect} from 'react-redux';
import update from 'react-addons-update';
import './style.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
import FuelStationModal from './FuelStationModal';
class FuelStation extends Component {
    constructor(props){
        super(props);
       this.state={
           isFetching : false,
           isOpenForm : false,
           isOpenDetail : false,
           editItem : null,
           viewItem : null,
           isShowSuccess:false,
           showEntries: 25
       }
    }
    componentDidMount(){
        this.loadData();
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.fuelStationReducer.isUpdated !== this.props.fuelStationReducer.isUpdated ){
            this.loadData();
        }
    }
    alertSuccess() {
        this.setState(update(this.state, { $set: { isShowSuccess: true, isOpenModal: false, editItem: null } }))
        setTimeout(() => {
            this.setState({ isShowSuccess: false })
        }, 300);
    }

    search(params){
       
        const { user } = this.props;   
        this.props.searchFuelStation(user.user,1,params);
    } 

    loadData(){
        const { user } = this.props;
        const page = 1;
        this.props.requestFuelStation(user.user, page);
    }
    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({ isOpenForm: true })
    handleHideForm = () => this.setState({ isOpenForm: false, editItem: null })
    loadByLimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        this.props.requestFuelStation(user.user,1,limit);    
    }
    loadByPagy(page) {
        const { user } = this.props;
        this.props.requestFuelStation(user.user, page, this.state.showEntries);
    }
    handleHideForm(){
        // this.props.clearUpdateOperator();
        this.setState({ isOpenForm: false, selectedItem: null, editItem: null })
    }
    renderForm() {
        return (
            <FuelStationModal
                isOpen={this.state.isOpenForm}
                callbackHide={() => this.handleHideForm()}
                callbackFormSuccess={() => this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    handleAddNew() {
        this.setState({ isOpenForm: true })
    }
    renderDetails(){
        return(
            <FuelStationDetail
                isOpen={this.state.isOpenDetail}
                hide={()=> this.setState({isOpenDetail: false})}
                viewItem={this.state.viewItem}
            />
        )
    }
    renderTables(){
        const {fuelStationReducer} = this.props;
        return (
            <Row>          
                <Col md="12" sm="12" xs="12">
                    <Card>
                    <CardHeader>
                        <TableTitleCard 
                            title="Fuel Stations"
                            callbackAction={()=> this.handleAddNew()}
                            actionName="Add Fuel Station"
                            className='new-fuel-station'
                        />                            
                    </CardHeader>
                    <CardBody>
                        <TableCaptions
                            selectedValue={this.state.showEntries}
                            callbackSelectFun={(value) => this.loadByLimit(value)}
                            searchable={['name', ' phone']}
                            search={(params) => this.search(params)}
                            isFetching={this.props.fuelStationReducer.isFetching}
                            isSearching={this.props.fuelStationReducer.isSearching}
                        />
                        <ListingTables                          
                            isFuelStationTable={true} 
                            headers={[
                                'Fuel Station Id',                                    
                                'Name',
                                'Code',
                                'Phone',
                                'Partner Name',
                                'Octane 92',
                                'Ocatane 95',
                                'Diesel',
                                'Premium Diesel',
                                'Status',
                                'Options'
                            ]}
                            listingData = {fuelStationReducer.stationList}
                            callbackEdit={(item)=> {this.setState({isOpenForm: true, editItem: item})}}
                            callbackView={(item)=> this.setState({isOpenDetail: true, viewItem: item})}
                        />                            
                    </CardBody>
                    <CardFooter>
                        {
                            (this.props.fuelStationReducer.meta) &&
                            <PaginationFooter listTotal={this.props.fuelStationReducer.meta.total}
                                listItem={this.props.fuelStationReducer.meta.per_page}
                                perPage={this.state.showEntries}
                                callbackPagy={(page) => this.loadByPagy(page)} 
                            />
                        }
                        
                    </CardFooter>
                    </Card>
                </Col>
            </Row>                
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenDetail && this.renderDetails()}
            </div>
        );
    }
}
const mapStateToProps = state =>({
    user: state.user,
    alerts: state.alerts,
    fuelStationReducer : state.fuelStationReducer
})

const mapDispatchToProps = dispatch =>{
    return {
     requestFuelStation : (user,page,limit=25) => dispatch(requestFuelStation(user,page,limit)),
     searchFuelStation : (user,page=1,params) => dispatch(searchFuelStation(user,page,params)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (FuelStation);