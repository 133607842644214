import * as actions from '../constants/actionTypes';
const INITIAL_STATE = {
    driverAttendances : [],
    isFetching : false,
    isUpdated: false,
    meta : null,
    page :1,
    isSearching: false,
    pickUpAddress: [],
    dropOffAddress: []
}

function driverHCMReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.REQUEST_DRIVER_ATTENDANCES_DATA : 
            return Object.assign({},state,{
                isFetching : true         
            });
        case actions.RECEIVE_DRIVER_ATTENDANCES_DATA :
            return Object.assign({},state,{
                driverAttendances : (action.payload) ? action.payload.data : [],
                isFetching : false,
                isUpdated: false,
                meta : (action.payload)? action.payload.meta : null,
                page: action.page
            })   
        case actions.SEARCH_DRIVER_ATTENDANCES : 
            return Object.assign({},state,{
                isSearching : true
            });
        case actions.RECEIVE_SEARCH_DRIVER_ATTENDANCES : 
            return Object.assign({},state,{
                driverAttendances:(action.payload)? action.payload.data : [],
                isSearching : false,
                isUpdated: false,
                meta : (action.payload)? action.payload.meta : null,
                page: action.page
            });
        case actions.RECEIVE_ADDRESS_PICKUP_DRIVER:            
            return Object.assign({}, state, {                
                pickUpAddress: [
                    {address: action.payload, id: action.id},
                    ...state.pickUpAddress
                ]
            })
        case actions.RECEIVE_ADDRESS_DROPOFF_DRIVER:
            return Object.assign({}, state, {
                dropOffAddress: [
                    {address: action.payload, id: action.id},
                    ...state.dropOffAddress
                ]
            })
        case actions.PUSH_ALERT: 
            if (action.updatedData === "driver_hcm") 
               return Object.assign({}, state, { isUpdated: true })  
            else
               return state
        default : return state                                                                  
    }
}

export default driverHCMReducer;