import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import { Button } from 'antd';
import { months } from '../../constants/constants';
import { pushError } from '../../actions/formActions';
import { addCabAmount, updateAmount, clearAmount, updateCabAmount } from '../../actions/cabActions';

class CabAmountForm extends React.Component {    
    handleSubmit(){        
        const { user, cab_amount_form, selectedCabAmountItem } = this.props;   
        const amount = {            
            "cap_amount": parseInt(cab_amount_form.cab_amount_form.values.cap_amount),
            "alert_amount": parseInt(cab_amount_form.cab_amount_form.values.alert_amount),
            "warning_percent": parseInt(cab_amount_form.cab_amount_form.values.warning_percent),
            "individual_max_amount": parseInt(cab_amount_form.cab_amount_form.values.individual_max_amount),
            "reset_day": parseInt(cab_amount_form.cab_amount_form.values.reset_day)
        }                
        if(selectedCabAmountItem.wallet.hasOwnProperty('balance') === true){
            this.props.updateCabAmount(user.user, selectedCabAmountItem.id, amount);
        }else{
            this.props.addCabAmount(user.user, selectedCabAmountItem.id, amount);
        }            
        this.props.callbackHide()
    }        
    render(){              
        return (            
        <form className="form cap-amount-form">
            <div className='form-border'>
            <FormInput 
                labelName="Cap Amount"
                name="cap_amount"
                placeholder="Enter cap amount"
                component="input"
                type="number"
            />
            <FormInput
                labelName="Alert Amount" 
                name="alert_amount"
                placeholder="Enter alert amount"
                component="input"
                type="number"
            />
            <FormInput
                labelName="Warning Percent" 
                name="warning_percent"
                placeholder="Enter warning amount percentage"
                component="input"
                type="number"
            />
            <FormInput
                labelName="Individual Max Amount" 
                name="individual_max_amount"
                placeholder="Enter individual max amount"
                component="input"
                type="number"
            />
            <FormInput
                labelName="Reset Date (monthly)" 
                name="reset_day"
                placeholder="select monthly"
                component="select"
                selectValue={months}
                type="number"
                option={true}
            />
            </div>
            <div className="form-group">            
                <Button 
                    type='primary'
                    size='large'                                                 
                    disabled={this.props.pristine || this.props.submitting}
                    onClick={()=> this.handleSubmit()}
                    className='submit'
                >
                    Submit
                </Button>                                        
                <Button size='large' onClick={()=> this.props.callbackHide()}>
                    Cancel
                </Button>
            </div>
        </form>                                           
        );    
    }    
}

CabAmountForm = reduxForm({    
    form: 'cab_amount_form'
})(CabAmountForm)

const mapStateToProps = state => ({     
    initialValues : state.wallet.wallet,
    cab_amount_form          : state.form,
    user : state.user    
 });

const mapDispatchToProps = dispatch => {
    return{                            
        // loadData      : (data)=> dispatch(loadData(data)),
        pushError     : (error)=> dispatch(pushError(error)),
        addCabAmount   : (user, id, data)=> dispatch(addCabAmount(user, id, data)),
        updateCabAmount   : (user, id, data)=> dispatch(updateCabAmount(user, id, data)),        
        updateAmount : (wallet)=> dispatch(updateAmount(wallet)),
        clearAmount  : ()=> dispatch(clearAmount())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CabAmountForm);