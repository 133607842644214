import React from 'react';
import { push } from 'react-router-redux';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { Route, Switch } from 'react-router-dom';
import './app.scss';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Headers from '../MainLayouts/Header';
import MainLayout from '../MainLayouts';

import Login from '../Login'
import Sidebar from '../Sidebar'
import Dashboard from '../Dashboard'
import Managers from '../Managers'
import Cabs from '../Cabs'
import Drivers from '../Drivers'
import DriverHCM from '../DriverHCM'
import Customers from '../Customers'
import Profile from '../Profile'
import Transition from '../Transition'
import CorporateOrders from '../CorporateOrders'
import FuelTransactions from '../FuelTransactions'
import FuelStations from '../FuelStations'
import FuelPartners from '../FuelPartners'
import AircabOrder from '../AircabOrder'
import AircabLocation from '../AircabLocation'
import AgentTransactions from '../AgentTransaction'
import Agents from '../Agents'

const userIsAuthenticated = connectedRouterRedirect({
	redirectPath: '/sign_in',
	authenticatedSelector: state => state.user.user !== null,
	redirectAction: push,
	wrapperDisplayName: 'UserIsAuthenticated'
})


class App extends React.Component {
	render() {
		const { user } = this.props;
        return (
			<main className="main-content">
				{/* <React.Suspense fallback={<Spinner />}> */}
                    { user.user &&
                        <Sidebar />
                    }
                    <MainLayout user={user.user}>
                        <Headers />
                        {(() => {
                            switch (user.user && user.user.role && user.user.role.name) {
                                case "Superadmin":
                                    return <TransitionGroup className='transition-group'>
                                        <CSSTransition
                                            timeout={500}
                                            classNames='fade'
                                        >
                                            <Switch>
                                                <Route exact path="/" component={userIsAuthenticated(Dashboard)} />
                                                <Route path="/sign_in" component={Login} />
                                                <Route path="/manager" component={Managers} />
                                                <Route path="/operator_list" component={Dashboard} />
                                                <Route path="/operator_drivers" component={Drivers} />
                                                <Route path="/operator_customers" component={Customers} />
                                                <Route path="/operator_transitions" component={Transition} />
                                                <Route path="/profile" component={Profile} />
                                                <Route path="/cabs" component={Cabs} />
                                                <Route path="/aircab_order" component={AircabOrder} />
                                                <Route path="/aircab_location_map" component={AircabLocation} />
                                                <Route path="/fuel_transactions" component={FuelTransactions} />
                                                <Route path="/agent_transactions" component={AgentTransactions} />
                                                <Route path="/fuel_stations" component={FuelStations} />
                                                <Route path="/fuel_partners" component={FuelPartners} />
                                                <Route path="/agents" component={Agents} />
                                                <Route path="/corporate_orders" component={CorporateOrders} />
                                                <Route path='/driver_hcm' component={DriverHCM} />
                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                                case "Fuel Manager":
                                    return <TransitionGroup className='transition-group'>
                                        <CSSTransition
                                            timeout={500}
                                            classNames='fade'
                                        >
                                            <Switch>
                                                <Route exact path="/" component={userIsAuthenticated(FuelTransactions)} />
                                                <Route path="/sign_in" component={Login} />
                                                <Route path="/fuel_transactions" component={FuelTransactions} />
                                                <Route path="/fuel_stations" component={FuelStations} />
                                                <Route path="/fuel_transactions" component={FuelTransactions} />
                                                <Route path="/profile" component={Profile} />

                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                                case "Airport Authority":
                                    return <TransitionGroup className='transition-group'>
                                        <CSSTransition
                                            timeout={500}
                                            classNames='fade'
                                        >
                                            <Switch>
                                                <Route exact path="/" component={userIsAuthenticated(AircabOrder)} />
                                                <Route path="/sign_in" component={Login} />
                                                <Route path="/aircab_order" component={AircabOrder} />
                                                <Route path="/aircab_location_map" component={AircabLocation} />
                                                <Route path="/profile" component={Profile} />
                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                                case "Corporate Manager":
                                    return <TransitionGroup className='transition-group'>
                                        <CSSTransition
                                            timeout={500}
                                            classNames='fade'
                                        >
                                            <Switch>
                                                <Route exact path="/" component={userIsAuthenticated(Customers)} />
                                                <Route path="/sign_in" component={Login} />
                                                <Route path="/operator_customers" component={Customers} />
                                                <Route path="/cabs" component={Cabs} />
                                                <Route path="/fuel_transactions" component={FuelTransactions} />
                                                <Route path="/corporate_orders" component={CorporateOrders} />
                                                <Route path='/driver_hcm' component={DriverHCM} />
                                                <Route path="/profile" component={Profile} />
                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                                default:
                                    return <TransitionGroup>
                                        <CSSTransition
                                            timeout={250}
                                            classNames='fade'
                                        >
                                            <Switch>
                                                <Route exact path="/" component={userIsAuthenticated(Dashboard)} />
                                                <Route path="/sign_in" component={Login} />
                                                <Route path="/manager" component={Managers} />
                                                <Route path="/operator_list" component={Dashboard} />
                                                <Route path="/operator_drivers" component={Drivers} />
                                                <Route path="/operator_customers" component={Customers} />
                                                <Route path="/operator_transitions" component={Transition} />
                                                <Route path="/profile" component={Profile} />
                                                <Route path="/cabs" component={Cabs} />
                                                <Route path="/aircab_order" component={AircabOrder} />
                                                <Route path="/aircab_location_map" component={AircabLocation} />
                                                <Route path="/fuel_transactions" component={FuelTransactions} />
                                                <Route path="/agent_transactions" component={AgentTransactions} />
                                                <Route path="/fuel_stations" component={FuelStations} />
                                                <Route path="/fuel_partners" component={FuelPartners} />
                                                <Route path="/agents" component={Agents} />
                                                <Route path="/corporate_orders" component={CorporateOrders} />
                                                <Route path='/driver_hcm' component={DriverHCM} />
                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                            }
                            
                        })()}
                    </MainLayout>
                {/* </React.Suspense> */}
			</main>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
})

export default connect(mapStateToProps, null)(App);