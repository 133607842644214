import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap'
import OperatorForm from './OperatorForm';

import { loadData } from '../../actions/formActions';

class OperatorModal extends React.Component {
    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadData(this.props.editItem)
        } else {
            this.props.loadData(null)
        }
    }
    render() {
        let startDate='', endDate='';
        if (this.props.editItem != null) {
            startDate = new Date(this.props.editItem.start_date)
            endDate = new Date(this.props.editItem.end_date)
        }
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            (!this.props.editItem) ?
                                "New Corporate Company" :
                                `COMPANY #${this.props.editItem.id} : ${this.props.editItem.name}`
                        }
                    </ModalHeader>
                    <ModalBody>
                        <OperatorForm
                            callbackFormSuccess={this.props.callbackFormSuccess}
                            callbackHide={this.props.callbackHide}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadData: (data) => dispatch(loadData(data))
    }
}

export default connect(null, mapDispatchToProps)(OperatorModal);