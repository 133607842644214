import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { clearError } from '../../actions/errorActions';
import { clearAlert } from '../../actions/alertActions';
import './styles.scss';

class MainLayouts extends Component {
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.errors !== this.props.errors && this.props.errors.errors.length !==0){
            setTimeout(() => {
                this.props.clearError()    
            }, 3000);          
        }
        if(prevProps.alerts !== this.props.alerts && this.props.alerts.alerts!==null){
            setTimeout(() => {
                this.props.clearAlert()    
            }, 3000);
        }
    }
    showErrorAlert(){
        const { errors } = this.props;
        if(errors.errors && errors.errors.length > 0){            
            return errors.errors.map((err, index)=> (
                <Alert 
                    key={index} 
                    className="error-alert" 
                    type="error" 
                    message={err} 
                    toggle={this.props.clearError} 
                    showIcon
                />
            ))
        }else if(errors.errors && errors.errors.message){
            return <Alert 
                className="error-alert" 
                type="error"
                message={errors.errors.message}
                toggle={this.props.clearError}
                showIcon
            />
        }
        else if(errors.errors.error){
            return <Alert 
                className="error-alert" 
                type="error"
                message={errors.errors.error}
                toggle={this.props.clearError}
                showIcon
            />
        }
        else{
            return null;
        }
    }
    showAlerts(){
        const { alerts } = this.props;
        if(alerts.alerts != null){
            return (
                <Alert 
                    className="success-alert"
                    message={(alerts.alerts.notice)?alerts.alerts.notice : alerts.alerts.message}
                    type="success" 
                    toggle={this.props.clearAlert}
                    showIcon 
                />
            )
        }else{
            return null;
        }
    }
    render() {        
        const styleObj = this.props.user ? {} : {width: '100%', marginLeft: 0}
        return (
            <div className="main-layouts" style={styleObj}>
                <div className="error-layouts">
                    {this.showErrorAlert()}
                </div>
                <div className="alert-layouts">
                    {this.showAlerts()}
                </div>                
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = state=>({
    errors:state.errors,
    alerts:state.alerts
});

const  mapDispatchToProps = dispatch=>{
    return {
        clearError : ()=>dispatch(clearError()),
        clearAlert : ()=>dispatch(clearAlert())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayouts);