import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FileUploader from "../../components/FileUploader";
import { requestCorporateFerryList, requestFerryCustomerData } from '../../actions/ferryActions';
import { uploadCsv, removeCsv } from '../../actions/uploadActions';

import {Col} from 'reactstrap'
import './styles.scss'
import {AutoComplete, Button} from "antd";
import {requestCompanyNameList} from "../../actions/coporateCompanyAction";
import {pushAlert} from "../../actions/alertActions";

class MultipleCustomerForm extends React.Component {
    constructor(props){
        super(props);
        this.state={
            errors:null,
            corporateCompany: null,
            uploadStatus : false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.uploadReducer.fileData != this.props.uploadReducer.fileData) {
            if (this.props.uploadReducer.fileData != null){
                this.setState({errors :null})
            }
        }

        if (!prevProps.uploadReducer.isUploading && this.props.uploadReducer.isUploading ) {
            this.handleUploadStatus(this.props.uploadReducer.isUploading)
        }


        if (prevProps.uploadReducer.isUploading && !this.props.uploadReducer.isUploading ){
            this.handleUploadStatus(this.props.uploadReducer.isUploading)
            this.props.callbackHide()
            const data = {
                notice : "Successfully uploaded file"
            }
            this.props.pushAlert(data)
        }

    }

    componentDidMount(){
        const {user}=this.props;
        if (user.user) {
            this.props.requestCompanyNameList(user);
        } else {
            History.push("/sign_up")
        }

    }

    handleUploadStatus(status) {
        this.setState({uploadStatus : status})
    }

    getdataSource () {
        const companyLists =  this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value : item.name
        }));
        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }
    handleSelectCompanyList = (e) => {
        this.setState({corporateCompany : e})
    }

    handleSubmit() {
        let errors={};
        const {user, uploadReducer } = this.props;
        if (uploadReducer.fileData == null) {
            errors = {...errors, fileErr:"Please upload file to add customers"}
        }

        if(Object.keys(errors).length === 0){
            this.setState({errors :null})
            const form = new FormData();
            form.append("file", uploadReducer.fileData);
            this.props.uploadCsv(form, user.user.sso_token)

        } else {
            this.setState({errors :errors})
        }
    }
    downloadCsv() {
        let errors={};
        let corporateId;
        const {user, multicustomer} = this.props

        if (user.user.role.name == "Superadmin") {
            if (this.state.corporateCompany == null){
                errors = {...errors,corporate_company_id:"Please choose company first"}
            }else{
                corporateId = this.props.coporateReducer.companyNameList.find(o => o.name === this.state.corporateCompany);
                if (corporateId.id == "") {
                    errors = {...errors, corporate_company_id:"Invalid corporate company. Please choose another"}
                } else {
                    delete errors["corporateCompany"];
                }
            }
        } else {
            corporateId = 0
        }


        if(Object.keys(errors).length === 0){
            this.setState({errors :null})
            this.props.requestFerryCustomerData(user.user.sso_token, corporateId.id)
        }else{
            this.setState({errors :errors})
        }
        this.props.callbackHide();
    }

    render() {
        const {uploadReducer, user} = this.props
        return (
            <form className="form">
                <h4>Download Template</h4>
                <div className="form-border">
                    <label>You must download via <strong>Email</strong> and use template excel file format to add multiple customers.</label>
                    <div className="row form-group">
                        <Col sm={7} >
                            {
                                this.props.coporateReducer.companyNameList.length > 0 ?
                                    <AutoComplete
                                        options={this.getdataSource()}
                                        id='companyId'
                                        placeholder="Select Company"
                                        onSelect={this.handleSelectCompanyList}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        style={{
                                            width: "100%",
                                            height: "40px"
                                        }}
                                    />
                                    :
                                    null
                            }
                            <span className="error-span">{(this.state.errors && this.state.errors.corporate_company_id)? this.state.errors.corporate_company_id : null}</span>
                        </Col>
                        <Col sm={5.5} >
                        <Button
                            type='primary'
                            size='large'
                            onClick={() => this.downloadCsv()}>
                            Send to email
                        </Button>
                        </Col>
                    </div>

                </div>
                <hr/>
                <h4>Upload Excel File</h4>
                <div className="form-border">
                    <FileUploader />
                    {
                        uploadReducer.fileName ?
                        <div className="file-display-container">
                            <div className="file-status-bar">
                                <div>
                                    {
                                        this.state.uploadStatus ?
                                            <>Uploading.... please wait</> :
                                            <> Your file is ready for uploading </>
                                    }
                                    <div className="file-type-logo"></div>
                                    <div className="file-type">xlsx</div>
                                    <span className="file-name">{uploadReducer.fileName}</span>
                                </div>
                                <div className="file-remove" onClick={()=> this.props.removeCsv()}>X</div>
                            </div>
                        </div>
                        :
                        null
                    }

                    <span className="error-span">
                        {
                            uploadReducer.fileData == null && uploadReducer.fileErr ?
                                "Unsupported file format. Please upload excel file only"
                                :
                                null
                        }
                        {
                            (uploadReducer.fileData == null && uploadReducer.fileErr == null && this.state.errors && this.state.errors.fileErr) ?
                                this.state.errors.fileErr
                                :
                                (uploadReducer.fileData == null && this.state.errors && this.state.errors.fileErr)?
                                    this.state.errors.fileErr
                                    :
                                    null
                        }
                    </span>

                </div>

                <div className="action-button">
                    <Button
                        type='primary'
                        size='large'
                        onClick={() => this.handleSubmit()}>
                        Upload
                    </Button>
                    <Button
                        className="ml-2"
                        size='large'
                        onClick={() => this.props.callbackHide()}>
                        Cancel
                    </Button>

                </div>
            </form>
        );
    }
}

MultipleCustomerForm = reduxForm({
    form: 'multicustomer'
})(MultipleCustomerForm)

const mapStateToProps = state => ({
    initialValues: state.operatorReducer.multicustomer,
    coporateReducer: state.coporateReducer,
    uploadReducer: state.uploadReducer,
    ferryCustomerReducer: state.ferryCustomerReducer,
    multicustomer: state.form,
    alerts : state.alerts,
    user : state.user
});

const mapDispatchToProps = dispatch => {
    return {
        requestFerryCustomerData: (payload, companyId) => dispatch(requestFerryCustomerData(payload, companyId)),
        uploadCsv: (payload, ssoToken) => dispatch(uploadCsv(payload, ssoToken)),
        requestFerryRouteList: (payload) => dispatch(requestCorporateFerryList(payload)),
        removeCsv: () => dispatch(removeCsv()),
        requestCompanyNameList : (user)=>dispatch(requestCompanyNameList(user)),
        pushAlert: (payload) => dispatch(pushAlert(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MultipleCustomerForm);
