import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FormInput from '../../components/FormInput';
import { addNewFuelPartner,updateFuelPartner } from '../../actions/fuelPartnerActions';
import { Button } from 'antd';
import 'antd/dist/antd.css';
import {fule_partner_error} from '../../constants/constants';

class FuelPartnerForm extends Component {
    constructor(props){
        super(props);
        this.state={
            errors : null
        }
    }
    validateForm(fuel_partner){
        let keys = Object.keys(fuel_partner);
        let values = fuel_partner.values;
        let errors = {}
        if(fuel_partner){
            for(var i=0; i<keys.length; i++){
                var key = keys[i];
                if(!fuel_partner[key]){
                  if(fule_partner_error[key]){
                    errors = {...errors,[key]:fule_partner_error[key]};
                  }
                }
            }

            if (Object.keys(errors).length === 0) {
                return false;
            } else {
                this.setState({errors});
                return true;
            }
        }else{
            this.setState({
                errors : fule_partner_error
            });
            return true;
        }


    }
    handleSubmit(){
        const { fuel_partner, user } = this.props;
        const isError = this.validateForm(fuel_partner.fuel_partner);
        let data = fuel_partner.fuel_partner.values;
        if(!isError){
            let payload = {
                fuel_partner : {
                 name : data.name,
                 phone : data.phone,
                 email : data.email,
                 address : data.address,
                 inactive : (data.status === "true")? 1 : 0,
                 station_code : data.station_code
                },
                fuel_manager : {
                  name : data.manager_name,
                  username:data.manager_username,
                  email : data.manager_email,
                  password : !!data.manager_password ? data.manager_password : '',
                  phone : data.manager_phone,
                  status : data.manager_status
                }
            }
            this.props.addNewFuelPartner(user.user,payload);
            this.props.callbackHide();
        }

    }
    handleUpdate(){
        const { fuel_partner, user } = this.props;
        const isError = this.validateForm(fuel_partner.fuel_partner.values);
        let data = fuel_partner.fuel_partner.values;
        if(!isError){

            let payload = {
                fuel_partner : {
                 name : data.name,
                 phone : data.phone,
                 email : data.email,
                 address : data.address,
                 inactive : (data.status === "true")? 1 : 0,
                 station_code : data.station_code
                },
                fuel_manager : {
                  name : data.manager_name,
                  username:data.manager_username,
                  email : data.manager_email,
                  password : data.manager_password,
                  phone : data.manager_phone,
                  status : data.manager_status
                },

            }

            this.props.updateFuelPartner(user.user,payload,this.props.editItem.id);
            this.props.callbackHide();
        }
    }
    render() {
        return (
            <form className="form">
                <h4>Fuel Partner</h4>
                <div className="form-border">
                <FormInput
                    labelName="Name"
                    name="name"
                    placeholder="Enter Partner Name"
                    component="input"
                    type="text"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.name) ? this.state.errors.name : null}</span>

                <FormInput
                    labelName="Code"
                    name="station_code"
                    placeholder="Enter Partner Code"
                    component ="input"
                    type = "text"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.code) ? this.state.errors.code : null}</span>

                <FormInput
                    labelName="Phone"
                    name="phone"
                    placeholder="Enter Partner Phone"
                    component="input"
                    type="tel"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.phone) ? this.state.errors.phone : null}</span>

                <FormInput
                    labelName="Email"
                    name="email"
                    placeholder="Enter Partner's Email"
                    component="input"
                    type="text"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.email) ? this.state.errors.email : null}</span>

                <FormInput
                    labelName="Address"
                    name="address"
                    placeholder="Enter Address"
                    component="input"
                    type="text"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.address) ? this.state.errors.address : null}</span>
                </div>
                <h4>Status</h4>
                <div className="form-border">
                <FormInput
                    labelName="Status"
                    name="status"
                    component="input"
                    checkbox={true}
                />
                 </div>

                <h4>Manager</h4>

                <div className="form-border">
                <FormInput
                    labelName="Manager Name"
                    name="manager_name"
                    placeholder="Enter Manager Name"
                    component="input"
                    type="text"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.manager_name) ? this.state.errors.manager_name : null}</span>
                <FormInput
                        labelName="Username"
                        name="manager_username"
                        placeholder="Enter Username"
                        component="input"
                        type="text"
                    />
                    <span className="error-span">{(this.state.errors && this.state.errors.username) ? this.state.errors.username : null}</span>

                <FormInput
                    labelName="Email"
                    name="manager_email"
                    placeholder="Enter Manager Email"
                    component="input"
                    type="text"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.manager_email) ? this.state.errors.manager_email : null}</span>
                <FormInput
                    labelName="Phone"
                    name="manager_phone"
                    placeholder="Enter Manager Phone"
                    component="input"
                    type="tel"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.manager_phone) ? this.state.errors.manager_phone : null}</span>

                <FormInput
                    labelName="Password"
                    name="manager_password"
                    placeholder="Enter Password"
                    component="input"
                    type="password"
                />
                <span className="error-span">{(this.state.errors && this.state.errors.manager_password) ? this.state.errors.manager_password : null}</span>

                
                </div>

                <div className="action-button">
                    {
                        this.props.editItem != null ?
                            <Button 
                            type="primary"
                            size="large"
                            disabled={this.props.pristine || this.props.submitting}
                            onClick={() => this.handleUpdate()}
                            >
                                Submit
                            </Button>
                            :
                            <Button 
                            type="primary"
                            size="large"
                            disabled={this.props.pristine || this.props.submitting}
                            onClick={() => this.handleSubmit()}
                            >
                                Submit
                            </Button>
                    }

                    <Button className="ml-2" size="large" onClick={() => this.props.callbackHide()}>Cancel</Button>

                </div>



            </form>
        );
    }
}
FuelPartnerForm = reduxForm({
    form: 'fuel_partner'
})(FuelPartnerForm);
const mapStateToProps = state => ({
    initialValues: state.operatorReducer.fuel_partner,
    fuel_partner: state.form,
    user: state.user,
    fuelPartnerReducer: state.fuelPartnerReducer
});

const mapDispatchToProps = dispatch => {
    return {
        addNewFuelPartner :(user,data) => dispatch( addNewFuelPartner(user,data)),
        updateFuelPartner : (user,data,id) => dispatch(updateFuelPartner(user,data,id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (FuelPartnerForm);
