import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListingTables from '../../components/ListingTables';
import CabDetails from './CabDetails';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter'
import { pushAlert } from '../../actions/alertActions';
import { loadCabsData, removeAssignDriver,searchCabData,removeAssignCompany } from '../../actions/cabActions';

import './styles.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, CardFooter
} from 'reactstrap'
import FormModal from './FormModal';
import CabAssignModal from './CabAssignModal';
import CabAssignFerryModel from "./CabAssignFerryModel";

class Cabs extends Component {
    constructor(props){
        super(props)
        this.state={
            cabLists: [],
            isFetching: false,
            editItem: null,
            isFerryRouteModel: false,
            isOpenForm: false,
            isOpenDetail: false,
            viewItem: null,
            isOpenAssignForm: false,
            isOpenEditRouteForm: false,
            showEntries: 25
        }
    }

    componentDidMount(){
        const { user, cabs } = this.props;
        if(this.props.cabs.cabsList.length === 0){
            this.props.loadCabsData(user.user, 1, this.state.showEntries);
        }        
    }

    componentDidMount(){
        this.loadData();
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.cabs.isUpdated !== this.props.cabs.isUpdated ){
            this.loadData();
        }

        if (!prevProps.cabs.isFerryRouteUpdating  &&  this.props.cabs.isFerryRouteUpdating){
            this.props.pushAlert({notice: 'Corporate driver is successfully assinged to corporate cab'})
            this.loadData();
        }

    }
    loadData() {
        const { user } = this.props;
        const page = 1;
        this.props.loadCabsData(user.user, page, this.state.showEntries);
    }
 
    loadByPagy(page){
        const { user } = this.props;
        this.props.loadCabsData(user.user, page, this.state.showEntries);
    }
    handleRemoveAssign(item){
        const cab = {
            cab_id: item.id
        }
        this.props.removeAssignDriver(this.props.user.user, JSON.stringify(cab))
    }
    handleRemoveCompany(item){
        const cab = {
            cab_id: item.id
        }
        this.props.removeAssignCompany(this.props.user.user, JSON.stringify(cab))
    }
    search(params){
        const { user } = this.props;   
        this.props.searchCabData(user.user,1,params);
    }

    loadByLimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        this.props.loadCabsData(user.user,1,limit);    
    }   
    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({isOpenForm: true})
    handleHideForm = () => this.setState({isOpenForm: false})
    handleHideAssignForm = () => this.setState({isOpenAssignForm: false})
    handleHideRouteEditForm = () => this.setState({isOpenEditRouteForm: false})
    renderTables(){
        return (
            <Row>          
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader className='page-header'>
                            <h4>Corporate Cabs</h4>
                            {/* <Button color="primary" onClick={()=> this.handleForm()}>Add Cab</Button> */}
                        </CardHeader>
                        <CardBody>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable = {['license',' cab id',' driver id']}
                                search= {(params)=>this.search(params)}
                                isFetching={this.props.cabs.isFetching}
                                isSearching={this.props.cabs.isSearching}
                            />
                                  
                            <ListingTables
                                isCabTable={true} 
                                headers={[
                                    'Cab Id',
                                    'Cab Type',
                                    'Licence Number',
                                    'Driver Id',
                                    'Company Id',
                                    'Company Name',
                                    'Name',
                                    'Phone',
                                    'Status',                                    
                                    'Options'
                                ]}
                                listingData={this.props.cabs.cabsList}
                                callbackEdit={(item)=> this.handleEditItem(item)}
                                callbackView={(item)=> this.setState({isOpenDetail: true, viewItem: item})}
                                callbackAssign={(item)=> this.setState({isOpenForm: true, editItem: item})}
                                callbackAssignCabtoDriver={(item)=> this.setState({isOpenAssignForm: true, assignItem: item})}
                                callbackEditFerryRoute={(item)=> this.setState({isOpenEditRouteForm: true, assignItem: item})}
                                callbackRemoveAssignDriver={(item)=> this.handleRemoveAssign(item)}
                                callbackRemoveAssignCompany={(item)=> this.handleRemoveCompany(item)}
                            />                            
                        </CardBody>
                        <CardFooter>
                        {
                            (this.props.cabs.meta) &&
                            <PaginationFooter 
                                listTotal={this.props.cabs.meta.total} 
                                listItem={this.props.cabs.meta.per_page}
                                perPage={this.state.showEntries}
                                callbackPagy={(page) => this.loadByPagy(page)}
                            />
                        }
                        </CardFooter>                       
                    </Card>
                </Col>
            </Row>                
        )
    }
    renderForm(){
        return(
            <FormModal 
                isOpen={this.state.isOpenForm}                
                callbackHide={()=> this.handleHideForm()}
                // callbackFormSuccess={()=> this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    renderDetails(){
        return(
            <CabDetails 
                isOpen={this.state.isOpenDetail}
                hide={()=> this.setState({isOpenDetail: false})}
                viewItem={this.state.viewItem}
            />
        )
    }
    renderAssignModal(){
        return(
            <CabAssignModal
                isOpen={this.state.isOpenAssignForm}                
                callbackHide={()=> this.handleHideAssignForm()}
                // callbackFormSuccess={()=> this.alertSuccess()}
                assignItem={this.state.assignItem}
            />
        )
    }

    renderEditFerryModal(){
        return(
            <CabAssignFerryModel
                isOpen={this.state.isOpenEditRouteForm}
                callbackHide={()=> this.handleHideRouteEditForm()}
                // callbackFormSuccess={()=> this.alertSuccess()}
                assignItem={this.state.assignItem}
            />
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenDetail && this.renderDetails()}
                {this.state.isOpenAssignForm && this.renderAssignModal()}
                {this.state.isOpenEditRouteForm && this.renderEditFerryModal()}
            </div>
        );
    }
}

const mapStateToProps = state=>({
    coporateReducer : state.coporateReducer,
    user : state.user,
    cabs : state.cabs
});

const  mapDispatchToProps = dispatch=>{
     return {         
        loadCabsData: (user, page, limit=25, corporate_company_id="")=> dispatch(loadCabsData(user, page, limit, corporate_company_id)),
        removeAssignDriver: (user, cab)=> dispatch(removeAssignDriver(user, cab)),
        removeAssignCompany: (user, cab)=> dispatch(removeAssignCompany(user, cab)),
        searchCabData : (user,page=1,params)=> dispatch(searchCabData(user,page,params)),
        pushAlert : (payload) => dispatch(pushAlert(payload))
     }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cabs);