import * as actions from '../constants/actionTypes';
export function requestAgents(user,page, limit, params){
    return {
        type : actions.REQUEST_AGENT,
        user : user,
        page : page,
        limit: limit
    }
}

export function receiveAgentData(data,page){
    return {
        type    : actions.RECEIVE_AGENT_DATA,
        payload : data,
        page    : page 
    }
}

export function searchAgents(user, params, page) {
    return {
        type    : actions.SEARCH_AGENT,
        user    : user,
        params  : params,
        page    : page
    }
}

export function receiveSearchAgentData(data, page) {
    return {
        type    : actions.RECEIVE_SEARCH_AGENT_DATA,
        payload : data,
        page    : page
    }
}

export function updateAgent(user,data,id){
    return {
        type : actions.UPDATE_AGENT,
        payload : data,
        user : user,
        id : id
    }
}

export function addNewAgent(user,data){
    
    return {
        type : actions.ADD_NEW_AGENT,
        payload : data,
        user : user
    }
}