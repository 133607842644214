import React, { Component } from 'react';
import {CardText,Col,Card,CardTitle} from 'reactstrap';


class CardView extends Component{
    
    render() {
        return (
            <Col sm="3" >
                <Card body className={this.props.class}>
                    <CardTitle>{this.props.header} </CardTitle>
                    <CardText>{this.props.count}</CardText>
                
                </Card>
            </Col>
                                       
            
         )
}

}


export default CardView;
