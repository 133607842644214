import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import { Label, Row, Col } from 'reactstrap';
import { loadData, pushError } from '../../actions/formActions';
import { addOperator, updateOperator } from '../../actions/operatorActions';
import {corporate_error} from '../../constants/constants';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'antd';

class OperatorForm extends React.Component {
    constructor(props){
        super(props);
        this.state={
            errors:null,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            disabled: true // on date select
        }
    }

    getKeyValue = (value) => !!value ? value : ' ';

    getStatusValue = (value) => value === 'true' ? 0 : 1;

    validateForm(coroperate){
        let keys = Object.keys(coroperate.registeredFields);
        let values = coroperate.values;
        let errors = {}
        if(values){
            for(var i=0; i<keys.length; i++){
                var key = keys[i];
              
                if(!values[key]){
                  if(corporate_error[key]){
                    errors = {...errors,[key]:corporate_error[key]};
                  }
                }
                if(key === 'email' && values[key]) {
                    if(!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(values[key])) {
                        errors = {...errors,[key]: 'Please enter correct email address'};
                    }
                }
                if(this.state.startDate == '') {
                    errors = {...errors,['start_date']:corporate_error['start_date']};
                }
            }
            if (Object.keys(errors).length === 0) {
                return false;
            } else {
               
                this.setState({errors});
              
                return true;
            }
        }else{
            this.setState({
                errors : corporate_error
            });
            return true;
        }


    }

    handleSubmit(){
        const { user, operator } = this.props;
        const isError = this.validateForm(operator.operator);
        
        if(!isError){
        var form_vlaue = {
            corporate_company: {
                name: this.getKeyValue(operator.operator.values.name),
                phone: this.getKeyValue(operator.operator.values.phone),
                email : this.getKeyValue(operator.operator.values.email),
                location: this.getKeyValue(operator.operator.values.location),
                inactive : (operator.operator.values.status==="true")? 0 : 1,
                start_date: this.getKeyValue(moment(this.state.startDate).format('DD-MM-YYYY')),
                end_date : this.getKeyValue(moment(this.state.endDate).format('DD-MM-YYYY')),
            }
        }
        this.props.addOperator(user.user, JSON.stringify(form_vlaue));
        this.props.callbackHide()
       }
    }
    handleUpdate(){
        const { initialValues, user, operator } = this.props;
        const update_form_vlaue = {
            corporate_company: {
                name: this.getKeyValue(operator.operator.values.name),
                email : this.getKeyValue(operator.operator.values.email),
                phone: this.getKeyValue(operator.operator.values.phone),
                location: this.getKeyValue(operator.operator.values.location),
                inactive : (operator.operator.values.status==="true")? 0 :1 ,
                start_date : this.getKeyValue(moment(this.state.startDate).format('DD-MM-YYYY')),
                end_date : this.getKeyValue(moment(this.state.endDate).format('DD-MM-YYYY'))
            }
        }
        this.props.updateOperator(user.user, initialValues.id, JSON.stringify(update_form_vlaue));
        this.props.callbackHide()
    }
    handleDateChange(e) {
        let ele = e.currentTarget
        ele.setAttribute(
            "data-date",
            moment(ele.value, "YYYY-MM-DD")
            .format( ele.getAttribute("data-date-format") )
        )
    }

    setDate(date, type) {
        if(type == 'start') {
            this.setState({ 
                startDate: date,
                disabled: false
            })    
        } else {
            this.setState({ 
                endDate: date,
                disabled: false
            })
        }
    }
    render(){
        return (
        <form className="form">
            <div className="form-border">
                <FormInput
                    labelName="Name"
                    name="name"
                    placeholder="Enter company name"
                    component="input"
                    type="text"
                /> 
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{ (this.state.errors && this.state.errors.name) && this.state.errors.name }</span></Col>
                </Row>
                <FormInput
                    labelName="Email"
                    name="email"
                    placeholder="Enter company email"
                    component="input"
                    type="email"
                />
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{ (this.state.errors && this.state.errors.email) && this.state.errors.email }</span></Col>
                </Row>
                <FormInput
                    labelName="Phone No."
                    name="phone"
                    placeholder="Enter company phone"
                    component="input"
                    type="tel"
                />
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{ (this.state.errors && this.state.errors.phone) && this.state.errors.phone }</span></Col>
                </Row>
                <FormInput
                    labelName="Location"
                    name="location"
                    placeholder="Enter company location"
                    component="input"
                    type="text"
                />
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{ (this.state.errors && this.state.errors.location) && this.state.errors.location }</span></Col>
                </Row>
                <Row className='form-group'>
                    <Col sm={4}>
                        <Label>Start Date</Label>
                    </Col>
                    <Col sm={8}>
                        <DatePicker 
                            className='form-control input'
                            name='start_date'
                            dateFormat="dd/MM/yyyy" 
                            isClearable
                            minDate={moment().toDate()}
                            placeholderText="Select start date"
                            selected={this.state.startDate} 
                            onChange={date => this.setDate(date, 'start')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{ (this.state.errors && this.state.errors.start_date) && this.state.errors.start_date }</span></Col>
                </Row>
                <Row className='form-group'>
                    <Col sm={4}>
                        <Label>End Date</Label>
                    </Col>
                    <Col sm={8}>
                        <DatePicker
                            className='form-control input'
                            name='end_date'
                            dateFormat="dd/MM/yyyy" 
                            isClearable
                            minDate={moment().toDate()}
                            placeholderText="Select end date"
                            selected={this.state.endDate} 
                            onChange={date => this.setDate(date, 'end')}
                        />
                    </Col>
                </Row><br />
                <FormInput
                    labelName="Status"
                    name="status"
                    component="input"
                    checkbox={true}
                />
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}><span className="error-span">{ (this.state.errors && this.state.errors.inactive) && this.state.errors.inactive }</span></Col>
                </Row>
            </div>
            <div className="form-group">
            {
                this.props.initialValues !== null ?
                    <Button
                        disabled={(this.props.pristine || this.props.submitting) && this.state.disabled}
                        type='primary'
                        size='large'
                        onClick={()=> this.handleUpdate()}
                        >
                        Submit
                    </Button>
                    :
                    <Button
                        type='primary'
                        size='large'
                        disabled={(this.props.pristine || this.props.submitting) && this.state.disabled}
                        onClick={()=> this.handleSubmit()}
                        >
                        Submit
                    </Button>
            }
            <Button
                size='large'
                className="ml-2"
                onClick={()=> this.props.callbackHide()}
                >
                Cancel
            </Button>
            </div>
        </form>
        );
    }
}

OperatorForm = reduxForm({
    form: 'operator',
})(OperatorForm)

const mapStateToProps = state => ({
    initialValues : state.operatorReducer.operator,
    operator : state.form,
    user : state.user
 });

const mapDispatchToProps = dispatch => {
    return{
        loadData      : (data)=> dispatch(loadData(data)),
        pushError     : (error)=> dispatch(pushError(error)),
        addOperator   : (user, data)=> dispatch(addOperator(user, data)),
        updateOperator: (user, id, data)=> dispatch(updateOperator(user, id, data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(OperatorForm);
