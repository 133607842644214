import React from 'react';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader, Badge
} from 'reactstrap';
import GoogleMapLatLng from '../../components/GoogleMapLatLng';

const DriverDetails = ({viewItem, isOpen, hide}) => (         
            <Modal 
                isOpen={isOpen} 
                toggle={hide}
                size="xl"
            >
                <ModalHeader>
                    { viewItem ? 'ID#'+viewItem.id + ' - ' + viewItem.name : 'view details'}
                </ModalHeader>
                <Card>                    
                    {
                        viewItem ?
                            <ListGroup>            
                                <ListGroupItem href="#" action><span>Name</span><span>{viewItem.name}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Phone</span><span>{viewItem.phone}</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>License Number</span><span>{(viewItem.registration_number)? viewItem.registration_number : '-' }</span></ListGroupItem>
                                <ListGroupItem href="#" action><span>Company Name</span><span>{viewItem.corporate_company_name}</span></ListGroupItem>
                                <ListGroupItem href="#" action>
                                    <span>Status</span>
                                    <span>
                                        {
                                            viewItem.inactive === 1 ? 
                                            <Badge color="danger" className="badge-padding">Inactive</Badge>
                                                :
                                            <Badge color="success" className="badge-padding">Active</Badge>
                                        }
                                    </span>
                                </ListGroupItem>
                                <ListGroupItem href="#" action><span>Pickup Address</span><span>{viewItem.driver_pickup_addr}</span></ListGroupItem>
                                <GoogleMapLatLng point={viewItem.driver_pickup_point} address={viewItem.driver_pickup_addr} />                
                            </ListGroup>
                            :
                            <div>something worng</div>
                    }                                        
                </Card>
            </Modal>
        ); 
export default DriverDetails;