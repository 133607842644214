import React, { Component } from 'react';
import ListingTables from '../../components/ListingTables';
import {requestManagerData, searchManagerData} from '../../actions/managerAction';
import {connect} from 'react-redux';
import './styles.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap';
import { Button } from 'antd';

import ManagerDetails from './ManagerDetails';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import ManagerModel from "./ManagerModel";
import history from "../../utils/history";
import {clearAlert} from'../../actions/alertActions';

class Managers extends Component {
    constructor(props){
        super(props)
        this.state={
            managerList: [],
            isFetching: true,
            editItem: null,
            isOpenForm: false,
            isOpenDetail: false,
            viewItem: null,
            showEntries: 25,
        }
    }
    componentDidMount(){
        if (this.props.managerReducer.managerList.length === 0) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.alerts !== this.props.alerts && this.props.alerts.alerts!==null){
            setTimeout(() => {
                this.props.clearAlert()    
            }, 3000);
        }
        if(prevProps.managerReducer.isUpdated !== this.props.managerReducer.isUpdated){
            this.loadData();
        }
    }

    loadByLimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        this.props.requestManagerData(user.user.sso_token,1,limit)
    }

    loadData(){
        const {user} = this.props;
        if (user.user) {
            this.props.requestManagerData(user.user.sso_token, 1, 25)
        }  else {
            history.push('/sign_in');
        }
    }
    loadByPagy(page) {
        const { user } = this.props;
        this.props.requestManagerData(user.user, page,this.state.showEntries);
    }
    
    searchManager(params){
        const { user } = this.props;
        if (user.user) {
            this.props.searchManagerData(user.user.sso_token, 1,25, params);
        }

    }

    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({isOpenForm: true})
    handleHideForm = () => this.setState({isOpenForm: false, editItem:null})
    renderTables(){
        return (
            <Row>          
                <Col md="12" sm="12" xs="12">
                    <Card>
                    <CardHeader>
                        <h4>Corporate Managers</h4>
                        <Button size='large' onClick={()=> this.handleForm()}>Add Manager</Button>
                    </CardHeader>
                    <CardBody>
                        <TableCaptions 
                            selectedValue={this.state.showEntries}
                            callbackSelectFun={(value)=> this.loadByLimit( value)}
                            searchable = {['manager name, phone']}
                            search= {(params)=>this.searchManager(params)}
                            isFetching={this.props.managerReducer.isFetching}
                            isSearching={this.props.managerReducer.isSearching}
                        />
                        <ListingTables
                            isManagerTable={true}
                            headers={[                                    
                                'Company Name',
                                'Manager Name',
                                'Manager Phone',
                                'Manager Email',
                                'Status',
                                'Options'
                            ]}
                            listingData={this.props.managerReducer.managerList}
                            callbackEdit={(item)=> this.setState({isOpenForm: true, editItem: item})}
                            callbackView={(item)=> this.setState({isOpenDetail: true, viewItem: item})}
                        />                            
                    </CardBody>
                    <CardFooter>
                    {
                        (this.props.managerReducer.meta) &&
                            <PaginationFooter
                                listTotal={this.props.managerReducer.meta.total}
                                listItem={this.props.managerReducer.meta.per_page}
                                perPage={this.state.showEntries}
                                callbackPagy={(page) => this.loadByPagy(page)}
                            />
                    }
                    </CardFooter>
                    </Card>
                </Col>
            </Row>                
        )
    }
    renderForm(){
        return(
            <ManagerModel
                isOpen={this.state.isOpenForm}                
                callbackHide={()=> this.handleHideForm()}
                callbackFormSuccess={()=> this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    renderDetails(){
        return(
            <ManagerDetails 
                isOpen={this.state.isOpenDetail}
                hide={()=> this.setState({isOpenDetail: false})}
                viewItem={this.state.viewItem}
            />
        )
    }
    render() {
        return (
            <div className="content">
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenDetail && this.renderDetails()}
            </div>
        );
    }
}
const mapStateToProps = state=>({
    managerReducer:state.managerReducer,
    user : state.user,
    alerts : state.alerts
});

const  mapDispatchToProps = dispatch=>{
    return {
        requestManagerData : (userToken,page,limit=25) => dispatch(requestManagerData(userToken, page, limit)),
        searchManagerData : (userToken,page,limit=25, param) => dispatch(searchManagerData(userToken, page, limit, param)),
        clearAlert : ()=>dispatch(clearAlert()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Managers);