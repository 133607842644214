import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';

import { login } from '../../actions/userActions';


class UserForm extends React.Component {

    handleSubmit(){
        const { user } = this.props;
        const payload = {
            username: user.user.values.username,
            password: user.user.values.password
        }        
        this.props.login(payload)
    }       
    render(){
        return (            
        <form className="form"> 
            <h2>Login</h2>           
            <FormInput 
                labelName="Username or Email"
                name="username"
                placeholder="Enter username or email"
                component="input"
                type="text"
                col={12}
            />
            <FormInput
                labelName="Password" 
                name="password"
                placeholder="Password"
                component="input"
                type="password"
                col={12}

            />              
            <div className="col-12">
                <button 
                    className="primary"
                    type="button" 
                    onClick={()=> this.handleSubmit()}
                >
                    Login
                </button>  
            </div>                  

        </form>                                           
        );    
    }    
}

UserForm = reduxForm({    
    form: 'user'
})(UserForm)

const mapStateToProps = state => ({     
    // initialValues      : state.operatorReducer.driver
    user : state.form
 });

const mapDispatchToProps = dispatch => {
    return{                            
        login      : (data)=> dispatch(login(data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UserForm);