import React, { Component } from 'react';
import UserForm from './UserForm';
import history from '../../utils/history';
import {connect} from 'react-redux';
import './styles.scss';

class Login extends Component {    

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.user!== this.props.user){
            history.push('/');
        }
    }
    render() {
        let {user} = this.props;
        if(user.user){
            return <div>Login Success</div> ;
        }else{
            return (
                <div className="login-wrapper">                
                    <UserForm />
                    <p> &copy; 2020 Oway Ride. All Rights Reserved </p>
                </div>
            );
        }

    }
}

const mapStateToProps = state=>({
    user:state.user
});
export default  connect(mapStateToProps,null)(Login);