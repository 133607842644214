import * as types from '../constants/actionTypes';
/**
 * form reducers.
 * @param state
 * @param action
 * @returns {*}
 */

const INITIAL_STATE = {
    wallet:{}
};    

function wallet(state = INITIAL_STATE , action) {
    switch (action.type) {
        case types.UPDATE_AMOUNT:
            return Object.assign({}, state, {
                wallet: action.payload
            });    
        case types.CLEAR_AMOUNT:
            return Object.assign({}, state, {
                wallet: {}
            });            
        default:
          return state
      }       
}

export default wallet;