import {switchMap, mergeMap, map, catchError} from 'rxjs/operators';
import {  ofType,  } from 'redux-observable';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import * as actions from "../constants/actionTypes";
import {pushAlert} from "../actions/alertActions";
import {pushError} from "../actions/errorActions";
import axios from "axios";
import fileDownload from "js-file-download";
import {logoutUser} from "../actions/userActions";
import {receiveCsvUploadResponse} from "../actions/uploadActions";
import {receiveManagerData} from "../actions/managerAction";

export function uploadDriverCsvs(action$){
    return action$.pipe(
        ofType(actions.UPLOAD_CSV),
        switchMap((payload)=> {
            return axios.post(`${API_URL}/manage/api/corporate_customers/upload_ferry_customers`, payload.payload,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': payload.token
                }})
                .then((res) =>{
                    const data = {
                        notice : "Successfully uploaded file"
                    }
                    return of(
                        receiveCsvUploadResponse(),

                    )
                }).catch((error) =>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                })
            })
    )
}

export function uploadDriverCsv(action$){
    return action$.pipe(
        ofType(actions.UPLOAD_CSV),
        mergeMap((payload)=>
            from(axios.post(`${API_URL}/manage/api/corporate_customers/upload_ferry_customers`, payload.payload,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': payload.token
                }
            })).pipe(
                map((response)=> receiveCsvUploadResponse(),
                catchError(error=>{
                        if (error.status === 401) {
                            const errorData = {
                                message: "session expired: please try again "
                            }
                            return of(
                                pushError(errorData),
                                logoutUser()
                            )
                        } else {
                            if(error.response){
                                return of(pushError(error.response.errors), receiveCsvUploadResponse())
                            }else{
                                const errorData = {
                                    message: "Unexpected error occurred : please try again "
                                }
                                return of(pushError(errorData), receiveCsvUploadResponse())
                            }
                        }
                    })
                )
            )
        )
    )
}