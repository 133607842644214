import * as actions from '../constants/actionTypes';

export function pushAlert(error,type=""){

    return({
        type: actions.PUSH_ALERT,
        updatedData : type,
        payload: error
    })
}
export function clearAlert(){
    return({
        type: actions.CLEAR_ALERT        
    })
}