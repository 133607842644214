import React, { Component } from 'react';
import { connect } from 'react-redux';
import {requestCoporateCompanines} from '../../actions/formActions';

import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import './styles.scss';

class PaginationFooter extends Component {    
    render() {
        let listItem = this.props.listItem, listTotal = this.props.listTotal, 
            perPage = this.props.perPage, totalAmt = this.props.totalAmt;
        if(listTotal === undefined || listTotal === 0) {
            return false;
        }
        return (    
            <>
                {
                    totalAmt > 0 &&
                    <div className='show-details'>
                        <div>
                            <span className='bold-text'>Total Amount&nbsp;:&nbsp;</span>
                            <span>{parseFloat(totalAmt)} MMK</span>
                        </div>
                    </div>
                }
                <Pagination
                    total={listTotal}
                    showTotal={(listTotal, range) => `Showing ${range[0]} to ${range[1]} of ${listTotal} records`}
                    defaultPageSize={perPage}
                    pageSize={listItem}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    hideOnSinglePage={ listTotal === 0 ? true : false }
                    onChange={(page) => this.props.callbackPagy(page)}
                />
            </>
        );
    }
}
const mapStateToProps = state=>({
    user : state.user
});
const  mapDispatchToProps = dispatch=>{
    return {
        requestCoporateCompanines : (user, page)=>dispatch(requestCoporateCompanines(user, page))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PaginationFooter);