import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import {requestCompanyNameList} from '../../actions/coporateCompanyAction';
import {addNewDriver,updateDriver} from '../../actions/driverAction';
import {Col, Row} from 'reactstrap'
import {AutoComplete, Button} from "antd";
import 'antd/dist/antd.css';
import GoogleMapLatLng from '../../components/GoogleMapLatLng'

class DriverForm extends React.Component {    
    constructor(props){
        super(props);
        this.state={
            errors:null,
            corporateCompany:null
        }
    } 
    componentDidMount(){
        this.loadCompanyNamelist();
    }  
    loadCompanyNamelist(){
       const {user}=this.props;
        this.props.requestCompanyNameList(user);
    }

    getdataSource () {
        const companyLists =  this.props.coporateReducer.companyNameList && this.props.coporateReducer.companyNameList.map(item => ({
            value : item.name
        }));

        companyLists.sort((a, b) => a.value.localeCompare(b.value))
        return companyLists
    }

    handleSelectCompanyList = (e) => {
        const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.name === e);
        this.setState({corporateCompany : selectedCompany})
    }

    getDefaultValue(){
        if (this.props.editItem && this.props.editItem.corporate_company_id) {
            const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.id === this.props.editItem.corporate_company_id);
            return selectedCompany.name
        } else {
            return ""
        }
    }
    setLatLng(data) {
        data.event.preventDefault()
        let location = data.lat + ',' + data.lng, values = {}
        if (window.confirm(`Copy - ${location}`) == true) {
			const el = document.createElement('textarea');
            el.value = `${location}`;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }       
    }
    handleSubmit(){
        const { driver,user} = this.props;
        if(driver.driver.values){
            let errors={};
            let payload ={}
            if(driver.driver.values.name){
                payload = {...payload,name:driver.driver.values.name}
            }else{
                errors = {...errors,name:"Please enter driver name"}
            }

            if(driver.driver.values.phone){
                payload = {...payload,phone:driver.driver.values.phone}
            }else{
                errors = {...errors,phone:"Please enter phone"}
            }

            if(driver.driver.values.license_id){
                payload = {...payload,license_id : driver.driver.values.license_id}
            }else{
                errors = {...errors,license_id:"Please enter license number"}
            }
            
            if(this.state.corporateCompany){
                payload = {...payload,corporate_company_id: this.state.corporateCompany.id}
            }else{
                errors = {...errors,corporate_company_id:"Please select company"}
            }

            if(driver.driver.values.driver_pickup_addr){
                payload = {...payload, driver_pickup_addr: driver.driver.values.driver_pickup_addr}
            }
            if(driver.driver.values.driver_pickup_point){
                payload = {...payload, driver_pickup_point: driver.driver.values.driver_pickup_point}
            }

            if(driver.driver.values.status){
                payload = {...payload,inactive:(driver.driver.values.status==="true")?0:1}
            }

            if(Object.keys(errors).length === 0){
            
                this.props.addNewDriver(user,payload);
                this.props.callbackHide();
            }else{
            
                this.setState({errors :errors})
            }
          

        }else{
            this.setState({
                errors:{
                    name : "Please enter driver name",
                    phone : "Please enter phone",
                    registration_number : "License Number is required",
                    corporate_company_id : "Please select company"
                }
            })
        }
    }
    handleUpdate(){
         const { driver,user } = this.props;
         let editItem = this.props.editItem, cabId=null;

        if(driver.driver.values){
            let errors={};
            let payload ={cab_id: cabId}
            if(driver.driver.values.name){
                payload = {...payload,name:driver.driver.values.name}
            }else{
                errors = {...errors,name:"Please enter driver name"}
            }

            if(driver.driver.values.phone){
                payload = {...payload,phone:driver.driver.values.phone}
            }else{
                errors = {...errors,phone:"Please enter phone"}
            }

            if(driver.driver.values.license_id){
                payload = {...payload,license_id : driver.driver.values.license_id}
            }else{
                errors = {...errors,license_id:"Please enter license number"}
            }
            if(driver.driver.values.driver_pickup_addr){
                payload = {...payload, driver_pickup_addr: driver.driver.values.driver_pickup_addr}
            }
            if(driver.driver.values.driver_pickup_point){
                payload = {...payload, driver_pickup_point: driver.driver.values.driver_pickup_point}
            }

            if (this.props.editItem && this.props.editItem.corporate_company_id != null) {
                const selectedCompany = this.props.coporateReducer.companyNameList.find(o => o.id === this.props.editItem.corporate_company_id);
                this.state.corporateCompany = selectedCompany
            }

            if(this.state.corporateCompany){
                payload = {...payload,corporate_company_id: this.state.corporateCompany.id}
            }else{
                errors = {...errors,corporate_company_id:"Please select company"}
            }
            if(this.props.editItem.cab) {
                payload = {...payload,cab_id: this.props.editItem.cab._id}
            }
        //  if(driver.driver.values.status){
        //   payload = {...payload,inactive:(driver.driver.values.status==="true")?0:1}
        // }
            if(Object.keys(errors).length === 0){
                payload = {...payload,inactive: (driver.driver.values.status==="true")? 0 : 1};
                this.props.updateDriver(editItem.id,user,payload);
                this.props.callbackHide();
            }else{
                this.setState({errors :errors})
            }
          

        }else{
            this.setState({
                errors:{
                    name : "Please enter driver name",
                    phone : "Please enter phone",
                    registration_number : "Please enter license number",
                    corporate_company_id : "Please select company"
                }
            })
        }

    }
    render(){
        let point = '', address = ''; 
        if (this.props.editItem !== null) {
            point = this.props.editItem.driver_pickup_point
            address = this.props.editItem.driver_pickup_addr
        }
        return (
            <form className="form">
                <div className="form-border">
                    <FormInput 
                        labelName="Name"
                        name="name"
                        placeholder="Enter driver name"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.name)? this.state.errors.name : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Phone No." 
                        name="phone"
                        placeholder="Enter phone"
                        component="input"
                        type="tel"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.phone)? this.state.errors.phone : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Licence ID" 
                        name="license_id"
                        placeholder="Enter licence Id"
                        component="input"
                        type="text"
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.registration_number)? this.state.errors.registration_number : null}</span></Col>
                    </Row>
                    <div className="row form-group">
                        <Col sm={4}>
                            <label className="label">Company Name</label>
                        </Col>
                        <Col sm={8} >
                            {
                                this.props.coporateReducer.companyNameList.length > 0 ?
                                    <AutoComplete
                                        options={this.getdataSource()}
                                        id='companyId'
                                        placeholder="Select company"
                                        onSelect={this.handleSelectCompanyList}
                                        defaultValue={this.getDefaultValue()}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                    :
                                    null
                            }
                        </Col>
                    </div>
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.corporate_company_id)? this.state.errors.corporate_company_id : null}</span></Col>
                    </Row>
                    <FormInput
                        labelName="Pickup Address" 
                        name="driver_pickup_addr"
                        placeholder="Pickup address in text"
                        component="input"
                        
                        type="text"
                    /><br />
                    <div className='row form-group'>
                        <Col sm={4}><label className="label">Pickup Address (Lat/Lng)</label></Col>
                        <Col sm={8}>
                            <Field
                                className="input form-control"
                                autoComplete="on"
                                name='driver_pickup_point'
                                component='input'
                                type='text'
                                placeholder='Pickup address in Lat/Lng'
                                value={'haha'}
                            />
                        </Col>
                    </div>
                    <br />
                    <FormInput
                        labelName="Status" 
                        name="status"                                
                        component="input"
                        checkbox={true}                                   
                    />
                    <Row>
                        <Col sm={4}></Col>
                        <Col sm={8}><span className="error-span">{(this.state.errors && this.state.errors.inactive) ? this.state.errors.inactive : null}</span></Col>
                    </Row>
                    <GoogleMapLatLng  callbackOnClick={(data) => this.setLatLng(data)} point={point} address={address}/>
                </div>
                <div className="action-button">
                    {
                        this.props.editItem != null ? 
                        <Button
                            type='primary'
                            size='large'
                            disabled={this.props.pristine || this.props.submitting}
                            onClick={()=> this.handleUpdate()}
                        >
                            Submit
                        </Button>
                        :
                        <Button 
                            type='primary'
                            size='large'
                            disabled={this.props.pristine || this.props.submitting}
                            onClick={()=> this.handleSubmit()}
                        >
                            Submit
                        </Button>    
                    }   
                                        
                    <Button className="ml-2" size='large' onClick={()=> this.props.callbackHide()}>Cancel</Button> 
                </div>
            </form>                                           
        );    
    }    
}

DriverForm = reduxForm({    
    form: 'driver'
})(DriverForm)

const mapStateToProps = state => ({     
    initialValues      : state.operatorReducer.driver,
    coporateReducer    : state.coporateReducer,
    driver : state.form,
    user : state.user
 });

const mapDispatchToProps = dispatch => {
    return{
        requestCompanyNameList : (user)=>dispatch(requestCompanyNameList(user)), 
        addNewDriver : (user,data)=>dispatch(addNewDriver(user,data)),
        updateDriver : (id,user,data)=>dispatch(updateDriver(id,user,data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DriverForm);