import { view } from 'ramda';
import React from 'react';
import { 
    Row,
    Col,
    Modal,
    Card,
    ModalHeader 
} from 'reactstrap';

const OrderDetails = props => {
    const { viewItem, isOpen, hide } = props;
    return (
        <Modal 
        isOpen={isOpen} 
        toggle={hide}
        size="xl" 
        >
            <ModalHeader>
                { viewItem ? 'Order Details - #ID-'+viewItem.id : 'view details'}
            </ModalHeader>
            <Card className='order-detail'>                    
                {
                    viewItem ?
                        <>
                           <Row>
                                <Col sm="3">Customer Name</Col>
                                <Col sm="9">{viewItem.customer_name}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Customer Phone</Col>
                                <Col sm="9">{viewItem.customer_phone}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Company Name</Col>
                                <Col sm="9">{viewItem.corporate_company_name}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Driver Name</Col>
                                <Col sm="9">{viewItem.cab_name}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Drive Phone</Col>
                                <Col sm="9">{viewItem.cab_phone}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Cab Type</Col>
                                <Col sm="9">{viewItem.requested_cab_types[0].charAt(0).toUpperCase() + viewItem.requested_cab_types[0].slice(1)}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Status</Col>
                                <Col sm="9">{viewItem.status.charAt(0).toUpperCase() + viewItem.status.slice(1)}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Order Mark</Col>
                                <Col sm="9">{viewItem.order_mark.charAt(0).toUpperCase() + viewItem.order_mark.slice(1)}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Order Type</Col>
                                <Col sm="9">{viewItem.is_fixed_fare ? 'Fixed Fare' : 'Metered Fare'}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Payment</Col>
                                <Col sm="9">{viewItem.payment_mode}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Pickup Date</Col>
                                <Col sm="9">{viewItem.requested_pickup_time.split('/')[0]}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Pickup Time</Col>
                                <Col sm="9">{viewItem.requested_pickup_time.split('/')[1]}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Start Location</Col>
                                <Col sm="9">{viewItem.start_location['address']}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">End Location</Col>
                                <Col sm="9">{viewItem.end_location['address']}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">ETA Distance</Col>
                                <Col sm="9">{viewItem.estimated_distance} KM</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Total Time</Col>
                                <Col sm="9">{viewItem.act_str_tot_time}</Col>
                            </Row>
                            <Row>
                                <Col sm="3">Total Fare</Col>
                                <Col sm="9">{viewItem.act_total_fare} MMK</Col>
                            </Row>
                        </>
                        :
                        <div>something worng</div>
                }                                        
            </Card>
        </Modal>
    )
}

export default OrderDetails;
