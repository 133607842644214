import * as actions from '../constants/actionTypes';
export function requestFuelTransaction(user,page,limit){
    return({
        type : actions.REQUEST_FUEL_TRANSACTION,
        user:user,
        page:page,
        limit:limit
    })
}
export function getCoporateCompany(user){
    return ({
        type : actions.REQUEST_FILTER_COMPANY_NAME,
        user : user
    })
}
export function recieveCoporateCompanyName(data){
    return ({
        type : actions.RECEIVE_FILTER_COMPANY_NAME,
        payload : data
    })
}
export function getStationName(user,query){
  
    return ({
        type : actions.REQUEST_FILTER_STATION_NAME,
        user : user,
        query : query
    })
}
export function receiveStationName(data){
    return({
        type : actions.RECEIVE_FILTER_STATION_NAME,
        payload : data
    })
}
export function receiveFuelTransaction(data,page,filtered=false){
    return({
        type : actions.RECEIVE_FUEL_TRANSACTION,
        payload : data,
        page : page,
        filtered : filtered
    })
}
export function receiveFuelTransactionSerchResult(data,page){
    return({
        type : actions.RECEIVE_FUEL_TRANSACTION_SEARCH_RESULT,
        payload : data,
    })
}
export function searchFuelTransaction(user,page,params,limit,query){
    return({
        type : actions.SEARCH_FUEL_TRANSACTION,
        user : user,
        params : params,
        page : page,
        query : query,
        limit : limit
    })
}

export function filterFuelTransaction(user,query,page,limit){
    return({
        type : actions.FILTER_FUEL_TRANSACTION,
        user : user,
        query : query,
        page : page,
        limit : limit
    })
}

export function exportCSV(user,query){
    return({
        type : actions.EXPORT_FUEL_TRANSACTION_CSV,
        user : user,
        query : query
    })
}

export function selectFilter(){
    return ({
        type : actions.SELECT_FILTER,
        payload : false
    })
}


