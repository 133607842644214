import React from 'react';
import update from 'react-addons-update';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Alert
} from 'reactstrap'
const stateModel = {
    name: {
        value: '',
        valid: false
    },
    phone: {
        value: '',
        valid: false
    },
    email: {
        value: '',
        valid: false
    },
    location: {
        value: '',
        valid: false
    },
    errors: [],
    showErr: false,
    isOpenSuccess: false
}
class AddNewModal extends React.Component {    
    constructor(props){
        super(props);       
        this.state= stateModel;
    }
    componentDidMount(){
        if(this.props.editItem != null){
            this.loadEditData()
        }
    }
    loadEditData(){
        this.setState(update(this.state, {
            $set:{
                name:{
                    value: this.props.editItem.name,
                    valid: true
                },
                phone:{
                    value: this.props.editItem.phone,
                    valid: true
                },
                email:{
                    value: this.props.editItem.email,
                    valid: true
                },
                location:{
                    value: this.props.editItem.location,
                    valid: true
                },
                id: this.props.editItem.id
            }
        }))
    }
    handleTextChange(e){
        this.setState(update(this.state,{$set:{
            name: {
                value: e.target.value, 
                valid: !!e.target.value ? true : false 
            },
            showErr: false
        }}))        
    }
    handlePhoneChange(e){
        this.setState(update(this.state,{$set:{
            phone:{
                value: e.target.value,
                valid: !!e.target.value ? true : false
            },
            showErr: false
        }}))
    }
    handleEmailChange(e){
        this.setState(update(this.state,{$set:{
            email:{
                value: e.target.value,
                valid: !!e.target.value ? true : false
            },
            showErr: false
        }}))
    }
    handleTypeLocation(e){
        this.setState(update(this.state,{$set:{
            location:{
                value: e.target.value,
                valid: !!e.target.value ? true : false
            },
            showErr: false
        }}))
    }
    handleSubmit(){        
        if(this.validInput()){
            fetch('https://beta.devoway.com/manage/api/corporate_companies', {
			    method: 'POST',
			    body: JSON.stringify({
                    name: this.state.name.value,
                    phone: this.state.phone.value,
                    email: this.state.email.value,
                    location: this.state.location.value
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
		    }).then(response => {
                if(response.status === 200){                    
                    this.props.callbackFormSuccess()
                }
            }				
			)
        }else{
            this.setState(update(this.state, {$set:{showErr: true}}))
        }
    }
    handleUpdate(id){        
        if(this.validInput()){
            fetch(`https://beta.devoway.com/manage/api/corporate_companies/${this.state.id}?`, {
			    method: 'PUT',
			    body: JSON.stringify({
                    name: this.state.name.value,
                    phone: this.state.phone.value,
                    email: this.state.email.value,
                    location: this.state.location.value
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
		    }).then(response => {
              
                if(response.status === 200){                    
                    this.props.callbackFormSuccess()
                }
            }				
			)
        }else{
            this.setState(update(this.state, {$set:{showErr: true}}))
        }
    }
    validInput = () => !!this.state.name.valid && 
                       !!this.state.phone.valid &&
                       !!this.state.email.valid &&
                       !!this.state.location.valid            
    
    renderErrorMsg(){                  
        return <Alert color="warning">something went wrong : please enter correctly</Alert>       
    }
    renderSuccessMessage(){
        return <Alert color="success">Success</Alert>
    }
    render(){
        return (
            <div>                
                <Modal 
                    isOpen={this.props.isOpen} 
                    toggle={()=> this.props.callbackHide()}                 
                    size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Add New Co-operate company</ModalHeader>
                    {this.state.showErr && this.renderErrorMsg()}
                    {this.state.isOpenSuccess && this.renderSuccessMessage()}
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input 
                                    type="text" 
                                    onChange={(e)=> this.handleTextChange(e)} 
                                    value={this.state.name.value}                                     
                                    placeholder="Enter Company Name" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="tel">Phone No.</Label>
                                <Input 
                                    type="tel" 
                                    onChange={(e)=> this.handlePhoneChange(e)} 
                                    value={this.state.phone.value}  
                                    placeholder="Enter Phone " />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input 
                                    type="email" 
                                    onChange={(e)=> this.handleEmailChange(e)} 
                                    value={this.state.email.value}
                                    placeholder="Enter Email" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="location">Location</Label>
                                <Input 
                                    type="text" 
                                    onChange={(e)=> this.handleTypeLocation(e)} 
                                    value={this.state.location.value}
                                    placeholder="Enter Location" />
                            </FormGroup>                        
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.editItem != null ? 
                                <Button color="primary" onClick={()=> this.handleUpdate()}>Submit</Button>
                                :
                                <Button color="primary" onClick={()=> this.handleSubmit()}>Submit</Button>    
                        }                        
                        <Button color="secondary" onClick={()=> this.props.callbackHide()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );    
    }    
}

export default AddNewModal;