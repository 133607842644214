import React, { Component } from 'react';
import { connect } from 'react-redux'
import {Map, InfoWindow, Marker, GoogleApiWrapper,Polygon} from 'google-maps-react';
import './styles.scss';
import { requestloadMap } from '../../actions/aircabLocationMapAction';
import busyTaxiImg from '../../assests/images/taxi_car_busy.png';
import freeTaxiImg from '../../assests/images/taxi_car_free.png';

export class GoogleMap extends Component  {
  constructor(props) {
    super(props);
    this.state = {
             seconds : 0,
             showingInfoWindow: false,
             activeMarker: {},
             selectedPlace: {},
             aircabMapLocationReducer:{}
    }
    this.tick = this.tick.bind(this);
  }
  tick(){
    this.props.requestloadMap();
    this.setState({
      seconds : this.state.seconds + 30
    });
  }
  componentDidMount(){
    this.props.requestloadMap();
    this.interval = setInterval(()=>this.tick(),30000);
  }

  onMarkerClick = (props, marker, e) =>
  this.setState({
    selectedPlace: props,
    activeMarker: marker,
    showingInfoWindow: true
  });

onMapClicked = (props) => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    })
  }
};

displayMarkers = () => {
  const {aircabMapLocationReducer} = this.props;
  if(aircabMapLocationReducer.cabList.length >0){
  return aircabMapLocationReducer.cabList.map((store, index) => {

    return (
      <Marker key={index} id={index} position={{
        lat: store.current_location.latitude,
        lng: store.current_location.longitude
      }}
      onClick={this.onMarkerClick} 
      name ={ store.name}
      id = {store.id}
      cab_type={store.cab_type}
      status={store.status}
      phone={store.phone}
      plate_number={store.plate_number}
      icon={{
        url: store.availability_status == 1 ? freeTaxiImg : busyTaxiImg,
        scaledSize: new this.props.google.maps.Size(50, 50)
      }}
      />
    )
   
  })
}
}

  render() {
    const mapStyles = {
      width: '100%',
      height: '400px',
      position:'relative'
    };
   
    return (
      <Map
        google={this.props.google}
        zoom={16}
        style={mapStyles}
        className={'map'}
        onClick={this.onMapClicked}
        initialCenter={{ lat:16.902742, lng: 96.135406}} >
            {this.displayMarkers()}
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}>
                <div>
                  <h4>{this.state.selectedPlace.name}</h4>
                    <ul>
                      <li><b>Card ID : </b>{this.state.selectedPlace.id} </li>
                      <li><b>Phone : </b>{this.state.selectedPlace.phone} </li>
                      <li><b>Cab Type : </b>{this.state.selectedPlace.cab_type} </li>
                      <li><b>Status : </b>{this.state.selectedPlace.status} </li>
                      <li><b>Car Plate No : </b>{this.state.selectedPlace.plate_number} </li>
                    </ul>
                </div>
            </InfoWindow>
      </Map>
    );
  }
}

 
const mapStateToProps = state => ({
  router : state.router,
  aircabMapLocationReducer: state.aircabMapLocationReducer  
});

const mapDispatchToProps = dispatch => {
    return {
      requestloadMap :(payload)=> dispatch(requestloadMap(payload))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(GoogleApiWrapper({apiKey: ('AIzaSyBEH6CtSIDi5YsTGfcHebGykudRsi_PJIc')})(GoogleMap));
