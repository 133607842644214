import React, { Component } from 'react';
import update from 'react-addons-update';
import {connect} from 'react-redux';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap'
import { Button } from 'antd'

import ListingTables from '../../components/ListingTables';
import AddNewModal from '../../components/AddNewModal';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import Details from './Details';
import OperatorModal from './OperatorModal';
import {requestCoporateCompanines,searchCorporateCompanies} from '../../actions/formActions';
import { clearAmount, updateAmount } from '../../actions/cabActions';
import { clearUpdateOperator } from '../../actions/operatorActions';
import './styles.scss'
import 'antd/dist/antd.css';
import CabAmountModal from './CabAmountModal';
import {clearAlert} from'../../actions/alertActions';
import {downloadQr} from '../../actions/ferryActions'

class Dashboard extends Component {
    state = {
        companyList: [],
        meta: null,
        isFetching: true,
        isOpenModal: false,
        isShowSuccess: false,
        editItem: null,
        showEntries: 25,
        isOpenForm: false,
        isOpenDetail: false,        
        selectedItem: null,
        isOpenCabAmountForm: false,
        selectedCabAmount: null,
        searchPercentage : 20,
    }    
    componentDidMount() {
        const { coporateReducer, user } = this.props;
        if(coporateReducer.coporateList.length < 1){
            this.props.requestCoporateCompanines(user.user, coporateReducer.page);
        }                
    }
    componentDidUpdate(prevProps,prevState){
        const { coporateReducer, user } = this.props;
        if(prevProps.alerts !== this.props.alerts && this.props.alerts.alerts!==null){
            this.props.requestCoporateCompanines(user.user, coporateReducer.page);
            setTimeout(() => {
                this.props.clearAlert()    
            }, 3000);
        }
        if(prevProps.coporateReducer.isUpdated !== this.props.coporateReducer.isUpdated){
            this.props.requestCoporateCompanines(user.user, coporateReducer.page);
        }
    }
    handleAddNew() {
        this.setState({ isOpenForm: true })
    }
    handleModal() {
        if (this.state.isOpenModal) {
            this.setState({ editItem: null })
        }
        this.setState({ isOpenModal: !this.state.isOpenModal })
    }    
    alertSuccess() {
        this.setState(update(this.state, { $set: { isShowSuccess: true, isOpenModal: false, editItem: null } }))
        setTimeout(() => {
            this.setState({ isShowSuccess: false })
        }, 300);
    }
    isFetchingData = () => this.state.isFetching;
    handleEditItem(item) {
        this.setState(update(this.state, { $set: { editItem: item, isOpenModal: true } }))
    }
    loadByPagy(page) {
        const { user,coporateReducer } = this.props;
        this.props.requestCoporateCompanines(user.user, page, this.state.showEntries);
    }
    handleHideCabAmount(){
        this.props.clearAmount()
        this.setState({ isOpenCabAmountForm: false })
    }
    editCabAmount(item){
        if(item.wallet.hasOwnProperty('balance') === true){
            this.props.updateAmount(item.wallet)
        }
        this.setState({isOpenCabAmountForm: true, selectedCabAmount: item})
    }
    handleHideForm(){
        this.props.clearUpdateOperator();
        this.setState({ isOpenForm: false, selectedItem: null, editItem: null })
    }
    loadBylimit(limit){
        const {user} = this.props;
        this.setState({ showEntries: limit });
        this.props.requestCoporateCompanines(user.user,1,limit);
    }
    search(params){
     const { user } = this.props;   
     this.props.searchCorporateCompanies(user.user,1,params);
    }
    renderTables() {
        const {user} = this.props;
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader>
                            <h4>Corporate Companies</h4>
                            <Button size='large' onClick={() => this.handleAddNew()} >Add Corporate</Button>
                        </CardHeader>
                        <CardBody>
                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadBylimit(value) }
                                searchable = {['name',' phone']}
                                search= {(params)=>this.search(params)}
                                isFetching={this.props.coporateReducer.isFetching}
                                isSearching={this.props.coporateReducer.isSearching}
                            /> 
                            <ListingTables
                                isOperatorTable={true}
                                headers={[
                                    'Company Name',
                                    'Phone',
                                    'Email',
                                    'Cap Amount',
                                    'Total Usage',
                                    'Reset Day',
                                    'Start Date',
                                    'End Date',
                                    'QR Code',
                                    'Status',
                                    'Options'
                                ]}
                                listingData={this.props.coporateReducer.coporateList}
                                callbackEdit={(item) => this.setState({ isOpenForm: true, selectedItem: item })}
                                callbackView={(item) => this.setState({ isOpenDetail: true, selectedItem: item })}
                                callbackAddAmount={(item)=> this.editCabAmount(item)}
                                downloadQr={(payload, ssoToken, name)=> this.props.downloadQr(payload, ssoToken, name)}
                                token={user.user.sso_token}
                            />
                        </CardBody>
                        <CardFooter>
                        {
                            (this.props.coporateReducer.meta) &&
                            <PaginationFooter 
                                listTotal={this.props.coporateReducer.meta.total} 
                                listItem={this.props.coporateReducer.meta.per_page}
                                perPage={this.state.showEntries}
                                callbackPagy={(page) => this.loadByPagy(page)}
                            /> 
                        }
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }
    renderModal() {
        return (
            <AddNewModal
                isOpen={this.state.isOpenModal}
                callbackHide={() => this.handleModal()}
                callbackFormSuccess={() => this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    renderForm() {
        return (
            <OperatorModal
                isOpen={this.state.isOpenForm}
                callbackHide={() => this.handleHideForm()}
                callbackFormSuccess={() => this.alertSuccess()}
                editItem={this.state.selectedItem}
            />
        )
    }
    renderDetails() {
        return (
            <Details
                isOpen={this.state.isOpenDetail}
                hide={() => this.setState({ isOpenDetail: false })}
                selectedItem={this.state.selectedItem}
            />
        )
    }
    renderCabAmountForm() {
        return (
            <CabAmountModal 
                isOpen={this.state.isOpenCabAmountForm}
                callbackHide={()=> this.handleHideCabAmount()}
                selectedCabAmountItem={this.state.selectedCabAmount}
            />
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenCabAmountForm && this.renderCabAmountForm()}
                {this.state.isOpenDetail && this.renderDetails()}                  
            </div>
        );
    }
}
const mapStateToProps = state=>({
    coporateReducer : state.coporateReducer,
    user : state.user,
    alerts : state.alerts
});

const  mapDispatchToProps = dispatch=>{
     return {
         requestCoporateCompanines : (user, page=0,limit=25)=>dispatch(requestCoporateCompanines(user, page,limit)),
         searchCorporateCompanies : (user,page=1,params,limit=25) => dispatch(searchCorporateCompanies(user,page,params,limit)),
         clearAmount : ()=> dispatch(clearAmount()),
         updateAmount : (item)=> dispatch(updateAmount(item)),
         clearUpdateOperator : ()=> dispatch(clearUpdateOperator()),
         clearAlert : ()=>dispatch(clearAlert()),
         downloadQr : (payload, ssoToken, name)=> dispatch(downloadQr(payload, ssoToken, name))
     }
};
export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);