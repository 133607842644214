import React from 'react'
import Loader from "react-loader-spinner";
import './styles.scss'

export default class App extends React.Component {
  //other logic
  render() {
    return (
      <Loader
        type="BallTriangle"
        color="#00BFFF"
        height={40}
        width={50}
        timeout={5000}
      />
    );
  }
}