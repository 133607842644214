import * as actions from '../constants/actionTypes';
import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { pushAlert } from '../actions/alertActions';
import { API_URL } from '../constants/credentials';
import { pushError} from '../actions/errorActions';
import {receiveFuelStation,receiveFuelStationPartner,receiveFuelStationSearchResult} from '../actions/fuelStationAction';
import {logoutUser} from "../actions/userActions";

export function loadStationData(action$){
   return action$.pipe(
    ofType(actions.REQUEST_FUEL_STATION),
    switchMap((payload)=>{
    return ajax.getJSON(`${API_URL}/manage/api/fuel_stations?per_page=${payload.limit}&page=${payload.page}`,
   
           {
            'Authorization': payload.user.sso_token
            })
            .pipe(
                map((response)=> receiveFuelStation(response,payload.page)),
                catchError(error=>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                  })
            )
       })
   ) 
}

export function requestFuelStationPartners(action$){
  return action$.pipe(
     ofType(actions.REQUEST_FUEL_STATION_PARTNER),
     switchMap((payload)=>{
     return ajax.getJSON(`${API_URL}/manage/api/fuel_partners/lists`,{
           'Authorization': payload.user.sso_token
        })
        .pipe(
           map((response)=> receiveFuelStationPartner(response)),
           catchError(error=>{
               if (error.status === 401) {
                   const errorData = {
                       message: "session expired: please try again"
                   }
                   return of(
                       pushError(errorData),
                       logoutUser()
                   )
               } else {
                   if(error.response){
                       return of(pushError(error.response.errors))
                   }else{
                       return of(pushError(error))
                   }
               }
          })
        )
            
     })
  )
}

export function addNewFuelStation(action$){
   return action$.pipe(
      ofType(actions.ADD_NEW_STATION),
      switchMap((payload)=>{
         return ajax.post(`${API_URL}/manage/api/fuel_stations`,payload.payload,
         {
            'Content-Type': 'application/json',  
            'Authorization': payload.user.sso_token
         })
         .pipe(
            map((response)=>  pushAlert(response.response)),
            catchError(error=>{
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )
                } else {
                    if(error.response){
                        return of(pushError(error.response.errors))
                    }else{
                        return of(pushError(error))
                    }
                }
            })
         )
      })
   )
}

export function searchFuelStation(action$){
   return action$.pipe(
    ofType(actions.SEARCH_FUEL_STATION),
    switchMap((payload)=>{
    return ajax.getJSON(`${API_URL}/manage/api/fuel_stations?search=${payload.params}&page=${payload.page}`,
   
           {
            'Authorization': payload.user.sso_token
            })
            .pipe(
                map((response)=> receiveFuelStationSearchResult(response,payload.page)),
                catchError(error=>{
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )
                    } else {
                        if(error.response){
                            return of(pushError(error.response.errors))
                        }else{
                            return of(pushError(error))
                        }
                    }
                  })
            )
       })
   ) 
}


export function updateFuelStation(action$){
   return action$.pipe(
      ofType(actions.UPDATE_FUEL_STATION),
      switchMap((payload)=>{
         return ajax.put(`${API_URL}/manage/api/fuel_stations/${payload.id}`,payload.payload,
         {
            'Content-Type': 'application/json',  
            'Authorization': payload.user.sso_token
         })
         .pipe(
            map((response)=> pushAlert(response.response,'fuel_station')),
            catchError(error=>{
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )
                } else {
                    if(error.response){
                        return of(pushError(error.response.errors))
                    }else{
                        return of(pushError(error))
                    }
                }
            })
         )
         
      })
   )
}