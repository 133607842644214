import React, { Component } from 'react';
import {connect} from 'react-redux';
import history from '../../../utils/history';
import {logoutUser, updateUser} from '../../../actions/userActions';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

class Header extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
    }
    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
    }
    handleLogout(){
        this.props.updateUser(this.props.user.user.id);
        this.props.logoutUser();
    }
    hanldeClickProfile(){
        history.push('/profile');
    }
    render() {
        let {user} = this.props;
        if(user.user!=null){
            return (
                <div>
                    <Navbar color="light" light expand="md">
                    <NavbarBrand href="/">Oway Corporate</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                                         
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>

                                    {(this.props.user)? this.props.user.user.name : "Default User"}

                                </DropdownToggle>
                                    <DropdownMenu right>
                                <DropdownItem onClick={()=>{this.hanldeClickProfile()}}>
                                    Profile
                                </DropdownItem>
                                {/*<DropdownItem>
                                    Setting
                                </DropdownItem>*/}
                                <DropdownItem onClick={()=>{this.handleLogout()}}>
                                    Logout
                                </DropdownItem>                        
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                    </Navbar>    
                </div>
            );
        }
        else{
           
        return <div>{  history.push('/sign_in')}</div>
        }

    }
}
const mapStateToProps = state=>({
    user:state.user
});
const  mapDispatchToProps = dispatch=>{
    return {
        logoutUser : ()=>dispatch(logoutUser()),
        updateUser : (id) => dispatch(updateUser(id))
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(Header);