import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { loadManager } from '../../actions/managerAction';
import ManagerForm from "./ManagerForm";

class ManagerModel extends Component {
    componentDidMount() {
        if (this.props.editItem != null) {
            this.props.loadManager(this.props.editItem)
        } else {
            this.props.loadManager(null)
        }
    }
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.callbackHide()}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.props.callbackHide()}>
                        {
                            (this.props.editItem != null) ?
                                `MANAGER #${this.props.editItem.id} : ${this.props.editItem.name}`
                                :
                                'New Company Manager'
                        }
                    </ModalHeader>
                    <ModalBody>
                        <ManagerForm
                            editItem={this.props.editItem}
                            callbackHide={this.props.callbackHide}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadManager: (data) => dispatch(loadManager(data))
    }
}

export default connect(null, mapDispatchToProps)(ManagerModel);