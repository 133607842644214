import {switchMap, map, catchError} from 'rxjs/operators';
import {  ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import { receiveCorporateOrders, receiveSearchCorporateOrders } from '../actions/corporateOrderActions';
import {logoutUser} from "../actions/userActions";

export function loadCorporateOrders(action$){
    return action$.pipe(
        ofType(actions.REQUEST_CORPORATE_ORDERS),
        switchMap((payload)=>{
           return ajax.getJSON(`${API_URL}/manage/api/corporate_companies/corporate_orders?per_page=${payload.limit}&page=${payload.page}&corporate_company_id=${payload.meta.corporate_company_id}&start=${payload.meta.startDate}&end=${payload.meta.endDate}`,{
               'Authorization': payload.user.sso_token
            }
            )
            .pipe(
                map((response)=> receiveCorporateOrders(response,payload.page)),
                catchError(error=>{
                    if(error.response){
                      return of(pushError(error.response.errors))
                    }else{
                      return of(pushError(error))
                    } 
                  })
            )
        })
    )
}

export function searchCorporateOrdersData(action$){
  return action$.pipe(
     ofType(actions.SEARCH_CORPORATE_ORDERS),
      switchMap((payload)=> {
    let url;
    if (payload.metadata != null) {
        url = `${API_URL}/manage/api/corporate_companies/corporate_orders?search=${payload.params}&corporate_company_id=${payload.metadata.corporate_company_id}&start=${payload.metadata.startDate}&end=${payload.metadata.endDate}&page=${payload.page}`
    } else {
        url = `${API_URL}/manage/api/corporate_companies/corporate_orders?search=${payload.params}&page=${payload.page}`
    }

    return ajax.getJSON(url, {
        'Authorization': payload.user.sso_token
    }).pipe(
        map((response) => {
            return receiveSearchCorporateOrders(response, payload.page)
        }),
        catchError(error => {
            if (error.status === 401) {
                const errorData = {
                    message: "session expired: please try again"
                }
                return of(
                    pushError(errorData),
                    logoutUser()
                )} else {
                if (error.response) {
                    return of(pushError(error.response.errors))
                } else {
                    return of(pushError(error))
                }
            }
        })
    )})
  )
}  
