import * as actions from '../constants/actionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { pushAlert } from '../actions/alertActions';
import { API_URL } from '../constants/credentials';
import { pushError } from '../actions/errorActions';
import { receiveFuelPartner,receiveFuelPartnerSearchResult } from '../actions/fuelPartnerActions';
import {logoutUser} from "../actions/userActions";

export function loadFuelPartnerData(action$) {
    return action$.pipe(
        ofType(actions.REQUEST_FUEL_PARTNER),
        switchMap((payload) => {
            return ajax.getJSON(`${API_URL}/manage/api/fuel_partners?per_page=${payload.limit}&page=${payload.page}`,
                {
                    'Authorization': payload.user.sso_token
                })
                .pipe(
                    map((response) => receiveFuelPartner(response, payload.page)),
                    catchError(error => {
                        if (error.status === 401) {
                            const errorData = {
                                message: "session expired: please try again"
                            }
                            return of(
                                pushError(errorData),
                                logoutUser()
                            )
                        } else {
                            if(error.response){
                                return of(pushError(error.response.errors))
                            }else{
                                return of(pushError(error))
                            }
                        }
                    })
                )
        })
    )

}
export function addNewFuelPartner(action$) {
    return action$.pipe(
        ofType(actions.ADD_NEW_FUEL_PARTNER),
        switchMap((payload) => {
            return ajax.post(`${API_URL}/manage/api/fuel_partners/fuel_partner_manager_setup`, payload.payload,
                {
                    'Content-Type': 'application/json',
                    'Authorization': payload.user.sso_token
                })
                .pipe(
                    map((response) => {
                        const data = {
                            notice: "New Fuel Partner is successfully Added"
                        }
                        return pushAlert(data);
                    }),
                    catchError(error => {
                        if (error.status === 401) {
                            const errorData = {
                                message: "session expired: please try again"
                            }
                            return of(
                                pushError(errorData),
                                logoutUser()
                            )
                        } else {
                            if(error.response){
                                return of(pushError(error.response.errors))
                            }else{
                                return of(pushError(error))
                            }
                        }
                    })
                )
        })
    )
}


export function updateFuelPartner(action$) {
    return action$.pipe(
        ofType(actions.UPDATE_FUEL_PARTNER),
        switchMap((payload) => {
            return ajax.put(`${API_URL}/manage/api/fuel_partners/fuel_partner_manager_edit/${payload.id}`, payload.payload,
                {
                    'Content-Type': 'application/json',
                    'Authorization': payload.user.sso_token
                })
                .pipe(
                    map((response) => {
                        const data = {
                            notice: `Fuel Partner #${payload.id} is successfully Updated`,
                        }
                        return pushAlert(data,"fuel_partner");
                    }),
                    catchError(error => {
                        if (error.status === 401) {
                            const errorData = {
                                message: "session expired: please try again"
                            }
                            return of(
                                pushError(errorData),
                                logoutUser()
                            )
                        } else {
                            if(error.response){
                                return of(pushError(error.response.errors))
                            }else{
                                return of(pushError(error))
                            }
                        }
                    })
                )

        })
    )
}
export function searchFuelPartner(action$){
    return action$.pipe(
     ofType(actions.SEARCH_FUEL_PARTNER),
     switchMap((payload)=>{
     return ajax.getJSON(`${API_URL}/manage/api/fuel_partners?per_page=${payload.limit}&search=${payload.params}&page=${payload.page}`,
         {
                'Authorization': payload.user.sso_token
         }).pipe(
             map((response)=> receiveFuelPartnerSearchResult(response,payload.page)), catchError(error=>{
                 if (error.status === 401) {
                     const errorData = {
                         message: "session expired: please try again"
                     }
                     return of(
                         pushError(errorData),
                         logoutUser()
                     )
                 } else {
                     if(error.response){
                         return of(pushError(error.response.errors))
                     }else{
                         return of(pushError(error))
                     }
                 }
             })
     )})
    ) 
}
