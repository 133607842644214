import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import { pushAlert } from '../actions/alertActions';
import {receiveCustomerDataResult,receiveSearchCustomerData, receiveCustomerAttendance} from '../actions/customerAction';
import {logoutUser} from "../actions/userActions";

/*export function loadCustomerDatas(action$){
    return action$.pipe(
      ofType(actions.REQUEST_CUSTOMER_DATA),
      switchMap((payload)=>
       ajax.getJSON(`${API_URL}/manage/api/corporate_customers?per_page=${payload.limit}&page=${payload.page}`,{
        'Authorization': payload.user.sso_token
       }).pipe(
           map((response)=>{ return receiveCustomerDataResult(response, payload.page)}),
           catchError(error=>{
              if(error.response){
                  return of(pushError(error.response.errors))
              }else{
                  return of(pushError(error))
              }
           })
       ))
    )
}*/


export function loadCustomerData(action$){
    return action$.pipe(
        ofType(actions.REQUEST_CUSTOMER_DATA),
        switchMap((payload)=> {
            let url;
            if (payload.meta != null) {
                url = `${API_URL}/manage/api/corporate_customers?start=${payload.meta.startDate}&end=${payload.meta.endDate}&corporate_company_id=${payload.meta.corporate_company_id}&page=${payload.page}&per_page=${payload.limit}`
            } else {
                url = `${API_URL}/manage/api/corporate_customers?page=${payload.page}&per_page=${payload.limit}`
            }

            return ajax.getJSON(url, {
                'Authorization': payload.user.sso_token
            }).pipe(
                map((response) => {
                    return receiveCustomerDataResult(response, payload.page)
                }),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )} else {
                        if (error.response) {
                            return of(pushError(error.response.errors))
                        } else {
                            return of(pushError(error))
                        }
                    }
                })
            )})
    )
}

export function searchCustomerAttendance(action$){
    return action$.pipe(
        ofType(actions.SEARCH_CUSTOMER_ATTENDANCE),
        switchMap((payload)=> {
            let url;
            if (payload.meta != null) {
                url = `${API_URL}/manage/api/corporate_customers?start=${payload.meta.startDate}&end=${payload.meta.endDate}&corporate_company_id=${payload.meta.corporate_company_id}&corporate_cab_id=${payload.meta.corporate_cab_id}&page=${payload.page}`
            } else {
                url = `${API_URL}/manage/api/corporate_customers?page=${payload.page}`
            }

            return ajax.getJSON(url, {
                'Authorization': payload.user.sso_token
            }).pipe(
                map((response) => {
                    return receiveCustomerAttendance(response, payload.page)
                }),
                catchError(error => {
                    if (error.status === 401) {
                        const errorData = {
                            message: "session expired: please try again"
                        }
                        return of(
                            pushError(errorData),
                            logoutUser()
                        )} else {
                        if (error.response) {
                            return of(pushError(error.response.errors))
                        } else {
                            return of(pushError(error))
                        }
                    }
                })
            )})
    )
}


export function searchCustomerData(action$){
      return action$.pipe(
         ofType(actions.SEARCH_CUSTOMER_DATA),
          switchMap((payload)=> {
        let url;
        if (payload.metadata != null) {
            url = `${API_URL}/manage/api/corporate_customers?search=${payload.params}&start=${payload.metadata.startDate}&end=${payload.metadata.endDate}&corporate_company_id=${payload.metadata.corporate_company_id}&page=${payload.page}`
        } else {
            url = `${API_URL}/manage/api/corporate_customers?search=${payload.params}&page=${payload.page}`
        }

        return ajax.getJSON(url, {
            'Authorization': payload.user.sso_token
        }).pipe(
            map((response) => {
                return receiveSearchCustomerData(response, payload.page)
            }),
            catchError(error => {
                if (error.status === 401) {
                    const errorData = {
                        message: "session expired: please try again"
                    }
                    return of(
                        pushError(errorData),
                        logoutUser()
                    )} else {
                    if (error.response) {
                        return of(pushError(error.response.errors))
                    } else {
                        return of(pushError(error))
                    }
                }
            })
        )})
      )
}  

export function addNewCustomer(action$){
    return action$.pipe(
      ofType(actions.ADD_NEW_CUSTOMER),
      switchMap((payload)=>{      
        return ajax.post(`${API_URL}/manage/api/corporate_customers/new_or_update?language=en`,payload.payload,{
          'Content-Type': 'application/json', 
          'Authorization': payload.user.user.sso_token
        })
           .pipe(
             map((response)=>{return pushAlert(response.response, 'customer')}),
             catchError(error=>{
                 if (error.status === 401) {
                     const errorData = {
                         message: "session expired: please try again"
                     }
                     return of(
                         pushError(errorData),
                         logoutUser()
                     )
                 } else {
                     if(error.response){
                         return of(pushError(error.response.errors))
                     }else{
                         return of(pushError(error))
                     }
                 }
             })
           )
        }     
      )
    )
  }  
export function updateCustomer(action$){
    return action$.pipe(
      ofType(actions.UPDATE_CUSTOMER),
      switchMap((payload)=>{
       return ajax.put(`${API_URL}/manage/api/corporate_customers/${payload.id}?language=en`,payload.payload,
       {
        'Content-Type': 'application/json',  
        'Authorization': payload.user.user.sso_token
      })
      .pipe(
              map((response)=>{return pushAlert(response.response,'customer')}),
              catchError(error=>{
                  if (error.status === 401) {
                      const errorData = {
                          message: "session expired: please try again"
                      }
                      return of(
                          pushError(errorData),
                          logoutUser()
                      )
                  } else {
                      if(error.response){
                          return of(pushError(error.response.errors))
                      }else{
                          return of(pushError(error))
                      }
                  }
              })
            )
      })
    )
  }

  export function changeToNormalCustomer(action$){
    
    return action$.pipe(
      ofType(actions.CHANGE_TO_NORMAL_CUSTOMER),
      switchMap((payload)=>{
      
        return ajax.post(`${API_URL}/manage/api/corporate_managers/change_to_customer`,payload.id,{
          'Content-Type': 'application/json',  
          'Authorization': payload.user.sso_token
        })
        .pipe(
          map((response)=>{return pushAlert(response.response,'customer')}),
          catchError(error=>{
              if (error.status === 401) {
                  const errorData = {
                      message: "session expired: please try again"
                  }
                  return of(
                      pushError(errorData),
                      logoutUser()
                  )
              } else {
                  if(error.response){
                      return of(pushError(error.response.errors))
                  }else{
                      return of(pushError(error))
                  }
              }
          })
        )
      })
    )
  }
