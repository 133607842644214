import * as actions from '../constants/actionTypes';
export function requestCustomerData(user,page,limit, metadata){
    return({
        type : actions.REQUEST_CUSTOMER_DATA,
        user : user,
        page : page,
        limit:limit,
        meta: metadata
    })
}
export function receiveCustomerDataResult(data, page){
    return ({
        type : actions.RECIEVE_CUSTOMER_DATA_RESULT,
        payload: data,
        page: page
    })
}

export function searchCustomerData(user,page,params, metadata){
    return ({
        type : actions.SEARCH_CUSTOMER_DATA,
        user : user,
        page : page,
        params : params,
        metadata: metadata
    })
}

export function searchCustomerAttendance(user, metadata) {
    return ({
        type : actions.SEARCH_CUSTOMER_ATTENDANCE,
        user : user,
        page : 1,
        limit : 25,
        meta : metadata
    })
}

export function receiveCustomerAttendance(data, page) {
    return ({
        type : actions.RECEIVE_CUSTOMER_ATTENDANCE,
        payload: data,
        page: page
    })
}

export function receiveSearchCustomerData(data,page){
    return ({
        type : actions.RECEIVE_SEARCH_CUSTOMER_DATA,
        payload : data,
        page : page
    })
}
export function addNewCustomer(user,data){ 
    return ({
        type : actions.ADD_NEW_CUSTOMER,
        user : user,
        payload : data
    })
}

export function pushSuccessAddingCustomer(data){    
    return ({
        type : actions.SUCCESS_ADDING_CUSTOMER,
        payload : data
    })
}

export function updateCustomer(id,user,data){    
    return({
        type : actions.UPDATE_CUSTOMER,
        id: id,
        user : user,
        payload:data
    })
}
export function pushSuccessUpdatingCustomer(data){
    return({
        type : actions.SUCCESS_UPDATING_CUSTOMER,
        payload :data
    })
}

export function changeToNormalCustomer(user,id){
    return ({
        type : actions.CHANGE_TO_NORMAL_CUSTOMER,
        id : id,
        user : user
    })
}