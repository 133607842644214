import React from 'react';
import { Badge } from 'reactstrap';
import { 
    ListGroup, 
    ListGroupItem,
    Modal,
    Card,
    ModalHeader
} from 'reactstrap';

const ManagerDetails = ({viewItem, isOpen, hide}) => (         
    <Modal 
        isOpen={isOpen} 
        toggle={hide}
        size="xl" 
    >
        <ModalHeader>
            { viewItem ? 'ID#' + viewItem.id + ' - ' + viewItem.name : 'view details'}
        </ModalHeader>
        <Card>                    
            {
                viewItem ?
                    <ListGroup>      
                        <ListGroupItem href="#" action><span>Name</span><span>{viewItem.name}</span></ListGroupItem>
                        <ListGroupItem href="#" action><span>Username</span><span>{viewItem.username}</span></ListGroupItem>
                        <ListGroupItem href="#" action><span>Phone</span><span>{viewItem.phone}</span></ListGroupItem>
                        <ListGroupItem href="#" action><span>Email</span><span>{viewItem.email}</span></ListGroupItem>
                        <ListGroupItem href="#" action><span>Company Name</span><span>{viewItem.company_name}</span></ListGroupItem>
                        <ListGroupItem href="#" action>
                            <span>Status</span>
                            <span>
                                {
                                    viewItem.inactive === 1 ? 
                                    <Badge color="danger" className="badge-padding">Inactive</Badge>
                                        :
                                    <Badge color="success" className="badge-padding">Active</Badge>
                                }
                            </span>
                        </ListGroupItem>    
                    </ListGroup>
                    :
                    <div>something worng</div>
            }                                        
        </Card>
    </Modal>
);
export default ManagerDetails;