import * as actions from '../constants/actionTypes';

const INITIAL_STATE={
    cabsList :[],
    ferryRoute : [],
    isFetching : false,
    isFerryRouteUpdating : false,
    meta:null,
    isSearching : false,
    page: 1,
    limit : 25
}

function cabs(state=INITIAL_STATE,action){
    switch(action.type){
        case actions.LOAD_CABS_DATA : 
            return Object.assign({}, state, {
                    isFetching: true,
                    limit : action.limit
                    });
        case actions.RECEIVE_CABS_DATA : 
            return Object.assign({},state,{
                    cabsList :(action.payload)?action.payload.data:[],
                    isFetching : false,
                    meta : (action.payload)?action.payload.meta:null,
                    page: action.page
                    });    
        case actions.SEARCH_CAB_DATA : 
            return Object.assign({},state,{
                     isSearching:true
                    }); 
        case actions.RECEIVE_SEARCH_CAB_DATA :
            return Object.assign({},state,{
                    cabsList :(action.payload)?action.payload.data:[],
                    isSearching : false,
                    meta : (action.payload)?action.payload.meta:null,
                    page: action.page
                  });
        case actions.UPDATE_FERRY_ROUTE :
            return Object.assign({}, state, {
                ferryRoute: action.payload
            })

        case actions.ASSIGN_CAB_TO_DRIVER :
            return Object.assign({}, state, {
                ferryRoute: state.ferryRoute,
                isFerryRouteUpdating : true
            })

        case actions.RECEIVE_ASSIGN_CAB_TO_DRIVER_RESPONSE :
            return Object.assign({}, state, {
                ferryRoute: state.ferryRoute,
                isFerryRouteUpdating : false
            })



        case actions.REMOVE_DRAGGABLE_FERRY_ROUTE :
            return Object.assign({}, state, {
                ferryRoute: []
            })
        default : return state;
    }
}
export default cabs;