import React, { Component } from 'react';
import { connect } from 'react-redux'
import { requestCabStatus,filterCabStatus } from '../../actions/aircabLocationMapAction';
import CardView from '../../components/CardView';
import PaginationFooter from '../../components/PaginationFooter';
import ListingTable from '../../components/ListingTables';
import TableCaptions from '../../components/TableCaptions';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter

} from 'reactstrap'
import GoogleMap from '../../components/GoogleMap';
import './style.scss';

class AircabLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aircabMapLocationReducer:{},
            isFetching: false,
        }
       
      }

    componentDidMount(){
        this.props.requestCabStatus();
    }

    loadByLimit(limit) {
        const { user, aircabMapLocationReducer } = this.props;
        this.setState({ showEntries: limit });
        this.props.requestCabStatus(user.user, 1, limit);
    }

    loadByPage(page) {
        const { user, aircabMapLocationReducer } = this.props;
        if (this.isEmpty(this.state.query))
            this.props.requestCabStatus(user.user, page, aircabMapLocationReducer.limit);
        else
            this.props.filterCabStatus(user.user, this.state.query, page);
    }

    loadNextPage() {
        const { user, aircabMapLocationReducer } = this.props;
        if (this.isEmpty(this.state.query))
            this.props.requestCabStatus(user.user, aircabMapLocationReducer.page + 1, aircabMapLocationReducer.limit);
        else
            this.props.filterCabStatus(user.user, this.state.query, aircabMapLocationReducer.page + 1, aircabMapLocationReducer.limit);
    }

    loadBackPage() {
        const { user, aircabMapLocationReducer } = this.props;
        if (this.isEmpty(this.state.query))
            this.props.requestCabStatus(user.user, aircabMapLocationReducer.page - 1, aircabMapLocationReducer.limit);
        else
            this.props.filterCabStatus(user.user, this.state.query, aircabMapLocationReducer.page - 1, aircabMapLocationReducer.limit);
    }

    renderMapCabList(){
        const {aircabMapLocationReducer} = this.props;
        const aircarList = this.props.aircabMapLocationReducer;
        return (
        <Row>
            <Col md="12" sm="12" xs="12">
                <Card>
                    <CardHeader className='page-header'>
                        <h4>AirCab Taxi Dashboard</h4>
                    </CardHeader>
                    <CardBody className="cab-location-body">
                        <Row className="cab-status-warp">
                            <CardView 
                                class={'cab-board-blue'}
                                header={'Total'}
                                count={aircabMapLocationReducer.cabStatus.total}
                            />
                            <CardView 
                                class={'cab-board-green'}
                                header={'Available'}
                                count={aircabMapLocationReducer.cabStatus.free}
                            />
                            <CardView 
                                class={'cab-board-red'}
                                header={'Busy'}
                                count={aircabMapLocationReducer.cabStatus.busy}
                            />
                                <CardView 
                                class={'cab-board-orange'}
                                header={'Offline'}
                                count={aircabMapLocationReducer.cabStatus.off_line}
                            />
                        </Row>
                        <Row className="map-wrap">
                            <Col sm="12">
                                <GoogleMap />      
                            </Col>
                            <Col sm="12">
                                <div className="aircab-order">
                                    <TableCaptions
                                            selectedValue={this.state.showEntries}
                                            callbackSelectFun={(value) => this.loadByLimit(value)}
                                            searchable={['Car Plate No']}
                                            AirCab Taxi Available List search={(params) => this.search(params)}
                                    />
                                </div>
                                    {
                                        (this.props.aircabMapLocationReducer.isSearching) ?
                                            <div>Searching......</div>
                                            :
                                            <div></div>
                                    }
                                <ListingTable
                                    isAircabListTable={true}
                                    headers={[
                                        
                                        'ID',
                                        'Status',
                                        'Name',
                                        'Phone',
                                        'Cab Type',
                                        'Last Update',
                                        'Car Plate No',
                                        'License Id',
                                        
                                    ]}
                                    listingData={aircarList.cabList}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {
                            (this.props.aircabMapLocationReducer.meta) ?
                                <PaginationFooter listTotal={this.props.aircabMapLocationReducer.meta.total}
                                    listItem={this.props.aircabMapLocationReducer.meta.per_page}

                                    current={this.props.aircabMapLocationReducer.page}
                                    callbackParent={(page) => this.loadByPage(page)}
                                    callbackNext={(page) => this.loadNextPage(page)}
                                    callbackBack={(page) => this.loadBackPage(page)} /> :
                                <PaginationFooter />
                        }

                    </CardFooter>
                </Card>
            </Col>
        </Row>

        )
    }

    render() {
        return (   
            this.renderMapCabList()                 
        );
    }
}

const mapStateToProps = state => ({
    router : state.router,
    aircabMapLocationReducer: state.aircabMapLocationReducer  
   
  });

const mapDispatchToProps = dispatch => {
      return {
        requestCabStatus :(payload, page, limit)=> dispatch(requestCabStatus(payload, page, limit))
      }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(AircabLocation);
  


