export const months = [
    {
      id:1,
      name:"1"  
    },
    {
      id:2,
      name:"2"
    },
    {
      id:3,
      name:"3"  
    },
    {
      id:4,
      name:"4"
    },
    {
      id:5,
      name:"5"
    },
    {
     id:6,
     name:"6"
    },
    {
     id:7,
     name:"7"   
    },
    {
     id:8,
     name:"8"
    },
    {
     id:9,
     name:"9"
    },
    {
     id:10,
     name:"10"
    },
    {
     id:11,
     name:"11"
    },
    {
     id:12,
     name:"12"   
    },
    {
      id:13,
      name:"13"  
    },
    {
      id:14,
      name:"14"
    },
    {
      id:15,
      name:"15"  
    },
    {
      id:16,
      name:"16"
    },
    {
      id:17,
      name:"17"
    },
    {
     id:18,
     name:"18"
    },
    {
     id:19,
     name:"19"   
    },
    {
     id:20,
     name:"20"
    },
    {
     id:21,
     name:"21"
    },
    {
     id:22,
     name:"22"
    },
    {
     id:23,
     name:"23"
    },
    {
     id:24,
     name:"24"   
    },
    {
      id:25,
      name:"25"  
    },
    {
      id:26,
      name:"26"
    },
    {
      id:27,
      name:"27"  
    },
    {
      id:28,
      name:"28"
    },
    {
      id:29,
      name:"29"
    },
    {
     id:30,
     name:"30"
    },
    {
     id:31,
     name:"31"   
    }
];
export const errorMessage = {
  name: "Fuel Station Name is required",
  // fuel_partner_name: "Fuel Partner Name is required",
  station_code: "Fuel Station Code is required",
  phone: "Phone Number is required" ,
  status: "Fuel Station Status is required",
  address: "Fuel Station Address is required",
  location: "Fuel Station Lat Lng is required",
  octane_92: "Octane 92 Price is required",
  octane_95: "Octane 95 Price is required",
  diesel: "Diesel Price is required",
  premium_diesel: "Premium Diesel Price is required",
  username: "Username is required",
  password: "Password is required",
  city: "City Name is required"
}

export const fule_partner_error = {
  name : "Fuel Partner Name is required",
  station_code : "Fuel Partner Code is required",
  phone : "Fuel Partner's Phone Number is required",
  email : "Fuel Partner's Email is required",
  address : "Fuel Partner's address is required",
  status : "Fuel Partner's status is required",
  manager_name : "Fuel Manager Name is required",
  manager_email : "Fuel Manager's Email is required",
  manager_phone : "Fuel Manager's Phone Number is required",
  manager_password : "Fuel Manager's Password is required",
}

export const corporate_error = {
  name : "Please enter name",
  email : "Please enter email",
  phone : "Please enter phone",
  location : "Please enter location",
  start_date : "Please select start date",
  end_date : "Please select end date",
  manager_name : "Manager name is required",
  manager_email : "Manager email is required",
  manager_phone : "Manager phone is required",
  username : "Login username is required",
  manager_password : "Login password is required"
}

export const cab_driver = {
    id : "cab id is required",
    corporate_driver_id : "driver id is required",
    start_route : "ferry route endpoint is required",
    end_route : "ferry route endpoint is required",
}

export const stores =  [
  {
    float_location: {
      latitude: 16.782423,
      longitude: 96.145166
    },
    name:'Mg Mg',
    description:'title 1' ,
    phone: "+959420256008",
    cab_type: "taxi",
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text: 1,
   },
   {
    float_location: {
      "latitude": 16.782434,
      "longitude": 96.142613
    },
    name:'mhtoo',
    description:'title 1' ,
    phone: "+959420256008",
    cab_type: "taxi",
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text: 0,
   },

  {
    float_location: {
      latitude: 16.783958,
      longitude: 96.145219
    },
    name:'Aung Aung',
    description:'title 2',
    phone: "+959420256008",
    cab_type: "Airport taxi",
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text: 1,
  },

  {
    float_location: {
      latitude: 16.784596,
      longitude: 96.143144
    },
    name:'Aung Aung1',
    description:'title 2',
    phone: "+959420256008",
    cab_type: "Airport taxi",
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text:0,
  },

  {
    float_location: {
      latitude: 16.780952,
      longitude: 96.144612
    },
    name:'Aung Aung2',
    description:'title 2',
    phone: "+959420256008",
    cab_type: "Airport taxi",
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text: 1,
  },
    
  {
    float_location: {
      latitude: 16.783157,
      longitude: 96.147257,
    },
    name :'kyaw kyaw2',
    description:'title 3',
    phone: "+959420256008",
    cab_type: "Bago taxi", 
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text: 0,
  },

  {
    float_location: {
      latitude: 16.781630,
      longitude: 96.146608,
    },
    name :'kyaw kyaw',
    description:'title 3',
    phone: "+959420256008",
    cab_type: "Bago taxi", 
    city: "Yangon",
    country: "Myanmar (Burma)",
    availability_text: 1,
  },
 ]