import * as actions from '../constants/actionTypes';
export function requestAircabOrder(user,page,limit,points){
  return ({
      type : actions.REQUEST_AIRCAB_ORDER,
      page : page,
      user :user,
      limit : limit,
    
  })
}
export function filterAircabOrders(user,query,page,limit){
    return ({
        type : actions.FILTER_AIRCAB_ORDER,
        page : page,
        user : user,
        query : query,
        limit : limit
    })
}

export function exportCSV(user,query,params,limit,page){
    return({
        type : actions.EXPORT_AIRCAB_ORDER_CSV,
        user : user,
        query : query,
        params: params,
        limit : limit,
        page :page
    })
}
export function searchAircabOrder(user,page,params,limit,query){
    return({
        type : actions.SEARCH_AIRCAB_ORDER,
        user : user,
        params : params,
        page : page,
        query : query,
        limit : limit
    })
}

export function receiveAircabOrderSearchResult(data,page){
    return ({
        type : actions.RECEIVE_AIRCAB_ORDER_SEARCH_RESULT,
        payload : data,
        page : page
    })
}
export function receiveAircabOrder(data,page,filtered = false){
    return ({
        type : actions.RECEIVE_AIRCAB_ORDER,
        page : page,
        payload : data,
        filtered : filtered
    })
}
export function selectFilter(){
    return ({
        type : actions.SELECT_FILTER,
        payload : false
    })
}