
import React, { Component,PureComponent } from 'react';
import './styles.scss';
import Loader from '../../components/Loader'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13
class TableCaptions extends Component {
    constructor(props){
        super(props);
        this.state = {
            params : ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    timer = null
    handleChange(e){

       this.setState({
           params : e.target.value
       })
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
    }
    handleKeyPress(e){
        if(e.key === 'Enter'){
            this.props.search(this.state.params);
        }
    }

    handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(this.timer)
            this.triggerChange()
        }
    }

    triggerChange = () => {
        const {params} = this.state;
        this.props.search(params);
    }

    render() {
        let {selectedValue, callbackSelectFun,searchable, param, meta} = this.props;
        return (
            <div className="caption-layout">
            <div className="show-entries">
                <span>show</span>
                <select value={selectedValue} onChange={(e)=> callbackSelectFun(e.target.value)}>
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                </select>               
            </div>
            {
                (this.props.isFetching || this.props.isSearching) &&
                    <Loader />
            }
            
            <div className="search-entries">
                <input
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.params} className="search"
                    onKeyPress={ this.handleKeyPress}
                    onKeyDown={this.handleKeyDown}
                    placeholder={`Search by ${searchable.toString()}`}
                />
            </div>
            </div>
        );
    }
}

export default TableCaptions;