import {switchMap, map, catchError} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import * as fileDownload from 'js-file-download';
import { API_URL } from '../constants/credentials';
import axios from 'axios';
import queryString from 'query-string';
import { pushAlert } from '../actions/alertActions';
import {receiveFuelTransaction,receiveStationName,receiveFuelTransactionSerchResult} from '../actions/fuelTransactionActions';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';
import {logoutUser} from "../actions/userActions";

export function loadTransaction(action$){
  return action$.pipe(
    ofType(actions.REQUEST_FUEL_TRANSACTION),
    switchMap((payload)=>{
    return ajax.getJSON(`${API_URL}/manage/api/fuel_managers/fuel_transactions?per_page=${payload.limit}&page=${payload.page}`,
        {
          'Authorization': payload.user.sso_token
        })     
         .pipe(
           map((response)=>{ return receiveFuelTransaction(response,payload.page)}),
           catchError(error=>{
               if (error.status === 401) {
                   const errorData = {
                       message: "session expired: please try again "
                   }
                   return of(
                       pushError(errorData),
                       logoutUser()
                   )
               } else {
                   if(error.response) {
                       return of(pushError(error.response.errors))
                   } else {
                       return of(pushError(error))
                   }
               }
          })
         )
    })
  );  
}


export function getFuelStation(action$){
  return action$.pipe(
    ofType(actions.REQUEST_FILTER_STATION_NAME),
    switchMap((payload)=>{
      return ajax.getJSON(`${API_URL}/manage/api/fuel_stations/names_collection?q=${payload.query}`,
        {
          'Authorization': payload.user.sso_token
        })     
         .pipe(
           map((response)=>{
              return receiveStationName(response);  
           }),
           catchError(error=>{
            if(error.response){
              return of(pushError(error.response.errors))
            }else{
               return of(pushError(error))  
            } 
          })
         )
    })
  )
}

export function exportCSV(action$){
   const day = new Date().getDate();
   const month = new Date().getMonth();
   const year = new Date().getFullYear();
    return action$.pipe(
          ofType(actions.EXPORT_FUEL_TRANSACTION_CSV),
          switchMap((payload)=>{
            let parameters = {};
            if(payload.query && payload.query.start){
              parameters = {...parameters,start:payload.query.start};
            }
            if(payload.query && payload.query.end){
              parameters = {...parameters,end : payload.query.end};
            }
            if(payload.query && payload.query.station){
             parameters = {...parameters,fuel_id : payload.query.station.id};
            }
            if(payload.query && payload.query.driver_id){
             parameters = {...parameters,driver_id : payload.query.driver_id};
            }
            if(payload.query && payload.query.company && payload.query.company.id!=='0'){
              parameters = {...parameters,company_id : payload.query.company.id};
            }
           return  axios.get(`${API_URL}/manage/api/fuel_managers/fuel_transactions.xlsx?${queryString.stringify(parameters)}`,{responseType:'blob',headers:{'Authorization': payload.user.sso_token}}).then((res)=>{
              fileDownload(res.data,`Fuel_Transactions_${day}${month}${year}.xlsx`);
              const data = {
                  notice : "CSV File is Downloaded"
              }
              return pushAlert(data);
            })
          })
    )
}

export function searchFuelTransaction(action$){
  return action$.pipe(
   ofType(actions.SEARCH_FUEL_TRANSACTION),
   switchMap((payload)=>{
   return ajax.getJSON(`${API_URL}/manage/api/fuel_managers/fuel_transactions?per_page=${payload.limit}&search=${payload.params}&page=${payload.page}`,
  
          {
           'Authorization': payload.user.sso_token
           })
           .pipe(
               map((response)=> receiveFuelTransactionSerchResult(response,payload.page)),
               catchError(error=>{
                   if(error.response){
                     return of(pushError(error.response.errors))
                   }else{
                      return of(pushError(error))  
                   } 
                 })
           )
      })
  ) 
}


export function filterFuelTransaction(action$){
     return action$.pipe(
       ofType(actions.FILTER_FUEL_TRANSACTION),
       switchMap((payload)=>{
         let parameters = {};
         if(payload.query && payload.query.start){
           parameters = {...parameters,start:payload.query.start};
         }
         if(payload.query && payload.query.end){
           parameters = {...parameters,end : payload.query.end};
         }
         if(payload.query && payload.query.station){
          parameters = {...parameters,fuel_id : payload.query.station.id};
         }
         if(payload.query && payload.query.driver_id){
          parameters = {...parameters,driver_id : payload.query.driver_id};
         }
         if(payload.query && payload.query.company && payload.query.company.id!=='0'){
           parameters = {...parameters,company_id : payload.query.company.id};
         }

         return ajax.getJSON(`${API_URL}/manage/api/fuel_managers/fuel_transactions?${queryString.stringify(parameters)}&page=${payload.page}&per_page=${payload.limit}`,
         {
           'Authorization': payload.user.sso_token
         })
          .pipe(
            map((response)=>{ 

              return receiveFuelTransaction(response,payload.page,true);
            }),
            catchError(error=>{
              if(error.response){
                return of(pushError(error.response.errors))
              }else{
                
                return of(pushError(error))
                
              } 
            })
          )
       })
     )
}