import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListingTables from '../../components/ListingTables';
import TableTitleCard from '../../components/TableTitleCard';
import TableCaptions from '../../components/TableCaptions';
import PaginationFooter from '../../components/PaginationFooter';
import { requestFuelPartner, searchFuelPartner } from '../../actions/fuelPartnerActions';
import { clearAlert } from '../../actions/alertActions';
import FuelParnterModal from './FuelPartnerModal';
import FuelPartnerDetail from './FuelPartnerDetail';
import './style.scss';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
class FuelPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            isOpenForm: false,
            isOpenDetail: false,
            editItem: null,
            viewItem: null,
            isShowSuccess: false,
            showEntries: 25
        }
    }
    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.alerts !== this.props.alerts && this.props.alerts.alerts!==null){
            setTimeout(() => {
                this.props.clearAlert()
            }, 3000);
        }
        if (prevProps.fuelPartnerReducer.isUpdated !== this.props.fuelPartnerReducer.isUpdated) {
            this.loadData();
        }
    }
    isFetchingData = () => this.state.isFetching;
    handleForm = () => this.setState({ isOpenForm: true })
    handleHideForm = () => this.setState({ isOpenForm: false, editItem: null })
    loadByLimit(limit) {
        const { user } = this.props;
        this.setState({ showEntries: limit });
        this.props.requestFuelPartner(user.user, 1, limit);
    }
    loadData() {
        const { user } = this.props;
        const page = 1;
        this.props.requestFuelPartner(user.user, page);
    }
    search(params) {
        const { user } = this.props;
        this.props.searchFuelPartner(user.user, 1, params, this.state.showEntries);
    }

    handleAddNew() {
        this.setState({ isOpenForm: true })
    }
    handleHideForm() {
        this.setState({ isOpenForm: false, editItem: null })
    }
    loadByPagy(page) {
        const { user } = this.props;
        this.props.requestFuelPartner(user.user, page, this.state.showEntries);
    }
    renderForm() {
        return (
            <FuelParnterModal
                isOpen={this.state.isOpenForm}
                callbackHide={() => this.handleHideForm()}
                callbackFormSuccess={() => this.alertSuccess()}
                editItem={this.state.editItem}
            />
        )
    }
    renderTables() {
        const { fuelPartnerReducer } = this.props;
        return (
            <Row>
                <Col md="12" sm="12" xs="12">
                    <Card>
                        <CardHeader>
                            <TableTitleCard
                                title="Fuel Partners"
                                callbackAction={() => this.handleAddNew()}
                                actionName="Add Fuel Partner"
                                className='new-fuel-partner'
                            />
                        </CardHeader>
                        <CardBody>

                            <TableCaptions
                                selectedValue={this.state.showEntries}
                                callbackSelectFun={(value) => this.loadByLimit(value)}
                                searchable={['name', ' phone']}
                                search={(params) => this.search(params)}
                                isFetching={this.props.fuelPartnerReducer.isFetching}
                                isSearching={this.props.fuelPartnerReducer.isSearching}
                            />
                            <ListingTables
                                isFuelPartnerTable={true}
                                headers={[
                                    'Name',
                                    'Code',
                                    'Phone',
                                    'Balance',
                                    'Status',
                                    'Station Count',
                                    'Options'
                                ]}
                                listingData={fuelPartnerReducer.partnerList}
                                callbackEdit={(item) => { this.setState({ isOpenForm: true, editItem: item }) }}
                                callbackView={(item) => this.setState({ isOpenDetail: true, viewItem: item })}

                            />
                        </CardBody>
                        <CardFooter>
                            {
                                (this.props.fuelPartnerReducer.meta) &&
                                    <PaginationFooter 
                                        listTotal={this.props.fuelPartnerReducer.meta.total}
                                        listItem={this.props.fuelPartnerReducer.meta.per_page}
                                        perPage={this.state.showEntries}
                                        callbackPagy={(page) => this.loadByPagy(page)}
                                    />
                            }

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }
    renderDetails() {
        return (
            <FuelPartnerDetail
                isOpen={this.state.isOpenDetail}
                hide={() => this.setState({ isOpenDetail: false })}
                viewItem={this.state.viewItem}
            />
        )
    }
    render() {
        return (
            <div className='content'>
                {this.renderTables()}
                {this.state.isOpenForm && this.renderForm()}
                {this.state.isOpenDetail && this.renderDetails()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    alerts: state.alerts,
    fuelPartnerReducer: state.fuelPartnerReducer
});
const mapDispatchToProps = dispatch => {
    return {
        clearAlert: () => dispatch(clearAlert()),
        requestFuelPartner: (user, page, limit = 25) => dispatch(requestFuelPartner(user, page, limit)),
        searchFuelPartner: (user, page = 1, params, limit = 25) => dispatch(searchFuelPartner(user, page, params, limit)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelPartners);