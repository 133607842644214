import * as actions from '../constants/actionTypes';
const INITIAL_STATE={
    coporateList : [],
    isFetching : false,
    isSearching : false,
    companyNameList:[],
    corporateFerryList:[],
    ferryRoute: [],
    updateRoute: [],
    isUpdated:false,
    meta:null,
    page: 1,
    limit: 25
}

function coporateReducer(state=INITIAL_STATE,action){
  switch(action.type){
      case actions.REQUEST_COPORATE_DATA : 
        return Object.assign({},state,{
                isFetching : true,
                limit : action.limit
        });
      case actions.RECEIVE_COPORATE_DATA_RESULT : 
        return Object.assign({},state,{
          coporateList: action.payload.data,
          meta : action.payload.meta,
          isFetching:false,
          isUpdated : false,
          page: action.page
        });
      case actions.LOAD_COMPANY_NAME_LIST : 
        return Object.assign({},state,{
          isFetching : true
        });
      case actions.RECEIVE_COMPANY_NAME_LIST : 
        return Object.assign({},state,{
          companyNameList: action.payload,
          isFetching:false
        });

      case actions.REQUEST_COMPANY_FERRY_LIST :
          return Object.assign({}, state, {
              isFetching : true
          });

      case actions.RECEIVE_COMPANY_FERRY_LIST :
          return Object.assign({}, state,{
              corporateFerryList: action.payload.data,
              isFetching:false
          });
      case actions.ADD_FERRY_ROUTE :
          const route = [];
          const multiRoute = [];
          route.push(action.payload)
          multiRoute.push(route)
          if (action.payload.length > 0) {
              return Object.assign({}, state, {
                  ferryRoute: [...state.ferryRoute, ...multiRoute]
              })
          } else {
              return Object.assign({}, state, {
                  ferryRoute: [...state.ferryRoute]
              })
          }

      case actions.UPDATE_ROUTE :
          state.ferryRoute = action.payload;
          if (action.payload.length > 0) {
              return Object.assign({}, state, {
                  ferryRoute: [...state.ferryRoute]
              })
          } else {
              return Object.assign({}, state, {
                  ferryRoute: [...state.ferryRoute]
              })
          }

      case actions.REMOVE_FERRY_ROUTE :
          const array = [...state.ferryRoute]; // make a separate copy of the array
          const index = array.indexOf(action.payload);
          if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
              array.splice(index, 1);
          }

          return Object.assign({}, state, {
              ferryRoute: [...array]
          })
      case actions.CLEAR_FERRY_ROUTE :
          return Object.assign({}, state, {
              ferryRoute: []
          })
      case actions.SEARCH_CORPORATE_DATA : 
         return Object.assign({},state,{
           isSearching: true,
         });

      case actions.REQUEST_CSV :
          return Object.assign({}, state, {
              isFetching : true
          });

      case actions.DOWNLOAD_QR :
          return Object.assign({}, state, {
              isFetching: true,
          })
      case actions.PUSH_ALERT:
         if (action.updatedData === "corporate")
            return Object.assign({}, state, { isUpdated: true })
         else
            return state     

      case actions.RECEIVE_SEARCH_CORPORATE_DATA : 
         return Object.assign({},state,{
           coporateList : action.payload.data,
           meta : action.payload.meta,
           isSearching : false,
           page : action.page
         })                                                                                                 
      default : return state;                                             
  }
}
export default coporateReducer;