import * as types from '../constants/actionTypes';

const   INITIAL_STATE= {
    is_fetching:true
}
function fetchingReducer (state=INITIAL_STATE,action){
    switch(action.types){
        case types.FETCHING_DATA : return Object.assign({}, state, {
            is_fetching : true
        });
        case types.COMPLETE_FETCHING :return Object.assign({}, state, {
            is_fetching : false
        });
        default : return state
    }
}

export default fetchingReducer;