import React from 'react';
import { connect } from 'react-redux';
import {    
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap'
import CabForm from './CabForm';

import { loadData } from '../../actions/formActions';
import { loadCabFormData } from '../../actions/operatorActions';

class FormModal extends React.Component {        
    componentDidMount(){
        const { editItem } = this.props;
        
        if(editItem != null){            
            const data = {
                cap_id: editItem.id,
                corporate_company_id: editItem.corporate_company_id
            }
            this.props.loadCabFormData(data)
        }
    }        
    render(){
        return (
            <div>                
                <Modal 
                    isOpen={this.props.isOpen} 
                    toggle={()=> this.props.callbackHide()}                 
                    size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Assign To Company</ModalHeader>                    
                    <ModalBody>
                        <CabForm 
                            callbackFormSuccess={this.props.callbackFormSuccess}
                            callbackHide={this.props.callbackHide}
                            editItem={this.props.editItem}                            
                        />                     
                    </ModalBody>                    
                </Modal>
            </div>
        );    
    }    
}

const mapDispatchToProps = dispatch => {
    return{                            
        loadData      : (data)=> dispatch(loadData(data)),
        loadCabFormData : (data)=> dispatch(loadCabFormData(data))
    }
}

export default connect(null, mapDispatchToProps)(FormModal);