/* eslint-disable no-undef */
import React from "react";
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} = require("react-google-maps");


const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBEH6CtSIDi5YsTGfcHebGykudRsi_PJIc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const tempArr = [...this.props.ferry_route];
      tempArr.shift();
      tempArr.pop();
      const routesCopy = tempArr.map(route => {
        return {
          location: { lat: route.lat, lng: route.lng },
          stopover: true
        };
      });  
      const waypoints = routesCopy;
      const originRoutelat = this.props.ferry_route[0].lat;
      const originRoutelng =  this.props.ferry_route[0].lng;
      const destinationRoutelat = this.props.ferry_route[this.props.ferry_route.length-1].lat;
      const destinationRoutelng = this.props.ferry_route[this.props.ferry_route.length-1].lng;
      const DirectionsService = new google.maps.DirectionsService();
      DirectionsService.route({
        origin: new google.maps.LatLng(originRoutelat, originRoutelng),
        destination: new google.maps.LatLng(destinationRoutelat, destinationRoutelng),
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(props =>
  <GoogleMap defaultZoom={15} defaultCenter={new google.maps.LatLng(props.ferry_route[0].lat, props.ferry_route[0].lng)} >
    {props.directions && <DirectionsRenderer directions={props.directions} />}
  </GoogleMap>
);
 

export default MapWithADirectionsRenderer;