import {switchMap, map, catchError} from 'rxjs/operators';
import {  ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { API_URL } from '../constants/credentials';
import * as actions from '../constants/actionTypes';
import { pushError} from '../actions/errorActions';

import {receiveloadMap,receiveCabStatus} from '../actions/aircabLocationMapAction';
import {logoutUser} from "../actions/userActions";

const authHeader= {
  'Authorization':'5280c76eaebdd9a6bbc28312ed14078d349e13346fcdc443845797708022db11',
};


export function loadAircabLocationMap(action$){
  return action$.pipe(
    ofType(actions.REQUEST_LOAD_MAP),
    switchMap((payload)=>
     ajax.getJSON(`${API_URL}/admins/aircabs_list?per_page=${payload.limit}&page=${payload.page}`,authHeader)
         .pipe(
           map((response)=>receiveloadMap(response)),
           catchError(error=>{
               if (error.status === 401) {
                   const errorData = {
                       message: "session expired: please try again"
                   }
                   return of(
                       pushError(errorData),
                       logoutUser()
                   )
               } else {
                   if(error.response){
                       return of(pushError(error.response.errors))
                   }else{
                       return of(pushError(error))
                   }
               }
          })
         ) 
    )
  )
}


export function loadAircabStatus(action$){
  return action$.pipe(
    ofType(actions.REQUEST_LOAD_MAP),
    switchMap(()=>
     ajax.getJSON(`${API_URL}/admins/aircab_count_statuses`,authHeader)
         .pipe(
           map((response)=>receiveCabStatus(response)),
           catchError(error=>{
               if (error.status === 401) {
                   const errorData = {
                       message: "session expired: please try again"
                   }
                   return of(
                       pushError(errorData),
                       logoutUser()
                   )
               } else {
                   if(error.response){
                       return of(pushError(error.response.errors))
                   }else{
                       return of(pushError(error))
                   }
               }
          })
         ) 
    )
  )
}

