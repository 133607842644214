import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {getDraggableFerryRoute} from "../../actions/cabActions";
import {connect} from "react-redux";
import {Button, Popover, PopoverBody} from "reactstrap";
import { CloseCircleOutlined } from '@ant-design/icons';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	position: "relative",
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 ${grid * 2}px 0 0`,
	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'orange',
	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'lightblue' : 'lightgrey',
	display: 'flex',
	padding: grid,
	overflow: 'auto',
});

class DragAndDrop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.getItems(),
			hover: false,
			hoverValue: '',
			hoverKey: '',
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	getItems(){
		return this.props.ferry_route && this.props.ferry_route.map((item, index) => ({
			id: `item-${index}`,
			content: `${index+1}`,
			data: item
		}))
	}

	onChangeRemoved(index){   
		this.state.items.splice(index, 1); 
		this.props.onChangeRemoved(index); 
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.items != this.state.items) {
			const newArr = this.state.items.map(obj => obj.data)
			this.props.getDraggableFerryRoute(newArr)
		}
	}

	openHover(key, value){
		const hoverKey = "Popover"+key;
		this.setState({hover: true})
		this.setState({hoverValue: value})
		this.setState({hoverKey: hoverKey})
	}

	closeHover(key, value){
		this.setState({hover: false})
		this.setState({hoverValue: ''})
		this.setState({hoverKey: ''})
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		this.setState({
			items,
		});
	}

	//TODO:: to split things out into separate components.
	render() {
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable" direction="horizontal">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
							{...provided.droppableProps}
						>
							{this.state.items.map((item, index) => (
								<Draggable key={item.id} draggableId={item.id} index={index} >
									{(provided, snapshot) => (
										<>
											<div 
												className="pop_over"
												id={"Popover"+index} 
												
												onMouseEnter={()=> this.openHover(index, item.data)}  
												onMouseLeave={()=> this.closeHover(index, item.data)}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
												)}
											>
												{item.content}
												{this.state.hover ?
													<Popover
														placement="top"
														isOpen={this.state.hover}
														target={this.state.hoverKey}
														modifiers={{preventOverflow: {boundariesElement: "viewport"}}}>
														<PopoverBody>{this.state.hoverValue}</PopoverBody>
													</Popover>:
													null
												}
												<CloseCircleOutlined
													onClick={()=>{ this.onChangeRemoved(index) }}  
													className="closeIcon" 
													title='Close' 
													style={{ position: "absolute", color: "#777777", top: -5, right: -5, display: "none" }} 
												/>
											</div> 
										</>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return{
		getDraggableFerryRoute : (payload)=> dispatch(getDraggableFerryRoute(payload)),
	}
}
export default connect(null, mapDispatchToProps)(DragAndDrop);