import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../../components/FormInput';
import { loadData } from '../../actions/formActions';
import { Button } from 'reactstrap';
import {assignDriver} from '../../actions/driverAction';

class DriverAssignForm extends Component {    
    handleAssign(){
     const { driverAssign,user} = this.props;     
     const payload ={
          corporate_driver_id : driverAssign.driverAssign.values.id,
          cab_id : Number(driverAssign.driverAssign.values.cab_id)
     }
     this.props.assignDriver(user,payload);
     this.props.callbackHide();
    }
    render() {
        return (
            <div>
                <form className="form">
                    <h4>Assign Driver to Cab</h4>
                    <FormInput
                        labelName="Driver Id"
                        name="id"
                        component="input"
                        type="text"
                    />
                    <FormInput
                        labelName="Cab Id"
                        name="cab_id"
                        placeholder="Enter Cab Id"
                        component="input"
                        type="text"
                    />
                    <Button color="primary" onClick={()=> this.handleAssign()}>Assign</Button>
                </form>
            </div>
        );
    }
}
DriverAssignForm = reduxForm({    
    form: 'driverAssign'
})(DriverAssignForm)

const mapStateToProps =state=>({
    initialValues : state.operatorReducer.driver,
    driverAssign : state.form,
    user : state.user
});
const mapDispatchToProps = dispatch => {
    return{                            
        loadData      : (data)=> dispatch(loadData(data)),
        assignDriver : (user,data)=> dispatch(assignDriver(user,data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DriverAssignForm);