export const LOAD_DATA = 'LOAD_DATA';
export const CLEAR_EDIT_DATA = 'CLEAR_EDIT_DATA';

export const LOAD_DRIVER_DATA = 'LOAD_DRIVER_DATA';

export const LOAD_CUSTOMER_DATA = 'LOAD_CUSTOMER_DATA';

export const FOUND_FORM_ERROR = 'FOUND_FORM_ERROR';

export const FETCH_DATA = 'FETCH_DATA';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const UPDATE_USER = 'UPDATE_USER'
export const PUSH_USER = 'PUSH_USER';

export const REQUEST_COPORATE_DATA ='REQUEST_COPORATE_DATA';
export const RECEIVE_COPORATE_DATA_RESULT= 'RECEIVE_COPORATE_DATA_RESULT';
export const SEARCH_CORPORATE_DATA = 'SEARCH_CORPORATE_DATA';
export const RECEIVE_SEARCH_CORPORATE_DATA = 'RECEIVE_SEARCH_CORPORATE_DATA';


export const FETCHING_DATA = 'FETCHING_DATA';
export const COMPLETE_FETCHING = 'COMPLETE_FETCHING';

export const REQUEST_CUSTOMER_DATA ='REQUST_CUSTOMER_DATA';
export const RECIEVE_CUSTOMER_DATA_RESULT='RECEIVE_CUSTOMER_DATA_RESULT';
export const ADD_NEW_CUSTOMER = 'ADD_NEW_CUSTOMER';
export const SUCCESS_ADDING_CUSTOMER = 'SUCCESS_ADDING_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDAT_CUSTOMER';
export const SUCCESS_UPDATING_CUSTOMER = 'SUCCESS_UPDATING_CUSTOMER';
export const SEARCH_CUSTOMER_DATA = 'SEARCH_CUSTOMER_DATA';
export const SEARCH_CUSTOMER_ATTENDANCE = 'SEARCH_CUSTOMER_ATTENDANCE';
export const RECEIVE_CUSTOMER_ATTENDANCE = 'RECEIVE_CUSTOMER_ATTENDANCE';
export const RECEIVE_SEARCH_CUSTOMER_DATA = 'RECEIVE_SEARCH_CUSTOMER_DATA';
export const CHANGE_TO_NORMAL_CUSTOMER = 'CHANGE_TO_NORMAL_CUSTOMER';

export const REQUEST_DRIVER_DATA = 'REQUEST_DRIVER_DATA';
export const RECEIVE_DRIVER_DATA_RESULT = 'RECEIVE_DRIVER_DATA_RESULT';
export const ADD_NEW_DRIVER = 'ADD_NEW_DRIVER';
export const SUCCESS_ADDING_DRIVER = 'SUCCESS_ADDING_DRIVER';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const SUCCESS_UPDATING_DRIVER = 'SUCCESS_UPDATING_DRIVER';
export const ASSIGN_DRIVER_TO_CAB = 'ASSIGN_DRIVER_TO_CAB';
export const SEARCH_DRIVER_DATA =' SEARCH_DRIVER_DATA';
export const RECEIVE_DRIVER_SEARCH_RESULT = 'RECEIVE_DRIVER_SEARCH_RESULT';
export const RECEIVE_FUEl_STATION_SEARCH_RESULT = 'RECEIVE_FUEl_STATION_SEARCH_RESULT';


export const REQUEST_MANAGER_DATA = 'REQUEST_MANAGER_DATA';
export const SEARCH_MANAGER_DATA = 'SEARCH_MANAGER_DATA';
export const RECEIVE_SEARCH_MANAGER_DATA='RECEIVE_SEARCH_MANAGER_DATA';
export const RECEIVE_MANAGER_DATA_RESULT = 'RECEIVE_MANAGER_DATA_RESULT';
export const CREATE_MANAGER = 'CREATE_MANAGER';
export const UPDATE_MANAGER = 'UPDATE_MANAGER'
export const LOAD_MANAGER = 'LOAD_MANAGER'

export const PUSH_ERROR = 'PUSH_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const LOAD_OPERATOR_DATA = 'LOAD_OPERATOR_DATA';

export const ADD_OPERATOR = 'ADD_OPERATOR';
export const UPDATE_OPERATOR = 'UPDATE_OPERATOR';

export const PUSH_ALERT = 'PUSH_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const LOAD_COMPANY_NAME_LIST = 'LOAD_COMPANY_NAME_LIST';
export const RECEIVE_COMPANY_NAME_LIST = 'RECEIVE_COMPANY_NAME_LIST';

export const ADD_CAB_AMOUNT = 'ADD_CAB_AMOUNT';
export const UPDATE_CAB_AMOUNT = 'UPDATE_CAB_AMOUNT';
export const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
export const CLEAR_AMOUNT = 'CLEAR_AMOUNT';
export const LOAD_CABS_DATA = 'LOAD_CABS_DATA';
export const RECEIVE_CABS_DATA = 'RECEIVE_CABS_DATA';
export const LOAD_CABS_FORM_DATA = 'LOAD_CABS_FORM_DATA';
export const ASSIGN_CAB_COPERATE = 'ASSIGN_CAB_COPERATE';
export const LOAD_CAB_ASSIGN_DATA = 'LOAD_CAB_ASSIGN_DATA';
export const ASSIGN_CAB_TO_DRIVER = 'ASSIGN_CAB_TO_DRIVER';
export const RECEIVE_ASSIGN_CAB_TO_DRIVER_RESPONSE = 'RECEIVE_ASSIGN_CAB_TO_DRIVER_RESPONSE';
export const REMOVE_ASSIGN_DRIVER = 'REMOVE_ASSIGN_DRIVER';
export const REMOVE_ASSIGN_COMPANY = 'REMOVE_ASSIGN_COMPANY';
export const SEARCH_CAB_DATA = 'SEARCH_CAB_DATA';
export const RECEIVE_SEARCH_CAB_DATA='RECEIVE_SEARCH_CAB_DATA';
export const UPDATE_FERRY_ROUTE =  'UPDATE_FERRY_ROUTE'
export const REMOVE_DRAGGABLE_FERRY_ROUTE =  'REMOVE_FERRY_ROUTE'

export const REQUEST_FUEL_TRANSACTION = 'REQUEST_FUEL_TRANSACTION';
export const RECEIVE_FUEL_TRANSACTION = 'RECEIVE_FUEL_TRANSACTION';
export const FILTER_FUEL_TRANSACTION ='FILTER_TRANSACTION';
export const SEARCH_FUEL_TRANSACTION = 'SEARCH_FUEL_TRANSACTION';
export const RECEIVE_FUEL_TRANSACTION_SEARCH_RESULT = 'RECEIVE_FUEL_TRANSACTION_SEARCH_RESULT';

export const REQUEST_FILTER_COMPANY_NAME = 'REQUEST_FILTER_COMPANY_NAME';
export const RECEIVE_FILTER_COMPANY_NAME = 'RECEIVE_FILTER_COMPANY_NAME';
export const REQUEST_FILTER_STATION_NAME = 'REQUEST_FILTER_STATION_NAME';
export const RECEIVE_FILTER_STATION_NAME = 'RECEIVE_FILTER_STATION_NAME';
export const SEARCH_FUEL_STATION = 'SEARCH_FUEL_STATION';

export const REQUEST_FUEL_STATION = 'REQUEST_FUEL_STATION';
export const RECEIVE_FUEL_STATION = 'RECEIVE_FUEL_STATION';
export const ADD_NEW_STATION = 'ADD_NEW_STATION';
export const UPDATE_FUEL_STATION = 'UPDATE_FUEL_STATION';
export const LOAD_FUEL_STATION_DATA = 'LOAD_FUEL_STATION_DATA';
export const EXPORT_FUEL_TRANSACTION_CSV = 'EXPORT_FUEL_TRANSACTION_CSV';

export const SELECT_FILTER = 'SELECT_FILTER';
export const REQUEST_FUEL_STATION_PARTNER = 'REQUEST_FUEL_STATION_PARTNER';
export const RECEIVE_FUEL_STATION_PARTNER = 'RECEIVE_FUEL_STATION_PARTNER';

export const REQUEST_AIRCAB_ORDER = 'REQUEST_AIRCAB_ORDER';
export const FILTER_AIRCAB_ORDER = 'FILTER_AIRCAB_ORDER';
export const EXPORT_AIRCAB_ORDER_CSV = 'EXPORT_AIRCAB_ORDER_CSV';
export const RECEIVE_AIRCAB_ORDER = 'RECEIVE_AIRCAB_ORDER';
export const SEARCH_AIRCAB_ORDER = 'SEARCH_AIRCAB_ORDER';
export const RECEIVE_AIRCAB_ORDER_SEARCH_RESULT = 'RECEIVE_AIRCAB_ORDER_SEARCH_RESULT';

export const REQUEST_FUEL_PARTNER = 'REQUEST_FUEL_PARTNER';
export const RECEIVE_FUEL_PARTNER = 'RECEIVE_FUEL_PARTNER';
export const ADD_NEW_FUEL_PARTNER = 'ADD_NEW_FUEL_PARTNER';
export const UPDATE_FUEL_PARTNER = 'UPDATE_FUEL_PARTNER';
export const LOAD_FUEL_PARTNER_DATA = 'LOAD_FUEL_PARTNER_DATA';
export const SEARCH_FUEL_PARTNER = 'SEARCH_FUEL_PARTNER';
export const RECEIVE_FUEL_PARTNER_SEARCH_RESULT = 'RECEIVE_FUEL_PARTNER_SEARCH_RESULT';


export const REQUEST_AGENT_TRANSACTION = 'REQUEST_AGENT_TRANSACTION';
export const RECEIVE_AGENT_TRANSACTION = 'RECEIVE_AGENT_TRANSACTION';
export const FILTER_AGENT_TRANSACTION = 'FILTER_AGENT_TRANSACTION';
export const EXPORT_AGENT_TRANSACTION_CSV = "EXPORT_AGENT_TRANSACTION_CSV";


export const REQUEST_AGENT = 'REQUEST_AGENT';
export const ADD_NEW_AGENT = 'ADD_NEW_AGENT';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const RECEIVE_AGENT_DATA = 'RECEIVE_AGENT_DATA';
export const LOAD_AGENT_DATA = 'LOAD_AGENT_DATA';
export const SEARCH_AGENT = 'SEARCH_AGENT';
export const RECEIVE_SEARCH_AGENT_DATA = 'RECEIVE_SEARCH_AGENT_DATA'

export const REQUEST_LOAD_MAP = 'REQUEST_LOAD_MAP';
export const RECEIVE_LOAD_MAP = 'RECEIVE_LOAD_MAP';
export const REQUEST_CAB_STATUS ='REQUEST_CAB_STATUS';
export const RECEIVE_CAB_STATUS ='RECEIVE_CAB_STATUS';


//ferry
export const REQUEST_COMPANY_FERRY_LIST = 'REQUEST_COMPANY_FERRY_LIST';
export const RECEIVE_COMPANY_FERRY_LIST = 'RECEIVE_COMPANY_FERRY_LIST';
export const ADD_FERRY_ROUTE = 'ADD_FERRY_ROUTE';
export const REMOVE_FERRY_ROUTE = 'REMOVE_FERRY_ROUTE';
export const CLEAR_FERRY_ROUTE = 'CLEAR_FERRY_ROUTE';
export const REQUEST_CSV = 'REQUEST_CSV'
export const REQUEST_FERRY_CUSTOMER_TEMPLATE_DATA = 'REQUEST_FERRY_CUSTOMER_TEMPLATE_DATA'
export const RECEIVE_FERRY_CUSTOMER_TEMPLATE_DATA = 'RECEIVE_FERRY_CUSTOMER_TEMPLATE_DATA'
export const DOWNLOAD_QR = 'DOWNLOAD_QR'
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const EDIT_ROUTE = 'EDIT_ROUTE';


//csv upload
export const UPLOAD_CSV = 'UPLOAD_CSV'
export const UPLOAD_CSV_RESPONSE = 'UPLOAD_CSV_RESPONSE'
export const UPLOAD_CSV_ERROR = 'UPLOAD_CSV_ERROR'
export const ADD_CSV = 'ADD_CSV'
export const DISPLAY_CSV_ERR = 'DISPLAY_CSV_ERR'
export const REMOVE_CSV = 'REMOVE_CSV'

// Corporate Orders
export const REQUEST_CORPORATE_ORDERS = 'REQUEST_CORPORATE_ORDERS'
export const RECEIVE_CORPORATE_ORDERS = 'RECEIVE_CORPORATE_ORDERS'
export const SEARCH_CORPORATE_ORDERS  = 'SEARCH_CORPORATE_ORDERS'
export const RECEIVE_SEARCH_CORPORATE_ORDERS = 'RECEIVE_SEARCH_CORPORATE_ORDERS'
export const REQUEST_CORPORATE_ORDERS_EXCEL = 'REQUEST_CORPORATE_ORDERS_EXCEL'

// driver hcm
export const REQUEST_DRIVER_ATTENDANCES_DATA = 'REQUEST_DRIVER_ATTENDANCES_DATA'
export const RECEIVE_DRIVER_ATTENDANCES_DATA = 'RECEIVE_DRIVER_ATTENDANCES_DATA'
export const SEARCH_DRIVER_ATTENDANCES = 'SEARCH_DRIVER_ATTENDANCES'
export const RECEIVE_SEARCH_DRIVER_ATTENDANCES = 'RECEIVE_SEARCH_DRIVER_ATTENDANCES'
export const REQUEST_DRIVER_ATTENDANCES_EXCEL = 'REQUEST_DRIVER_ATTENDANCES_EXCEL'
export const APPROVED_DRIVER_ATTENDANCE = 'APPROVED_DRIVER_ATTENDANCE'
export const REQUEST_DRIVER_ATTENDANCE_BY_ID = 'REQUEST_DRIVER_ATTENDANCE_BY_ID'
export const RECEIVE_DRIVER_ATTENDANCE_BY_ID = 'RECEIVE_DRIVER_ATTENDANCE_BY_ID'
export const REQEUST_ADDRESS_PICKUP_DRIVER = 'REQEUST_ADDRESS_PICKUP_DRIVER'
export const RECEIVE_ADDRESS_PICKUP_DRIVER = 'RECEIVE_ADDRESS_PICKUP_DRIVER'
export const REQEUST_ADDRESS_DROPOFF_DRIVER = 'REQEUST_ADDRESS_DROPOFF_DRIVER'
export const RECEIVE_ADDRESS_DROPOFF_DRIVER = 'RECEIVE_ADDRESS_DROPOFF_DRIVER'