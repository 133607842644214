import * as actions from '../constants/actionTypes';

export function requestloadMap(data){    
    return {
        type: actions.REQUEST_LOAD_MAP,
        payload: data,
    }
}

export function receiveloadMap(data){    
    return {
        type: actions.RECEIVE_LOAD_MAP,
        payload: data
    }
}

export function requestCabStatus(data,limit,page){    
    return {
        type: actions.REQUEST_CAB_STATUS,
        payload: data,
        limit :limit,
        page : page
    }
}

export function receiveCabStatus(data){    
    return {
        type: actions.RECEIVE_CAB_STATUS,
        payload: data
    }
}