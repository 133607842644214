import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter    
} from 'reactstrap';
import HistoryMapComponent from '../../components/HistoryMapComponent';

class AircabRouteModal extends React.Component {    
    componentDidMount(){
    }
    reformatedPlaces(places){
        var formtedPlaces = []
        places && places.map( item => {
            formtedPlaces.push({latitude: item[0],longitude: item[1]})
        })
        return formtedPlaces;
    }
    render(){
      
        return (
            <div> 
                             
                <Modal 
                    isOpen={this.props.isOpen} 
                    toggle={()=> this.props.callbackHide()}                 
                    size="xl"
                >
                    <ModalHeader toggle={()=> this.props.callbackHide()}>Route History</ModalHeader>
                      
                    <ModalBody>
                        <HistoryMapComponent defaultZoom={7} places={this.props.places ? this.reformatedPlaces(this.props.places) : []} />
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            </div>
            
        );    
    }    
}

const mapDispatchToProps = dispatch => {
    return{                            
        // loadCustomerData      : (data)=> dispatch(loadCustomerData(data)),
        // addNewCustomer : (data)=>dispatch(addNewCustomer(data))
    }
}

export default connect(null, mapDispatchToProps)(AircabRouteModal);